import { defineMessages } from 'react-intl';

export default defineMessages({
    download: {
        id: 'ScriptDashboard.download',
        defaultMessage: 'Скачать',
    },
    openStepDialog: {
        id: 'ScriptDashboard.openStepDialog',
        defaultMessage: 'Открыть',
    },
    noData: {
        id: 'ScriptDashboard.noData',
        defaultMessage: 'Нет данных',
    },
    notDownloaded: {
        id: 'ScriptDashboard.notDownloaded',
        defaultMessage: 'Не скачано',
    },
    noParameters: {
        id: 'ScriptDashboard.noParameters',
        defaultMessage: 'Нет параметров',
    },
    parameters: {
        id: 'ScriptDashboard.parameters',
        defaultMessage: 'Параметры',
    },
    // Columns
    scriptColumnName: {
        id: 'ScriptDashboard.scriptColumnName',
        defaultMessage: 'Скрипт',
    },
    statusColumnName: {
        id: 'ScriptDashboard.statusColumnName',
        defaultMessage: 'Статус',
    },
    stepColumnName: {
        id: 'ScriptDashboard.stepColumnName',
        defaultMessage: 'Шаг',
    },
    stepDialogColumnName: {
        id: 'ScriptDashboard.stepDialogColumnName',
        defaultMessage: 'Диалог шага',
    },
    resultColumnName: {
        id: 'ScriptDashboard.resultColumnName',
        defaultMessage: 'Результат',
    },
    logColumnName: {
        id: 'ScriptDashboard.logColumnName',
        defaultMessage: 'Лог',
    },
    startDateColumnName: {
        id: 'ScriptDashboard.startDateColumnName',
        defaultMessage: 'Дата запуска',
    },
    downloadedDateColumnName: {
        id: 'ScriptDashboard.downloadedDateColumnName',
        defaultMessage: 'Дата скачивания',
    },
    finishDateColumnName: {
        id: 'ScriptDashboard.finishDateColumnName',
        defaultMessage: 'Дата завершения',
    },
    paramNameColumnName: {
        id: 'ScriptDashboard.paramNameColumnName',
        defaultMessage: 'Имя параметра',
    },
    paramValueColumnName: {
        id: 'ScriptDashboard.paramValueColumnName',
        defaultMessage: 'Значение',
    },
    scriptExecutionPanel: {
        id: 'ScriptDashboard.scriptExecutionPanel',
        defaultMessage: 'Панель выполнения скриптов',
    },
    scriptSchedulerPanel: {
        id: 'ScriptDashboard.scriptSchedulerPanel',
        defaultMessage: 'Расписание скриптов',
    },
});
