import { IScriptNode } from '../models/bpm/bpm-model-impl.types';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { viewModelProperties } from '../actions/modelProperty.actions';
import { TREE_ITEM_CONTEXT_MENU_ACTION, TREE_ITEM_OPEN_PROPERTY_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction, TTreeItemOpenPropertyAction } from '../actions/tree.actions.types';
import { ObjectPropertiesDialogActiveTab } from '../models/objectPropertiesDialog';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import {
    openEdgeDefinitionPropertyDialog,
    openFolderPropertyDialog,
    openObjectPropertyDialog,
} from './dialogProperty.saga';
import {
    AttributeType,
    DefaultId,
    GeneralModel,
    ModelType,
    Node,
    ObjectDefinitionNode,
    PresetDTO,
    ScriptNode,
    SubscriptionPrincipalInfo,
    UserDTO,
} from '../serverapi/api';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { nodeService } from '../services/NodeService';
import { TreeSelectors } from '../selectors/tree.selectors';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import {
    treeItemOpenPropertyAction,
    treeItemUmlObjectsUpdate,
    treeNodeAddSubscriptionToStore,
} from '../actions/tree.actions';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { objectDefinitionUmlUpdate } from '../actions/entities/objectDefinition.actions';
import { UML_OBJECT_TYPE } from '../mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';
import { edgeDefinitionsAdd } from '../actions/entities/edgeDefinition.actions';
import { scriptRequestSuccess } from '../actions/entities/script.actions';
import { loadNodeApprovals } from '@/actions/approval.actions';
import { TreeNode } from '@/models/tree.types';
import { getPresetList } from '../actions/methodologySetting.actions';
import { getUser } from '@/selectors/authorization.selectors';
import { SubscribedNodesIdsSelectors } from '@/selectors/subscribedNodesIds.selectors';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { CustomObjectTypesForLink, ObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import { TabsBusActions } from '@/actionsTypes/tabsBus.actionTypes';
import { AttributeTypesDaoService } from '@/services/dao/AttributeTypesDaoService';
import { ModelTypeSelectors } from '@/selectors/modelType.selectors';

type TOpenDialogProps = {
    node: Node;
    attributeTypes: AttributeType[];
    parentFolderTypeId?: string;
    presets?: PresetDTO[];
    tab?: ObjectPropertiesDialogActiveTab;
    elementId?: string;
    path: string;
};

function* handlePropertyView({ payload }: TTreeItemContextMenuAction) {
    // todo: мб можно объединить modelProperties.saga и objectProperties.saga
    const { nodeId, type, action } = payload;
    // todo: почему для вики и скриптов action == EDIT_ATTRIBUTES а для остальных типов PROPERTIES. Мб надо переделать
    if ([TreeItemContextMenuAction.PROPERTIES, TreeItemContextMenuAction.EDIT_ATTRIBUTES].includes(action)) {
        const user: UserDTO | undefined = yield select(getUser);
        const isUserSubscribedToNode: boolean = yield select(SubscribedNodesIdsSelectors.getIsSubscribedToNode(nodeId));

        if (!isUserSubscribedToNode) {
            const nodeSubscriptions: SubscriptionPrincipalInfo[] = yield TreeDaoService.getNodeSubscriptions(nodeId);

            if (nodeSubscriptions.some((subs) => subs.principalId === user?.id && !subs.fromParent)) {
                yield put(treeNodeAddSubscriptionToStore(nodeId));
            }
        }

        switch (type) {
            case TreeItemType.Repository:
            case TreeItemType.ScriptFolder:
            case TreeItemType.Script:
            case TreeItemType.Wiki:
            case TreeItemType.FileFolder:
            case TreeItemType.File:
            case TreeItemType.Spreadsheet:
            case TreeItemType.Dashboard:
            case TreeItemType.Kanban:
                yield openPropertyTreeItem(treeItemOpenPropertyAction(nodeId, type));
                break;
            case TreeItemType.Model:
            case TreeItemType.Matrix:
                yield put(viewModelProperties(nodeId));
                break;
            case TreeItemType.ObjectDefinition:
                yield openObjectPropertyDialog(nodeId, ObjectPropertiesDialogActiveTab.NameAndAttributes);
                break;
            case TreeItemType.EdgeDefinition:
                yield openEdgeDefinitionPropertyDialog(nodeId, ObjectPropertiesDialogActiveTab.NameAndAttributes);
                break;
            case TreeItemType.Folder:
                yield openFolderPropertyDialog(nodeId);
                break;
            default:
                break;
        }
    }
}

function* openPropertyTreeItem({ payload: { nodeId, type, elementIds } }: TTreeItemOpenPropertyAction) {
    const { serverId, id, repositoryId } = nodeId;

    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const node: Node | undefined = yield nodeService().loadNodeFromServer(nodeId);

    if (!node) {
        return;
    }

    // если presetId не задан значит относим заданный type к стандартной методологии. Редактировать атрибуты для этого типа можно только в стандартной методологии
    const presetId: string = (yield select(TreeSelectors.presetById(nodeId))) || DefaultId.DEFAULT_PRESET_ID;

    // todo: список доступных атрибутов берется только с бэк и не хранится в сторе
    let attributeTypes: AttributeType[] = [];
    const [attributeTypesRes, pathRes] = yield Promise.allSettled([
        server.api.attributeType.byNodeTypeId({
            presetId,
            id: type,
        }),
        TreeDaoService.getNodePath(serverId, id, repositoryId),
    ]);

    attributeTypes = attributeTypesRes.value;
    const { path } = pathRes.value;

    if (
        attributeTypes.length === 0 &&
        (type === TreeItemType.ObjectDefinition || type === TreeItemType.EdgeDefinition)
    ) {
        attributeTypes = yield call(() =>
            server.api.attributeType.byPresetId({
                presetId,
            }),
        );
    }

    if (attributeTypes.length === 0 && type === CustomObjectTypesForLink.Approval && node.type === TreeItemType.Model) {
        const modelType: ModelType | undefined = yield select(
            ModelTypeSelectors.byId({ modelTypeId: (node as GeneralModel).modelTypeId || '', serverId }, presetId),
        );
        if (modelType) {
            attributeTypes = modelType.attributes || [];
        } else {
            attributeTypes = yield AttributeTypesDaoService.getAttributeTypesByModelTypeId(
                presetId,
                (node as GeneralModel).modelTypeId || '',
            );
        }
    }

    if (node.type === TreeItemType.Script) {
        const selected: TreeNode | undefined = yield select(SelectedNodesSelector.getNode());
        const data: IScriptNode = node as IScriptNode;
        yield put(scriptRequestSuccess(serverId, data));

        if (selected?.language) {
            (node as ScriptNode).language = selected.language;
        }
    }

    const props: TOpenDialogProps = {
        node,
        attributeTypes,
        path,
    };

    if (type === ObjectTypesForLink.Approval) {
        props.tab = ObjectPropertiesDialogActiveTab.Approvals;
        props.elementId = elementIds ? elementIds[0] : '';
    }

    if (type === TreeItemType.Repository) {
        yield put(getPresetList());
    }

    if (
        node.type === TreeItemType.ObjectDefinition &&
        (node as ObjectDefinitionNode).objectTypeId === UML_OBJECT_TYPE.CLASS
    ) {
        const loadedWithChildren: ObjectDefinitionImpl[] = (yield TreeDaoService.getNodeAndChildrenList(
            serverId,
            node.nodeId,
        )).filter(({ type }) => type === TreeItemType.ObjectDefinition);
        yield put(objectDefinitionUmlUpdate({ objectDefinitions: loadedWithChildren }));
        yield put(treeItemUmlObjectsUpdate({ objectDefinitions: loadedWithChildren }));
    }

    if (node.type === TreeItemType.EdgeDefinition) {
        yield put(edgeDefinitionsAdd([node]));
    }
    yield put(loadNodeApprovals({ nodeId }));

    try {
        yield put(openDialog(DialogType.PROPERTIES_DIALOG, props));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

export function* initTreeNodeContextMenuActionsSagaDialog() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handlePropertyView);
    yield takeEvery(TREE_ITEM_OPEN_PROPERTY_ACTION, openPropertyTreeItem);
}
