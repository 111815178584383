import type { NodeViewProps } from '@tiptap/core';
import { Resizable } from 're-resizable';
import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import { useResizeHandlers } from './useResizeImage';
import theme from './imageRenderer.scss';

export const ImageRenderer = (props: NodeViewProps) => {
    const { node, updateAttributes } = props;
    const { src, width, height } = node.attrs;

    const { onResizeStart, onResizeStop, lockAspectRatio } = useResizeHandlers({ width, height, updateAttributes });

    return (
        <NodeViewWrapper>
            <Resizable
                size={{ width, height }}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                lockAspectRatio={lockAspectRatio}
            >
                <img src={src} className={theme.image} />
            </Resizable>
        </NodeViewWrapper>
    );
};
