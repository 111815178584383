// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contextMenu__dropdownContainer__LGogP{-webkit-app-region:no-drag}", "",{"version":3,"sources":["webpack://./src/modules/ContextMenu/components/contextMenu.scss"],"names":[],"mappings":"AAAA,uCACI,0BAAA","sourcesContent":[".dropdownContainer {\r\n    -webkit-app-region: no-drag;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContainer": "contextMenu__dropdownContainer__LGogP"
};
export default ___CSS_LOADER_EXPORT___;
