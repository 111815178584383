import { TreeItemContext, TreeItemContextMenuAction, TreeItemType } from '../../models/tree';
import * as ContextMenuItems from '../../ContextMenu/contextMenuItems';
import { TTreeContextMenuItem } from '../../Tree.types';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';
import { ContextMenuItemDivider, ItemSubscribe, ItemUnsubscribe } from '../../ContextMenu/contextMenuItems';

export const contextMenuItemsByTreeItemType = {
    [TreeItemType.Server]: {
        [TreeItemContext.Default]: ContextMenuItems.serverActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Repository]: {
        [TreeItemContext.Default]: ContextMenuItems.DBActions,
        [TreeItemContext.CreateDecompositionDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemContext.CreateModelDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Folder]: {
        [TreeItemContext.Default]: ContextMenuItems.FolderActions,
        [TreeItemContext.CreateDecompositionDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemContext.CreateModelDialog]: [ContextMenuItems.ItemCreateFolder],
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.ObjectDefinition]: {
        [TreeItemContext.Default]: ContextMenuItems.ObjectActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Model]: {
        [TreeItemContext.Default]: ContextMenuItems.ModelActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
        [TreeItemContext.TwoModelsSelected]: ContextMenuItems.CompareModelsActions,
    },
    [TreeItemType.ScriptFolder]: {
        [TreeItemContext.Default]: ContextMenuItems.ScriptFolderActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Script]: {
        [TreeItemContext.Default]: ContextMenuItems.ScriptActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Wiki]: {
        [TreeItemContext.Default]: ContextMenuItems.WikiActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Matrix]: {
        [TreeItemContext.Default]: ContextMenuItems.MatrixActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.SimulationModeling]: {
        [TreeItemContext.Default]: ContextMenuItems.SimulationModelingActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.FileFolder]: {
        [TreeItemContext.Default]: ContextMenuItems.FileFolderActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.File]: {
        [TreeItemContext.Default]: ContextMenuItems.FileActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Spreadsheet]: {
        [TreeItemContext.Default]: ContextMenuItems.SpreadsheetActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Kanban]: {
        [TreeItemContext.Default]: ContextMenuItems.KanbanActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.EdgeDefinition]: {
        [TreeItemContext.Default]: ContextMenuItems.EdgeActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.Dashboard]: {
        [TreeItemContext.Default]: ContextMenuItems.DashboardActions,
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
    [TreeItemType.AdminTool]: {
        [TreeItemContext.CreateScriptDialog]: ContextMenuItems.ScriptDialogActions,
    },
};

export const getMenuItems = (
    itemType: TreeItemType,
    context: TreeItemContext,
    isOpenInDialog: boolean,
    isShowDeletedObjectsAccessible?: boolean,
): TTreeContextMenuItem[] => {
    let menuItems = contextMenuItemsByTreeItemType[itemType]?.[context] || [];
    if (TreeItemType.Repository === itemType && !isOpenInDialog && ExperimentalFeatures.isEnable()) {
        menuItems =
            context === TreeItemContext.CreateScriptDialog
                ? menuItems
                : [...menuItems, ContextMenuItemDivider, ItemSubscribe, ItemUnsubscribe];
    }

    if (
        TreeItemType.Repository === itemType &&
        context === TreeItemContext.Default &&
        isShowDeletedObjectsAccessible &&
        !isOpenInDialog
    ) {
        const dbSearchIndex = menuItems.findIndex((item) => item?.titleAction === TreeItemContextMenuAction.DB_SEARCH);
        menuItems = [...menuItems];
        menuItems?.splice(dbSearchIndex + 1, 0, ContextMenuItems.ItemDbSearchDeletedElements);
    }

    return menuItems;
};
