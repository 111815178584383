// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RichTextEditor-component__editorContainer___h51e{height:100%;width:100%;position:relative;-webkit-transform-origin:0 0;transform-origin:0 0;overflow-x:hidden}.RichTextEditor-component__editor__agi9z{position:relative;overflow:hidden;z-index:20;line-height:1;outline:none;border-left:1px solid #dfe2e5;font-family:Roboto;-webkit-transform-origin:0 0;transform-origin:0 0}.RichTextEditor-component__editor__agi9z>div{height:100%}.RichTextEditor-component__editor__agi9z .public-DraftEditor-content{padding:20px 30px}.RichTextEditor-component__resizer__HFU_9{border-left:3px solid rgba(255,255,255,0);border-right:3px solid rgba(255,255,255,0)}.RichTextEditor-component__hideResizer___i3_R{display:none}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/RichTextEditor/Editor/RichTextEditor.component.scss"],"names":[],"mappings":"AAAA,kDACI,WAAA,CACA,UAAA,CACA,iBAAA,CACA,4BAAA,CAAA,oBAAA,CACA,iBAAA,CAGJ,yCACI,iBAAA,CACA,eAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,4BAAA,CAAA,oBAAA,CACA,6CACI,WAAA,CAIA,qEACI,iBAAA,CAKZ,0CACI,yCAAA,CACA,0CAAA,CAEJ,8CACI,YAAA","sourcesContent":[".editorContainer {\r\n    height: 100%;\r\n    width:  100%;\r\n    position: relative;\r\n    transform-origin: 0 0; \r\n    overflow-x: hidden;\r\n}\r\n\r\n.editor{\r\n    position: relative;\r\n    overflow: hidden;\r\n    z-index: 20;\r\n    line-height: 1;\r\n    outline: none;\r\n    border-left: 1px solid #dfe2e5;\r\n    font-family: Roboto;\r\n    transform-origin: 0 0;\r\n    & > div {\r\n        height: 100%;;\r\n    }\r\n\r\n    :global{\r\n        .public-DraftEditor-content{\r\n            padding:20px 30px;\r\n        }\r\n    }\r\n}\r\n\r\n.resizer {\r\n    border-left: 3px solid rgba(255, 255, 255, 0);\r\n    border-right: 3px solid rgba(255, 255, 255, 0);\r\n}\r\n.hideResizer {\r\n    display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editorContainer": "RichTextEditor-component__editorContainer___h51e",
	"editor": "RichTextEditor-component__editor__agi9z",
	"resizer": "RichTextEditor-component__resizer__HFU_9",
	"hideResizer": "RichTextEditor-component__hideResizer___i3_R"
};
export default ___CSS_LOADER_EXPORT___;
