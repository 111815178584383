import type { TRootState } from '../reducers/root.reducer.types';
import type { NodeId } from '../serverapi/api';
import { createSelector } from 'reselect';
import { TreeItemType } from '../modules/Tree/models/tree';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';

const getState = (state: TRootState) => state.selectedNodes;

export namespace SelectedNodesSelector {
    export const getNode = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector(getState, (state) => state[treeName]?.selectedNode);

    export const getNodes = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector(getState, (state) => state[treeName]?.selectedNodes || []);

    export const isNodeSelected = (nodeId?: NodeId, treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector(
            getState,
            (state) => !!state[treeName]?.selectedNodes.some((node) => compareNodeIds(node.nodeId, nodeId)),
        );

    export const moreThanOneSelected = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector(getNodes(treeName), (selectedNodes) => selectedNodes?.length > 1);

    export const twoModelsSelected = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector(
            getNodes(treeName),
            (selectedNodes) =>
                selectedNodes?.length === 2 && !selectedNodes?.some((treeNode) => treeNode.type !== TreeItemType.Model),
        );
}
