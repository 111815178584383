// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wrapper__container___2kIp{height:64px;border-top:1px solid var(--Border-SecondaryNeutral200-UIKit);width:100%}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/DialogWrapper/Wrapper.scss"],"names":[],"mappings":"AAAA,2BACI,WAAA,CACA,4DAAA,CACA,UAAA","sourcesContent":[".container {\r\n    height: 64px;\r\n    border-top: 1px solid var(--Border-SecondaryNeutral200-UIKit);\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Wrapper__container___2kIp"
};
export default ___CSS_LOADER_EXPORT___;
