import { TreeDataNode } from 'antd';
import { compareNodeIds } from '../../src/utils/nodeId.utils';
import { TPreset } from '../models/preset.types';
import {
    CTreeNodeKey,
    ISymbolWithModelId,
    scriptContextTypes,
    TItemName,
    TModelSymbolNames,
} from '../modules/ObjectPropertiesDialog/components/ScriptContext/scriptContext.types';
import { TRootState } from '../reducers/root.reducer.types';
import {
    AllowedScriptContext,
    AllowedScriptModelSymbols,
    InternationalString,
    NodeId,
    EdgeType,
    ModelType,
    ObjectType,
    FolderType,
    Symbol,
    Node,
} from '../serverapi/api';
import {
    getNodesTreeData,
    ObjectTypesToTreeDataChildren,
    treeDataWithRoots,
} from '../services/bll/scriptContextBLLService';
import { EdgeTypeSelectors } from './edgeType.selectors';
import { EdgeTypeGroupSelectors } from './edgeTypeGroup.selectors';
import { ServerSelectors } from './entities/server.selectors';
import { EventsAccessSelectors } from './eventsAccess.selectors';
import { FolderTypeSelectors } from './folderType.selectors';
import { ModelTypeSelectors } from './modelType.selectors';
import { ModelTypeGroupSelectors } from './modelTypeGroup.selectors';
import { ObjectTypeSelectors } from './objectType.selectors';
import { ObjectTypeGroupSelectors } from './objectTypeGroup.selectors';
import { PresetSelectors } from './preset.selectors';
import { ScriptSelectors } from './script.selectors';
import { SymbolSelectors } from './symbol.selectors';
import { TreeSelectors } from './tree.selectors';
import { createSelector } from 'reselect';
import { TScriptContextState } from '../reducers/scriptContext.reducer.types';

const getState = (state: TRootState) => state.scriptContext;

export const scriptContextLoadingStatus = createSelector<TRootState, TScriptContextState, boolean>(
    getState,
    (state) => state.isLoading,
);

export namespace scriptContextTreeDataSelectors {
    export const getObjectTypesTreeData = (serverId: string, allPresets: TPreset[]) => {
        return (state: TRootState): TreeDataNode[] => {
            return allPresets.map((preset) => {
                const objectTypesGroups = ObjectTypeGroupSelectors.byPresetId({ serverId, presetId: preset.id })(state);
                const objectTypes = ObjectTypeSelectors.listAllByPreset(serverId, preset.id)(state);
                const children = ObjectTypesToTreeDataChildren(
                    objectTypesGroups,
                    objectTypes,
                    scriptContextTypes.runningOnObjects,
                );

                return treeDataWithRoots(preset, children);
            });
        };
    };

    export const getModelTypesTreeData = (serverId: string, allPresets: TPreset[]) => {
        return (state: TRootState): TreeDataNode[] => {
            return allPresets.map((preset) => {
                const modelTypesGroups = ModelTypeGroupSelectors.byPresetId({ serverId, presetId: preset.id })(state);
                const modelTypes = ModelTypeSelectors.byServerIdPresetIdArr(serverId, preset.id)(state);
                const children = ObjectTypesToTreeDataChildren(
                    modelTypesGroups,
                    modelTypes,
                    scriptContextTypes.runningOnModels,
                );

                return treeDataWithRoots(preset, children);
            });
        };
    };

    export const getEdgesTypesTreeData = (serverId: string, allPresets: TPreset[]) => {
        return (state: TRootState): TreeDataNode[] => {
            return allPresets.map((preset) => {
                const EdgesTypesGroups = EdgeTypeGroupSelectors.byPresetId({ serverId, presetId: preset.id })(state);
                const edgesTypes = EdgeTypeSelectors.listByPresetId(serverId, preset.id)(state);
                const children = ObjectTypesToTreeDataChildren(
                    EdgesTypesGroups,
                    edgesTypes,
                    scriptContextTypes.runningOnEdges,
                );

                return treeDataWithRoots(preset, children);
            });
        };
    };

    export const getFolderTypesTreeData = (serverId: string, allPresets: TPreset[]) => {
        return (state: TRootState): TreeDataNode[] => {
            return allPresets.map((preset) => {
                const foldersTypes = FolderTypeSelectors.listWithDefaultFolderTypeByPresetId({
                    serverId,
                    presetId: preset.id,
                })(state);
                const children = ObjectTypesToTreeDataChildren(
                    undefined,
                    foldersTypes,
                    scriptContextTypes.runningOnFolders,
                );

                return treeDataWithRoots(preset, children);
            });
        };
    };

    export const getObjectInstancesTypesTreeData = (serverId: string, allPresets: TPreset[]) => {
        return (state: TRootState): TreeDataNode[] => {
            return allPresets.map((preset) => {
                const objectTypesGroups = ObjectTypeGroupSelectors.byPresetId({ serverId, presetId: preset.id })(state);
                const objectTypes = ObjectTypeSelectors.listAllByPreset(serverId, preset.id)(state);
                const symbolsTypes = SymbolSelectors.byServerIdPresetId(serverId, preset.id)(state);
                const children = ObjectTypesToTreeDataChildren(
                    objectTypesGroups,
                    objectTypes,
                    scriptContextTypes.runningOnObjects,
                    symbolsTypes,
                );

                return treeDataWithRoots(preset, children);
            });
        };
    };

    export const getObjectInstancesTypesByModelsTreeData = (serverId: string, allPresets: TPreset[]) => {
        return (state: TRootState): TreeDataNode[] => {
            return allPresets.map((preset) => {
                const modelTypesGroups = ModelTypeGroupSelectors.byPresetId({ serverId, presetId: preset.id })(state);
                const modelTypes = ModelTypeSelectors.byServerIdPresetIdArr(serverId, preset.id)(state);
                const symbolsTypes: ISymbolWithModelId[] = modelTypes
                    .map((modelType) => {
                        return modelType.symbols.map((symbol) => {
                            return { ...symbol, modelTypeId: modelType.id };
                        });
                    })
                    .flat(1);
                const modelTypesWithSymbols = modelTypes.filter((modelType) =>
                    symbolsTypes.map((symbolsType) => symbolsType.modelTypeId).includes(modelType.id),
                );
                const children = ObjectTypesToTreeDataChildren(
                    modelTypesGroups,
                    modelTypesWithSymbols,
                    scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes,
                );

                return treeDataWithRoots(preset, children);
            });
        };
    };

    export const getNodesTreedata = (showModels: boolean, showObjects: boolean) => {
        return (state: TRootState): TreeDataNode[] => {
            const servers = TreeSelectors.treeStructure(state);

            return getNodesTreeData(servers, showModels, showObjects);
        };
    };
    export const getTreeDataByContextType = (contextType: scriptContextTypes, presets) => {
        return (state: TRootState): TreeDataNode[] => {
            const serverId: string = ServerSelectors.serverList(state)[0].id;
            switch (contextType) {
                case scriptContextTypes.runningOnObjects: {
                    return scriptContextTreeDataSelectors.getObjectTypesTreeData(serverId, presets)(state);
                }
                case scriptContextTypes.runningOnModels: {
                    return scriptContextTreeDataSelectors.getModelTypesTreeData(serverId, presets)(state);
                }
                case scriptContextTypes.runningOnEdges: {
                    return scriptContextTreeDataSelectors.getEdgesTypesTreeData(serverId, presets)(state);
                }
                case scriptContextTypes.runningOnFolders: {
                    return scriptContextTreeDataSelectors.getFolderTypesTreeData(serverId, presets)(state);
                }
                case scriptContextTypes.runningOnObjectInstances: {
                    return scriptContextTreeDataSelectors.getObjectInstancesTypesTreeData(serverId, presets)(state);
                }
                case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
                    return scriptContextTreeDataSelectors.getObjectInstancesTypesByModelsTreeData(
                        serverId,
                        presets,
                    )(state);
                }
                default:
                    return [];
            }
        };
    };
}

export namespace scriptContextTreeDataKeys {
    export const getDefaultCheckedKeys = (type: scriptContextTypes, allKeys: string[], scriptId: NodeId) => {
        return (state: TRootState): string[] => {
            const context: AllowedScriptContext = ScriptSelectors.context(scriptId)(state);

            let keys: string[] = [];
            switch (type) {
                case scriptContextTypes.runningOnObjects: {
                    keys = context.allowedObjectTypeIds || [];
                    break;
                }
                case scriptContextTypes.runningOnModels: {
                    keys = context.allowedModelTypeIds || [];
                    break;
                }
                case scriptContextTypes.runningOnEdges: {
                    keys = context.allowedEdgeTypeIds || [];
                    break;
                }
                case scriptContextTypes.runningOnFolders: {
                    keys = context.allowedFolderTypeIds || [];
                    break;
                }
                case scriptContextTypes.runningOnObjectInstances: {
                    keys = context.allowedSymbolTypeIds || [];
                    break;
                }
                case scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes: {
                    keys =
                        context.allowedModelSymbols?.flatMap((modelSymbol) =>
                            modelSymbol.symbolIds.map((symbolId) => {
                                return new CTreeNodeKey({
                                    modelTypeId: modelSymbol.modelTypeIds,
                                    symbolId,
                                }).getSymbolIdAndModelTypeId();
                            }),
                        ) || [];
                    break;
                }
                default:
                    keys = [];
            }
            if (type === scriptContextTypes.runningOnObjectInstancesWithBindingModelTypes) {
                return allKeys.filter((key) =>
                    keys.includes(CTreeNodeKey.keyToObject(key).getSymbolIdAndModelTypeId()),
                );
            }

            return allKeys.filter((key) => keys.includes(CTreeNodeKey.keyToObject(key).getTypeId()));
        };
    };
}

export namespace scriptContextItemNamesSelectors {
    export const getAllObjectTypes = () => {
        return (state: TRootState): ObjectType[] => {
            const serverId = ServerSelectors.serverId(state);
            const presets = PresetSelectors.presetArrByServerId(serverId)(state);
            const allObjectTypes = presets
                .map((preset) => {
                    return ObjectTypeSelectors.listAllByPreset(serverId, preset.id)(state);
                })
                .flat();

            return allObjectTypes;
        };
    };

    export const getObjectTypesNames = (allowedObjectTypeIds: string[]) =>
        createSelector<TRootState, ObjectType[], TItemName[]>(getAllObjectTypes(), (allObjectTypes) => {
            const objectTypeNames: (string | InternationalString)[] = allowedObjectTypeIds.map(
                (typeId) => allObjectTypes.find((type) => type.id === typeId)?.multilingualName || typeId,
            );

            return objectTypeNames;
        });

    export const getAllModelTypes = () => {
        return (state: TRootState): ModelType[] => {
            const serverId = ServerSelectors.serverId(state);
            const presets = PresetSelectors.presetArrByServerId(serverId)(state);
            const allModelTypes = presets
                .map((preset) => {
                    return ModelTypeSelectors.byServerIdPresetIdArr(serverId, preset.id)(state);
                })
                .flat();

            return allModelTypes;
        };
    };

    export const getModelTypesNames = (allowedModelTypeIds: string[]) =>
        createSelector<TRootState, ModelType[], TItemName[]>(getAllModelTypes(), (allModelTypes) => {
            const modelTypeNames: (string | InternationalString)[] = allowedModelTypeIds.map(
                (typeId) => allModelTypes.find((type) => type.id === typeId)?.multilingualName || typeId,
            );

            return modelTypeNames;
        });

    export const getAllEdgeTypes = () => {
        return (state: TRootState): EdgeType[] => {
            const serverId = ServerSelectors.serverId(state);
            const presets = PresetSelectors.presetArrByServerId(serverId)(state);
            const allEdgeTypes = presets
                .map((preset) => {
                    return EdgeTypeSelectors.listByPresetId(serverId, preset.id)(state);
                })
                .flat();

            return allEdgeTypes;
        };
    };

    export const getEdgeTypesNames = (allowedEdgeTypeIds: string[]) =>
        createSelector<TRootState, EdgeType[], TItemName[]>(getAllEdgeTypes(), (allEdgeTypes) => {
            const edgeTypeNames: (string | InternationalString)[] = allowedEdgeTypeIds.map(
                (typeId) => allEdgeTypes.find((type) => type.id === typeId)?.multilingualName || typeId,
            );

            return edgeTypeNames;
        });

    export const getAllFolderTypes = () => {
        return (state: TRootState): FolderType[] => {
            const serverId = ServerSelectors.serverId(state);
            const presets = PresetSelectors.presetArrByServerId(serverId)(state);
            const allFolderTypes = presets
                .map((preset) => {
                    return FolderTypeSelectors.listWithDefaultFolderTypeByPresetId({
                        serverId,
                        presetId: preset.id,
                    })(state);
                })
                .flat();

            return allFolderTypes;
        };
    };

    export const getFolderTypesNames = (allowedFolderTypeIds: string[]) =>
        createSelector<TRootState, FolderType[], TItemName[]>(getAllFolderTypes(), (allFolderTypes) => {
            const edgeTypeNames: (string | InternationalString)[] = allowedFolderTypeIds.map(
                (typeId) => allFolderTypes.find((type) => type.id === typeId)?.multilingualName || typeId,
            );

            return edgeTypeNames;
        });

    export const getAllSymbolTypes = () => {
        return (state: TRootState): Symbol[] => {
            const serverId = ServerSelectors.serverId(state);
            const presets = PresetSelectors.presetArrByServerId(serverId)(state);
            const allSymbolTypes = presets
                .map((preset) => {
                    return SymbolSelectors.byServerIdPresetId(serverId, preset.id)(state);
                })
                .flat();

            return allSymbolTypes;
        };
    };

    export const getSymbolTypesNames = (allowedSymbolTypeIds: string[]) =>
        createSelector<TRootState, Symbol[], TItemName[]>(getAllSymbolTypes(), (allSymbolTypes) => {
            const symbolTypeNames: (string | InternationalString)[] = allowedSymbolTypeIds.map(
                (typeId) => allSymbolTypes.find((type) => type.id === typeId)?.multilingualName || typeId,
            );

            return symbolTypeNames;
        });

    export const getModelSymbolTypesNames = (allowedModelSymbols: AllowedScriptModelSymbols[]) =>
        createSelector<TRootState, Symbol[], ModelType[], TModelSymbolNames[]>(
            getAllSymbolTypes(),
            getAllModelTypes(),
            (allSymbolTypes, allModelTypes) => {
                const modelSymbolNames: TModelSymbolNames[] = allowedModelSymbols.map((modelSymbol) => {
                    return {
                        modelName:
                            allModelTypes.find((type) => type.id === modelSymbol.modelTypeIds)?.multilingualName ||
                            modelSymbol.modelTypeIds,
                        symbolNames: modelSymbol.symbolIds.map(
                            (symbolId) =>
                                allSymbolTypes.find((type) => type.id === symbolId)?.multilingualName || symbolId,
                        ),
                    };
                });

                return modelSymbolNames;
            },
        );

    export const getNodeNames = (allowedNodeIds: NodeId[]) =>
        createSelector<TRootState, Node[], TItemName[]>(EventsAccessSelectors.getEventNodes(), (nodes) => {
            const nodeNames: (string | InternationalString)[] = allowedNodeIds.map(
                (nodeId) => nodes.find((node) => compareNodeIds(node.nodeId, nodeId))?.multilingualName || nodeId.id,
            );

            return nodeNames;
        });
}
