import { Dropdown } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { useState, useCallback, useEffect, FC } from 'react';
import { CommentFilterStatus } from '../../../../../models/commentMarkerConstants';
import { useIntl } from 'react-intl';
import messages from '../../../messages/CommentsPanel.messages';
import theme from '../CommentsAdditionalMenu.scss';
import { getMenuItem } from '../../../../../utils/antdMenuItem.utils';
import type { MenuProps } from 'antd';
import check from '../../../../../resources/icons/check.svg';
import checkDisabled from '../../../../../resources/icons/checkDisabled.svg';
import { NavigatorPanelButton } from '../../../../Navigator/components/NavigatorPanel/NavigatorPanelButton/NavigatorPanelButton.component';
import { FilterIcon } from '../../../../Navigator/components/NavigatorPanel/icons/FilterIcon.component';
import { getAntdPopupContainer } from '../../../../../utils/getAntdPopupContainer';
import { Icon } from '../../../../UIKit';

enum CanvasMenuActions {
    showMarkers = 'SHOW_MARKERS',
    showAll = 'SHOW_ALL',
    showResolved = 'SHOW_RESOLVED',
    showUnresolved = 'SHOW_UNRESOLVED',
}

type TCanvasActionsDropdownProps = {
    isShowMarkers: boolean;
    setIsShowMarkers: React.Dispatch<React.SetStateAction<boolean>>
    onChangeMarkersShow: (checked: boolean, filterStatus: CommentFilterStatus) => void;
};

export const CanvasActionsDropdown: FC<TCanvasActionsDropdownProps> = ({ isShowMarkers, setIsShowMarkers, onChangeMarkersShow }) => {
    const intl = useIntl();

    const [canvasFilterStatus, setCanvasFilterStatus] = useState<CommentFilterStatus>(CommentFilterStatus.all);
    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
    }, []);

    useEffect(() => onChangeMarkersShow(isShowMarkers, canvasFilterStatus), [isShowMarkers, canvasFilterStatus]);

    const onClick = (param: MenuInfo) => {
        switch (param.key) {
            case CanvasMenuActions.showMarkers:
                setIsShowMarkers((prevState) => !prevState);
                break;
            case CanvasMenuActions.showAll:
                setCanvasFilterStatus(CommentFilterStatus.all);
                break;
            case CanvasMenuActions.showUnresolved:
                setCanvasFilterStatus(CommentFilterStatus.notResolved);
                break;
            case CanvasMenuActions.showResolved:
                setCanvasFilterStatus(CommentFilterStatus.resolved);
                break;
            default:
                break;
        }
    };

    const items: MenuProps['items'] = [
        getMenuItem(
            <div data-test="comment-panel_display-markers-btn" className={theme.menuItem}>
                {intl.formatMessage(messages.showMarkers)}
            </div>,
            CanvasMenuActions.showMarkers,
            false,
            <div className={theme.iconContainer}>{isShowMarkers && <Icon spriteSymbol={check} />}</div>,
        ),
        {
            type: 'divider',
        },
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.commentsPanelFilterAll)}</div>,
            CanvasMenuActions.showAll,
            !isShowMarkers,
            <div className={theme.iconContainer}>
                {canvasFilterStatus === CommentFilterStatus.all && (
                    <Icon spriteSymbol={isShowMarkers ? check : checkDisabled} />
                )}
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.commentOpen)}</div>,
            CanvasMenuActions.showUnresolved,
            !isShowMarkers,
            <div className={theme.iconContainer}>
                {canvasFilterStatus === CommentFilterStatus.notResolved && (
                    <Icon spriteSymbol={isShowMarkers ? check : checkDisabled} />
                )}
            </div>,
        ),
        getMenuItem(
            <div className={theme.menuItem}>{intl.formatMessage(messages.commentClose)}</div>,
            CanvasMenuActions.showResolved,
            !isShowMarkers,
            <div className={theme.iconContainer}>
                {canvasFilterStatus === CommentFilterStatus.resolved && (
                    <Icon spriteSymbol={isShowMarkers ? check : checkDisabled} />
                )}
            </div>,
        ),
    ];

    return (
        <Dropdown
            open={open}
            onOpenChange={toggleOpen}
            menu={{ onClick, items }}
            trigger={['click']}
            getPopupContainer={getAntdPopupContainer}
            destroyPopupOnHide
        >
            <NavigatorPanelButton
                IconComponent={FilterIcon}
                active={open}
                dataTest="comment-panel_markers-options-btn"
            />
        </Dropdown>
    );
};
