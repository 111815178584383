import React from 'react';
import theme from './TreeItemContextMenu.scss';
import { Tooltip } from 'antd';
import type { MenuProps, DropdownProps } from 'antd';
import { MenuInfo } from 'rc-menu/es/interface';
import { TreeItemContextMenuAction, TreeContextMenuActionItem } from '../../models/tree';
import { CONTEXT_MENU_ACTION, CONTEXT_MENU_DIVIDER } from '../../../../actionsTypes/tree.actionTypes';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import contextMenuMessages from '../../messages/TreeContextMenu.messages';
import { ContextMenu } from '../../../ContextMenu/components/ContextMenu.component';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { TFilteredMenu, TTreeNodeWithLevel } from '../../Tree.types';
import { getMenuItem } from '../../../../utils/antdMenuItem.utils';
import { getMenuItems } from './TreeItemContextMenu.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, hasModelEditorLicense, isUserHasAccess } from '@/selectors/authorization.selectors';
import userAccessRightTypes from '@/models/userAccessRightTypes';
import { FolderType, UserDTO, UserDTOAccessesEnum } from '@/serverapi/api';
import { treeItemContextMenuAction } from '@/actions/tree.actions';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { ServerProfileSelectors } from '@/selectors/serverProfile.selectors';
import { DialogsSelectors } from '@/selectors/dialogs.selectors';
import { getMenuItemsFilter } from '../../containers/MenuUtils';
import { StatusBarSelectors } from '@/selectors/statusBar.selector';
import { TExecutingProcess } from '@/reducers/statusBar.reducer.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { FolderTypeSelectors } from '@/selectors/folderType.selectors';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { FavoritesSelectors } from '@/selectors/favorites.selectors';
import { getTreeItemContext } from '../TreeItem/TreeItem.utils';

type TTreeItemContextMenuProps = {
    rowData: TTreeNodeWithLevel;
    visible: boolean;
    hideContextMenu: () => void;
} & Omit<DropdownProps, 'overlay'>;

type TSubItemMenu = {
    titleAction: string;
    formattedMessageId: string;
    icon: TSpriteSymbol;
};

export const TreeItemContextMenu = (props: TTreeItemContextMenuProps) => {
    const { rowData, visible, children, hideContextMenu } = props;

    const { name, nodeId, type, modelTypeId, edgeTypeId = '', fileFolderType, deleted } = rowData;

    const dispatch = useDispatch();

    const selectedNode = useSelector(SelectedNodesSelector.getNode(NAVIGATOR_STRUCTURE));

    const profileName: string =
        useSelector(ServerProfileSelectors.activeProfileName(selectedNode?.nodeId?.serverId || '')) || '';
    const isOpenInDialog: boolean = useSelector(DialogsSelectors.isVisibleDialog);
    const processes: TExecutingProcess[] = useSelector(StatusBarSelectors.getProcesses);
    const connected = useSelector(TreeSelectors.connected);
    const connectedServerId = connected[0];
    const user: UserDTO | undefined = useSelector(getUser);
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const nodeFolderType: FolderType | undefined = useSelector(FolderTypeSelectors.byNodeId({ nodeId, presetId }));
    const isModelEditor: boolean = useSelector(hasModelEditorLicense);
    const isEdgeTypeDeletable = useSelector(
        UserProfileSelectors.isEdgeTypeDeletable(nodeId.serverId, presetId, edgeTypeId),
    );
    const isFavorite: boolean = useSelector(FavoritesSelectors.isFavorite(nodeId));
    const moreThanOneElementSelected: boolean = useSelector(
        SelectedNodesSelector.moreThanOneSelected(NAVIGATOR_STRUCTURE),
    );
    const twoModelsSelected: boolean = useSelector(SelectedNodesSelector.twoModelsSelected(NAVIGATOR_STRUCTURE));
    const lastOpenDialogType = useSelector(DialogsSelectors.getLastVisibleDialogType);
    const treeItemContext = getTreeItemContext(lastOpenDialogType, moreThanOneElementSelected, twoModelsSelected);

    const isShowDeletedObjectsAccessible: boolean = useSelector(
        isUserHasAccess(userAccessRightTypes.SHOW_DELETED_OBJECT as UserDTOAccessesEnum),
    );

    const onContextMenuAction = (action: TreeItemContextMenuAction) => {
        if (selectedNode) {
            dispatch(treeItemContextMenuAction({ nodeId, name, action, type }));
        }
    };

    const filterMenuActions = () => {
        const menuItemsFilter = getMenuItemsFilter(
            processes,
            connectedServerId,
            user,
            nodeFolderType,
            isModelEditor,
            isEdgeTypeDeletable,
            !!deleted,
            isFavorite,
        );

        return menuItemsFilter(nodeId, type, fileFolderType, modelTypeId);
    };

    const handleMenuAction = (param: MenuInfo) => {
        if (hideContextMenu) {
            hideContextMenu();
        }

        if (TreeItemContextMenuAction[param.key]) {
            onContextMenuAction(param.key as TreeItemContextMenuAction);
        }
    };

    const renderMenuItem = (action: TreeContextMenuActionItem, filtered: TFilteredMenu, subItems: TSubItemMenu[]) => {
        const disabled = filtered[action.titleAction]?.disabled;
        const disabledMsg = filtered[action.titleAction]?.message;
        const hidden = filtered[action.titleAction]?.hidden;

        if (hidden) {
            return null;
        }

        if (subItems.length && !disabled) {
            const childrenItems: MenuProps['items'] = subItems.map((subItem: TSubItemMenu) => {
                const itemDisabled = disabled || filtered[subItem.titleAction]?.disabled;

                return getMenuItem(
                    <div data-test={`tree-item_context-menu_options_${subItem.titleAction}`}>
                        <Icon
                            disabled={itemDisabled}
                            spriteSymbol={subItem.icon}
                            className={theme.iconContextMenuItem}
                        />
                        <FormattedMessage {...contextMenuMessages[subItem.formattedMessageId]} />
                    </div>,
                    subItem.titleAction,
                    itemDisabled,
                );
            });

            return getMenuItem(
                <div style={{ display: 'inline' }} data-test={`tree-item_context-menu_${action.titleAction}`}>
                    <Icon disabled={disabled} spriteSymbol={action.icon} className={theme.iconContextMenuItem} />
                    <FormattedMessage {...contextMenuMessages[action.formattedMessageId]} />
                </div>,
                `SUB_${action.titleAction}`,
                false,
                undefined,
                childrenItems,
            );
        }

        return getMenuItem(
            <div data-test={`tree-item_context-menu-options_${action.formattedMessageId}`}>
                <Tooltip title={disabledMsg}>
                    <Icon disabled={disabled} spriteSymbol={action.icon} className={theme.iconContextMenuItem} />
                    <FormattedMessage
                        {...contextMenuMessages[action.formattedMessageId]}
                        values={{
                            profileName,
                        }}
                    />
                </Tooltip>
            </div>,
            action.titleAction,
            disabled,
        );
    };

    const menuItems = getMenuItems(type, treeItemContext, isOpenInDialog, isShowDeletedObjectsAccessible);

    const items: MenuProps['items'] = visible
        ? menuItems.map((item, index) => {
              if (item.type === CONTEXT_MENU_ACTION) {
                  const filteredMenu: TFilteredMenu =
                      typeof filterMenuActions === 'function' ? filterMenuActions() : {};

                  if (item.subMenu.length) {
                      return renderMenuItem(item, filteredMenu, item.subMenu);
                  }

                  return renderMenuItem(item, filteredMenu, []);
              }
              if (index > 0 && item.type === CONTEXT_MENU_DIVIDER) {
                  return { type: 'divider' };
              }

              return getMenuItem('', `empty_${index}`);
          })
        : [];

    return (
        <ContextMenu
            overlay={menuItems.length ? items : null}
            visible={visible}
            onOpenChange={hideContextMenu}
            onClick={handleMenuAction}
        >
            {children}
        </ContextMenu>
    );
};
