import { defineMessages } from 'react-intl';

export default defineMessages({
    LINK: {
        id: 'ExtraButtonProperties.LINK',
        defaultMessage: 'Ссылка на внешний источник',
    },
    MODEL: {
        id: 'ExtraButtonProperties.MODEL',
        defaultMessage: 'Ссылка на модель',
    },
    formDeclineButton: {
        id: 'ExtraButtonProperties.formDeclineButton',
        defaultMessage: 'Отмена',
    },
    formConfirmButton: {
        id: 'ExtraButtonProperties.formConfirmButton',
        defaultMessage: 'Сохранить',
    },
    selectImageForm: {
        id: 'ExtraButtonProperties.selectImageForm',
        defaultMessage: ' Выберите картинку',
    },
    selectProfilesForm: {
        id: 'ExtraButtonProperties.selectProfilesForm',
        defaultMessage: 'Выберите профили',
    },
    selectModelForm: {
        id: 'ExtraButtonProperties.selectModelForm',
        defaultMessage: 'Выберите модель',
    },
    modalName: {
        id: 'ExtraButtonProperties.modalName',
        defaultMessage: 'Создание / Редактирование кнопки',
    },
    extraBtnName: {
        id: 'ExtraButtonProperties.extraBtnName',
        defaultMessage: 'Имя кнопки',
    },
    extraBtnType: {
        id: 'ExtraButtonProperties.extraBtnType',
        defaultMessage: 'Тип кнопки',
    },
    extraBtnLink: {
        id: 'ExtraButtonProperties.extraBtnLink',
        defaultMessage: 'Ссылка на внешний источник',
    },
    extraBtnSelectModel: {
        id: 'ExtraButtonProperties.extraBtnSelectModel',
        defaultMessage: 'Выбрать модель',
    },
    extraBtnNoModelSelected: {
        id: 'ExtraButtonProperties.extraBtnNoModelSelected',
        defaultMessage: 'Модель не выбрана',
    },
    extraBtnSelectImage: {
        id: 'ExtraButtonProperties.extraBtnSelectImage',
        defaultMessage: 'Выбрать картинку',
    },
    extraBtnSelectProfile: {
        id: 'ExtraButtonProperties.extraBtnSelectProfile',
        defaultMessage: 'Выбрать профиль',
    },
    allowedToProfiles: {
        id: 'ExtraButtonProperties.allowedToProfiles',
        defaultMessage: 'Доступно профилям: ',
    },
    editButton: {
        id: 'ExtraButtonProperties.editButton',
        defaultMessage: 'Редактировать',
    },
    createButton: {
        id: 'ExtraButtonProperties.createButton',
        defaultMessage: 'Создать кнопку',
    },
    nameErrorValidation: {
        id: 'ExtraButtonProperties.nameErrorValidation',
        defaultMessage: 'Имя должно быть не более 25 символов',
    },
    typeErrorValidation: {
        id: 'ExtraButtonProperties.typeErrorValidation',
        defaultMessage: 'Тип должен быть выбран',
    },
    urlErrorValidation: {
        id: 'ExtraButtonProperties.urlErrorValidation',
        defaultMessage: 'URL должен быть задан',
    },
    deleteButton: {
        id: 'ExtraButtonProperties.deleteButton',
        defaultMessage: 'Удалить',
    },
    title: {
        id: 'ExtraButtonProperties.title',
        defaultMessage: 'Кнопки главной страницы',
    },
    deleteModalTitle: {
        id: 'ExtraButtonProperties.deleteModalTitle',
        defaultMessage: 'Удаление кнопки с главной страницы',
    },
    deleteModalText: {
        id: 'ExtraButtonProperties.deleteModalText',
        defaultMessage: 'Вы уверены, что хотите удалить кнопку "{name}" с главной страницы?',
    },
});
