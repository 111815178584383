import {FileNodeDTO, NodeId} from '../serverapi/api';
import { TServerEntity } from '../models/entities.types';
import { FILE_UPLOAD, FILE_UPLOAD_FAIL, FILE_UPLOAD_SUCCESS } from '../actionsTypes/uploader.actionTypes';
import { TFileUploadFailAction, TFileUploadAction, TFileUploadSuccessAction } from './uploader.actions.types';

export const fileUpload = (file: File, nodeId: NodeId, parentNodeId?: NodeId, uploadedFromTree?: boolean): TFileUploadAction => ({
    type: FILE_UPLOAD,
    payload: {
        file,
        nodeId,
        parentNodeId,
        uploadedFromTree
    }
});

export const fileUploadSuccess = (
    result: FileNodeDTO,
    server: TServerEntity,
    id: string,
): TFileUploadSuccessAction => ({
    type: FILE_UPLOAD_SUCCESS,
    payload: {
        result,
        server,
        id,
    },
});

export const fileUploadFail = (
    id: string
): TFileUploadFailAction => ({
    type: FILE_UPLOAD_FAIL,
    payload: {
        id
    }
});
