import type { AttributeTypeStyleFormatEnum, PresetImage } from '../../../../../../serverapi/api';
import type { RadioChangeEvent } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Radio } from 'antd';
import messages from './OverlayTypeSelector.messages';
import theme from './OverlayTypeSelector.scss';
import { floatingAttributesChangeFormatAction } from '../../../../actions/FloatingAttributes.actions';
import { StyleFormat } from '../../../../FloatingAttributes.constants';

type TOverlayTypeSelectorProps = {
    value?: AttributeTypeStyleFormatEnum;
    images: PresetImage[];
    disabled?: boolean;
};

export const OverlayTypeSelector = ({ value = StyleFormat.TEXT, images, disabled }: TOverlayTypeSelectorProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onChangeHandler = (e: RadioChangeEvent) => dispatch(floatingAttributesChangeFormatAction(e.target.value));

    return (
        <>
            <Radio.Group onChange={onChangeHandler} value={value} disabled={disabled} className={theme.container}>
                <Radio value="TEXT" data-test="attributes-around-object_text-format">
                    {' '}
                    {intl.formatMessage(messages.text)}
                </Radio>
                <Radio value="IMAGE" disabled={!images.length} data-test="attributes-around-object_image-format">
                    {intl.formatMessage(messages.icon)}
                </Radio>
                <Radio value="NONE" data-test="attributes-around-object_do-not-display">
                    {intl.formatMessage(messages.none)}
                </Radio>
            </Radio.Group>
        </>
    );
};
