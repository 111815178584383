import type { TReducer } from '../../utils/types';
import type { NodeId } from '@/serverapi/api';
import {
    WIKI_REMOVE_SUCCESS,
    WIKI_REQUEST_SUCCESS,
    CHANGE_WIKI_DATA,
    WIKI_COPY_TO_CLIPBOARD,
    WIKI_SET_IS_CLIPBOARD_AVAILABLE,
    WIKI_UPLOAD_IMAGE,
} from '../../actionsTypes/entities/wiki.actionTypes';
import { IWikiNode } from '../../models/bpm/bpm-model-impl.types';
import { TNodeState } from './TNodeState';
import { TWikiState } from './wiki.reducer.types';
import { CustomMap } from '@/utils/map';

const INITIAL_WIKI_STATE: TWikiState = {
    data: new TNodeState(),
    clipboard: {
        text: undefined,
        isAvailable: true,
    },
    imageUpload: new CustomMap<NodeId, Record<string, string>>(),
};

export const wikiReducer: TReducer<TWikiState> = (state = INITIAL_WIKI_STATE, action) => {
    switch (action.type) {
        case WIKI_REQUEST_SUCCESS: {
            const {
                payload: { wiki },
            } = action;

            return { ...state, data: state.data.set(wiki.nodeId, wiki) };
        }

        case CHANGE_WIKI_DATA: {
            const {
                payload: { wiki, nodeId },
            } = action;
            const existWiki: IWikiNode | undefined = state.data.byNodeId.get(nodeId);
            if (existWiki) {
                return { ...state, data: state.data.set(nodeId, { ...existWiki, ...wiki }) };
            }

            return { ...state, data: state.data.set(nodeId, { ...wiki, nodeId }) };
        }

        case WIKI_REMOVE_SUCCESS: {
            const {
                payload: { nodeId },
            } = action;

            return { ...state, data: state.data.delete(nodeId) };
        }

        case WIKI_COPY_TO_CLIPBOARD: {
            const {
                payload: { text },
            } = action;

            return { ...state, clipboard: { ...state.clipboard, text } };
        }

        case WIKI_SET_IS_CLIPBOARD_AVAILABLE: {
            const {
                payload: { isAvailable },
            } = action;

            return { ...state, clipboard: { ...state.clipboard, isAvailable } };
        }

        case WIKI_UPLOAD_IMAGE: {
            const {
                payload: { fileId, modelId },
            } = action;

            const stateClone = state.imageUpload.clone();
            stateClone.set(modelId, { [fileId.id]: fileId.id });

            return { ...state, imageUpload: stateClone };
        }

        default:
            return state;
    }
};
