import React, { FC } from 'react';
import theme from './CellSymbolsSettings.scss';
import messages from './CellSymbolsSettings.messages';
import { Table } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { MatrixStyle } from '@/serverapi/api';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import cn from 'classnames';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { renderCellSymbol } from '@/modules/Matrix/utils/Matrix.utils';

type TCellSymbolsSettingsProps = {
    currentStyles: MatrixStyle[];
    selectedIds: string[];
    setSelectedCellStylesIds: (ids: string[]) => void;
};

export const CellSymbolsSettings: FC<TCellSymbolsSettingsProps> = ({
    currentStyles,
    selectedIds,
    setSelectedCellStylesIds,
}) => {
    const intl = useIntl();
    const currentLocale = useSelector(getCurrentLocale);

    const renderStyleRow = (style: MatrixStyle) => {
        const { id, description } = style;
        const localeDescription = LocalesService.internationalStringToString(description, currentLocale);

        const { symbol, color } = renderCellSymbol(style, theme.antdIconsContainer);

        const styleSelectHandler = () => {
            if (selectedIds.includes(id)) {
                setSelectedCellStylesIds(selectedIds.filter((selectedId) => selectedId !== id));
            } else {
                setSelectedCellStylesIds([...selectedIds, id]);
            }
        };

        return (
            <Table.Row key={id} className={cn(theme.symbolRow)} onClick={styleSelectHandler}>
                <Table.Cell className={theme.checkBoxCell}>
                    <Checkbox
                        className={theme.checkBox}
                        checked={selectedIds.includes(id)}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Table.Cell>
                <Table.Cell className={theme.symbolCell} style={{ color: color }}>
                    {symbol}
                </Table.Cell>
                <Table.Cell className={theme.descriptionCell}>{localeDescription}</Table.Cell>
            </Table.Row>
        );
    };

    return (
        <div className={theme.tableContainer}>
            {
                // @ts-ignore
                <Table definition className={theme.table}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="left" />
                            <Table.HeaderCell textAlign="left">
                                {intl.formatMessage(messages.iconSymbol)}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">
                                {intl.formatMessage(messages.iconDescription)}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{currentStyles.map((style) => renderStyleRow(style))}</Table.Body>
                </Table>
            }
        </div>
    );
};
