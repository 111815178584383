import * as React from 'react';
import { FC } from 'react';
import { ExtendableSelect } from '../ExtendableSelect/ExtendableSelect.component';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import messages from './ParagraphStyleSelect.messages';
import { paragraphStyleOptions } from '../../../../utils/configuration';

interface IParagraphStyleSelectProps {
    value: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    className?: string;
    renderNewOption?: boolean;
    tooltipTitle?: string;
}
type TParagraphStyleSelectPropsAllProps = WrappedComponentProps & IParagraphStyleSelectProps;

const ParagraphStyleSelect: FC<TParagraphStyleSelectPropsAllProps> = (props) => (
    <ExtendableSelect
        renderNewOption={props.renderNewOption}
        className={props.className}
        baseOptions={paragraphStyleOptions.map((option) => ({
            ...option,
            label: props.intl.formatMessage(messages[option.label]),
        }))}
        onChange={props.onChange}
        disabled={props.disabled}
        value={{ value: props.value, label: props.value }}
        tooltipTitle={props.tooltipTitle}
    />
);
const ParagraphStyleSelectWithIntl = injectIntl(ParagraphStyleSelect);

export { ParagraphStyleSelectWithIntl as ParagraphStyleSelect };

