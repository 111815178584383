import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import theme from './EllipsisText.scss';
import { getTextBlockRenderedSize } from '../../../../../../utils/render.utils';

const lineHeight = 22;
const linesQuantity = 2;

export const EllipsisText = ({ text }) => {
    const textRef: React.RefObject<any> = React.useRef(null);

    const [showTooltip, setShowTooltip] = React.useState(false);

    useEffect(() => {
        const parentWidth: number = textRef.current?.offsetWidth;

        const { height } = getTextBlockRenderedSize(text, {
            width: `${String(parentWidth)}px`,
            overflowWrap: 'wrap',
            fontFamily: 'Segoe UI',
            wordBreak: 'break-all',
            fontSize: '14px',
            lineHeight: `${lineHeight}px`,
        });

        if (height > linesQuantity * lineHeight) {
            setShowTooltip(true);
        } else {
            setShowTooltip(false);
        }
    });

    return (
        <Tooltip mouseLeaveDelay={0} title={showTooltip ? text : ''}>
            <div ref={textRef} className={theme.ellipsis}>
                <div style={{ width: 'fit-content', height: 'fit-content' }}>
                    {text}
                </div>
            </div>
        </Tooltip>
    );
};
