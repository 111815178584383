import React, { useState } from 'react';
import theme from './myApprovalsTab.scss';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import messages from './myApprovalsTab.messages';
import { isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import { ApprovalSelectors } from '@/selectors/approval.selectors';
import { RangeValue } from 'rc-picker/lib/interface';
import { Dayjs } from 'dayjs';
import { dateFormat } from '../../utils/date.time.utils';
import iRanger from '../../resources/icons/iRangePicker.svg';
import iRefresh from '../../resources/icons/ic-update.svg';
import { Select } from '../UIKit/components/Select/Select.component';
import { Icon } from '../UIKit/components/Icon/Icon.component';
import { CreatorSelector } from './CreatorsSelector.component';
import { TMyApprovalsStatus, TMyApprovalsTableData } from './myApprovals.types';
import { MyApprovalsTable } from './MyApprovalsTable.component';
import { ApprovalSearchResultCurrentUserStatusesEnum, UserDTOAccessesEnum } from '@/serverapi/api';
import { refreshMyApprovals } from '@/actions/approval.actions';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';
import userAccessRightTypes from '@/models/userAccessRightTypes';
import { isUserHasAccess } from '@/selectors/authorization.selectors';
import { DatePickerProvider } from '@/modules/UIKit/H.O.C/DatePickerProvider/DatePickerProvider.hoc';
import { Button } from '../UIKit/components/Button/Button.component';

const statusArr: TMyApprovalsStatus[] = [
    'MY_APPROVALS',
    'WAITING_TO_APPROVE',
    'APPROVED',
    'NOT_APPROVED',
    'CANCELLED',
    'DRAFT',
];

const userStatusArr: ApprovalSearchResultCurrentUserStatusesEnum[] = ['AUTHOR', 'USER', 'ASSISTANT'];

export const MyApprovalsTab = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showPlaceHolder, setShowPlaceHolder] = useState<boolean>(true);
    const [statusFilter, setStatusFilter] = useState<TMyApprovalsStatus[]>([]);
    const [userStatusFilter, setUserStatusFilter] = useState<ApprovalSearchResultCurrentUserStatusesEnum[]>([]);
    const [creatorFilter, setCreatorFilter] = useState<string[]>([]);
    const [createdAtRangeFilter, setCreatedAtRangeFilter] = useState<RangeValue<Dayjs>>(null);
    const [allApprovals, setAllApprovals] = useState<boolean>(false);
    const filteredTableData: TMyApprovalsTableData[] = useSelector(
        ApprovalSelectors.getMyApprovalsTableDataFiltered(
            statusFilter,
            creatorFilter,
            createdAtRangeFilter,
            userStatusFilter,
        ),
        (oldData: TMyApprovalsTableData[], newData: TMyApprovalsTableData[]) => isEqual(oldData, newData),
    );
    const hasApprovalAdminAccess = useSelector(
        isUserHasAccess(userAccessRightTypes.APPROVAL_ADMIN as UserDTOAccessesEnum),
    );

    const handleChangeCreatedAtRangeFilter = (e: RangeValue<Dayjs>) => {
        setCreatedAtRangeFilter(e);
        if (e) {
            setShowPlaceHolder(false);
        } else {
            setShowPlaceHolder(true);
        }
    };

    const handleChangeStatusFilter = (statusArr: TMyApprovalsStatus[]) => {
        setStatusFilter(statusArr);
    };

    const handleChangeUserStatusFilter = (statusArr: ApprovalSearchResultCurrentUserStatusesEnum[]) => {
        setUserStatusFilter(statusArr);
    };

    const handleRefresh = () => dispatch(refreshMyApprovals({ allUsersApprovals: allApprovals }));

    const handleToggleAllApprovals = () => {
        dispatch(refreshMyApprovals({ allUsersApprovals: !allApprovals }));
        setAllApprovals(!allApprovals);
    };

    return (
        <div className={theme.myApprovalsTab}>
            <div className={theme.headerContainer}>
                <div className={theme.filters}>
                    <div className={theme.rangePickerContainer}>
                        <DatePickerProvider>
                            <DatePicker.RangePicker
                                className={cx(theme.rangePicker, { [theme.showPlaceHolder]: showPlaceHolder })}
                                format={dateFormat}
                                placeholder={showPlaceHolder ? [intl.formatMessage(messages.period), ''] : ['', '']}
                                onChange={handleChangeCreatedAtRangeFilter}
                                suffixIcon={<Icon className={theme.iconRange} spriteSymbol={iRanger} />}
                            />
                        </DatePickerProvider>
                    </div>
                    <CreatorSelector creatorFilter={creatorFilter} setCreatorFilter={setCreatorFilter} />
                    <div className={theme.selectContainer}>
                        <Select
                            isMultiSelect
                            value={intl.formatMessage(messages.status)}
                            onChange={handleChangeStatusFilter}
                        >
                            {statusArr.map((status) => (
                                <Select.Option
                                    key={status}
                                    value={status}
                                    checked={statusFilter.includes(status)}
                                    label={intl.formatMessage(messages[status])}
                                />
                            ))}
                        </Select>
                    </div>
                    <div className={theme.selectContainer}>
                        <Select
                            isMultiSelect
                            value={intl.formatMessage(messages.userStatuses)}
                            onChange={handleChangeUserStatusFilter}
                        >
                            {userStatusArr.map((status) => (
                                <Select.Option
                                    key={status}
                                    value={status}
                                    checked={userStatusFilter.includes(status)}
                                    label={intl.formatMessage(messages[status])}
                                />
                            ))}
                        </Select>
                    </div>
                    <div className={theme.checkboxWrapper}>
                        {hasApprovalAdminAccess ? (
                            <Checkbox
                                className={theme.checkbox}
                                checked={allApprovals}
                                onChange={handleToggleAllApprovals}
                            >
                                {intl.formatMessage(messages.allApprovals)}
                            </Checkbox>
                        ) : null}
                    </div>
                </div>
                <Button icon={iRefresh} onClick={handleRefresh}>
                    {intl.formatMessage(messages.refresh)}
                </Button>
            </div>
            <div className={theme.tableContainer}>
                <MyApprovalsTable
                    approvalTableData={filteredTableData}
                    hasApprovalAdminAccess={hasApprovalAdminAccess}
                />
            </div>
        </div>
    );
};
