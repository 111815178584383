// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EllipsisText__ellipsis__jO9a_{height:100% !important;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;text-overflow:ellipsis;word-break:break-all}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/MethodologySettingPage/EllipsisText/EllipsisText.scss"],"names":[],"mappings":"AAAA,+BACI,sBAAA,CACA,mBAAA,CACA,oBAAA,CACA,2BAAA,CACA,eAAA,CACA,sBAAA,CACA,oBAAA","sourcesContent":[".ellipsis {\r\n    height: 100% !important;\r\n    display: -webkit-box;\r\n    -webkit-line-clamp: 2;\r\n    -webkit-box-orient: vertical;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n    word-break: break-all;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipsis": "EllipsisText__ellipsis__jO9a_"
};
export default ___CSS_LOADER_EXPORT___;
