import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { SearchRequest, SearchResponse, SearchResult } from '@/serverapi/api';

export class SearchDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getExtendedSearchResponse(searchRequest: SearchRequest): Promise<Array<SearchResult>> {
        const api = this.getApi();
        const searchResponse: SearchResponse = await api.search.searchExtended({ body: searchRequest });

        return searchResponse.resultList
            ? searchResponse.resultList.map((result) => ({
                  ...result,
                  nodeId: { ...result.nodeId, serverId: searchRequest.rootSearchNodeId.serverId },
              }))
            : [];
    }
}

