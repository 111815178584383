import { TFormValues } from '@/modules/dialogs/WikiLinkDialog/WikiLinkDialog.types';
import { TTreeEntityState } from '../../../../models/tree.types';
import { TreeItemType } from '../../../Tree/models/tree';

export const urlDataTextCheck = (dataTextValue: string, isElectron?: boolean): string => {
    const urlDataText: string = dataTextValue;
    const mail = `${urlDataText}`.includes('mailto:');

    if (mail) {
        return urlDataText;
    }

    if (!urlDataText.includes('://')) {
        if (isElectron) {
            return `${'http://'}${urlDataText}`;
        }

        return `${'//'}${dataTextValue}`;
    }

    return urlDataText;
};

export const prepareUrl = (formValues: TFormValues, node: TTreeEntityState | undefined) => {
    let url: string;
    if (formValues.url.trim().startsWith('bpm://') && node) {
        const { nodeId } = node;
        const { type } = node;
        url = `${urlDataTextCheck(formValues.url)}/${nodeId.repositoryId}/${nodeId.id}/${type}`;
    } else {
        url = urlDataTextCheck(formValues.url);
    }

    return url;
};

export const prepareTreeItemData = (uri: string) => {
    const partsUri = uri.split('/');
    const type = partsUri[partsUri.length - 1] as TreeItemType;
    const id = partsUri[partsUri.length - 2];
    const repositoryId = partsUri[partsUri.length - 3];

    return {
        type,
        id,
        repositoryId,
    };
};
