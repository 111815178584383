import { all, call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { isUndefined } from 'is-what';
import { v4 as uuid } from 'uuid';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import {
    changingStatusNotificationShow,
    dashboardOpen,
    openScriptDialog,
    requestScriptsExecution,
    saveScriptContextSuccess,
    scriptClose,
    scriptExecute,
    scriptExecuteRequestFailure,
    scriptNodeToExecute,
    scriptNodeToSchedule,
    scriptOpen,
    scriptRemoveSuccess,
    scriptRequestSuccess,
    scriptSaveRequest,
    scriptSaveRequestSuccess,
    scriptsDashboardOpen,
    scriptsExecutionRequestFailure,
    scriptsExecutionSaveToStore,
    scriptsExecutionUpdateInStore,
    scriptsSaveRequestFailure,
    updateOperationProgressInStore,
} from '../actions/entities/script.actions';
import {
    TScriptDefaultAction,
    TScriptDefaultRequestAction,
    TScriptSubmittedViaNode,
    TScriptOpenByNodeIdAction,
    TScriptChangeModeAction,
    TScriptExecuteAction,
    TScriptExecutionSubscribeAction,
    TScriptsExecutionRequestAction,
    TDashboardOpenAction,
    TScriptChangingStatusNotificationShowAction,
    TScriptSetReportDownloadDateAction,
    TFileDownloadedFileNotificationShowAction,
    TScriptScheduleAction,
    TScriptEditScheduleSubmitAction,
    TScriptNodeToExecuteAction,
    TScriptNodeToScheduleAction,
    TScriptDialogOpenAction,
    TScriptCreateAction,
} from '../actions/entities/script.actions.types';
import {
    DASHBOARD_OPEN,
    FILE_DOWNLOADED_NOTIFICATION_SHOW,
    REPORT_DOWNLOAD_DATE_SET,
    SCRIPT_CHANGE_LOCALLY,
    SCRIPT_CLOSE,
    SCRIPT_CREATE,
    SCRIPT_EXECUTE,
    SCRIPT_EXECUTION_SUBSCRIBE,
    SCRIPT_MODE_CHANGE,
    SCRIPT_OPEN,
    SCRIPT_OPEN_BY_NODE_ID,
    SCRIPT_SAVE,
    SCRIPT_SAVE_REQUEST,
    SCRIPT_SAVE_REQUEST_FAILURE,
    SCRIPT_SAVE_REQUEST_SUCCESS,
    SCRIPT_SUBMITTED_VIA_NODE,
    SCRIPTS_DASHBOARD_OPEN,
    SCRIPTS_EXECUTION_REQUEST,
    SCRIPTS_EXECUTION_SAVE_TO_STORE,
    STATUS_CHANGE_NOTIFICATION_SHOW,
    SCRIPT_SCHEDULE,
    SCRIPT_SCHEDULE_LOAD_ALL,
    SCRIPT_SCHEDULE_TASK_DELETE,
    SCRIPT_CONTEXT_FETCH,
    SCRIPT_SCHEDULE_TASK_EDIT,
    SCRIPT_EDIT_SCHEDULE_SUBMIT,
    SCRIPT_NODE_TO_EXECUTE,
    SCRIPT_NODE_TO_SCHEDULE,
    OPEN_SCRIPT_DIALOG,
} from '../actionsTypes/entities/script.actionTypes';
import { showNotification } from '../actions/notification.actions';
import { recentAddModel } from '../actions/recent.actions';
import {
    SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID,
    SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES,
    SCRIPT_EXECUTE_DIALOG_UPLOAD_FILE,
} from '../actionsTypes/scriptExecuteDialog.actionTypes';
import {
    scriptExecuteDialogAddNodeId,
    scriptExecuteDialogAddNodePath,
    scriptExecuteDialogRequestSuccess,
    scriptExecuteDialogSetStatus,
} from '../actions/scriptExecuteDialog.actions';
import {
    TScriptExecuteDialogUploadFileAction,
    TScriptExecuteDialogDeleteParamFilesAction,
    TScriptExecuteDialogAddNodeId,
} from '../actions/scriptExecuteDialog.actions.types';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../actionsTypes/tabs.actionTypes';
import { workspaceAddTab, workspaceChangeTab, workspaceRemoveTab } from '../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../actions/tabs.actions.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemChildAdd } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { IScriptNode } from '../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../models/editorMode';
import { TServerEntity } from '../models/entities.types';
import { IFileDownloadedModel } from '../models/fileDownloadedModel.types';
import { NotificationType } from '../models/notificationType';
import { IStatusChangeModel } from '../models/statusChangingModel.types';
import {
    TWorkspaceTabItemParams,
    IWorkspaceTabItemScriptParams,
    TWorkspaceTab,
    TWorkspaceTabItemActions,
} from '../models/tab.types';
import { TreeNode } from '../models/tree.types';
import { SCRIPT_DIAGRAM_TYPE_ID, SCRIPT_DIAGRAM_TYPE_NAME } from '../models/tree';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { FileUploadStatus, ParameterType } from '../reducers/scriptExecuteDialog.reducer.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { ScriptSelectors } from '../selectors/script.selectors';
import { ScriptExecutionSelectors } from '../selectors/scriptExecution.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import {
    ChangeProgressMessage,
    LockInfoDTO,
    Node,
    NodeId,
    RootNodeId,
    SchedulerTask,
    SchedulerTaskInfo,
    ScriptOperationData,
    ScriptParameter,
    ScriptParameterParsingResult,
    StubResponse,
} from '../serverapi/api';
import { getStore } from '../store';
import { setServerIdToNodeOriginal, toNodeId } from '../utils/nodeId.utils';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import messages from '../modules/ScriptDashboard/messages/ScriptDashboardTable.messages';
import { IDiagramLockError } from '../models/notification/diagramLockError.types';
import { unlock } from '../actions/lock.actions';
import { getActiveModelContext } from './utils';
import { IModelContext } from './utils.types';
import { editorModeChangedAction } from '../actions/editor.actions';
import {
    TDeleteScheduledTaskAction,
    TEditScheduledTaskAction,
    TLoadScriptScheduleAction,
    TSchedulerTaskWithName,
} from '../actions/scriptScheduler.actions.types';
import { loadScriptSchedule, setLoadingScriptSchedule, setScriptSchedule } from '../actions/scriptScheduler.actions';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { TabsBusActions } from '../actionsTypes/tabsBus.actionTypes';
import { TSaveScriptContext } from '../actions/scriptContext.actions.types';
import { ScriptDAOService } from '../services/dao/ScriptDAOService';
import { WSService } from '../services/ws/WSService';
import { LocalStorageDaoService } from '../services/dao/LocalStorageDaoService';
import { SchedulerDaoService } from '@/services/dao/SchedulerDAOService';
import { TScheduleScriptDialogProps } from '@/modules/dialogs/ScheduleScriptDialog/ScheduleScriptDialog.types';
import { setOpenScriptSchedulerData } from '@/actions/openScriptSchedulerData.actions';
import { SCRIPT_DASHBOARD_DOWNLOAD_LOG } from '@/actionsTypes/scriptDashboard.actionTypes';
import { TLogFileDownloadAction } from '@/actions/scriptDashboard.actions.types';
import { BrowserDaoService } from '@/services/dao/BrowserDaoService';
import { downloadDocx } from '@/actions/app.actions';
import { DownloadUrl } from '@/modules/Header/components/Logo/logo.types';
import { openLink } from '@/utils/url.utils';
import { TScriptExecuteDialogStoreProps } from '@/modules/MainMenu/components/ScriptExecuteDialog/ScriptExecuteDialog.types';

function* handleSubscribeToScriptExecutions(action: TScriptExecutionSubscribeAction) {
    const { serverId } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    WSService.client().subscribe('script', (scriptOperationData: ScriptOperationData) => {
        getStore().dispatch(scriptsExecutionUpdateInStore([scriptOperationData]));
        getStore().dispatch(changingStatusNotificationShow(server, scriptOperationData));
    });
    WSService.client().subscribe('operation/progress', (changeProgressMessage: ChangeProgressMessage) => {
        if (changeProgressMessage.operationId) {
            getStore().dispatch(updateOperationProgressInStore(changeProgressMessage.operationId, changeProgressMessage.progress));
        }
    });
}

function* handleStatusChangeNotificationShow(action: TScriptChangingStatusNotificationShowAction) {
    const { server, scriptOperationData } = action.payload;
    const script: IScriptNode = yield call(() => server.api.script.getScript({ scriptId: scriptOperationData.scriptId! }));
    setServerIdToNodeOriginal(script, server.id);

    if (scriptOperationData.nextStep?.scriptId) {
        scriptOperationData.nextStep.scriptId.serverId = server.id;
    }

    if (scriptOperationData.status === 'SUCCESS' && !!scriptOperationData.nextStep) {
        if (!scriptOperationData.nextStep.silent) {
            yield put(openScriptDialog(server.id, scriptOperationData.id))
        }

        if (scriptOperationData.result || scriptOperationData.nextStep.silent) {
            yield put(
                showNotification({
                    id: uuid(),
                    type: NotificationType.NEW_SCRIPT_STATUS_NOTIFICATION,
                    data: { server, scriptName: script.name, scriptOperationData } as IStatusChangeModel,
                }),
            );
        }

        return;
    }

    yield put(
        showNotification({
            id: uuid(),
            type: NotificationType.NEW_SCRIPT_STATUS_NOTIFICATION,
            data: { server, scriptName: script.name, scriptOperationData } as IStatusChangeModel,
        }),
    );
}

function* handleOpenScriptDialog(action: TScriptDialogOpenAction) {
    const { serverId, scriptOperationDataId } = action.payload;
    const operationData: ScriptOperationData = yield select(ScriptExecutionSelectors.byId(serverId, scriptOperationDataId));
    const nextStep = operationData?.nextStep;
    const scriptId = operationData?.scriptId;

    yield put(
        openDialog(DialogType.SCRIPT_EXECUTE_DIALOG, {
            scriptId: nextStep?.scriptId ? {...nextStep.scriptId, serverId} : { id: scriptId, repositoryId: 'script-root-id', serverId},
            existingParams: [],
            parsedParams: nextStep?.parameters || [],
            step: nextStep?.callback,
            message: nextStep?.message,
            title: nextStep?.title,
            confirmText: nextStep?.confirmText,
            cancelText: nextStep?.cancelText,
            previousOperationId: operationData?.parentOperationDataId,
            operationId: operationData?.id,
            parameterGroups: nextStep?.parameterGroups,
            fileParams: {},
            nodeParams: {},
        } as TScriptExecuteDialogStoreProps),
    );
}

function* handleReportDownloadedNotificationShow(action: TFileDownloadedFileNotificationShowAction) {
    const { fileName, filePath } = action.payload;
    yield put(
        showNotification({
            id: uuid(),
            type: NotificationType.FILE_DOWNLOADED,
            data: { fileName, filePath } as IFileDownloadedModel,
        }),
    );
}

function* scriptRequest(serverId: string, scriptId: string) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const data: IScriptNode = yield call(() => server.api.script.getScript({ scriptId }));
    setServerIdToNodeOriginal(data, serverId);
    yield put(scriptRequestSuccess(serverId, data));

    return data;
}

function* handleScriptSaveRequest(action: TScriptDefaultRequestAction) {
    const { serverId, script, forceSave } = action.payload;

    if (!isUndefined(script)) {
        const server: TServerEntity = yield select(ServerSelectors.server(serverId));
        const actualScript = yield select(ScriptSelectors.byId(script.nodeId));
        if (!actualScript || script.src !== actualScript.src || forceSave) {
            if (actualScript) {
                script.version = actualScript.version;
            }
            try {
                const data = yield call(() => server.api.script.saveScript({ body: script }));
                setServerIdToNodeOriginal(data, serverId);
                yield put(scriptSaveRequestSuccess(serverId, data));
            } catch (e) {
                yield put(scriptsSaveRequestFailure(serverId, e.message));
                throw e;
            }
        }
    }
}

function* addRecent(script: IScriptNode) {
    yield put(
        recentAddModel({
            nodeId: script.nodeId,
            type: TreeItemType.Script,
            parentId: script.parentNodeId || null,
            createdAt: new Date().toISOString(),
            title: script.name,
            modelTypeId: SCRIPT_DIAGRAM_TYPE_ID,
            modelTypeName: SCRIPT_DIAGRAM_TYPE_NAME,
        }),
    );
}

function* handleScriptOpen(action: TScriptDefaultAction) {
    const { script } = action.payload;
    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: script.name,
        type: SCRIPT_DIAGRAM_TYPE_ID,
        nodeId: script.nodeId,
        content: script,
        params: {} as IWorkspaceTabItemScriptParams,
        mode: EditorMode.Read,
    };
    yield put(workspaceAddTab(workspaceTab));
    yield addRecent(script);
}

function* handleScriptSelectedViaNode(action: TScriptSubmittedViaNode) {
    const { scriptNodeId, nodeId, nodesIdsList, modelId, elementId, elementsIdsList, isScheduler } = action.payload;
    const { serverId } = scriptNodeId;
    const script: IScriptNode = yield scriptRequest(serverId, scriptNodeId.id);
    const existingParams = [
        { name: 'nodeId', value: nodeId ? JSON.stringify(nodeId) : '', paramType: 'NODE' } as ScriptParameter,
        {
            name: 'nodesIdsList',
            value: nodesIdsList ? JSON.stringify(nodesIdsList) : '',
            paramType: 'NODES_LIST',
        } as ScriptParameter,
        {
            name: 'modelId',
            value: modelId ? JSON.stringify(modelId) : '',
            paramType: 'NODE',
        } as ScriptParameter,
        {
            name: 'elementId',
            value: elementId || '',
            paramType: 'STRING',
        } as ScriptParameter,
        {
            name: 'elementsIdsList',
            value: elementsIdsList ? JSON.stringify(elementsIdsList) : '',
            paramType: 'STRING_LIST',
        } as ScriptParameter,
    ];

    if (isScheduler) {
        yield put(scriptNodeToSchedule({ script, existingParams }));
    } else {
        yield put(scriptNodeToExecute({ script, existingParams }));
    }
}

function* handleScriptOpenByTreeNode(action: TScriptOpenByNodeIdAction) {
    const {
        nodeId: { id, serverId },
    } = action.payload;
    try {
        const script: IScriptNode = yield scriptRequest(serverId, id);
        script.type = TreeItemType.Script;
        yield put(scriptOpen({ ...script, serverId }));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    }
}

function* handleTabScriptClose(action: TWorkspaceTabsRemoveAction) {
    const { workspaceTab } = action.payload;

    if (workspaceTab.content && workspaceTab.content.type === TreeItemType.Script) {
        const script = workspaceTab.content as IScriptNode;
        yield put(scriptClose(script));
    } else if (workspaceTab.content && workspaceTab.content.type === TreeItemType.ScriptFolder) {
        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* handleScriptClose(action: TScriptDefaultAction) {
    const { script } = action.payload;
    const workspaceTab: TWorkspaceTab = yield select(TabsSelectors.byId(script.nodeId));
    if (!isUndefined(workspaceTab)) {
        yield put(workspaceRemoveTab(workspaceTab));
        yield put(scriptRemoveSuccess(script.nodeId));
    }
}

function* handleScriptChangeLocally(action: TScriptDefaultAction) {
    const { script } = action.payload;
    const workspaceTab: TWorkspaceTab = yield select(TabsSelectors.byId(action.payload.script.nodeId));
    if (workspaceTab.content) {
        const oldScript: IScriptNode = workspaceTab.content;
        if (script.src === oldScript.src) {
            Object.assign(workspaceTab.content, script, { isDirty: true });
            yield workspaceChangeTab(workspaceTab);
        }
    }
}

function* handleScriptSave(action: TScriptDefaultAction) {
    const { script } = action.payload;
    if (!script) return;
    // Не нашел другого способа доставить новое имя, если переименование было сделано после открытия вкладки
    const oldScript: IScriptNode = yield select(ScriptSelectors.byId(script.nodeId));
    if (!isUndefined(oldScript)) {
        script.name = oldScript.name;
        script.attributes = oldScript.attributes;
        script.confidential = oldScript.confidential;
        script.parentNodeId = oldScript.parentNodeId;
    }

    yield put(scriptSaveRequest(script.serverId, script));

    const saveResult = yield take([SCRIPT_SAVE_REQUEST_SUCCESS, SCRIPT_SAVE_REQUEST_FAILURE]);
    if (saveResult.type === SCRIPT_SAVE_REQUEST_SUCCESS) {
        yield put(
            treeItemChildAdd({
                parentNodeId: {
                    ...script.nodeId,
                    id: script.parentNodeId ? script.parentNodeId.id : '',
                },
                child: [
                    {
                        ...script,
                        parentNodeId: script.parentNodeId || {},
                        hasChildren: script.countChildren && script.countChildren > 0,
                    } as TreeNode,
                ],
            }),
        );
    }
}

function* handleScriptContextSave(action: TSaveScriptContext) {
    const { scriptContext, scriptId } = action.payload;

    yield put(saveScriptContextSuccess(scriptContext, scriptId));
    yield put(closeDialog(DialogType.ADD_SCRIPT_CONTEXT));
}

function* handleScriptExecute(action: TScriptExecuteAction) {
    const {
        scriptId: { id, serverId },
        params,
        step,
        parentOperationId
    } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    try {
        yield call(() => server.api.scriptExecutor.executeScript({ scriptId: id, body: params, step, parentOperationId }));
    } catch (e) {
        yield put(scriptExecuteRequestFailure(serverId, e.message));
        throw e;
    }
}

function* handleParsedScriptParametersRequest(scriptId: string, scriptName: string) {
    const parsingResult: ScriptParameterParsingResult = yield ScriptDAOService.getParseScriptParameters(scriptId);

    if (
        parsingResult.status === 'SCRIPT_COMPILATION_ERROR' ||
        parsingResult.status === 'SCRIPT_ENGINE_IS_UNAVAILABLE'
    ) {
        yield put(
            showNotification({
                id: uuid(),
                type: NotificationType[parsingResult.status],
                data: { scriptName },
            }),
        );

        return;
    }

    const uniqParseParam: ScriptParameter[] = Array.from(
        (parsingResult.scriptParameters || [])
            .filter((sp) => sp.name)
            .reduce((map, sp) => (map.set(sp.name!, sp), map), new Map<String, ScriptParameter>())
            .values(),
    );

    const nodeParams = uniqParseParam.filter((param) => param.paramType === ParameterType.NODE);
    yield all(
        nodeParams.map((param) =>
            param.name && param.defaultValue
                ? put(scriptExecuteDialogAddNodeId(param.name, toNodeId(param.defaultValue) || ({} as NodeId)))
                : undefined,
        ),
    );

    return uniqParseParam;
}

function* handleScriptNodeToExecute(action: TScriptNodeToExecuteAction) {
    const { script, existingParams } = action.payload;

    const uniqParseParam: ScriptParameter[] | undefined = yield handleParsedScriptParametersRequest(
        script.nodeId.id,
        script.name,
    );

    if (!uniqParseParam) {
        return;
    }

    const step  = 'main'

    if (uniqParseParam?.length) {
        yield put(
            openDialog(DialogType.SCRIPT_EXECUTE_DIALOG, {
                scriptId: script.nodeId,
                existingParams,
                parsedParams: uniqParseParam || [],
                step,
            }),
        );
    } else {
        yield put(scriptExecute(script.nodeId, existingParams, step));
        yield put(scriptExecuteDialogRequestSuccess());
    }
}

function* handleScriptNodeToSchedule(action: TScriptNodeToScheduleAction) {
    const { script, existingParams } = action.payload;

    const uniqParseParam: ScriptParameter[] | undefined = yield handleParsedScriptParametersRequest(
        script.nodeId.id,
        script.name,
    );
    if (!uniqParseParam) return;

    yield put(setOpenScriptSchedulerData({ script, existingParams, parsedParams: uniqParseParam }));
}

function* handleScriptNodePathRequest(action: TScriptExecuteDialogAddNodeId) {
    const { paramName, nodeId } = action.payload;
    const { id, repositoryId, serverId } = nodeId;

    if (id && repositoryId && serverId) {
        const { path } = yield TreeDaoService.getNodePath(serverId, id, repositoryId);

        yield put(scriptExecuteDialogAddNodePath(paramName, path));
    }
}

function* handleUploadParamFile(action: TScriptExecuteDialogUploadFileAction) {
    const { file, serverId, paramName } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const newFile = new File([file], `${file.name}_${uuid()}`, { type: file.type });
    yield put(closeDialog(DialogType.SCRIPT_UPLOAD_PARAM_FILE_DIALOG));
    try {
        yield put(scriptExecuteDialogSetStatus(paramName, FileUploadStatus.LOADING));
        const response: StubResponse | undefined = yield call(() =>
            server.api.fileTmpStorage.uploadBigTmpFile({ body: { file: newFile } }),
        );
        yield put(
            scriptExecuteDialogSetStatus(paramName, FileUploadStatus.DONE, response?.result || newFile.name, file.name),
        );
    } catch (e) {
        yield put(scriptExecuteDialogSetStatus(paramName, FileUploadStatus.ERROR));
        throw e;
    }
}

function* handleDeleteParamFiles(action: TScriptExecuteDialogDeleteParamFilesAction) {
    const { serverId, fileNames } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    for (const fileName of fileNames) {
        yield call(() => server.api.fileTmpStorage.deleteUserTmpFile({ fileName }));
    }
}

function* handleTreeItemsScriptAdd({ payload: { nodeId, type, action, treeName } }: TTreeItemContextMenuAction) {
    if (type !== TreeItemType.ScriptFolder && type !== TreeItemType.Script) return;

    if (action === TreeItemContextMenuAction.ADD_SCRIPT) {
        yield put(openDialog(DialogType.SCRIPT_CREATE_DIALOG, { parentId: nodeId }));
    } else if (action === TreeItemContextMenuAction.ADD_SCRIPT_FOLDER) {
        yield put(openDialog(DialogType.FOLDER_DIALOG, { type, parentNodeId: nodeId, treeName }));
    }

    if (action === TreeItemContextMenuAction.OPEN_SCRIPT_RESULT) {
        yield put(scriptsDashboardOpen(nodeId));
    }

    if (action === TreeItemContextMenuAction.OPEN_SCRIPT_SCHEDULER) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
            title: intl.formatMessage(messages.scriptSchedulerPanel),
            type: <string>WorkSpaceTabTypes.SCRIPT_SCHEDULER,
            nodeId: {
                id: 'script-scheduler-id',
                repositoryId: 'script-scheduler-id',
                serverId: nodeId.serverId,
            },
            mode: EditorMode.Read,
            content: { serverId: nodeId.serverId },
            params: {
                closable: true,
            } as TWorkspaceTabItemParams,
        };
        yield put(workspaceAddTab(workspaceTab));
    }

    if (action === TreeItemContextMenuAction.SCRIPT_DOCUMENTATION_SCRIPTS) {
        yield put(downloadDocx(DownloadUrl.SCRIPTS_DOCX_URL));
    }
    if (action === TreeItemContextMenuAction.SCRIPT_DOCUMENTATION_API_DOCX) {
        yield put(downloadDocx(DownloadUrl.API_DOCX_URL));
    }
    if (action === TreeItemContextMenuAction.SCRIPT_DOCUMENTATION_API_HTML) {
        openLink(DownloadUrl.SWAGGER_URL);
    }
}

function* handleScriptsDashboardOpen(action: TScriptOpenByNodeIdAction) {
    const {
        nodeId: { serverId },
    } = action.payload;
    yield put(requestScriptsExecution(serverId));
    yield take(SCRIPTS_EXECUTION_SAVE_TO_STORE);
    const scriptExecutions: ScriptOperationData[] = yield select(ScriptExecutionSelectors.byServerId(serverId));
    yield put(dashboardOpen(scriptExecutions, serverId));
}

function* handleScriptsExecutionRequest(action: TScriptsExecutionRequestAction) {
    const { serverId } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    try {
        const data: ScriptOperationData[] = yield call(() => server.api.scriptOperation.results());
        yield put(scriptsExecutionSaveToStore(serverId, data));
    } catch (e) {
        yield put(scriptsExecutionRequestFailure(serverId, e.message));
        throw e;
    }
}

export function* handleScriptCreate(action: TScriptCreateAction) {
    let { params } = action.payload;
    const script: IScriptNode = {
        ...params,
        src: '',
        nodeId: {
            id: uuid(),
            repositoryId: params.parentNodeId.repositoryId,
            serverId: params.parentNodeId.serverId,
        } as NodeId,
        type: TreeItemType.Script,
        serverId: params.parentNodeId.serverId,
        isDirty: true,
        isNew: true,
    };

    try {
        const data: IScriptNode = yield call(() => ScriptDAOService.createScript(script));

        yield put(scriptSaveRequestSuccess(script.serverId, data));

        const workspaceTab: TWorkspaceTab = {
            title: data.name,
            type: WorkSpaceTabTypes.SCRIPT_EDITOR,
            nodeId: data.nodeId,
            content: data,
            params: {} as IWorkspaceTabItemScriptParams,
            mode: EditorMode.Edit,
            actions: {} as TWorkspaceTabItemActions,
        };

        yield put(workspaceAddTab(workspaceTab));

        yield put(
            treeItemChildAdd({
                parentNodeId: {
                    ...script.nodeId,
                    id: script.parentNodeId?.id || '',
                },
                child: [
                    {
                        ...script,
                        parentNodeId: script.parentNodeId,
                        hasChildren: Boolean(script.countChildren && script.countChildren > 0),
                        countChildren: script.countChildren || 0,
                    } as TreeNode,
                ],
            }),
        );

        yield addRecent(data);
        yield put(closeDialog(DialogType.SCRIPT_CREATE_DIALOG));
    } catch (e) {
        yield put(scriptsSaveRequestFailure(script.serverId, e.message));
        throw e;
    }
}

function* handleDashboardOpen(action: TDashboardOpenAction) {
    const { serverId } = action.payload;
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const workspaceTab: TWorkspaceTab = <TWorkspaceTab>{
        title: intl.formatMessage(messages.scriptExecutionPanel),
        type: <string>WorkSpaceTabTypes.SCRIPT_DASHBOARD,
        nodeId: {
            id: 'script-dashboard-fake-id',
            repositoryId: 'script-dashboard-fake-id',
            serverId
        },
        mode: EditorMode.Read,
        params: {
            closable: true,
        } as TWorkspaceTabItemParams,
    };
    yield put(workspaceAddTab(workspaceTab));
}

function* handleReportDownloadDateSet(action: TScriptSetReportDownloadDateAction) {
    const { serverId } = action.payload;
    yield delay(1000);
    yield put(requestScriptsExecution(serverId));
}

function* handleChangeMode(action: TScriptChangeModeAction) {
    const { mode, nodeId } = action.payload;
    const activeModelContext: IModelContext = yield getActiveModelContext();

    if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
        const lock: LockInfoDTO = yield ScriptDAOService.lockScript(nodeId);

        if (lock.locked) {
            yield put(
                showNotification({
                    id: uuid(),
                    type: NotificationType.DIAGRAM_LOCK_ERROR,
                    data: {
                        lockOwner: lock.ownerName,
                    } as IDiagramLockError,
                }),
            );

            return;
        }
    } else if (mode !== EditorMode.Edit && activeModelContext.schema.mode === EditorMode.Edit) {
        yield put(unlock(nodeId, TreeItemType.Script));
    }
    yield put(editorModeChangedAction(mode));
}

function* handleLoadScriptSchedule(action: TLoadScriptScheduleAction) {
    const { serverId } = action.payload;

    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    try {
        yield put(setLoadingScriptSchedule(serverId, true));

        const schedule: SchedulerTaskInfo[] = yield call(() => server.api.scheduler.getAll());

        // Костыльный запрос для получения имен скриптов
        const scriptList = yield call(() =>
            server.api.tree.loadBulk({
                body: schedule.map((task) => ({
                    id: task.scriptId,
                    repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
                    serverId,
                })),
            }),
        );

        const setScriptNameToTask = (nameList: Node[], taskList: SchedulerTaskInfo[]): TSchedulerTaskWithName[] =>
            taskList.map((task) => ({
                ...task,
                name: nameList.find((item) => item.nodeId.id === task.scriptId)?.name || '',
            }));

        yield put(
            setScriptSchedule(
                serverId,
                setScriptNameToTask(scriptList, schedule).sort((a, b) => a.id.localeCompare(b.id)),
            ),
        );
    } catch (e) {
        throw new Error(e);
    } finally {
        yield put(setLoadingScriptSchedule(serverId, false));
    }
}

function* handleScriptSchedule(action: TScriptScheduleAction) {
    const {
        scriptId: { id, serverId },
        params,
        timeSettings,
        disable,
    } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    const body: SchedulerTask = {
        id: uuid(),
        scriptId: id,
        timeSettings,
        parameters: params,
    };

    if (timeSettings) {
        body.timeSettings = timeSettings;
        body.disable = disable;
    }

    yield call(() => server.api.scheduler.create({ body }));

    yield call(() => handleLoadScriptSchedule(loadScriptSchedule(serverId)));
}

function* handleDeleteScheduledTask(action: TDeleteScheduledTaskAction) {
    const { serverId, taskIds } = action.payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    // eslint-disable-next-line
    yield all(taskIds.map((id) => call(() => server.api.scheduler._delete({ id }))));
    yield call(() => handleLoadScriptSchedule(loadScriptSchedule(serverId)));
}

function* handleEditScheduledTask(action: TEditScheduledTaskAction) {
    const { serverId, taskId, scriptId } = action.payload;

    const script: IScriptNode = yield ScriptDAOService.getScript(scriptId, serverId);
    const schedule: SchedulerTask = yield SchedulerDaoService.getSchedulerTask(taskId);

    const nodeId = schedule.parameters?.find((param) => param.name === 'nodeId' && param.value !== '')?.value;
    const nodesIdsList = schedule.parameters?.find(
        (param) => param.name === 'nodesIdsList' && param.value !== '',
    )?.value;
    const modelId = schedule.parameters?.find((param) => param.name === 'modelId' && param.value !== '')?.value;
    const existingParamsNames = ['nodeId', 'nodesIdsList', 'modelId', 'elementId', 'elementsIdsList'];

    const scheduleScriptDialog: TScheduleScriptDialogProps = {
        id: taskId,
        serverId,
        nodeId: nodeId ? JSON.parse(nodeId) : undefined,
        nodesIdsList: nodesIdsList ? JSON.parse(nodesIdsList) : undefined,
        modelId: modelId ? JSON.parse(modelId) : undefined,
        disable: schedule.disable,
        schedulerTimeSettings: schedule.timeSettings,
        isEdit: true,
        login: schedule.login,
    };

    yield put(
        setOpenScriptSchedulerData({
            script,
            existingParams:
                schedule.parameters?.filter((param) => existingParamsNames.includes(param.name || '')) || [],
            parsedParams: schedule.parameters?.filter((param) => !existingParamsNames.includes(param.name || '')) || [],
        }),
    );
    yield put(openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, scheduleScriptDialog));
}

function* handleEditScheduleSubmit(action: TScriptEditScheduleSubmitAction) {
    const { serverId, task } = action.payload;

    yield SchedulerDaoService.updateSchedulerTask(task);

    yield call(() => handleLoadScriptSchedule(loadScriptSchedule(serverId)));
}

function* handleDownloadLogFile(action: TLogFileDownloadAction) {
    const { operationId, serverId } = action.payload;

    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const response = yield call(() => server.api.scriptOperation.getLog({ id: operationId }));
    const file = yield call(() => response.blob());
    BrowserDaoService.downloadFile(file, { defaultPath: 'script-log.zip' });
}

export function* scriptSaga() {
    yield takeEvery(SCRIPT_OPEN_BY_NODE_ID, handleScriptOpenByTreeNode);
    yield takeEvery(SCRIPT_OPEN, handleScriptOpen);
    yield takeEvery(SCRIPT_CLOSE, handleScriptClose);
    yield takeEvery(SCRIPT_CHANGE_LOCALLY, handleScriptChangeLocally);

    yield takeEvery(DASHBOARD_OPEN, handleDashboardOpen);
    yield takeEvery(SCRIPTS_DASHBOARD_OPEN, handleScriptsDashboardOpen);
    yield takeEvery(SCRIPTS_EXECUTION_REQUEST, handleScriptsExecutionRequest);
    yield takeEvery(STATUS_CHANGE_NOTIFICATION_SHOW, handleStatusChangeNotificationShow);
    yield takeEvery(REPORT_DOWNLOAD_DATE_SET, handleReportDownloadDateSet);
    yield takeEvery(FILE_DOWNLOADED_NOTIFICATION_SHOW, handleReportDownloadedNotificationShow);

    yield takeEvery(SCRIPT_SAVE, handleScriptSave);
    yield takeEvery(SCRIPT_SAVE_REQUEST, handleScriptSaveRequest);
    yield takeEvery(SCRIPT_CONTEXT_FETCH, handleScriptContextSave);

    yield takeEvery(SCRIPT_EXECUTE, handleScriptExecute);

    yield takeEvery(SCRIPT_SUBMITTED_VIA_NODE, handleScriptSelectedViaNode);

    yield takeEvery(SCRIPT_CREATE, handleScriptCreate);
    yield takeEvery(SCRIPT_EXECUTION_SUBSCRIBE, handleSubscribeToScriptExecutions);

    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabScriptClose);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsScriptAdd);

    yield takeEvery(SCRIPT_EXECUTE_DIALOG_ADD_NODE_ID, handleScriptNodePathRequest);
    yield takeEvery(SCRIPT_EXECUTE_DIALOG_DELETE_PARAM_FILES, handleDeleteParamFiles);
    yield takeEvery(SCRIPT_EXECUTE_DIALOG_UPLOAD_FILE, handleUploadParamFile);
    yield takeEvery(SCRIPT_MODE_CHANGE, handleChangeMode);
    yield takeEvery(SCRIPT_SCHEDULE, handleScriptSchedule);
    yield takeEvery(SCRIPT_SCHEDULE_LOAD_ALL, handleLoadScriptSchedule);
    yield takeEvery(SCRIPT_SCHEDULE_TASK_DELETE, handleDeleteScheduledTask);
    yield takeEvery(SCRIPT_SCHEDULE_TASK_EDIT, handleEditScheduledTask);
    yield takeEvery(SCRIPT_EDIT_SCHEDULE_SUBMIT, handleEditScheduleSubmit);
    yield takeEvery(SCRIPT_NODE_TO_EXECUTE, handleScriptNodeToExecute);
    yield takeEvery(SCRIPT_NODE_TO_SCHEDULE, handleScriptNodeToSchedule);

    yield takeEvery(SCRIPT_DASHBOARD_DOWNLOAD_LOG, handleDownloadLogFile);
    yield takeEvery(OPEN_SCRIPT_DIALOG, handleOpenScriptDialog);
}
