import type { TCustomDecorator, TRichTextEditor } from './RichTextEditor.types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LinkDecorator } from './decorators/Link/LinkDecorator';
import { TextAlignDecorator } from './decorators/TextAlign/TextAlignDecorator';
import theme from './RichTextEditor.component.scss';
import { debounce } from 'lodash-es';
import { markdownToDraft } from './utils/markdownToDraft';
import { blockEntities, getBlockStyleItems } from './entities';
import getBlockRendererFn from './renderers/BlockRenderer';
import getBlockStyleFn from './renderers/BlockStyleRenderer';
import WikiEditorContextMenuComponent from './ContextMenu.component';
import { isTableBlock } from './renderers/Table/TableContentsBlocks.utils';
import { deleteAtomicBlock, getCurrentBlock, isCursorPositionInStartOfBlock } from '../common/contentBlocks.utils';
import { hasIndentionInBlock, insertNewLine, removeIndent } from '../common/indentBlocks.utils';
import { refreshEditor } from '../common/editorState.utils';
import { getBlockRenderMap } from './renderers/BlockRenderMap';
import { CommentsDecorator } from './decorators/Comment/CommentDecorator';
import getStateObserver from '../common/sharedState.class';

const SAVE_DEBOUNCE_TIME = 1000;
const customStyleMap = {
    SUPERSCRIPT: {
        verticalAlign: 'super',
        fontSize: '12px',
    },
    SUBSCRIPT: {
        verticalAlign: 'sub',
        fontSize: '12px',
    },
};

const getStateForExport = (editorState) => {
    const raw = convertToRaw(editorState.getCurrentContent());
    try {
        return JSON.stringify(raw);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Wiki export error', e);

        return '';
    }
};

const importState = (jsonState: string | undefined) => {
    if (!jsonState) {
        return EditorState.createEmpty();
    }
    let restoredRawState: RawDraftContentState;

    try {
        restoredRawState = JSON.parse(jsonState);
    } catch (e) {
        restoredRawState = markdownToDraft(jsonState, {
            remarkablePlugins: [],
            blockEntities,
            blockStyles: getBlockStyleItems(),
        });
    }

    try {
        return EditorState.createWithContent(convertFromRaw(restoredRawState));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Cant't initialize state from JSON", e, jsonState);

        return EditorState.createEmpty();
    }
};

export const RichTextEditor: FC<TRichTextEditor> = ({
    id,
    value,
    zoomLevel = 100,
    onChange,
    onRendered,
    handlers,
    CommentPopoverContent,
    disable = false,
    modelId,
}) => {
    const editorRef = useRef<typeof Editor>();
    const { imageLinkMapper, readFromClipboard, copyToClipboard } = handlers;
    const [editorState, setEditorState] = useState(importState(value));

    const shareWikiState = () => {
        const stateObserver = getStateObserver(id);
        stateObserver.share(editorRef.current);
    };

    const exportState = debounce((state) => {
        const source = getStateForExport(state);
        onChange(source);
    }, SAVE_DEBOUNCE_TIME);

    const onEditorStateChange = useCallback((state) => {
        setEditorState(state);
        exportState(state);
        shareWikiState();
    }, []);

    const changeEditorState = editorRef.current?.onChange;

    const blockRendererFn = getBlockRendererFn({
        containerElement: editorRef?.current?.wrapper,
        getEditorState: () => editorRef?.current?.state?.editorState,
        changeEditorState,
        getEditor: () => editorRef?.current,
        imageLinkMapper,
        disable,
    });

    const handleReturn = () => {
        const currentBlock = getCurrentBlock(editorState);

        if (isTableBlock(currentBlock)) {
            setEditorState(RichUtils.insertSoftNewline(editorState));

            return 'handled';
        }
        if (hasIndentionInBlock(editorState)) {
            setEditorState(insertNewLine(editorState));

            return 'handled';
        }

        return 'not-handled';
    };

    const handleKeyCommand = (event, actualEditorState) => {
        const currentBlock = getCurrentBlock(actualEditorState);

        if (isTableBlock(currentBlock)) {
            if (event === 'backspace') {
                return isCursorPositionInStartOfBlock(actualEditorState) ? 'handled' : 'not-handled';
            }
        }

        if (hasIndentionInBlock(actualEditorState)) {
            if (event === 'backspace') {
                onEditorStateChange(removeIndent(actualEditorState));

                return 'handled';
            }
        }

        if (event === 'delete' && currentBlock.getType() === 'atomic') {
            onEditorStateChange(deleteAtomicBlock(actualEditorState, currentBlock));

            return 'not-handled';
        }

        return 'not-handled';
    };

    useEffect(() => {
        if (value) {
            importState(value);
        }
    }, [value]);

    useEffect(() => {
        if (!editorRef.current) return;

        refreshEditor(editorRef.current.onChange, editorState);
        shareWikiState();
    }, [editorRef.current]);

    useEffect(() => {
        if (onRendered) {
            onRendered();
        }
    }, []);

    const [isReadOnly, setReadOnly] = useState(false);

    const onCommentClick = useCallback((isOpen) => {
        const { editor } = editorRef.current.editor;
        editor?.setAttribute('contenteditable', !isOpen);
        setReadOnly(isOpen);
    }, []);

    const isSupportComments = !!CommentPopoverContent;

    const renderPopover = (threadId: string) => {
        if (!isSupportComments) {
            return <></>;
        }

        return <CommentPopoverContent threadId={threadId} />;
    };

    const focusOnEditor = () => editorRef?.current?.editor?.focus();

    const customDecorators: TCustomDecorator[] = [LinkDecorator, TextAlignDecorator];

    if (isSupportComments) {
        customDecorators.push(CommentsDecorator({ onCommentClick, renderPopover }));
    }

    return (
        <div
            style={{ transform: `scale(${zoomLevel / 100})` }}
            className={theme.editorContainer}
            onClick={focusOnEditor}
            data-test="wiki-editor_container"
        >
            <WikiEditorContextMenuComponent
                editorState={editorState}
                changeEditorState={changeEditorState}
                readFromClipboard={readFromClipboard}
                copyToClipboard={copyToClipboard}
                modelId={modelId}
            >
                <Editor
                    readOnly={isReadOnly || disable}
                    ref={editorRef}
                    editorState={editorState}
                    handleDrop={() => true} // disable default drop behaviour
                    handleKeyCommand={handleKeyCommand}
                    toolbarHidden
                    blockRendererFn={blockRendererFn}
                    blockRenderMap={getBlockRenderMap()}
                    blockStyleFn={getBlockStyleFn}
                    editorClassName={theme.editor}
                    onEditorStateChange={onEditorStateChange}
                    customStyleMap={customStyleMap}
                    handleReturn={handleReturn}
                    customDecorators={customDecorators}
                />
            </WikiEditorContextMenuComponent>
        </div>
    );
};

export default RichTextEditor;
