import * as React from 'react';
import { WrappedComponentProps, injectIntl, FormattedMessage } from 'react-intl';
import { Table, Popover, Progress } from 'antd';
import theme from './ScriptExecutionsTable.scss';
import messages from '../../messages/ScriptDashboardTable.messages';
import statusMessages from '../../../App/messages/AppScriptExecutionStatuses.messages';
import { isUndefined } from 'is-what';
import icStatusFail from '../../../../resources/icons/ic-script-exec-fail.svg';
import icStatusSuccess from '../../../../resources/icons/ic-script-exec-success.svg';
import icStatusStopped from '../../../../resources/icons/ic-script-exec-stopped.svg';
import icStatusRunning from '../../../../resources/icons/ic-script-exec-running.svg';
import icStatusWait from '../../../../resources/icons/ic-script-exec-wait.svg';
import icEdit from '../../../../resources/icons/ic-edit.svg';
import dots from '../../../../resources/icons/dotsGrey.svg';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { NextStepParameters, NodeId, OperationDataStatusEnum, OperationProgress, PercentOperationProgress, ScriptOperationData } from '../../../../serverapi/api';
import { ColumnProps } from 'antd/es/table';
import { isEqual } from 'lodash-es';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

type TScriptExecutionsTableProps = {
    data: ScriptOperationData[];
    serverId: string;
    downloadFile: (fileId: NodeId) => void;
    downloadLogFile: (scriptExecutionId: string) => void;
    runScriptStep: (operationId: string, serverId: string) => void;
    openScript: (scriptId: NodeId) => void;
} & JSX.IntrinsicAttributes;

type TScriptExecutionsTableAllProps = WrappedComponentProps & TScriptExecutionsTableProps;

type TScriptExecutionsTableState = {
    data: ScriptOperationData[];
};

type TParameter = {
    name: string;
    value: string;
};

type TScriptExecutionColumnData = {
    scripExecutionId?: string;
    name?: string;
    statusLabel?: string;
    status?: OperationDataStatusEnum;
    statusIcon?: TSpriteSymbol;
    author?: string;
    executionResult?: NodeId;
    createdDate?: Date;
    finishedDate?: Date;
    downloadedDate?: Date;
    createdDateStr?: string;
    finishedDateStr?: string;
    downloadedDateStr?: string;
    parameters?: TParameter[];
    executionLog?: string;
    step?: string;
    nextStep?: NextStepParameters;
    scriptId?: NodeId;
    previousOperationId?: string;
    operationProgress?: OperationProgress;
};

class ScriptExecutionsTable extends React.Component<TScriptExecutionsTableAllProps, TScriptExecutionsTableState> {
    firstLoad: boolean = true;

    columns: Array<ColumnProps<TScriptExecutionColumnData>>;
    parametersColumns: Array<ColumnProps<TParameter>>;

    constructor(props: TScriptExecutionsTableAllProps) {
        super(props);

        this.columns = [
            {
                title: props.intl.formatMessage(messages.scriptColumnName),
                dataIndex: 'name',
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderScriptColumn(value, record.scriptId!);
                },
            },
            {
                width: 120,
                title: props.intl.formatMessage(messages.statusColumnName),
                dataIndex: 'statusLabel',
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderStatusColumn(value, record);
                },
            },
            {
                width: 160,
                title: props.intl.formatMessage(messages.startDateColumnName),
                dataIndex: 'createdDateStr',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 160,
                title: props.intl.formatMessage(messages.finishDateColumnName),
                dataIndex: 'finishedDateStr',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.resultColumnName),
                dataIndex: 'scripExecutionId',
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderDownloadColumn(record);
                },
            },
            {
                width: 80,
                title: props.intl.formatMessage(messages.parameters),
                dataIndex: 'parameters',
                render: (value: TParameter[]) => {
                    return this.renderParamsColumn(value);
                },
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.stepDialogColumnName),
                dataIndex: 'nextStep',
                render: (value: NextStepParameters, record: TScriptExecutionColumnData) => {
                    return this.renderStepDialogColumn(record);
                },
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.stepColumnName),
                dataIndex: 'step',
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderStepColumn(value);
                },
            },
            {
                width: 100,
                title: props.intl.formatMessage(messages.logColumnName),
                render: (value: string, record: TScriptExecutionColumnData) => {
                    return this.renderLogDownloadColumn(record);
                },
            },
        ];

        this.parametersColumns = [
            {
                width: 150,
                title: props.intl.formatMessage(messages.paramNameColumnName),
                dataIndex: 'name',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
            {
                width: 300,
                title: props.intl.formatMessage(messages.paramValueColumnName),
                dataIndex: 'value',
                render: (value: string) => {
                    return this.renderStringColumn(value);
                },
            },
        ];

        this.state = { data: props.data };
    }

    renderParamsColumn(value: TParameter[]) {
        if (value.length) {
            return (
                // tslint:disable-line
                <Popover
                    trigger="hover"
                    placement="left"
                    content={
                        // tslint:disable-line
                        <Table
                            rowKey={(record: TParameter) => `${record.name}`}
                            columns={this.parametersColumns}
                            dataSource={value}
                            size="small"
                            className={theme.table}
                            bordered
                            pagination={false}
                        />
                    }
                >
                    <div className={theme.params}>
                        <Icon className={theme.iconParams} spriteSymbol={dots} />

                    </div>
                </Popover>
            );
        }

        return (
            <div>
                <FormattedMessage {...messages.noParameters} />
            </div>
        );
    }

    renderStringColumn(value: string) {
        return (
            <div>
                <span>{value}</span>
            </div>
        );
    }

    renderScriptColumn(value: string, scriptId: NodeId) {
        return (
            <span className={theme.script_name} onClick={() => this.props.openScript(scriptId)}>
                <span>{value}</span>
                <div className={theme.icon_edit}>
                    <span>
                        <Icon spriteSymbol={icEdit} />
                    </span>
                </div>
            </span>
        );
    }

    renderStepColumn(value: string) {
        return (
            <div>
                <span>{value}</span>
            </div>
        );
    }

    renderStepDialogColumn(record: TScriptExecutionColumnData) {
        const { nextStep, scripExecutionId } = record;
        const { serverId } = this.props;
        if (!nextStep) {
            return (
                <Button size="small" disabled>
                    <FormattedMessage {...messages.noData} />
                </Button>
            );
        }

        return (
            <div>
                <Button
                    size="small"
                    onClick={() => {
                        this.props.runScriptStep(scripExecutionId!, serverId);
                    }}
                >
                    <FormattedMessage {...messages.openStepDialog} />
                </Button>
            </div>
        );
    }

    renderStatusColumn(value: string, record: TScriptExecutionColumnData) {
        if (record.status === 'RUN' && record.operationProgress && record.operationProgress.type === 'PERCENTS') {
            return (
                <div>
                    <span>
                        <Progress
                            type="circle"
                            percent={Math.floor((record.operationProgress as PercentOperationProgress).percents || 0)}
                            size={25}
                        />
                        &nbsp;
                        {value}
                    </span>
                </div>

            );
        }

        return (
            <div>
                <span>
                    <Icon className={theme.icon} spriteSymbol={record.statusIcon} />
                    &nbsp;
                    {value}
                </span>
            </div>
        );

    }

    renderDownloadColumn(record: TScriptExecutionColumnData) {
        const { executionResult } = record;
        const { serverId } = this.props;
        if (!executionResult) {
            return (
                <Button size="small" disabled>
                    <FormattedMessage {...messages.noData} />
                </Button>
            );
        }

        return (
            <Button
                size="small"
                onClick={() => {
                    this.props.downloadFile({ ...executionResult, serverId });
                }}
            >
                    <FormattedMessage {...messages.download} />
            </Button>
        );

    }

    noDataButton() {
        return (
            <Button size="small" disabled>
                <FormattedMessage {...messages.noData} />
            </Button>
        );
    }

    tableButton(onClick: () => void) {
        return (
            <Button
                size="small"
                onClick={onClick}
            >
                <FormattedMessage {...messages.download} />
            </Button>
        );
    }

    renderLogDownloadColumn(record: TScriptExecutionColumnData) {
        const { scripExecutionId } = record;

        if (!scripExecutionId) {
            return this.noDataButton();
        }

        return this.tableButton(() => this.props.downloadLogFile(scripExecutionId));
    }

    componentDidMount() {
        if (this.firstLoad) {
            this.firstLoad = false;
            const { data } = this.state;
            this.setState({ data });
        }
    }

    componentDidUpdate({ data }: Readonly<TScriptExecutionsTableAllProps>): void {
        if (!isEqual(this.state.data, data)) {
            this.setState({ data });
        }
    }

    render() {
        const { intl, serverId } = this.props;
        const { data } = this.state;

        const statuses = {
            WAIT: intl.formatMessage(statusMessages.appScriptExecutionStatusWait),
            RUN: intl.formatMessage(statusMessages.appScriptExecutionStatusRunning),
            SUCCESS: intl.formatMessage(statusMessages.appScriptExecutionStatusSuccess),
            FAIL: intl.formatMessage(statusMessages.appScriptExecutionStatusFail),
            CANCEL: intl.formatMessage(statusMessages.appScriptExecutionStatusStopped),
        };

        const statusesIcons = {
            WAIT: icStatusWait,
            RUN: icStatusRunning,
            SUCCESS: icStatusSuccess,
            FAIL: icStatusFail,
            CANCEL: icStatusStopped,
        };

        let columnsData =
            data &&
            data.map((e) => {
                const item: TScriptExecutionColumnData = {};
                item.scripExecutionId = e.id!;
                item.name = e.scriptName;
                item.statusLabel = statuses[e.status!];
                item.status = e.status;
                item.statusIcon = statusesIcons[e.status!];
                item.createdDate = new Date(e.startDate!);
                item.finishedDate = new Date(e.stopDate!);
                item.createdDateStr = new Date(e.startDate!).toLocaleString();
                item.finishedDateStr =
                    isUndefined(e.stopDate) || e.stopDate == null
                        ? intl.formatMessage(messages.noData)
                        : new Date(e.stopDate!).toLocaleString();
                item.executionResult = e.result;
                item.parameters = [];
                item.step = e.step;
                item.nextStep = e.nextStep;
                item.scriptId = { id: e.scriptId, repositoryId: 'script-root-id', serverId } as NodeId;
                item.operationProgress = e.progress;


                if (e.parameters) {
                    item.parameters = e.parameters.map((p) => ({ name: p.name, value: p.value } as TParameter));
                }

                return item;
            });

        columnsData =
            columnsData &&
            columnsData.sort((n1, n2) => {
                return (n2.createdDate?.getTime() || 0) - (n1.createdDate?.getTime() || 0);
            });

        return (
            <div className={theme.container}>
                <Table
                    rowKey={(record: TScriptExecutionColumnData) => `${record.scripExecutionId}`}
                    columns={this.columns}
                    dataSource={columnsData}
                    size="small"
                    className={theme.table}
                    bordered
                    scroll={{
                        y: 'max-content',
                        x: 'max-content',
                    }}
                    pagination={false}
                />
            </div>

        );
    }
}

const ScriptExecutionsTableWithIntl = injectIntl(ScriptExecutionsTable);

export { ScriptExecutionsTableWithIntl as ScriptExecutionsTable };
