import { nodeStatisticsLoadSuccess } from '@/actions/nodeStatistics.actions';
import { TNodeStatisticsLoadAction } from '@/actions/nodeStatistics.actions.types';
import { NODE_STATISTICS_LOAD } from '@/actionsTypes/nodeStatistics.actionTypes';
import { NodeStats } from '@/serverapi/api';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { put, takeEvery } from 'redux-saga/effects';

function* handleNodeStatisticsLoad({ payload: { nodeId } }: TNodeStatisticsLoadAction) {
    const nodeStatistics: NodeStats = yield TreeDaoService.getNodeStatistics(nodeId);
    yield put(nodeStatisticsLoadSuccess(nodeStatistics, nodeId));
}

export function* nodeStatisticsSaga() {
    yield takeEvery(NODE_STATISTICS_LOAD, handleNodeStatisticsLoad);
}
