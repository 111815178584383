import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { NodeId } from '../../../serverapi/api';
import { TreeItemType } from '../../Tree/models/tree';
import { addVisioFiles } from '../../../actions/import.actions';
import { TFileUploadDialogProps } from '../types/FileUploadDialog.types';

type TFileUploadImportDialpgOwnProps = {
    parentNodeId: NodeId;
    type: TreeItemType;
};

const mapStateToProps = (state) => ({
    multiple: true,
});

const mapDispatchToProps = (dispatch, ownProps: TFileUploadImportDialpgOwnProps): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.UPLOAD_VISIO_FILES_DIALOG)),
    onSubmit: (fileList: File[]) => {
        dispatch(closeDialog(DialogType.UPLOAD_VISIO_FILES_DIALOG));
        dispatch(addVisioFiles(fileList));
    },
});

export const FileUploadImportVisioDialogContainer = connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);
