import type { TRestoreDeletedNodeDialogProps } from './RestoreDeletedNodeDialog.types';
import type { TreeNode } from '@/models/tree.types';
import { Button, Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './RestoreDeletedNodeDialog.messages';
import theme from './RestoreDeletedNodeDialog.scss';
import { EditableText } from '@/modules/UIKit/components/EditableText/EditableText.component';
import { EditOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

export function RestoreDeletedNodeDialog(props: TRestoreDeletedNodeDialogProps) {
    const {
        open,
        nodeId,
        nodeId: { serverId, repositoryId },
        onClose,
        onSubmit,
        onChooseParent,
    } = props;
    const [restoreChildren, setRestoreChildren] = useState(false);
    const [restoreParent, setRestoreParent] = useState(true);
    const [restoreModelElements, setRestoreModelElements] = useState(true);
    const [restoreOnlySameDeleteOperation, setRestoreOnlySameDeleteOperation] = useState(false);
    const [newParentNode, setNewParentNode] = useState<TreeNode | null>(null);
    const isRestoreDisabled: boolean = !(restoreChildren || restoreParent || restoreModelElements);

    useEffect(() => {
        if (isRestoreDisabled) {
            setRestoreOnlySameDeleteOperation(false);
        }
    }, [restoreChildren, restoreParent, restoreModelElements]);

    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                    dataTest: 'restore-deleted-object-window_cancel_button',
                },
                {
                    key: 'ok',
                    onClick: () =>
                        onSubmit({
                            nodeId,
                            newParentNodeId: !restoreParent && newParentNode ? newParentNode.nodeId : null,
                            restoreChildren,
                            restoreParent,
                            restoreModelElements,
                            restoreOnlySameDeleteOperation,
                        }),
                    value: intl.formatMessage(messages.restore),
                    visualStyle: 'primary',
                    dataTest: 'restore-deleted-object-window_restore_button',
                },
            ]}
        />
    );

    const handleChooseParent = () =>
        !restoreParent &&
        onChooseParent({
            serverId,
            repositoryId,
            onSubmit: (nodeId, node) => setNewParentNode(node),
            onClear: () => setNewParentNode(null),
        });

    const newParentDisabled = !!restoreParent;

    return (
        <>
            <Dialog
                footer={footer}
                onCancel={onClose}
                open={open}
                title={intl.formatMessage(messages.title)}
                width="520px"
            >
                <div className={theme.checkbox}>
                    <Checkbox
                        data-test="check-box_restore_children"
                        onChange={(e) => setRestoreChildren(e.target.checked)}
                        checked={restoreChildren}
                    >
                        {intl.formatMessage(messages.restoreChildren)}
                    </Checkbox>
                </div>
                <div className={theme.checkbox}>
                    <Checkbox
                        data-test="check-box_restore-parent"
                        onChange={(e) => setRestoreParent(e.target.checked)}
                        checked={restoreParent}
                    >
                        {intl.formatMessage(messages.restoreParent)}
                    </Checkbox>
                </div>
                <div className={theme.checkbox}>
                    <Checkbox
                        data-test="check-box_restore-model-elements"
                        onChange={(e) => setRestoreModelElements(e.target.checked)}
                        checked={restoreModelElements}
                    >
                        {intl.formatMessage(messages.restoreModelElements)}
                    </Checkbox>
                </div>
                <div className={theme.checkbox}>
                    <Checkbox
                        data-test="check-box_restore-only-same-delete-operation"
                        onChange={(e) => setRestoreOnlySameDeleteOperation(e.target.checked)}
                        disabled={isRestoreDisabled}
                        checked={restoreOnlySameDeleteOperation}
                    >
                        {intl.formatMessage(messages.restoreOnlySameDeleteOperation)}
                    </Checkbox>
                </div>
                <div className={theme.chooseNewParent}>
                    <span>{intl.formatMessage(messages.chooseNewParent)}</span>
                    <Tooltip
                        mouseLeaveDelay={0}
                        title={newParentDisabled && intl.formatMessage(messages.chooseNewParentDisabled)}
                    >
                        <div
                            className={cx(
                                theme.editableTextContainer,
                                newParentDisabled && theme.disabledEditableTextContainer,
                            )}
                        >
                            <EditableText
                                className={theme.newParentName}
                                text={newParentNode?.name || ''}
                                disabled={newParentDisabled}
                                isUrlType
                                onClickLink={handleChooseParent}
                            />
                            <Button
                                disabled={newParentDisabled}
                                icon={<EditOutlined />}
                                onClick={handleChooseParent}
                                data-test="button_choose-new-parent"
                            />
                        </div>
                    </Tooltip>
                </div>
            </Dialog>
        </>
    );
}
