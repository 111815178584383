// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportDialog__itemType__MMcpj .ant-form-item-label label{white-space:normal}.ImportDialog__radioStyle___W_Q_{display:inline-block}.ImportDialog__radioStyle___W_Q_ label{display:block;line-height:30px}", "",{"version":3,"sources":["webpack://./src/modules/ImportDialog/components/ImportDialog/ImportDialog.scss"],"names":[],"mappings":"AAEQ,0DACI,kBAAA,CAIZ,iCACI,oBAAA,CAEA,uCACI,aAAA,CACA,gBAAA","sourcesContent":[".itemType {\r\n    :global {\r\n        .ant-form-item-label label {\r\n            white-space: normal;\r\n        }\r\n    }\r\n}\r\n.radioStyle {\r\n    display: inline-block;\r\n\r\n    label {\r\n        display: block;\r\n        line-height: 30px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemType": "ImportDialog__itemType__MMcpj",
	"radioStyle": "ImportDialog__radioStyle___W_Q_"
};
export default ___CSS_LOADER_EXPORT___;
