import { showNotificationByType } from '../actions/notification.actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { workspaceAddTab } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction } from '../modules/Tree/models/tree';
import {
    ADD_VISIO_FILES,
    IMPORT_VISIO_FILE,
    CANCEL_IMPORT_VISIO,
    DELETE_VISIO_PAGE,
} from '../actionsTypes/import.actionTypes';
import { clearDataAfterImport, deleteVisioFile, setImportVisioData } from '../actions/import.actions';
import { TAddVisioFiles, TDeleteVisioPageAction } from '../actions/import.actions.types';
import { ImportSelectors } from '../selectors/import.selectors';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import {
    EdgeType,
    NodeId,
    VisioImportRequestConflictResolutionStrategyEnum,
    VisioImportResponse,
    VisioModelMap,
} from '../serverapi/api';
import { VisioPageAdapter } from '../adapters/import/VisioPage.adapter';
import { TreeSelectors } from '../selectors/tree.selectors';
import { presetMetaDataRequest } from '../actions/notation.actions';
import { ResponseVisioFileAdapter } from '../adapters/import/ResponseVisioFile.adapter';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { edgeTypeRequestSuccess } from '../actions/edgeType.actions';
import { LocalesService } from '../services/LocalesService';
import { getCurrentLocale } from '../selectors/locale.selectors';
import messages from '../modules/FileUpload/messages/FileUploadDialog.messages';
import { NotificationType } from '../models/notificationType';
import { FileDaoService } from '../services/dao/FileDaoService';
import { treeItemRefresh } from '../actions/tree.actions';

function* handleAddVisioFiles({ payload: { fileList } }: TAddVisioFiles) {
    const nodeId: NodeId = yield select(ImportSelectors.nodeId);
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const presetId: string = yield select(TreeSelectors.presetById(nodeId));
    const storedFiles: VisioPageAdapter[][] | null | undefined = yield select(ImportSelectors.files);

    try {
        yield put(setImportVisioData({ isLoading: true }));
        yield put(presetMetaDataRequest([presetId]));

        const edgeTypes: EdgeType[] = yield call(() => server.api.edgeType.byPresetId({ presetId }));
        yield put(edgeTypeRequestSuccess(server.id, edgeTypes, presetId));

        const fileNamesList = fileList.map((file) => file.name);

        const filesUUIDs: string[] = (yield all(
            fileList.map((file) => FileDaoService.uploadTmpFile(nodeId.serverId, file)),
        )).map((resp) => resp.result);

        const response: VisioModelMap[][] = yield all(
            filesUUIDs.map((fileUUID) =>
                server.api.visio.getModelMap({ tmpFile: fileUUID, repositoryId: nodeId.repositoryId }),
            ),
        );
        const pagesFileName = (pages: VisioPageAdapter[]) => (pages?.length ? pages[0].fileName : undefined);
        const filePredicate = (name: string | undefined) => name && !fileNamesList.includes(name);
        const filteredStoredFiles = storedFiles?.filter((pages) => filePredicate(pagesFileName(pages)));

        const data = response.map((file: VisioModelMap[], index) =>
            file
                .map((page) => new VisioPageAdapter(page, fileNamesList[index], filesUUIDs[index]))
                .map((page) => ({
                    ...page,
                    objectMappers: page.objectMappers?.map((o) => ({
                        ...o,
                        silaTypeId: o.offeredSilaTypeId,
                        silaSymbolId: o.offeredSilaSymbolId,
                    })),
                    edgeMappers: page.edgeMappers?.map((e) => ({ ...e, silaTypeId: e.offeredSilaTypeId })),
                })),
        );
        yield put(setImportVisioData({ files: [...(filteredStoredFiles || []), ...data] }));
    } catch (e) {
        yield put(showNotificationByType(NotificationType.INVALID_IMPORT_VISIO));
    } finally {
        yield put(setImportVisioData({ isLoading: false }));
    }
}

function* handleOpenImportVisio({ payload: { nodeId, name, action, type } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.IMPORT_VISIO) {
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        yield put(setImportVisioData({ nodeId, presetId }));

        const contentLoadingPageTab: TWorkspaceTab = {
            title: intl.formatMessage(messages.importVisio),
            nodeId,
            type: WorkSpaceTabTypes.IMPORT_VISIO,
            mode: EditorMode.Read,
            params: {
                nodeId,
                name,
                type,
            } as TWorkspaceTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

enum ErrorTypes {
    MODEL_TYPE = 'MODEL_TYPE',
    OBJECT_TYPE = 'OBJECT_TYPE',
}

const setErrorText = (fileName, pageName, errorType, objectName, intl) => {
    if (errorType === ErrorTypes.MODEL_TYPE) {
        return `${intl.formatMessage(messages.forFile)} "${fileName}", ${intl.formatMessage(
            messages.tab,
        )}: "${pageName}" ${intl.formatMessage(messages.typeNotSelected)} ${intl.formatMessage(messages.model)}`;
    }

    if (errorType === ErrorTypes.OBJECT_TYPE) {
        return `${intl.formatMessage(messages.typeNotSelected)} "${fileName}", ${intl.formatMessage(
            messages.tab,
        )}: "${pageName}", ${intl.formatMessage(messages.object)}: "${objectName}" ${intl.formatMessage(
            messages.forFile,
        )}`;
    }

    return '';

    // intl.formatMessage(messages.typeObject)
};

function* handleImportVisioFile() {
    const nodeId: NodeId = yield select(ImportSelectors.nodeId);
    const conflictResolutionStrategy: VisioImportRequestConflictResolutionStrategyEnum = yield select(
        ImportSelectors.conflictResolutionStrategy,
    );
    const files: VisioPageAdapter[][] = yield select(ImportSelectors.files);
    const resolutionById = yield select(ImportSelectors.resolutionById);
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    const errorText: string | undefined = files.reduce((acc: string | undefined, file) => {
        if (acc) return acc;

        file.forEach((page) => {
            if (!acc) {
                page.silaModelTypeId === ''
                    ? (acc = setErrorText(page.fileName, page.visioPageName, ErrorTypes.MODEL_TYPE, '', intl))
                    : acc;

                page.objectMappers?.forEach((item) => {
                    if (!acc && item.silaSymbolId === undefined) {
                        acc = setErrorText(
                            page.fileName,
                            page.visioPageName,
                            ErrorTypes.OBJECT_TYPE,
                            item.visioMasterName,
                            intl,
                        );
                    }
                });

                page?.edgeMappers?.forEach((item) => {
                    if (!acc && item.silaTypeId === undefined) {
                        acc = setErrorText(
                            page.fileName,
                            page.visioPageName,
                            ErrorTypes.OBJECT_TYPE,
                            item.visioMasterName,
                            intl,
                        );
                    }
                });
            }
        });

        return acc;
    }, '');

    if (errorText) {
        yield put(openDialog(DialogType.IMPORT_VISIO_ERROR, { errorText }));

        return;
    }

    try {
        yield put(setImportVisioData({ isLoading: true }));

        const conflicts = {};

        for (const file of files) {
            const response: VisioImportResponse = yield call(() =>
                server.api.visio.doImport({
                    body: new ResponseVisioFileAdapter(
                        file,
                        nodeId,
                        conflictResolutionStrategy,
                        resolutionById?.[file[0].fileName!],
                    ),
                }),
            );

            if (response.status === 'OK') {
                conflicts[file[0].fileName!] = response.conflictingObjectsByObjectId;
            }

            if (response.status === 'SUCCESS') {
                yield call(() => server.api.fileTmpStorage.deleteUserTmpFile({ fileName: file[0].fileUUID! }));
                yield put(deleteVisioFile({ fileName: file[0].fileUUID! }));
            }
        }

        if (Object.keys(conflicts).length) {
            yield put(openDialog(DialogType.IMPORT_VISIO_DB_CONFLICT, { conflicts }));
        } else {
            yield put(showNotificationByType(NotificationType.SUCCESSFUL_IMPORT_VISIO));
            yield put(clearDataAfterImport());
            yield put(treeItemRefresh(nodeId));
        }
    } catch (e) {
        // todo: Добавить всплывающее окно с ошибкой
        console.warn(e);
    } finally {
        yield put(setImportVisioData({ isLoading: false }));
    }
}

function* handleCancelImportVisio() {
    const fileNamesList: string[] = yield select(ImportSelectors.fileNamesList);
    const nodeId: NodeId = yield select(ImportSelectors.nodeId);
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));

    yield put(setImportVisioData({ isLoading: true }));
    try {
        yield all(
            fileNamesList.map((fileName) => call(() => server.api.fileTmpStorage.deleteUserTmpFile({ fileName }))),
        );
    } catch (e) {
        // todo: Добавить всплывающее окно с ошибкой
        console.warn(e);
    } finally {
        yield put(
            setImportVisioData({
                isLoading: false,
                files: null,
                conflictResolutionStrategy: 'ASK_USER',
            }),
        );
    }
}

function* handleDeleteVisioFile({ payload: { fileName } }: TDeleteVisioPageAction) {
    const numberOfPages: number = yield select(ImportSelectors.getNumberOfVisioPages(fileName));
    const nodeId: NodeId = yield select(ImportSelectors.nodeId);
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));

    if (numberOfPages === 0) {
        yield call(() => server.api.fileTmpStorage.deleteUserTmpFile({ fileName }));
    }
}

export function* importSaga() {
    yield takeEvery(DELETE_VISIO_PAGE, handleDeleteVisioFile);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenImportVisio);
    yield takeEvery(ADD_VISIO_FILES, handleAddVisioFiles);
    yield takeEvery(IMPORT_VISIO_FILE, handleImportVisioFile);
    yield takeEvery(CANCEL_IMPORT_VISIO, handleCancelImportVisio);
}
