import { ContentBlock } from 'draft-js';
import CheckableListRenderer from './CheckableList/CheckableListRenderer.component';
import { CHECKABLE_LIST_ITEM } from '../../common/constants';
import { getTableBlockType, TableBlock, TablePortalBlock, TableShapeBlock } from './Table/TableRenderer.component';
import { isTableBlock, resizeTable } from './Table/TableContentsBlocks.utils';
import { ImageRenderer } from './Image/ImageRenderer.component';

export default function getBlockRendererFn({
    getEditor,
    containerElement,
    getEditorState,
    changeEditorState,
    imageLinkMapper,
    disable,
}) {
    if (!containerElement) {
        return () => {};
    }

    const onResize = (block, sizes) => {
        return changeEditorState(resizeTable(getEditorState(), block, sizes));
    };

    return (block: ContentBlock) => {
        const editorState = getEditorState();
        const type = block.getType();
        if (isTableBlock(block)) {
            const tableRenderers = {
                TableBlock: () => ({
                    component: TableBlock,
                    editable: !disable,
                }),
                PortalBlock: () => ({
                    component: TablePortalBlock,
                    props: {
                        getEditor,
                    },
                    editable: false,
                }),
                ShapeBlock: () => ({
                    component: TableShapeBlock,
                    props: {
                        onResize,
                        getEditor,
                    },
                    editable: !disable,
                }),
            };

            const tableBlockType = getTableBlockType(block);
            const renderCurrentTablePart = tableRenderers[tableBlockType];

            return renderCurrentTablePart();
        }

        if (type === CHECKABLE_LIST_ITEM) {
            return {
                component: CheckableListRenderer,
                props: {
                    block,
                    getEditorState,
                    changeEditorState,
                },
            };
        }

        if (block.getType() === 'atomic') {
            const contentState = editorState.getCurrentContent();
            const entity = contentState.getEntity(block.getEntityAt(0));
            if (entity && entity.type === 'IMAGE') {
                return {
                    component: ImageRenderer,
                    props: {
                        getEditorState,
                        changeEditorState,
                        imageLinkMapper,
                    },
                };
            }

            return undefined;
        }

        return null;
    };
}
