import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from './approvalTable.scss';
import { changeCheckAllApprovals } from '../../../actions/approval.actions';
import { NodeId } from '../../../serverapi/api';
import { ApprovalSelectors } from '../../../selectors/approval.selectors';
import { Checkbox } from '../../UIKit/components/Checkbox/Checkbox.component';

type THeadCheckBoxProps = {
    tableDataLength: number;
};

export const HeadCheckBox = (props: THeadCheckBoxProps): JSX.Element => {
    const dispatch = useDispatch();
    const { tableDataLength } = props;
    const checkedIds: NodeId[] = useSelector(ApprovalSelectors.getCheckedApprovalIds);
    return (
        <div className={theme.headerCheckBoxContainer} data-test="admin-approvals_select-all_checkbox">
            <Checkbox
                checked={!!tableDataLength && tableDataLength === checkedIds.length}
                onChange={() => {
                    dispatch(changeCheckAllApprovals());
                }}
            />
        </div>
    );
};
