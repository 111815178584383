import { TTreeItemContextMenuAction } from '@/actions/tree.actions.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '@/actionsTypes/tree.actionTypes';
import { TWorkspaceTab, TWorkspaceTabItemParams } from '@/models/tab.types';
import { TreeItemContextMenuAction, TreeItemType } from '@/modules/Tree/models/tree';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';
import { put, select, takeEvery } from 'redux-saga/effects';
import { defaultWorkspaceTabActions } from '../models/tab';
import messages from '../modules/Tree/messages/TreeContextMenu.messages';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { EditorMode } from '@/models/editorMode';
import { workspaceAddTab, workspaceRemoveTab } from '@/actions/tabs.actions';
import { SearchResult } from '@/serverapi/api';
import { SearchDaoService } from '@/services/dao/SearchDAOService';
import {
    addSearchedDeletedElements,
    eraseSearchedDeletedElementsSuccess,
    restoreSearchedDeletedElementsSuccess,
} from '@/actions/searchedDeletedElements.actions';
import {
    ERASE_SEARCHED_DELETED_ELEMENTS,
    RESTORE_SEARCHED_DELETED_ELEMENTS,
    SEARCH_DELETED_ELEMENTS,
} from '@/actionsTypes/searchedDeletedElements.actionTypes';
import {
    TEraseSearchedDeletedElementsAction,
    TRestoreSearchedDeletedElementsAction,
    TSearchDeletedElementsAction,
} from '@/actions/searchedDeletedElements.actions.types';
import { closeDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { treeItemRefresh } from '@/actions/tree.actions';
import { TreeDaoService } from '@/services/dao/TreeDaoService';
import { getSearchedElementsByRepositoryIdById } from '@/selectors/searchedDeletedElements.selectors';
import { getContentLoadingPageTab } from './utils';
import { NotificationType } from '@/models/notificationType';
import { showNotificationByType } from '@/actions/notification.actions';
import { SearchDeletedElementsBllService } from '@/services/bll/SearchDeletedElementsBLLService';

function* handleOpenSearchDeletedElementsTab({ payload: { nodeId, name, action, type } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.DB_SEARCH_DELETED_ELEMENTS) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);
        contentLoadingPageTab.title = `${intl.formatMessage(messages.deletedElements)}`;
        yield put(workspaceAddTab(contentLoadingPageTab));

        const searchResult: SearchResult[] = yield SearchDaoService.getExtendedSearchResponse({
            rootSearchNodeId: nodeId,
            searchVisibility: 'DELETED',
            includePath: true,
            includeCount: false,
        });

        yield put(addSearchedDeletedElements({ repositoryId: nodeId.repositoryId, id: nodeId.id, searchResult }));

        const deletedElementsTab: TWorkspaceTab = {
            title: `${intl.formatMessage(messages.deletedElements)}`,
            nodeId,
            type: WorkSpaceTabTypes.DB_SEARCH_DELETED_ELEMENTS,
            mode: EditorMode.Read,
            params: {
                nodeId,
                name,
                type,
            } as TWorkspaceTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield put(workspaceRemoveTab(contentLoadingPageTab));
        yield put(workspaceAddTab(deletedElementsTab));
    }
}

function* handleSearchDeletedElements({ payload: { nodeId, searchText, nodeTypes } }: TSearchDeletedElementsAction) {
    const searchResult: SearchResult[] = yield SearchDaoService.getExtendedSearchResponse({
        rootSearchNodeId: nodeId,
        searchVisibility: 'DELETED',
        includePath: true,
        includeCount: false,
        searchText,
        nodeTypes,
    });

    yield put(addSearchedDeletedElements({ repositoryId: nodeId.repositoryId, id: nodeId.id, searchResult }));
}

function* handleEraseSearchedDeletedElements({
    payload: { nodeId, nodeIdsToErase },
}: TEraseSearchedDeletedElementsAction) {
    try {
        yield put(closeDialog(DialogType.CONFIRMATION));
        yield TreeDaoService.erase(nodeIdsToErase);

        const searchResult: SearchResult[] = yield select(
            getSearchedElementsByRepositoryIdById(nodeId.repositoryId, nodeId.id),
        );

        const idsToDeleteFromStore = SearchDeletedElementsBllService.getIdsToDeleteOnEraseNodes(
            nodeIdsToErase.map(({ id }) => id),
            searchResult,
        );

        yield put(
            eraseSearchedDeletedElementsSuccess({
                repositoryId: nodeId.repositoryId,
                id: nodeId.id,
                idsToDelete: idsToDeleteFromStore,
            }),
        );

        yield put(treeItemRefresh(nodeId, TreeItemType.Repository));
    } catch (error) {
        yield put(showNotificationByType(NotificationType.ERASE_NODE));
    }
}

function* handleRestoreSearchedDeletedElements({
    payload: { nodeId, nodeIdsToRestore },
}: TRestoreSearchedDeletedElementsAction) {
    try {
        yield TreeDaoService.restore(nodeIdsToRestore, {
            restoreParent: true,
            restoreModelElements: true,
        });

        const searchResult: SearchResult[] = yield select(
            getSearchedElementsByRepositoryIdById(nodeId.repositoryId, nodeId.id),
        );

        const idsToDeleteFromStore = SearchDeletedElementsBllService.getIdsToDeleteOnRestoreNodes(
            nodeId.repositoryId,
            nodeIdsToRestore.map(({ id }) => id),
            searchResult,
        );

        yield put(
            restoreSearchedDeletedElementsSuccess({
                repositoryId: nodeId.repositoryId,
                id: nodeId.id,
                idsToDelete: idsToDeleteFromStore,
            }),
        );

        yield put(treeItemRefresh(nodeId, TreeItemType.Repository));
    } catch (error) {
        yield put(showNotificationByType(NotificationType.RESTORE_NODE));
    }
}

export function* searchDeletedElementsSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenSearchDeletedElementsTab);
    yield takeEvery(SEARCH_DELETED_ELEMENTS, handleSearchDeletedElements);
    yield takeEvery(ERASE_SEARCHED_DELETED_ELEMENTS, handleEraseSearchedDeletedElements);
    yield takeEvery(RESTORE_SEARCHED_DELETED_ELEMENTS, handleRestoreSearchedDeletedElements);
}
