import { ModelType, ModelTypeGroup } from '../serverapi/api';
import {
    DELETE_MODEL_TYPE_GROUP,
    DELETE_MODEL_TYPE_GROUP_REQUEST,
    DELETE_MODEL_TYPE_GROUP_SUCCESS,
    EDIT_MODEL_TYPE_GROUP,
    MODEL_TYPE_REQUEST_SUCCESS,
} from '../actionsTypes/modelType.actionTypes';
import {
    TDeleteModelTypeGroupAction,
    TDeleteModelTypeGroupPayload,
    TDeleteModelTypeGroupRequestAction,
    TDeleteModelTypeGroupRequestPayload,
    TDeleteModelTypeGroupSuccessAction,
    TEditModelTypeGroupAction,
    TEditModelTypeGroupPayload,
    TModelTypeRequestSuccessAction,
} from './modelType.actions.types';

export const modelTypeRequestSuccess = (
    serverId: string,
    modelTypes: ModelType[],
    presetId: string,
    groups: ModelTypeGroup[],
): TModelTypeRequestSuccessAction => ({
    type: MODEL_TYPE_REQUEST_SUCCESS,
    payload: {
        serverId,
        modelTypes,
        presetId,
        groups,
    },
});

export const deleteModelTypeGroupRequest = (payload: TDeleteModelTypeGroupRequestPayload): TDeleteModelTypeGroupRequestAction => ({
    type: DELETE_MODEL_TYPE_GROUP_REQUEST,
    payload,
});

export const deleteModelTypeGroup = (payload: TDeleteModelTypeGroupPayload): TDeleteModelTypeGroupAction => ({
    type: DELETE_MODEL_TYPE_GROUP,
    payload,
});

export const deleteModelTypeGroupSuccess = (
    payload: TDeleteModelTypeGroupRequestPayload,
): TDeleteModelTypeGroupSuccessAction => ({
    type: DELETE_MODEL_TYPE_GROUP_SUCCESS,
    payload,
});

export const editModelTypeGroup = (payload: TEditModelTypeGroupPayload): TEditModelTypeGroupAction => ({
    type: EDIT_MODEL_TYPE_GROUP,
    payload,
});
