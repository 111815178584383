import { MatrixDataBPM8764, MatrixLane } from '@/serverapi/api';
import { useEffect, useRef, useState } from 'react';
import { MIN_HEADER_SIZE, MIN_LANE_SIZE } from '../utils/Matrix.constants';
import { HeaderResizeDirections, TColResizeData, THeaderResizeData, TRowResizeData } from './Matrix.types';

type TMatrixResizeProps = {
    matrixData: MatrixDataBPM8764 | undefined;
    rowsHeaders: MatrixLane[];
    colsHeaders: MatrixLane[];
    updateMatrixData: (data: MatrixDataBPM8764) => void;
};

export const useMatrixResize = ({ matrixData, colsHeaders, rowsHeaders, updateMatrixData }: TMatrixResizeProps) => {
    const initColHeaderHeight: number = matrixData?.columnHeaderHeight || MIN_HEADER_SIZE;
    const initRowHeaderWidth: number = matrixData?.rowHeaderWidth || MIN_HEADER_SIZE;

    const headerResizeDataRef = useRef<THeaderResizeData>({ isResizing: false });
    const setHeaderResizeData = (data: THeaderResizeData) => {
        headerResizeDataRef.current = data;
    };
    const [actualHeaderSizes, setActualHeaderSizes] = useState<{ width: number; height: number }>({
        width: initRowHeaderWidth,
        height: initColHeaderHeight,
    });

    const colResizeDataRef = useRef<TColResizeData>({ id: '', isResizing: false });
    const setColResizeData = (data: TColResizeData) => {
        colResizeDataRef.current = { ...colResizeDataRef.current, ...data };
    };
    const [currentColWidth, setCurrentColWidth] = useState<number>(MIN_LANE_SIZE);

    const rowResizeDataRef = useRef<TRowResizeData>({ id: '', isResizing: false });
    const setRowResizeData = (data: TRowResizeData) => {
        rowResizeDataRef.current = { ...rowResizeDataRef.current, ...data };
    };
    const [currentRowHeight, setCurrentRowHeight] = useState<number>(MIN_LANE_SIZE);

    useEffect(() => {
        if (!headerResizeDataRef.current.isResizing) {
            setActualHeaderSizes({ width: initRowHeaderWidth, height: initColHeaderHeight });
        }
    }, [initColHeaderHeight, initRowHeaderWidth]);

    useEffect(() => {
        const mouseMoveHandler = (e: MouseEvent) => {
            if (!matrixData) return;

            if (colResizeDataRef.current.isResizing) {
                setCurrentColWidth((prevColWidth) => prevColWidth + e.movementX);
            }

            if (rowResizeDataRef.current.isResizing) {
                setCurrentRowHeight((prevRowHeight) => prevRowHeight + e.movementY);
            }

            const headerResizeData = headerResizeDataRef.current;
            if (headerResizeData.isResizing) {
                if (headerResizeData.direction === HeaderResizeDirections.horizontal) {
                    setActualHeaderSizes(({ width, height }) => ({
                        width: width + e.movementX,
                        height,
                    }));
                }
                if (headerResizeData.direction === HeaderResizeDirections.vertical) {
                    setActualHeaderSizes(({ width, height }) => ({
                        width,
                        height: height + e.movementY,
                    }));
                }
                if (headerResizeData.direction === HeaderResizeDirections.both) {
                    setActualHeaderSizes(({ width, height }) => ({
                        width: width + e.movementX,
                        height: height + e.movementY,
                    }));
                }
            }
        };

        const mouseUpHandler = (e: MouseEvent) => {
            if (!matrixData) return;

            if (rowResizeDataRef.current.isResizing) {
                setRowResizeData({ isResizing: false });
                const currentRow: MatrixLane | undefined = rowsHeaders.find(
                    (lane) => lane.id === rowResizeDataRef.current.id,
                );
                if (currentRow) {
                    setCurrentRowHeight((prevRowHeight) => {
                        currentRow.headerSize = prevRowHeight + e.movementY;
                        updateMatrixData(matrixData);
                        return currentRow.headerSize;
                    });
                }
            }

            if (colResizeDataRef.current.isResizing) {
                setColResizeData({ isResizing: false });
                const currentCol: MatrixLane | undefined = colsHeaders.find(
                    (lane) => lane.id === colResizeDataRef.current.id,
                );
                if (currentCol) {
                    setCurrentColWidth((prevColWidth) => {
                        currentCol.headerSize = prevColWidth + e.movementX;
                        updateMatrixData(matrixData);
                        return currentCol.headerSize;
                    });
                }
            }

            const headerResizeData = headerResizeDataRef.current;
            if (headerResizeData.isResizing) {
                setHeaderResizeData({ isResizing: false });

                if (headerResizeData.direction === HeaderResizeDirections.horizontal) {
                    setActualHeaderSizes(({ height, width }) => {
                        matrixData.rowHeaderWidth = width + e.movementX;
                        updateMatrixData(matrixData);
                        return { width: matrixData.rowHeaderWidth, height };
                    });
                }

                if (headerResizeData.direction === HeaderResizeDirections.vertical) {
                    setActualHeaderSizes(({ height, width }) => {
                        matrixData.columnHeaderHeight = height + e.movementY;
                        updateMatrixData(matrixData);
                        return { width, height: matrixData.columnHeaderHeight };
                    });
                }
                if (headerResizeData.direction === HeaderResizeDirections.both) {
                    setActualHeaderSizes(({ height, width }) => {
                        matrixData.rowHeaderWidth = width + e.movementX;
                        matrixData.columnHeaderHeight = height + e.movementY;
                        updateMatrixData(matrixData);
                        return { width: matrixData.rowHeaderWidth, height: matrixData.columnHeaderHeight };
                    });
                }
            }
        };
        document.addEventListener('mouseup', mouseUpHandler);
        document.addEventListener('mousemove', mouseMoveHandler);

        return () => {
            document.removeEventListener('mouseup', mouseUpHandler);
            document.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [
        matrixData,
        rowsHeaders,
        colsHeaders,
        colResizeDataRef.current,
        rowResizeDataRef.current,
        headerResizeDataRef.current,
    ]);

    return {
        colResizeDataRef,
        rowResizeDataRef,
        currentColWidth,
        currentRowHeight,
        actualHeaderSizes,
        setHeaderResizeData,
        setColResizeData,
        setRowResizeData,
        setCurrentColWidth,
        setCurrentRowHeight,
    };
};
