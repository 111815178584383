import { TServerEntity } from "../../models/entities.types";
import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';

export namespace ServerSelectors {
    const serverState = (state: TRootState) => state.entities.server;

    export const serverById = createSelector(serverState, (state) => state.byId);

    export const server = (id: string) => createSelector(serverById, (serverMap) => serverMap[id]);

    export const serverList = createSelector(serverById, (serverMap) => Object.values(serverMap));
    
    export const serverId = createSelector<TRootState, TServerEntity[], string>(serverList, (list) => list[0].id || '');

    export const connected = createSelector(serverState, (state) => state.connected);

    export const connectedServers = createSelector(connected, serverById, (connectedServersIds, serverMap) => {
        return connectedServersIds.map((id) => serverMap[id]);
    });

    export const disconnected = createSelector(serverState, (state) => state.disconnected);

    export const pending = createSelector(serverState, (state) => state.pending);

    export const serverByUrl = (url: string) =>
        createSelector(serverState, (state) => Object.values(state.byId).find((srv) => srv.url === url));
}
