import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUsersRequest, openUserDataEditingTab } from '../../../../../actions/users.actions';
import { UserDataEdititngTabMode } from '../../../../../actions/users.actions.types';
import { MetaDataSelectors } from '../../../../../selectors/admintools.selectors';
import { UserDTO } from '../../../../../serverapi/api';
import { ContentLoadingPageTab } from '../../../../Workspace/components/ContentLoadingPageTab/ContentLoadingPageTab.component';
import messages from '../../messages/userManagment.messages';
import { UsersSelectors } from '../../../../../selectors/users.selectors';
import theme from './UserList.scss';
import { sortByAlpha } from '../../../../../utils/sortByAlpha';
import { SUPERADMIN_LOGIN } from '../../../data/admintool';
import { StatusList } from '../userManagment.constances';
import { TableUIKit } from '../../../../UIKit/components/Table/TableUIKit.component';
import { uniqBy } from 'lodash-es';
import { TColumn, TTableData } from '../../../../UIKit/components/Table/TableUIKit.types';
import { Button } from '../../../../UIKit/components/Button/Button.component';
import icEdit from '../../../../../resources/icons/edit.svg';

type TUserList = {
    status: StatusList;
    searchInput: string;
    checkedUsers: UserDTO[];
    setCheckedUsers: (users: UserDTO[]) => void;
};

const UserList: FC<TUserList> = (props) => {
    const { status, searchInput, checkedUsers, setCheckedUsers } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const userList: UserDTO[] | undefined = useSelector(UsersSelectors.getAll(serverId));
    const loading = useSelector(UsersSelectors.fetchAllUsersLoading());
    const [firstMount, setFirstMount] = useState(true);

    useEffect(() => {
        dispatch(fetchAllUsersRequest({ serverId }));
        setFirstMount(false);
    }, []);

    if (loading || firstMount) {
        return <ContentLoadingPageTab />;
    }

    const renderRowBtns = (userData: UserDTO) => {
        return (
            <div className={theme.buttonsBox}>
                <Button
                    visualStyle={{ type: 'text' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openUserDataEditingTab({ userData, mode: UserDataEdititngTabMode.EDIT }));
                    }}
                    icon={icEdit}
                    dataTest="open-user-editing-tab-button"
                ></Button>
            </div>
        );
    };

    const statusFilter = (srcData: UserDTO[]) => {
        switch (status) {
            case StatusList.notBlocked:
                return srcData.filter((user) => !user.blocked);
            case StatusList.blocked:
                return srcData.filter((user) => user.blocked);
            default:
                return srcData;
        }
    };

    const getDataSource = (): UserDTO[] => {
        const filteredData = statusFilter(userList);

        const dataSource: UserDTO[] =
            searchInput === ''
                ? filteredData
                : filteredData.filter(
                      (item) =>
                          [item.login, item.id, item.lastName, item.firstName, item.middleName].filter((s) => {
                              if (!s) return false;
                              const lowerCased = `${s}`.toLowerCase();

                              return lowerCased.includes(searchInput);
                          }).length,
                  );

        return dataSource;
    };

    const handleCheckUsers = (rows: TTableData[], checked: boolean) => {
        const users: UserDTO[] = userList.filter((user) => rows.find((row) => row.id === user.id));
        if (checked) {
            setCheckedUsers(uniqBy([...checkedUsers, ...users], 'id'));
        } else {
            setCheckedUsers(checkedUsers.filter((user) => !rows.find((row) => row.id === user.id)));
        }
    };

    const tableData: TTableData[] = getDataSource().map((user) => ({
        id: user.id,
        login: user.login,
        lastName: user.lastName,
        firstName: user.firstName,
        middleName: user.middleName,
        blocked: user.blocked ? intl.formatMessage(messages.blocked) : intl.formatMessage(messages.notBlocked),
        manageBtn: renderRowBtns(user),
        checked: !!checkedUsers.find((checkedUser) => checkedUser.id === user.id),
        disabled: user.login === SUPERADMIN_LOGIN,
    }));

    const columns: TColumn[] = [
        {
            dataKey: 'id',
            title: intl.formatMessage(messages.userId),
            sortFunction: (a: TTableData, b: TTableData) => (a.id as number) - (b.id as number),
            defaultSort: 'ASC',
        },
        {
            dataKey: 'login',
            title: intl.formatMessage(messages.userLogin),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.login as string, b.login as string),
        },
        {
            dataKey: 'lastName',
            title: intl.formatMessage(messages.lastName),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.lastName as string, b.lastName as string),
        },
        {
            dataKey: 'firstName',
            title: intl.formatMessage(messages.firstName),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.firstName as string, b.firstName as string),
        },
        {
            dataKey: 'middleName',
            title: intl.formatMessage(messages.middleName),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.middleName as string, b.middleName as string),
        },
        {
            dataKey: 'blocked',
            title: intl.formatMessage(messages.blockedColumn),
            sortFunction: (a: TTableData, b: TTableData) => sortByAlpha(a.blocked as string, b.blocked as string),
        },
        {
            dataKey: 'manageBtn',
            title: '',
            sortFunction: undefined,
            withoutSorter: true,
            width: 40,
        },
    ];

    return <TableUIKit columns={columns} tableData={tableData} withCkeckBox checkRows={handleCheckUsers} />;
};

export default UserList;
