export const findNextActiveOption = (
    index: number | null,
    optionsDisabled: boolean[],
    direction: 'up' | 'down',
): number | null => {
    if (optionsDisabled.every((optionDisaibled) => optionDisaibled)) return null;
    if (direction === 'down') {
        if (index === null) return optionsDisabled.findIndex((optionDisaibled) => !optionDisaibled);
        if (index < 0 || index > optionsDisabled.length) return null;
        if (index === optionsDisabled.length - 1) return index;

        const nextIndex: number = optionsDisabled.findIndex((optionDisaibled, ind) => index < ind && !optionDisaibled);
        if (nextIndex === -1) return index;
        return nextIndex;
    } else {
        if (index === null) return optionsDisabled.findLastIndex((optionDisaibled) => !optionDisaibled);
        if (index < 0 || index > optionsDisabled.length) return null;
        if (index === 0) return index;

        const nextIndex: number = optionsDisabled.findLastIndex(
            (optionDisaibled, ind) => index > ind && !optionDisaibled,
        );
        if (nextIndex === -1) return index;
        return nextIndex;
    }
};
