export enum RuleType {
    HAS_VALUE = 'HAS_VALUE',
    HAS_NOT_VALUE = 'HAS_NOT_VALUE',
    EQUALS = 'EQUALS',
    NOT_EQUALS = 'NOT_EQUALS',
    GREATER = 'GREATER',
    GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
    LESS = 'LESS',
    LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    STARTS_WITH = 'STARTS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    BETWEEN = 'BETWEEN',
    NOT_BETWEEN = 'NOT_BETWEEN',
}

export enum AttributeValueType {
    NUMERIC = 'NUMERIC',
    STRING = 'STRING',
    PRINCIPAL = 'PRINCIPAL',
    MULTI_STRING = 'MULTI_STRING',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    TIME = 'TIME',
    DATE_TIME = 'DATE_TIME',
    PERIOD = 'PERIOD',
    SELECT = 'SELECT',
    MULTI_SELECT = 'MULTI_SELECT',
    JSON = 'JSON',
    NODE = 'NODE',
    URL = 'URL',
    INTEGER = 'INTEGER',
    FILE = 'FILE',
    QUERY_SELECT = 'QUERY_SELECT',
    QUERY_MULTI_SELECT = 'QUERY_MULTI_SELECT',
    TIME_WITHOUT_TIMEZONE = 'TIME_WITHOUT_TIMEZONE',
    DATE_TIME_WITHOUT_TIMEZONE = 'DATE_TIME_WITHOUT_TIMEZONE',
}
