import type { TAttributesAware, TGetterFn, IPropertyDescriptor } from './property-descriptor.types';
import type { BPMMxGraph } from '../../mxgraph/bpmgraph';
import type { MxCell } from '../../mxgraph/mxgraph';
import type { ObjectDefinitionImpl, ObjectInstanceImpl } from '../bpm/bpm-model-impl';
import type { IModelNode } from '../bpm/bpm-model-impl.types';
import type { Locale } from '../../modules/Header/components/Header/header.types';
import type { TCurrentUserProfile } from '../../reducers/userProfile.reducer.types';
import type { TEdgeInstanceWithEdgeType } from './accessible-properties.types';
import type { TNavigatorPropertiesData } from '../navigatorPropertiesSelectorState.types';
import type {
    AttributeType,
    AttributeValue,
    AttributeValueString,
    EdgeDefinitionNode,
    EdgeType,
    FolderNode,
    InternationalString,
    ModelNode,
    Node,
    ObjectType,
    ScriptNode,
} from '../../serverapi/api';
import { buildPropDescriptorByAttribute } from './property-descriptor';
import { TPropertyDescriptor } from './TPropertyDescriptor';
import edgeTypeDirectionMessages from '../edge-type-direction.messages';
import propertiesMessages from '../accessible-properties.messages';
import edgeTypeMessages from '../../modules/MainMenu/messages/EdgeTypesName.messages';
import { isUndefined } from 'is-what';
import { modelService } from '../../services/ModelService';
import modelTypeMessages from '../modelType.messages';
import { LocalesService } from '../../services/LocalesService';
import { UNKNOWN_ATTRIBUTE_TYPE } from '../../utils/consts';
import { CellTypes } from '../../utils/bpm.mxgraph.utils';
import { options, systemAttributeFolderType } from './accessible-properties.constants';

/**
 * добавлен костыль-код для обработки параметра timeMillis ввиде строкового представления даты НЕ в формате ISO8601,
 * при вызове функции getModelWithAttributes в ответе createdAt и updatedAt.
 * желательно чтобы с сервера поля дат приходили ввиде меток времени как ожидается в оригинале.
 * @param timeMillis
 * @param locale
 */
const dateToAttributeValue = (
    timeMillis: string | number | undefined,
    id: string,
    locale: string | undefined,
): AttributeValue => {
    const intl = LocalesService.useIntl();

    if (timeMillis && timeMillis !== 'null') {
        const date = isNaN(Number(timeMillis))
            ? new Date(`${(timeMillis as string).split(' ').join('T')}+00:00`).toLocaleString(locale, options)
            : new Date(Number(timeMillis)).toLocaleString(locale, options);

        return {
            id,
            typeId: 'SYSTEM',
            valueType: 'STRING',
            value: date,
        } as AttributeValue;
    }

    return {
        id,
        typeId: 'SYSTEM',
        valueType: 'STRING',
        value: intl.formatMessage(propertiesMessages.EmptyValue),
    };
};

const valueToAttributeValue = (val: string | undefined, id: string): AttributeValue => {
    const intl = LocalesService.useIntl();

    return {
        id,
        typeId: 'SYSTEM',
        valueType: 'STRING',
        value: val?.toString() ?? intl.formatMessage(propertiesMessages.EmptyValue),
    };
};

const booleanToAttributeValue = (val: boolean | undefined, id: string): AttributeValue => {
    const intl = LocalesService.useIntl();

    return {
        id,
        typeId: 'SYSTEM',
        valueType: 'BOOLEAN',
        value: val?.toString() ?? intl.formatMessage(propertiesMessages.EmptyValue),
    };
};

const stringToAttributeValue = (str: InternationalString | undefined, id: string, locale?: string): AttributeValue => {
    return {
        id,
        typeId: 'SYSTEM',
        valueType: 'STRING',
        value: LocalesService.internationalStringToString(str, locale as Locale) || '',
        str,
    } as AttributeValueString;
};

const getSymbolTypeProperties = (symbolName: string | undefined) => {
    const intl = LocalesService.useIntl();

    return new TPropertyDescriptor('symbol', 'Symbol type', 'STRING', false, true, () =>
        valueToAttributeValue(symbolName || intl.formatMessage(propertiesMessages.EmptyValue), 'symbol'),
    );
};

export const PROP_ID = new TPropertyDescriptor('nodeId', 'ID', 'STRING', false, true, (obj: Node) =>
    valueToAttributeValue(obj.nodeId?.id, 'nodeId'),
);

export const PROP_CONFIDENTIAL = new TPropertyDescriptor(
    'confidential',
    'Confidential',
    'BOOLEAN',
    true,
    true,
    (node: Node) => booleanToAttributeValue(node.confidential, 'confidential'),
);

export const PROP_NAME = new TPropertyDescriptor(
    'multilingualName',
    'Name',
    'STRING',
    false,
    true,
    (node: Node, locale?: string) => stringToAttributeValue(node.multilingualName, 'multilingualName', locale),
);

export const PROP_USER_EDIT_DISABLED = new TPropertyDescriptor(
    'userEditDisabled',
    'UserEditDisabled',
    'BOOLEAN',
    false,
    true,
    (node: Node) => booleanToAttributeValue(node.userEditDisabled, 'userEditDisabled'),
);

export const PROP_SCRIPT_ENGINE_EDIT_DISABLED = new TPropertyDescriptor(
    'scriptEngineEditDisabled',
    'ScriptEngineEditDisabled',
    'BOOLEAN',
    false,
    true,
    (node: Node) => booleanToAttributeValue(node.scriptEngineEditDisabled, 'scriptEngineEditDisabled'),
);

export const PROP_MODEL_TYPE = new TPropertyDescriptor(
    'modelTypeId',
    'Model Type',
    'STRING',
    false,
    true,
    (obj: IModelNode) => valueToAttributeValue(obj.modelType ? obj.modelType.name : obj.modelTypeId, 'modelTypeId'),
);

export const PROP_MATRIX_TYPE = new TPropertyDescriptor(
    'modelTypeId',
    'Model Type',
    'STRING',
    false,
    true,
    (node: Node) => {
        const intl = LocalesService.useIntl();

        return valueToAttributeValue(
            modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]),
            'modelTypeId',
        );
    },
);

export const PROP_WIKI_TYPE = new TPropertyDescriptor(
    'modelTypeId',
    'Model Type',
    'STRING',
    false,
    true,
    (node: Node) => {
        const intl = LocalesService.useIntl();

        return valueToAttributeValue(
            modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]),
            'modelTypeId',
        );
    },
);

export const PROP_SPREADSHEET_TYPE = new TPropertyDescriptor(
    'modelTypeId',
    'Model Type',
    'STRING',
    false,
    true,
    (node: Node) => {
        const intl = LocalesService.useIntl();

        return valueToAttributeValue(
            modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]),
            'modelTypeId',
        );
    },
);

export const PROP_KANBAN_TYPE = new TPropertyDescriptor(
    'modelTypeId',
    'Model Type',
    'STRING',
    false,
    true,
    (node: Node) => {
        const intl = LocalesService.useIntl();

        return valueToAttributeValue(
            modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]),
            'modelTypeId',
        );
    },
);

export const PROP_CREATED_AT = new TPropertyDescriptor(
    'createdAt',
    'Created At',
    'STRING',
    false,
    true,
    (obj: ObjectDefinitionImpl, locale?: string) => dateToAttributeValue(obj.createdAt, 'createdAt', locale),
);

export const PROP_PUBLISHED_AT = new TPropertyDescriptor(
    'publishedAt',
    'Published At',
    'STRING',
    false,
    true,
    (model: ModelNode, locale?: string) => dateToAttributeValue(model.publishedAt, 'publishedAt', locale),
);

export const PROP_CREATED_BY = new TPropertyDescriptor('createdBy', 'Created By', 'STRING', false, true);

export const PROP_PUBLISHED_BY = new TPropertyDescriptor('publishedBy', 'Published By', 'STRING', false, true);

export const PROP_UPDATED_AT = new TPropertyDescriptor(
    'updatedAt',
    'Updated At',
    'STRING',
    false,
    true,
    (obj: ObjectDefinitionImpl, locale?: string) => dateToAttributeValue(obj.updatedAt, 'updatedAt', locale),
);

export const PROP_UPDATED_BY = new TPropertyDescriptor('updatedBy', 'Updated By', 'STRING', false, true);

const edgeTypeGetter: TGetterFn = ({
    edgeType,
    edgeDefinitionName,
    edgeTypeId,
}: EdgeDefinitionNode & TEdgeInstanceWithEdgeType) => {
    const intl = LocalesService.useIntl();

    if (edgeDefinitionName)
        if (!edgeTypeMessages[edgeDefinitionName]) {
            return valueToAttributeValue(edgeDefinitionName, 'edgeType');
        } else {
            return valueToAttributeValue(intl.formatMessage(edgeTypeMessages[edgeDefinitionName]), 'edgeType');
        }

    if (isUndefined(edgeType)) {
        return valueToAttributeValue(edgeTypeId, 'edgeType');
    }
    const { localizableName } = edgeType;
    if (!edgeTypeMessages[localizableName]) {
        return valueToAttributeValue(localizableName, 'edgeType');
    }

    return valueToAttributeValue(intl.formatMessage(edgeTypeMessages[localizableName]), 'edgeType');
};

export const PROP_EDGE_TYPE = new TPropertyDescriptor('edgeType', 'Edge Type', 'STRING', false, true, edgeTypeGetter);

export const PROP_KEY_EDGE_LINE_TYPE = 'lineType';

export const PROP_KEY_EDGE_START_ARROW = 'startArrow';

export const PROP_KEY_EDGE_END_ARROW = 'endArrow';

export const PROP_KEY_EDGE_LINKED_OBJECT_TYPES = 'linkedObjectTypes';

const edgeDirectionGetter: TGetterFn = ({ edgeType }: TEdgeInstanceWithEdgeType): AttributeValue => {
    const intl = LocalesService.useIntl();

    if (isUndefined(edgeType)) {
        return valueToAttributeValue(intl.formatMessage(propertiesMessages.EmptyValue), 'direction');
    }

    return valueToAttributeValue(intl.formatMessage(edgeTypeDirectionMessages[edgeType.direction]), 'direction');
};

export const PROP_EDGE_TYPE_DIRECTION = new TPropertyDescriptor(
    'direction',
    'Direction',
    'STRING',
    false,
    true,
    edgeDirectionGetter,
);

export const PROP_EDGE_LINKED_OBJECT_TYPES = new TPropertyDescriptor(
    'linkedObjectTypes',
    'Linked Object Types',
    'STRING',
    false,
    true,
    () => {
        const intl = LocalesService.useIntl();

        return valueToAttributeValue(intl.formatMessage(propertiesMessages.EmptyValue), 'linkedObjectTypes');
    },
);

export const PROP_SCRIPT_LANGUAGE = new TPropertyDescriptor(
    'language',
    'Script language',
    'STRING',
    false,
    true,
    (obj: ScriptNode) => {
        const intl = LocalesService.useIntl();

        return valueToAttributeValue(obj.language || intl.formatMessage(propertiesMessages.EmptyValue), 'language');
    },
);

export const PROP_FOLDER_TYPE = new TPropertyDescriptor(
    'folderType',
    'Folder type',
    'SELECT',
    true,
    true,
    (fn: FolderNode) => {
        return {
            id: 'folderType',
            typeId: systemAttributeFolderType,
            valueType: 'SELECT',
            value: fn.folderType,
        } as AttributeValue;
    },
);

const OBJECT_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    // PROP_DESCRIPTION,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
];

const EDGE_PROPERTIES = [
    PROP_NAME,
    // PROP_DESCRIPTION,
    PROP_EDGE_TYPE,
    PROP_EDGE_TYPE_DIRECTION,
    PROP_EDGE_LINKED_OBJECT_TYPES,
];

const MODEL_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    PROP_MODEL_TYPE,
    // PROP_DESCRIPTION,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
    PROP_PUBLISHED_BY,
    PROP_PUBLISHED_AT,
];
const SETTINGS_PROPERTIES = [PROP_USER_EDIT_DISABLED, PROP_SCRIPT_ENGINE_EDIT_DISABLED];

const MATRIX_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    PROP_MATRIX_TYPE,
    // PROP_DESCRIPTION,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
];

const WIKI_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    PROP_WIKI_TYPE,
    // PROP_DESCRIPTION,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
];

const SPREADSHEET_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    PROP_SPREADSHEET_TYPE,
    // PROP_DESCRIPTION,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
];

const COMMON_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    PROP_FOLDER_TYPE,
    // PROP_DESCRIPTION,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
];

const EDGE_DEFINITION_PROPERTIES = [
    PROP_NAME,
    PROP_EDGE_TYPE,
    UNKNOWN_ATTRIBUTE_TYPE,
    PROP_ID,
    PROP_UPDATED_BY,
    PROP_UPDATED_AT,
    PROP_CREATED_BY,
    PROP_CREATED_AT,
    PROP_CONFIDENTIAL,
];

const KANBAN_PROPERTIES = [
    PROP_ID,
    PROP_NAME,
    PROP_KANBAN_TYPE,
    PROP_CREATED_AT,
    PROP_CREATED_BY,
    PROP_UPDATED_AT,
    PROP_UPDATED_BY,
    PROP_CONFIDENTIAL,
];

const SCRIPT_PROPERTIES = [...COMMON_PROPERTIES, PROP_SCRIPT_LANGUAGE];

const fillUpAttributes = (
    navProperties: TNavigatorPropertiesData,
    accessibleObj: TAttributesAware | undefined,
    locale: Locale,
    attributeTypes: AttributeType[],
    userProfile: TCurrentUserProfile | undefined,
): TNavigatorPropertiesData => {
    return (accessibleObj?.attributes || []).reduce((acc: TNavigatorPropertiesData, attributeValue: AttributeValue) => {
        const type: AttributeType = attributeTypes?.find((t) => t.id === attributeValue.typeId) || {
            ...UNKNOWN_ATTRIBUTE_TYPE,
            name: attributeValue.id,
            multilingualName: LocalesService.changeLocaleValue(undefined, locale, attributeValue.id),
        };

        if (!(userProfile?.attributeTypeAcls?.[type.id]?.read ?? true)) {
            return { ...acc };
        }
        const descriptor = buildPropDescriptorByAttribute({ ...attributeValue, typeId: type.id }, type);

        return {
            ...acc,
            [attributeValue.id]: descriptor.buildNavigatorPropertyValue(accessibleObj, locale),
        };
    }, navProperties);
};

const instanceAttributes =
    (properties: Array<IPropertyDescriptor>) =>
    (
        attr: TAttributesAware | undefined,
        locale: Locale,
        attributeTypes: AttributeType[],
        userProfile: TCurrentUserProfile | undefined,
        isEdgeDefinition: boolean = false,
    ): TNavigatorPropertiesData => {
        const result: TNavigatorPropertiesData = properties.reduce(
            (acc: TNavigatorPropertiesData, prop: IPropertyDescriptor) => {
                if (prop.isDefined?.(attr, locale)) {
                    const navValue = prop.buildNavigatorPropertyValue(attr, locale);

                    return { ...acc, [prop.key]: navValue };
                }

                return acc;
            },
            {},
        );
        if (isEdgeDefinition) return result;

        return fillUpAttributes(result, attr, locale, attributeTypes, userProfile);
    };

const settingAttributes =
    (properties: Array<IPropertyDescriptor>) =>
    (attr: TAttributesAware | undefined, locale: Locale): TNavigatorPropertiesData => {
        const result: TNavigatorPropertiesData = properties.reduce(
            (acc: TNavigatorPropertiesData, prop: IPropertyDescriptor) => {
                if (prop.isDefined?.(attr, locale)) {
                    const navValue = prop.buildNavigatorPropertyValue(attr, locale);

                    return { ...acc, [prop.key]: navValue };
                }

                return acc;
            },
            {},
        );

        return result;
    };

export const buildObjectProperties = (
    objectDef: TAttributesAware,
    locale: Locale,
    objectType: ObjectType | undefined,
    attributeTypes: AttributeType[],
    userProfile: TCurrentUserProfile | undefined,
    objectInstance?: ObjectInstanceImpl | undefined,
    symbolName?: string | undefined,
): TNavigatorPropertiesData => {
    const PROP_SYMBOL_TYPE = getSymbolTypeProperties(symbolName);

    const OBJECT_INSTANCE_PROPERTIES = [
        PROP_SYMBOL_TYPE,
        PROP_ID,
        PROP_NAME,
        PROP_CREATED_AT,
        PROP_CREATED_BY,
        PROP_UPDATED_AT,
        PROP_UPDATED_BY,
        PROP_CONFIDENTIAL,
    ];

    const PROP_OBJECT_TYPE = new TPropertyDescriptor('objectType', 'Object Type', 'STRING', false, true, () =>
        valueToAttributeValue(objectType?.name || (objectDef as ObjectDefinitionImpl).objectTypeId, 'objectType'),
    );
    const properties = objectInstance ? [...OBJECT_INSTANCE_PROPERTIES] : [...OBJECT_PROPERTIES];
    properties.splice(2, 0, PROP_OBJECT_TYPE);
    const objectDefAttrs = objectDef.attributes || [];
    const objectInstanceAttrs = objectInstance?.attributes || [];
    const allAttr = [...objectDefAttrs, ...objectInstanceAttrs];

    return instanceAttributes(properties)({ ...objectDef, attributes: allAttr }, locale, attributeTypes, userProfile);
};

export const buildModelProperties = instanceAttributes(MODEL_PROPERTIES);

export const buildSettingsProperties = settingAttributes(SETTINGS_PROPERTIES);

export const buildCommonProperties = instanceAttributes(COMMON_PROPERTIES);

export const buildPropertiesForEdgeDefinition = (
    edgeDefinition: EdgeDefinitionNode,
    locale: Locale,
    attributeTypes: AttributeType[],
    userProfile: TCurrentUserProfile | undefined,
): TNavigatorPropertiesData => {
    return instanceAttributes(EDGE_DEFINITION_PROPERTIES as TPropertyDescriptor[])(
        edgeDefinition,
        locale,
        attributeTypes,
        userProfile,
        true,
    );
};

export const buildScriptProperties = instanceAttributes(SCRIPT_PROPERTIES);

export const buildMatrixProperties = instanceAttributes(MATRIX_PROPERTIES);

export const buildWikiProperties = instanceAttributes(WIKI_PROPERTIES);

export const buildSpreadsheetProperties = instanceAttributes(SPREADSHEET_PROPERTIES);

export const buildKanbanProperties = instanceAttributes(KANBAN_PROPERTIES);

export const buildEdgeDefinitionProperties = (
    edgeDef: TAttributesAware,
    locale: Locale,
    edgeType: EdgeType | undefined,
    attributeTypes: AttributeType[],
    userProfile: TCurrentUserProfile | undefined,
): TNavigatorPropertiesData => {
    const intl = LocalesService.useIntl();
    const EDGE_TYPE = new TPropertyDescriptor('edgeType', 'Edge Type', 'STRING', false, true, () =>
        valueToAttributeValue(edgeType?.name || (edgeDef as EdgeDefinitionNode).edgeTypeId, 'edgeType'),
    );
    const EDGE_TYPE_DIRECTION = new TPropertyDescriptor('direction', 'Direction', 'STRING', false, true, () => {
        if (isUndefined(edgeType)) {
            return valueToAttributeValue(intl.formatMessage(propertiesMessages.EmptyValue), 'direction');
        }

        return valueToAttributeValue(intl.formatMessage(edgeTypeDirectionMessages[edgeType.direction]), 'direction');
    });

    const properties = [...EDGE_PROPERTIES, ...COMMON_PROPERTIES];
    properties.splice(1, 1, EDGE_TYPE);
    properties.splice(2, 1, EDGE_TYPE_DIRECTION);
    const actualProperties = properties.filter((el) => el.key !== 'linkedObjectTypes');

    return instanceAttributes(actualProperties)(edgeDef, locale, attributeTypes, userProfile);
};

export const buildEdgeProperties =
    (graph: BPMMxGraph, cell: MxCell) =>
    (
        edgeInstance: TEdgeInstanceWithEdgeType,
        locale: Locale,
        attributeTypes: AttributeType[],
        userProfile: TCurrentUserProfile | undefined,
    ): TNavigatorPropertiesData => {
        const objectTypes = modelService().linkedObjectTypes(cell.getValue(), graph);
        const objectTypesStr =
            objectTypes
                ?.filter((it) => it)
                .map((it) => it.name)
                .join('; ') || '';
        const linkedObjectTypesDescriptor = new TPropertyDescriptor(
            PROP_KEY_EDGE_LINKED_OBJECT_TYPES,
            'Linked Object Types',
            'STRING',
            false,
            true,
            () => valueToAttributeValue(objectTypesStr, PROP_KEY_EDGE_LINKED_OBJECT_TYPES),
        );

        return instanceAttributes([
            ...EDGE_PROPERTIES,
            // lineStyleDescriptor,
            linkedObjectTypesDescriptor,
            // startArrowDescriptor,
            // endArrowDescriptor,
        ])(edgeInstance, locale, attributeTypes, userProfile, true);
    };

export const buildInstanceProperties = (
    value: ObjectInstanceImpl | TEdgeInstanceWithEdgeType,
    locale: Locale,
    attributeTypes: AttributeType[],
    userProfile: TCurrentUserProfile | undefined,
    symbolName?: string | undefined,
): TNavigatorPropertiesData => {
    const isObject = value.type === CellTypes.Object;
    const PROP_SYMBOL_TYPE = isObject ? Array(getSymbolTypeProperties(symbolName)) : [];

    return instanceAttributes(PROP_SYMBOL_TYPE)(value, locale, attributeTypes, userProfile);
};
