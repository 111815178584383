import type { TRichTextEditorHandlers } from '../RichTextEditor.types';
import type { Editor } from '@tiptap/core';
import { getHTMLFromFragment } from '@tiptap/core';
import { v4 as uuid } from 'uuid';

export const getSelectedText = (editor: Editor | null): string => {
    if (!editor) {
        return '';
    }

    const { view, state } = editor;
    const { from, to } = view.state.selection;

    return state.doc.textBetween(from, to, '');
};

export const getSelectedContentHtml = (editor: Editor | null): string => {
    const selection = editor?.view?.state?.selection;
    const content = selection?.content().content;

    return content && editor?.schema ? getHTMLFromFragment(content, editor?.schema) : '';
};

export const handleCut = (editor: Editor | null, { copyToClipboard }: TRichTextEditorHandlers, withStyles: boolean) => {
    const getContent = withStyles ? getSelectedContentHtml : getSelectedText;

    copyToClipboard(getContent(editor));
    editor?.commands.deleteSelection();
};

export const handleCopy = (
    editor: Editor | null,
    { copyToClipboard }: TRichTextEditorHandlers,
    withStyles: boolean,
) => {
    // const getContent = (editor) => editor?.view?.state?.selection?.content().content;
    const getContent = withStyles ? getSelectedContentHtml : getSelectedText;
    // generateHTML
    // generateJSON
    // generateText
    copyToClipboard(getContent(editor));
    // copyToClipboard(JSON.stringify(getContent(editor)));
};

export const handlePaste = (
    editor: Editor | null,
    { readFromClipboard }: TRichTextEditorHandlers,
    withStyles: boolean,
) =>
    // TODO исправить, работает некорректно
    readFromClipboard((text: string) => {
        // const content = JSON.parse(text);
        const content = text;
        const selection = editor?.view?.state?.selection;

        if (!selection) {
            return;
        }
        // editor?.commands.deleteSelection();
        if (text.length > 0) {
            editor?.commands.insertContent(content);
            // editor
            //     ?.chain()
            //     .command(({ tr }) => {
            //         // tr.insert(selection.from, content);
            //         tr.replaceWith(selection.from, selection.to, content)

            //         return true;
            //     })
            //     .run();
            // editor?.commands.insertContentAt({ from: selection.from - 1, to: selection.to }, JSON.parse(text), {
            //     updateSelection: true,
            //     parseOptions: { preserveWhitespace: true },
            // });
        }
    });

export const handleComment = (editor: Editor | null, { openComment }) => {
    const threadId = editor?.getAttributes('comment').threadId || uuid();
    const commentId = uuid();

    openComment(commentId, threadId, () => editor?.commands.setComment(threadId, commentId));
};
