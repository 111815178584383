import {
    ADD_ATTRIBUTE_TYPES,
    ADD_MODEL_TYPE_EDGES,
    ADD_SYMBOLS_MODEL_TYPE,
    CHANGE_MODEL_TYPE_DESCRIPTION,
    CHANGE_MODEL_TYPE_ENABLE_MODEL,
    CHANGE_MODEL_TYPE_GROUP,
    CHANGE_MODEL_TYPE_GROUP_SYMBOL,
    CHANGE_MODEL_TYPE_ID,
    CHANGE_MODEL_TYPE_NAME,
    ADD_STORE_EDIT_MODEL_TYPE_WORKSPACE_TAB,
    DELETE_MODEL_TYPE_EDGES,
    DELETE_SYMBOL_EDIT_MODEL_TYPE,
    REMOVE_ATTRIBUTE_TYPES,
    REMOVE_ATTRIBUTE_TYPES_GROUPS,
    CHANGE_MODEL_TYPE_ALLOW_ANY_OBJECT,
    CHANGE_MODEL_TYPE_SYNONYMS_IDS,
    CHANGE_MODEL_TYPE_ALLOW_APPROVALS,
    CHANGE_MODEL_TYPE_AUTO_CREATE_EDGE,
    CHANGE_MODEL_TYPE_ALLOW_DRAW_INVISIBLE_EDGES,
} from '../../actionsTypes/workspaceTab/editModelTypeWorkspaceTab.actionTypes';
import {
    TAddAttributeTypesAction,
    TAddAttributeTypesPayload,
    TAddModelTypeEdgesAction,
    TAddModelTypeEdgesActionPayload,
    TAddSymbolsModelTypeAction,
    TAddSymbolsModelTypePayload,
    TChangeModelTypeDescriptionAction,
    TChangeModelTypeDescriptionPayload,
    TChangeModelTypeEnableModelAction,
    TChangeModelTypeEnableModelPayload,
    TChangeModelTypeGroupAction,
    TChangeModelTypeGroupPayload,
    TChangeModelTypeGroupSymbolAction,
    TChangeModelTypeGroupSymbolPayload,
    TChangeModelTypeIdAction,
    TChangeModelTypeIdPayload,
    TChangeModelTypeNameAction,
    TChangeModelTypeNamePayload,
    TDeleteSymbolEditModelTypeAction,
    TDeleteSymbolEditModelTypePayload,
    TRemoveAttributeTypesAction,
    TRemoveAttributeTypesGroupsAction,
    TRemoveAttributeTypesGroupsPayload,
    TRemoveAttributeTypesPayload,
    TRemoveModelTypeEdgesAction,
    TRemoveModelTypeEdgesActionPayload,
    TAddStoreEditModelTypeWorkspaceTabAction,
    TAddStoreEditModelTypeWorkspaceTabPayload,
    TChangeModelTypeAllowAnyObjectAction,
    TChangeModelTypeAllowAnyObjectPayload,
    TChangeModelTypeSynonymsIdsPayload,
    TChangeModelTypeSynonymsIdsAction,
    TChangeModelTypeAllowApprovalsPayload,
    TChangeModelTypeAllowApprovalsAction,
    TChangeModelTypeAutoCreateEdgePayload,
    TChangeModelTypeAutoCreateEdgeAction,
    TChangeModelTypeAllowDrawInvisibleEdgesAction,
    TChangeModelTypeAllowDrawInvisibleEdgesPayload,
} from './editModelTypeWorkspaceTab.actions.types';

export const addStoreEditModelTypeWorkspaceTab = (
    payload: TAddStoreEditModelTypeWorkspaceTabPayload,
): TAddStoreEditModelTypeWorkspaceTabAction => ({
    type: ADD_STORE_EDIT_MODEL_TYPE_WORKSPACE_TAB,
    payload,
});

export const deleteSymbolEditModelType = (
    payload: TDeleteSymbolEditModelTypePayload,
): TDeleteSymbolEditModelTypeAction => ({
    type: DELETE_SYMBOL_EDIT_MODEL_TYPE,
    payload,
});

export const changeModelTypeName = (payload: TChangeModelTypeNamePayload): TChangeModelTypeNameAction => ({
    type: CHANGE_MODEL_TYPE_NAME,
    payload,
});

export const changeModelTypeId = (payload: TChangeModelTypeIdPayload): TChangeModelTypeIdAction => ({
    type: CHANGE_MODEL_TYPE_ID,
    payload,
});

export const changeModelTypeSynonymsId = (
    payload: TChangeModelTypeSynonymsIdsPayload,
): TChangeModelTypeSynonymsIdsAction => ({
    type: CHANGE_MODEL_TYPE_SYNONYMS_IDS,
    payload,
});

export const changeModelTypeDescription = (
    payload: TChangeModelTypeDescriptionPayload,
): TChangeModelTypeDescriptionAction => ({
    type: CHANGE_MODEL_TYPE_DESCRIPTION,
    payload,
});

export const changeModelTypeGroup = (payload: TChangeModelTypeGroupPayload): TChangeModelTypeGroupAction => ({
    type: CHANGE_MODEL_TYPE_GROUP,
    payload,
});

export const changeModelTypeGroupSymbol = (
    payload: TChangeModelTypeGroupSymbolPayload,
): TChangeModelTypeGroupSymbolAction => ({
    type: CHANGE_MODEL_TYPE_GROUP_SYMBOL,
    payload,
});

export const changeModelTypeEnableModel = (
    payload: TChangeModelTypeEnableModelPayload,
): TChangeModelTypeEnableModelAction => ({
    type: CHANGE_MODEL_TYPE_ENABLE_MODEL,
    payload,
});

export const changeModelTypeAllowAnyObject = (
    payload: TChangeModelTypeAllowAnyObjectPayload,
): TChangeModelTypeAllowAnyObjectAction => ({
    type: CHANGE_MODEL_TYPE_ALLOW_ANY_OBJECT,
    payload,
});

export const addAttributeTypes = (payload: TAddAttributeTypesPayload): TAddAttributeTypesAction => ({
    type: ADD_ATTRIBUTE_TYPES,
    payload,
});

export const removeAttributeTypes = (payload: TRemoveAttributeTypesPayload): TRemoveAttributeTypesAction => ({
    type: REMOVE_ATTRIBUTE_TYPES,
    payload,
});

export const removeAttributeTypesGroups = (
    payload: TRemoveAttributeTypesGroupsPayload,
): TRemoveAttributeTypesGroupsAction => ({
    type: REMOVE_ATTRIBUTE_TYPES_GROUPS,
    payload,
});

export const addSymbolsModelType = (payload: TAddSymbolsModelTypePayload): TAddSymbolsModelTypeAction => ({
    type: ADD_SYMBOLS_MODEL_TYPE,
    payload,
});

export const removeModelTypeEdges = (payload: TRemoveModelTypeEdgesActionPayload): TRemoveModelTypeEdgesAction => ({
    type: DELETE_MODEL_TYPE_EDGES,
    payload,
});

export const addModelTypeEdges = (payload: TAddModelTypeEdgesActionPayload): TAddModelTypeEdgesAction => ({
    type: ADD_MODEL_TYPE_EDGES,
    payload,
});

export const changeModelTypeAllowApprovals = (
    payload: TChangeModelTypeAllowApprovalsPayload,
): TChangeModelTypeAllowApprovalsAction => ({
    type: CHANGE_MODEL_TYPE_ALLOW_APPROVALS,
    payload,
});

export const changeModelTypeAutoCreateEdge = (
    payload: TChangeModelTypeAutoCreateEdgePayload,
): TChangeModelTypeAutoCreateEdgeAction => ({
    type: CHANGE_MODEL_TYPE_AUTO_CREATE_EDGE,
    payload,
});

export const changeModelTypeAllowDrawInvisibleEdges = (
    payload: TChangeModelTypeAllowDrawInvisibleEdgesPayload,
): TChangeModelTypeAllowDrawInvisibleEdgesAction => ({
    type: CHANGE_MODEL_TYPE_ALLOW_DRAW_INVISIBLE_EDGES,
    payload,
});
