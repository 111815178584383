import { defineMessages } from 'react-intl';

export default defineMessages({
    today: {
        id: 'ClearJournal.today',
        defaultMessage: 'Сегодня',
    },
    currentMonth: {
        id: 'ClearJournal.currentMonth',
        defaultMessage: 'Текущий месяц',
    },
    startDate: {
        id: 'ClearJournal.startDate',
        defaultMessage: 'Начальная дата',
    },
    endDate: {
        id: 'ClearJournal.endDate',
        defaultMessage: 'Конечная дата',
    },
    deleteAuditTitle: {
        id: 'ClearJournal.deleteAuditTitle',
        defaultMessage: 'Удаление событий аудита',
    },
    deleteAudit: {
        id: 'ClearJournal.deleteAudit',
        defaultMessage: 'Удалить события аудита',
    },
    deleteSessionTitle: {
        id: 'ClearJournal.deleteSessionTitle',
        defaultMessage: 'Удаление сессий пользователя',
    },
    deleteSession: {
        id: 'ClearJournal.deleteSession',
        defaultMessage: 'Удалить сессии пользователя',
    },
    yes: {
        id: 'ClearJournal.yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'ClearJournal.no',
        defaultMessage: 'Нет',
    },
    password: {
        id: 'ClearJournal.password',
        defaultMessage: 'Пароль superadmin',
    },
    missingRequiredParameter: {
        id: 'ClearJournal.missingRequiredParameter',
        defaultMessage: 'Введите пароль',
    },
    rangeError: {
        id: 'ClearJournal.rangeError',
        defaultMessage: 'Период должен быть заполнен и должен быть старше 6 месяцев',
    },
});
