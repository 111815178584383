import type { TRichTextEditorProps } from './RichTextEditor.types';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';
import { EditorContent, useEditor, Editor, Content } from '@tiptap/react';
import { Color } from '@tiptap/extension-color';
import { TextStyle } from './extensions/text-style';
import { LineHeight } from './extensions/line-height';
import { Indent } from './extensions/indent';
import { Link } from './extensions/link';
import { Image } from './extensions/image';
import { Table } from './extensions/table';
import { TableCell } from './extensions/table-cell';
import { TableHeader } from './extensions/table-header';
import { Comment } from './extensions/comment';
import { Code } from './extensions/code';
import { TableOfContents } from './extensions/table-of-contents';
import Document from '@tiptap/extension-document';
import FontFamily from '@tiptap/extension-font-family';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import TableRow from '@tiptap/extension-table-row';
import Heading from '@tiptap/extension-heading';
import Blockquote from '@tiptap/extension-blockquote';
import Gapcursor from '@tiptap/extension-gapcursor';
import History from '@tiptap/extension-history';
import { getStateObserver } from '../common/sharedState.class';
import { ContextMenu } from './components/ContextMenu.component';
import theme from './RichTextEditor.component.scss';
import { isInternalLink } from '@/utils/url.utils';
import { IMAGE_NODE_NAME } from './extensions/constants';
import { getFullHierarchicalIndexes } from './extensions/table-of-contents.utils';

const SAVE_DEBOUNCE_TIME = 1000;
const importState = (jsonState: string | undefined): Content => {
    try {
        return jsonState ? JSON.parse(jsonState) : '';
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Wiki import error', e);

        return '';
    }
};

const getStateForExport = (editor: Editor): string => {
    try {
        return JSON.stringify(editor.getJSON());
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Wiki export error', e);

        return '';
    }
};

const extensions = [
    Color,
    TextStyle,
    LineHeight.configure({ types: ['paragraph'], defaultLineHeight: '1.2' }),
    Indent.configure({ types: ['paragraph', IMAGE_NODE_NAME] }),
    Document,
    Paragraph.configure({ HTMLAttributes: { class: 'wiki-block' } }),
    Text,
    TextAlign.configure({ types: ['paragraph', IMAGE_NODE_NAME, 'listItem', 'heading'] }),
    FontFamily,
    Bold,
    Italic,
    Underline,
    Strike,
    Superscript,
    Subscript,
    Code,
    BulletList,
    OrderedList,
    ListItem,
    TaskList,
    TaskItem.configure({ nested: true }),
    Table.configure({ resizable: true, allowTableNodeSelection: true, HTMLAttributes: { class: 'wiki-block' } }),
    TableRow,
    TableHeader,
    TableCell,
    Heading,
    Blockquote,
    TableOfContents.configure({ getIndex: getFullHierarchicalIndexes }),
    Gapcursor,
    History.configure({ depth: 1000 }),
];

export const RichTextEditor: FC<TRichTextEditorProps> = ({
    id,
    value,
    zoomLevel = 100,
    onChange,
    onRendered,
    handlers,
    CommentPopoverContent,
    modelId,
}) => {
    const [editorState] = useState<Content>(importState(value));
    const { readFromClipboard, copyToClipboard, openComment, openInternalLink, uploadImage } = handlers;

    const shareEditorState = (editor: Editor) => {
        const stateObserver = getStateObserver(id);
        stateObserver.share(editor);
    };

    const onEditorStateChange = useCallback(({ editor }) => {
        shareEditorState(editor);
        // TODO вызывать только на update ?
        exportState(editor);
    }, []);

    const isSupportComments = !!CommentPopoverContent;
    const isSupportImagePaste = !!uploadImage;

    const onLinkClick = useCallback((link, href: string, target: string) => {
        if (isInternalLink(href)) {
            window.open(href, target);
            openInternalLink(href);

            return true;
        } else if (link && href) {
            window.open(href, target);

            return true;
        }

        return false;
    }, []);

    const editor: Editor | null = useEditor({
        extensions: [
            ...extensions,
            ...(isSupportComments ? [Comment.configure({ commentTooltipComponent: CommentPopoverContent })] : []),
            Image.configure({
                inline: false,
                HTMLAttributes: { class: 'wiki-block' },
                imageUpload: isSupportImagePaste ? uploadImage : undefined,
            }),
            Link.configure({ autolink: false, openOnClick: false, onLinkClick }),
        ],
        // TODO
        // при наличии неизвестных имен узлов импорт всего документа фейлится и заменяется на пустой
        // например, в мок данных можно изменить { type: "extendedImage", ... } на { type: "image", ... }
        // необходимо учесть это при настройке обратной совместимости
        // дополнить story всеми типами node и mark, чтобы зафиксировать типы данных
        content: editorState,
        onTransaction: onEditorStateChange,
    });

    const handleContainerClick = () => editor?.commands.focus();

    const exportState = debounce((editor: Editor) => {
        const source = getStateForExport(editor);

        onChange(source);
    }, SAVE_DEBOUNCE_TIME);

    useEffect(() => {
        if (!editor) {
            return;
        }

        shareEditorState(editor);
        onRendered?.(editor);
    }, [editor]);

    return (
        <div
            style={{ transform: `scale(${zoomLevel / 100})` }}
            className={theme.editorContainer}
            onClick={handleContainerClick}
            data-test="wiki-editor_container"
        >
            <ContextMenu
                editor={editor}
                // TODO вынести обращение к modelId во вне редактора
                modelId={modelId}
                readFromClipboard={readFromClipboard}
                copyToClipboard={copyToClipboard}
                openComment={openComment}
            >
                <EditorContent editor={editor} className={theme.editor} />
            </ContextMenu>
        </div>
    );
};
