import { put, race, select, take, takeEvery } from 'redux-saga/effects';
import { DRAG_FILE, CREATE_DIAGRAM_ELEMENT } from '../actionsTypes/navigatorSymbol.actionTypes';
import { TDragFileSymbolAction, TCreateDiagramElementAction } from '../actions/navigatorSymbol.actions.types';
import { FileNodeDTO, ObjectType, Symbol, UserDTO } from '../serverapi/api';
import { getActiveModelContext } from './utils';
import { IModelContext } from './utils.types';
import { dragSymbol } from '../actions/entities/objectDefinition.actions';
import { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import { getUser } from '../selectors/authorization.selectors';
import { objectTypeStateSelector } from '../selectors/objectType.selectors';
import { TObjectTypeState } from '../reducers/objectType.reducer.types';
import { v4 as uuid } from 'uuid';
import { getActiveGraph } from '../selectors/editor.selectors';
import { fileUpload } from '../actions/uploader.actions';
import { FILE_UPLOAD_FAIL, FILE_UPLOAD_SUCCESS } from '../actionsTypes/uploader.actionTypes';
import { TFileUploadSuccessAction } from '../actions/uploader.actions.types';
import { createEditorDNDHandler } from '../actions/editor.actions';
import { isImageFile } from '../utils/files.utils';

export function getObjectTypeBySymbol(
    objectTypes: TObjectTypeState,
    serverId: string,
    symbol: Symbol,
): ObjectType | null {
    let objectType: any = null;
    const objectTypesByServer = objectTypes.byServerId[serverId]; // todo: check types are loaded from server
    if (objectTypesByServer && objectTypesByServer.byPresetId) {
        const objectTypesBySymbol = Object.values(objectTypesByServer.byPresetId).reduce(
            (acc, item: { byId: { [id: string]: ObjectType } }) => {
                if (item.byId) {
                    const filtered = Object.values(item.byId).filter(
                        (objectType: ObjectType) => objectType.id === symbol.objectType,
                    );

                    return [...acc, ...filtered];
                }

                return acc;
            },
            [],
        );
        if (objectTypesBySymbol.length > 0) {
            objectType = objectTypesBySymbol[0];
        }
    }

    return objectType;
}

function* createDiagramElement(action: TCreateDiagramElementAction) {
    const modelContext: IModelContext = yield getActiveModelContext();
    const user: UserDTO | undefined = yield select(getUser);
    const objectTypes = yield select(objectTypeStateSelector);
    const symbol: Symbol = action.payload.draggedSymbol;
    const objectType: ObjectType | null = getObjectTypeBySymbol(objectTypes, modelContext.nodeId.serverId, symbol);
    const getObjectDefinitionImpl = (symbol: Symbol, objectType: ObjectType | null) =>
        new ObjectDefinitionImpl({
            nodeId: {
                ...modelContext.nodeId,
                id: uuid(),
            },
            name: symbol.name,
            multilingualName: {...symbol.multilingualName },
            objectTypeId: (objectType && objectType.id) || symbol.objectType,
            createdBy: user?.login,
            createdAt: new Date().getTime(),
            type: 'OBJECT',
            isDirty: true,
        });
    const objectDefinitions = [getObjectDefinitionImpl(symbol, objectType)];

    yield put(dragSymbol(symbol, objectDefinitions, action.payload.sourceCell));
}

function* handleDnDFile(action: TDragFileSymbolAction) {
    const { draggedFile } = action.payload;
    const activeGraphId = yield select(getActiveGraph);

    const uploadResultId = uuid();
    yield put(fileUpload(draggedFile, { ...activeGraphId, id: uploadResultId }));
    const { success }: { success: TFileUploadSuccessAction | undefined } = yield race({
        success: take(FILE_UPLOAD_SUCCESS),
        fail: take(FILE_UPLOAD_FAIL),
    });
    const uploadedFileNode: FileNodeDTO | undefined = success?.payload?.result;

    if (!uploadedFileNode || !isImageFile(uploadedFileNode)) {
        return;
    }
    if (uploadedFileNode.nodeId) {
        const { serverId } = activeGraphId;
        yield put(createEditorDNDHandler({ ...uploadedFileNode.nodeId, serverId }));
    }
}

export function* symbolNavigatorSaga() {
    yield takeEvery(CREATE_DIAGRAM_ELEMENT, createDiagramElement);
    yield takeEvery(DRAG_FILE, handleDnDFile);
}
