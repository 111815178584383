// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImportPresetDialog__itemType__W_VW8 .ant-form-item-label label{white-space:normal}.ImportPresetDialog__radio__YHVJI{display:inline-block}.ImportPresetDialog__radio__YHVJI label{display:block;line-height:30px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/Methodology/components/ImportDialog/ImportPresetDialog.scss"],"names":[],"mappings":"AAEQ,gEACI,kBAAA,CAIZ,kCACI,oBAAA,CACA,wCACI,aAAA,CACA,gBAAA","sourcesContent":[".itemType {\r\n    :global {\r\n        .ant-form-item-label label {\r\n            white-space: normal;\r\n        }\r\n    }\r\n}\r\n.radio {\r\n    display: inline-block;\r\n    label {\r\n        display: block;\r\n        line-height: 30px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemType": "ImportPresetDialog__itemType__W_VW8",
	"radio": "ImportPresetDialog__radio__YHVJI"
};
export default ___CSS_LOADER_EXPORT___;
