import { IPresetSettingsModelTypeData } from './../../reducers/presetSettings/presetSettingsModelType.reducer.types';
import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { PRESET_SETTINGS_INITIAL_MODEL_TYPE_SERVER_STATE } from '../../reducers/presetSettings/presetSettingsModelType.reducer';
import { ModelType } from '../../serverapi/api';

const modelTypeStateSelector = (state: TRootState) => state.presetSettings.modelTypes;

export namespace PresetSettingsModelTypeSelectors {
    export const byServerIdPresetId = (compositeId: { presetId: string; serverId: string }) =>
        createSelector(modelTypeStateSelector, (state) => {
            const { serverId, presetId } = compositeId;

            return state.byServerId[serverId]?.[presetId] || PRESET_SETTINGS_INITIAL_MODEL_TYPE_SERVER_STATE;
        });

    export const listByPresetId = (serverId: string, presetId: string) =>
        createSelector <TRootState,IPresetSettingsModelTypeData, ModelType[]> (byServerIdPresetId({ serverId, presetId }), (state) => Object.values(state.byId));
}
