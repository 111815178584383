import type { TImageRenderProps } from './bpmgraph.types';
/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import { MxConstants, MxSvgCanvas2D } from './mxgraph';
import memoize from '../utils/memoized-class-decorator';
import { MIN_EDGE_TEXT_HEIGHT, MIN_EDGE_TEXT_WIDTH } from './bpmgraph.constants';
import {
    UML_STEREOTYPE_SPECIAL_END_CHARS,
    UML_STEREOTYPE_SPECIAL_START_CHARS,
} from '@/mxgraph/ComplexSymbols/symbols/UML/UMLSymbols.constants';

// Функция экранирует спец символы. Без экранирования не работает получение изображения модели, при наличии спец символов.
// Вынесена за пределеы BPMMxSvgCanvas2D для тестов
export function escapeString(text: string) {
    if (text.includes('<svg') && text.trim().includes('</svg>')) {
        return text;
    }

    if (text.includes(UML_STEREOTYPE_SPECIAL_START_CHARS) && text.includes(UML_STEREOTYPE_SPECIAL_END_CHARS)) {
        return text;
    }

    return text
        .replace(/<br>/gi, '\n')
        .replace(/<br\/>/gi, '\n') // по BR допустим перенос, но чтобы у <br> не экранировались тэги преобразуем его в \n
        .replace(/&/gi, '&amp;')
        .replace(/</gi, '&lt;')
        .replace(/>/gi, '&gt;')
        .replace(/\n/gi, '<br/>'); // возвращаем <br>
}

export class BPMMxSvgCanvas2D extends MxSvgCanvas2D {
    multilineOverflow: boolean;
    isEdgeText: boolean;
    imageRenderProps: TImageRenderProps | undefined;

    constructor(node: any, styleEnabled: any, options?: any) {
        super(node, styleEnabled);
        this.isEdgeText = false;
        this.multilineOverflow = !!options?.multilineOverflow;
    }

    text(x, y, w, h, str, align, valign, wrap, format, overflow, clip, rotation) {
        this.isEdgeText = w === 0 && h === 0;
        const textStyle = this.getTextCss();
        const containerStyle = this.getInnerTextStyle(align, valign, overflow);
        const [aw, ah] = this.getActualElementSize(w, h, str);
        const [dx, dy] = this.getAdjustmentDelta(w, h, align, valign);
        const html = this.getWrappedText(x + dx, y + dy, aw, ah, str, rotation, textStyle, containerStyle, overflow);

        this.root.insertAdjacentHTML('beforeend', html);
        if (overflow === 'overlap') {
            this.applyOverlap(y, h);
        }
    }

    getTextCss() {
        const s = this.state;
        const lh = MxConstants.ABSOLUTE_LINE_HEIGHT
            ? `${s.fontSize * MxConstants.LINE_HEIGHT}px`
            : MxConstants.LINE_HEIGHT * this.lineHeightCorrection;
        const overflowStyle = this.multilineOverflow
            ? 'text-overflow: ellipsis; white-space: nowrap;'
            : 'display: -webkit-box;';
        let css = `
            ${overflowStyle}
            -webkit-box-orient: vertical;
            word-break: break-word;
            overflow: hidden;
            font-size: ${s.fontSize}px;
            font-family: ${s.fontFamily};
            color: ${s.fontColor};
            opacity: ${s.alpha};
            line-height: ${lh};
            pointer-events: none;`;

        if ((s.fontStyle & MxConstants.FONT_BOLD) === MxConstants.FONT_BOLD) {
            css += 'font-weight: bold; ';
        }

        if ((s.fontStyle & MxConstants.FONT_ITALIC) === MxConstants.FONT_ITALIC) {
            css += 'font-style: italic; ';
        }

        const deco: string[] = [];

        if ((s.fontStyle & MxConstants.FONT_UNDERLINE) === MxConstants.FONT_UNDERLINE) {
            deco.push('underline');
        }

        if ((s.fontStyle & MxConstants.FONT_STRIKETHROUGH) === MxConstants.FONT_STRIKETHROUGH) {
            deco.push('line-through');
        }

        if (deco.length > 0) {
            css += `text-decoration: ${deco.join(' ')}; `;
        }

        return css;
    }

    @memoize
    getInnerTextStyle(align, valign, overflow) {
        return `display: flex;
            overflow: ${overflow === 'overlap' ? 'visible' : 'hidden'};
            text-align: ${align};
            height: 100%;
            align-items: ${
                valign === MxConstants.ALIGN_TOP
                    ? 'flex-start'
                    : valign === MxConstants.ALIGN_BOTTOM
                    ? 'flex-end'
                    : 'center'
            };
            justify-content: ${
                align === MxConstants.ALIGN_LEFT
                    ? 'flex-start'
                    : align === MxConstants.ALIGN_RIGHT
                    ? 'flex-end'
                    : 'center'
            }; `;
    }

    @memoize
    getAdjustmentDelta(w, h, align, valign) {
        let dx = 0;
        let dy = 0;
        if (align === 'left') {
            dx = w / 2;
        }
        if (align === 'right') {
            dx = -w / 2;
        }
        if (valign === 'top') {
            dy = h / 2;
        }
        if (valign === 'bottom') {
            dy = -h / 2;
        }

        return [dx, dy];
    }

    getWrappedText(x, y, w, h, str, rotation, textStyle, containerStyle, overflow) {
        // если в str приходит <br/> то количество строк равно количество br + 1, но /n при этом игнорируется
        const lines = this.getLineClamp(h, overflow);
        const t = this.getTransformStyle(x, y, rotation, this.state.scale);

        const overflowStyle = overflow === 'overlap' ? 'visible' : 'unset';

        return `<foreignObject data-test="graph-element_name" x="${x - w / 2}" y="${
            y - h / 2
        }" width="${w}" height="${h}" transform="${t}" style="contain: style;pointer-events:none;overflow:${overflowStyle};">
                <div style="${containerStyle}"  xmlns="http://www.w3.org/1999/xhtml">
                    <div style="-webkit-line-clamp:${lines};${textStyle}" class="cont" xmlns="http://www.w3.org/1999/xhtml">
                        ${escapeString(str)}
                    </div>
                </div>
            </foreignObject>`;
    }

    getLineClamp(h, overflow) {
        if (overflow === 'overlap') return 'unset';
        const lineClamp = h / (this.state.fontSize * MxConstants.LINE_HEIGHT);

        return Math.floor(lineClamp);
    }

    @memoize
    getTransformStyle(x, y, rotation, scale) {
        const r = (this.rotateHtml ? this.state.rotation : 0) + (rotation != null ? rotation : 0);
        let t =
            (this.foOffset !== 0 ? `translate(${this.foOffset} ${this.foOffset})` : '') +
            (scale !== 1 ? `scale(${scale})` : '');
        t += r !== 0 ? `rotate(${r} ${x} ${y})` : '';

        return t;
    }

    applyOverlap(y, h) {
        const cont = this.root.querySelector('.cont');
        const { height } = cont.getBoundingClientRect();
        const deltaH = height - h;
        const fo: HTMLElement = this.root.querySelector('foreignObject');
        fo.style.overflow = 'visible';
        if (deltaH > 0) {
            fo.setAttribute('y', String(y - height / 2));
            fo.setAttribute('height', String(height));
        }
    }

    calcTextBounds(str: string) {
        const s = this.state;

        const { previousElementSibling } = this.root;
        const { width: rootWidth, height: rootHeight } = previousElementSibling.getBoundingClientRect();

        let textStyle = '';
        if ((s.fontStyle & MxConstants.FONT_BOLD) === MxConstants.FONT_BOLD) {
            textStyle += 'bold ';
        }

        if ((s.fontStyle & MxConstants.FONT_ITALIC) === MxConstants.FONT_ITALIC) {
            textStyle += 'italic';
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        let textWidth = 0;
        let textHeight = 0;
        let linesCount = 0;
        if (context) {
            context.font = `${textStyle} ${s.fontSize}px ${s.fontFamily}`;

            const lines = escapeString(str).split('<br/>');
            lines.forEach((line) => {
                const { width } = context.measureText(line);
                // если пустая строка, просто прибавляем к количеству линий 1
                // иначе считаем, сколько строк займет текст относительно доступной длины
                if (width === 0) {
                    linesCount += 1;
                } else {
                    linesCount += Math.ceil(width / Math.max(rootWidth, MIN_EDGE_TEXT_WIDTH));
                }
                textWidth = Math.max(textWidth, width);
            });

            const lh = MxConstants.LINE_HEIGHT * this.lineHeightCorrection;
            textHeight = Math.min(Math.max(rootHeight, MIN_EDGE_TEXT_HEIGHT), Math.ceil(linesCount * s.fontSize * lh));
        }

        if (textWidth > MIN_EDGE_TEXT_WIDTH) {
            console.warn('diff', textWidth, rootWidth);
            textWidth = Math.min(textWidth, rootWidth);
        }

        return { textWidth, textHeight };
    }

    getActualElementSize(w, h, str: string) {
        if (w === 0 && h === 0) {
            const { textHeight, textWidth } = this.calcTextBounds(str);

            return [
                this.additionalSettings ? this.additionalSettings.width : textWidth,
                this.additionalSettings ? this.additionalSettings.height : textHeight,
            ];
        }

        return [w, h];
    }

    updateText(x, y, w, h, align, valign, wrap, overflow, clip, rotation, node) {
        const fo = node.firstChild;

        if (!fo) {
            return;
        }

        let currentText = '';
        if (fo.firstElementChild?.outerText) {
            currentText = fo.firstElementChild.outerText;
        }

        const [dx, dy] = this.getAdjustmentDelta(w, h, align, valign);
        const [aw, ah] = this.getActualElementSize(w, h, currentText);
        const t = this.getTransformStyle(x, y, rotation, this.state.scale);

        fo.setAttribute('x', x - aw / 2 + dx);
        fo.setAttribute('y', y - ah / 2 + dy);
        fo.setAttribute('width', aw);
        fo.setAttribute('height', ah);
        fo.style.contentVisibility = 'visible';
        fo.setAttribute('transform', t);
    }

    image(
        x: number,
        y: number,
        w: number,
        h: number,
        src: string,
        aspect: string | undefined | null | boolean,
        flipH,
        flipV,
    ) {
        src = this.converter.convert(src);
        aspect = aspect != null ? aspect : true;
        flipH = flipH != null ? flipH : false;
        flipV = flipV != null ? flipV : false;

        const s = this.state;
        let [imageWidth, imageHeight] = [w, h];

        const fixedState = this.getFixedState(aspect);
        let imageX = x;
        let imageY = y;
        if (fixedState) {
            const { imageAlign, imageWidth: fixedImageWidth, imageHeight: fixedImageHeight } = fixedState;

            imageWidth = fixedImageWidth;
            imageHeight = fixedImageHeight;

            let dX = 0;
            let dY = 0;
            if (imageAlign.includes('center')) {
                dX = w / 2 - imageWidth / 2;
            }
            if (imageAlign.includes('right')) {
                dX = w - imageWidth;
            }
            if (imageAlign.includes('middle')) {
                dY = h / 2 - imageHeight / 2;
            }
            if (imageAlign.includes('bottom')) {
                dY = h - imageHeight;
            }
            imageX += dX;
            imageY += dY;
        }

        imageX += s.dx;
        imageY += s.dy;

        const node = this.createElement('image');
        node.setAttribute('x', this.format(imageX * s.scale) + this.imageOffset);
        node.setAttribute('y', this.format(imageY * s.scale) + this.imageOffset);
        node.setAttribute('width', `${this.format(imageWidth * s.scale)}`);
        node.setAttribute('height', `${this.format(imageHeight * s.scale)}`);

        // Workaround for missing namespace support
        if (node.setAttributeNS == null) {
            node.setAttribute('xlink:href', src);
        } else {
            node.setAttributeNS(MxConstants.NS_XLINK, 'xlink:href', src);
        }

        if (!aspect) {
            node.setAttribute('preserveAspectRatio', 'none');
        } else if (!fixedState && typeof aspect === 'string') {
            node.setAttribute('preserveAspectRatio', aspect);
        }

        if (s.alpha < 1 || s.fillAlpha < 1) {
            node.setAttribute('opacity', `${s.alpha * s.fillAlpha}`);
        }

        let tr = this.state.transform || '';

        if (flipH || flipV) {
            let sx = 1;
            let sy = 1;
            let dx = 0;
            let dy = 0;

            if (flipH) {
                sx = -1;
                dx = -imageWidth - 2 * imageX;
            }

            if (flipV) {
                sy = -1;
                dy = -imageHeight - 2 * imageY;
            }

            // Adds image tansformation to existing transform
            tr += `scale(${sx},${sy})translate(${dx * s.scale},${dy * s.scale})`;
        }

        if (tr.length > 0) {
            node.setAttribute('transform', tr);
        }

        if (!this.pointerEvents) {
            node.setAttribute('pointer-events', 'none');
        }

        this.root.appendChild(node);
    }

    private getFixedState(
        aspect: string | undefined | null | boolean,
    ): { imageWidth: number; imageHeight: number; imageAlign: string } | null {
        if (typeof aspect !== 'string' || !this.imageRenderProps) {
            return null;
        }
        const { w, h, align } = this.imageRenderProps;

        if (aspect.includes('fixed') && typeof w === 'string' && typeof h === 'string' && typeof align === 'string') {
            return { imageWidth: parseInt(w), imageHeight: parseInt(h), imageAlign: align };
        }

        return null;
    }
}
