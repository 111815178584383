import { TReducer } from '../utils/types';
import {
    APP_BOOTSTRAP_REQUEST,
    APP_BOOTSTRAP_SUCCESS,
    APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM,
    APP_SIZE_TOGGLE,
    APP_START,
    APP_STOP,
    APP_LOAD_VIEW_COLORS,
    APP_SET_IS_EN_LOCALE_ALLOWED,
} from '../actionsTypes/app.actionTypes';
import { AppStatus } from '../models/app';
import { TAppState } from './app.reducer.types';

const initial: TAppState = {
    status: AppStatus.Initial,
    isMaximized: false,
    isStarted: false,
    appColor: { secondColor: '', mainColor: '', selectedRowColor: '' },
    isEnLocaleAllowed: false,
};

export const app: TReducer<TAppState> = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_APP_MODE_TO_ONLY_DIAGRAM: {
            return {
                ...state,
                fullModelDefinition: action.payload.definition,
            };
        }

        case APP_SIZE_TOGGLE: {
            return {
                ...state,
                isMaximized: !state.isMaximized,
            };
        }

        case APP_BOOTSTRAP_REQUEST: {
            return {
                ...state,
                status: AppStatus.Bootstrapping,
            };
        }

        case APP_START: {
            return {
                ...state,
                isStarted: true,
            };
        }

        case APP_BOOTSTRAP_SUCCESS: {
            return {
                ...state,
                status: AppStatus.Bootstrapped,
            };
        }

        case APP_STOP: {
            const { status } = action.payload;

            return {
                ...state,
                isStarted: false,
                status,
            };
        }

        case APP_LOAD_VIEW_COLORS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        
        case APP_SET_IS_EN_LOCALE_ALLOWED: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            return state;
        }
    }
};
