import { getUserFIO } from '@/modules/ObjectPropertiesDialog/components/utils';
import { Icon } from '@/modules/UIKit';
import { PrincipalDescriptor } from '@/serverapi/api';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import iDelete from '@/resources/icons/delete_red.svg';
import theme from './UsersSelect.scss';

type TSelectedUsersListProps = {
    selectedUsers: PrincipalDescriptor[];
    'data-test'?: string;
    onRemove: (id: number) => void;
};

export const SelectedUsersList: FC<TSelectedUsersListProps> = (props) => {
    const { selectedUsers, onRemove } = props;

    return (
        <Col data-test={`${props['data-test']}_usersList`} className={theme.usersListContainer}>
            {selectedUsers.map((user) => (
                <Row key={user.id} className={theme.userRow}>
                    <Col>{getUserFIO(user)}</Col>
                    <Col>
                        <Icon
                            dataTest={`${props['data-test']}_delete`}
                            onClick={() => onRemove(user.id)}
                            className={theme.icon}
                            spriteSymbol={iDelete}
                        />
                    </Col>
                </Row>
            ))}
        </Col>
    );
};
