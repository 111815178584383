import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { INITIAL_ATTRIBUTE_TYPE_SERVER_STATE } from '../reducers/attributeType.reducer';
import { AttributeType, AttributeTypeGroup } from '../serverapi/api';

export const attributeTypeStateSelector = (state: TRootState) => state.attributeType;

export namespace AttributeTypeSelectors {
    export const byServerId = (serverId: string) =>
        createSelector(attributeTypeStateSelector, (state) => {
            const s = state.byServerId[serverId];
            if (s) {
                return { ...s };
            }

            return { ...INITIAL_ATTRIBUTE_TYPE_SERVER_STATE };
        });

    export const all = (serverId: string) =>
        createSelector(byServerId(serverId), (state) =>
            Object.values(state.byPresetId)
                .map((value: { byId: { [id: string]: AttributeType } }): AttributeType[] => Object.values(value.byId))
                .reduce((acc: AttributeType[], attributes: AttributeType[]): AttributeType[] => {
                    return [...acc, ...attributes];
                }, []),
        );

    export const nameAndIdList = (serverId: string) =>
        createSelector(all(serverId), (state) =>
            state.map((item) => ({
                id: item.id,
                name: item.name,
            })),
        );

    export const allInPreset = (serverId: string, presetId: string) =>
        createSelector(
            byServerId(serverId),
            (state) => (state.byPresetId[presetId] && Object.values(state.byPresetId[presetId]?.byId || {})) || [],
        );

    export const allInPresetSorted = (serverId: string, presetId: string) =>
        createSelector(allInPreset(serverId, presetId), (state) => state.sort((a, b) => a.name.localeCompare(b.name)));

    export const byPresetId = (compositeId: { serverId: string; presetId: string }) =>
        createSelector(byServerId(compositeId.serverId), (state) => state.byPresetId[compositeId.presetId]);

    export const byObjectTypeId = (nodeId: { objectTypeId: string; presetId: string; serverId: string }) =>
        createSelector(byPresetId({ serverId: nodeId.serverId, presetId: nodeId.presetId }), (state) => {
            return state.byId[nodeId.objectTypeId];
        });

    export const byId = (serverId: string, presetId: string, id: string) =>
        createSelector(byPresetId({ serverId, presetId }), (state) => state.byId[id]);

    export const byIds = (serverId: string, presetId: string, ids: string[]) =>
        createSelector(byPresetId({ serverId, presetId }), (state) =>
            ids.map((id) => state.byId[id]).filter((a) => !!a),
        );

    export const isLoad = (serverId: string, presetId: string) =>
        createSelector(
            attributeTypeStateSelector,
            (state) => !!(state.byServerId[serverId] && state.byServerId[serverId].byPresetId[presetId]),
        );

    export const takeAttributeTypesFromGroup = (
        serverId: string,
        presetId: string,
        attributeTypeGroups: AttributeTypeGroup[],
    ) =>
        createSelector(allInPreset(serverId, presetId), (state) =>
            state.filter((at: AttributeType) =>
                attributeTypeGroups.some(
                    (atg: AttributeTypeGroup) =>
                        atg.id === at.attributeTypeGroup?.id && atg.presetId === at.attributeTypeGroup.presetId,
                ),
            ),
        );
}
