import type { NodeId } from '../../serverapi/api';
import { TRootState } from '../../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const wikiStateSelector = (state: TRootState) => state.wiki;

export namespace WikiSelectors {
    export const byId = (nodeId: NodeId) => createSelector(wikiStateSelector, (state) => state.data.get(nodeId));
    export const clipboard = () => createSelector(wikiStateSelector, (state) => state.clipboard);
    export const state = createSelector(wikiStateSelector, (state) => state);
    export const imageUpload = (modelId: NodeId) =>
        createSelector(wikiStateSelector, (state) => state.imageUpload.get(modelId));
}
