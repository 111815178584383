import {
    CHANGE_MATRIX_PROPERTIES,
    MATRIX_REQUEST_SUCCESS,
    MATRIX_SAVE_REQUEST_SUCCESS,
} from '@/modules/Matrix/actionTypes/matrix.actionTypes';
import {
    MATRIX_ADD_CELL_DATA,
    MATRIX_CLEAR_SELECTED_CELLS,
    MATRIX_MOVE_TOGGLED_HEADER_CELLS,
    MATRIX_SELECT_CELLS,
    MATRIX_SELECT_HEADER_CELLS,
    MATRIX_START_LOADING_CELL_DATA,
    MATRIX_TOGGLE_HEADER_CELL,
} from '@/modules/Matrix/actionTypes/matrixEditor.actionTypes';
import { TNodeState } from '@/reducers/entities/TNodeState';
import { TReducer } from '@/utils/types';
import { HeaderType } from '../MatrixEditor/Matrix.types';
import { TMatrixEditor, TMatrixEditorState } from './matrixEditor.reducer.types';

const INITIAL_MATRIX_STATE: TMatrixEditorState = new TNodeState<TMatrixEditor>();
const initData: TMatrixEditor = {
    cellsData: {},
    selectedCells: [],
    selectedHeaderCells: {
        ids: [],
        type: HeaderType.column,
    },
    toggledColumnsIds: [],
    toggledRowsIds: [],
    unsaved: false,
    isLoadingCellData: false,
};

export const matrixEditorReducer: TReducer<TMatrixEditorState> = (state = INITIAL_MATRIX_STATE, action) => {
    switch (action.type) {
        case MATRIX_SELECT_HEADER_CELLS: {
            const {
                payload: { matrixNodeId: nodeId, ids, type },
            } = action;

            if (ids.length === 0) return state;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    selectedHeaderCells: {
                        type,
                        ids,
                    },
                    selectedCells: [],
                });
            }

            return state.set(nodeId, {
                ...initData,
                selectedHeaderCells: {
                    type,
                    ids,
                },
            });
        }

        case MATRIX_CLEAR_SELECTED_CELLS: {
            const {
                payload: { matrixNodeId: nodeId },
            } = action;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    selectedHeaderCells: {
                        type: HeaderType.column,
                        ids: [],
                    },
                    selectedCells: [],
                });
            }

            return state.set(nodeId, {
                ...initData,
                selectedHeaderCells: {
                    type: HeaderType.column,
                    ids: [],
                },
            });
        }

        case MATRIX_SELECT_CELLS: {
            const {
                payload: { matrixNodeId: nodeId, ids },
            } = action;

            if (ids.length === 0) return state;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    selectedHeaderCells: {
                        type: HeaderType.column,
                        ids: [],
                    },
                    selectedCells: ids,
                });
            }

            return state.set(nodeId, {
                ...initData,
                selectedCells: ids,
            });
        }

        case CHANGE_MATRIX_PROPERTIES: {
            const {
                payload: {
                    matrix: { nodeId },
                },
            } = action;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    unsaved: true,
                });
            }

            return state.set(nodeId, {
                ...initData,
                unsaved: true,
            });
        }

        case MATRIX_SAVE_REQUEST_SUCCESS:
        case MATRIX_REQUEST_SUCCESS: {
            const {
                payload: {
                    matrix: { nodeId },
                },
            } = action;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    unsaved: false,
                });
            }

            return state.set(nodeId, {
                ...initData,
                unsaved: false,
            });
        }

        case MATRIX_ADD_CELL_DATA: {
            const {
                payload: { matrixNodeId: nodeId, cellId, cellData },
            } = action;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    cellsData: {
                        ...existedState.cellsData,
                        [cellId]: { ...cellData },
                    },
                    isLoadingCellData: false,
                });
            }

            return state.set(nodeId, {
                ...initData,
                cellsData: {
                    [cellId]: { ...cellData },
                },
                isLoadingCellData: false,
            });
        }

        case MATRIX_START_LOADING_CELL_DATA: {
            const {
                payload: { matrixNodeId: nodeId },
            } = action;

            const existedState = state.get(nodeId);

            if (existedState) {
                return state.set(nodeId, {
                    ...existedState,
                    isLoadingCellData: true,
                });
            }

            return state.set(nodeId, {
                ...initData,
                isLoadingCellData: true,
            });
        }

        case MATRIX_TOGGLE_HEADER_CELL: {
            const {
                payload: { matrixNodeId, id, type },
            } = action;

            const existedState = state.get(matrixNodeId);

            if (existedState) {
                const { toggledColumnsIds, toggledRowsIds } = existedState;

                let newToggledHeaderCellIds: string[] = type === HeaderType.column ? toggledColumnsIds : toggledRowsIds;

                if (newToggledHeaderCellIds.includes(id)) {
                    newToggledHeaderCellIds = newToggledHeaderCellIds.filter((toggledId) => toggledId !== id);
                } else {
                    newToggledHeaderCellIds = [...newToggledHeaderCellIds, id];
                }

                if (type === HeaderType.column) {
                    return state.set(matrixNodeId, {
                        ...existedState,
                        toggledColumnsIds: newToggledHeaderCellIds,
                    });
                } else {
                    return state.set(matrixNodeId, {
                        ...existedState,
                        toggledRowsIds: newToggledHeaderCellIds,
                    });
                }
            }

            return state;
        }

        case MATRIX_MOVE_TOGGLED_HEADER_CELLS:
            const {
                payload: { matrixNodeId, ids, targetType },
            } = action;

            const existedState = state.get(matrixNodeId);

            if (existedState) {
                const { toggledColumnsIds, toggledRowsIds } = existedState;

                let newToggledRowsIds: string[];
                let newToggledColsIds: string[];

                if (targetType === HeaderType.column) {
                    newToggledRowsIds = toggledRowsIds.filter((toggledId) => !ids.includes(toggledId));
                    const idsFromRows = toggledRowsIds.filter((toggledId) => ids.includes(toggledId));
                    newToggledColsIds = [...toggledColumnsIds, ...idsFromRows];
                } else {
                    newToggledColsIds = toggledColumnsIds.filter((toggledId) => !ids.includes(toggledId));
                    const idsFromCols = toggledColumnsIds.filter((toggledId) => ids.includes(toggledId));
                    newToggledRowsIds = [...toggledRowsIds, ...idsFromCols];
                }

                return state.set(matrixNodeId, {
                    ...existedState,
                    toggledRowsIds: newToggledRowsIds,
                    toggledColumnsIds: newToggledColsIds,
                });
            }

            return state;

        default:
            return state;
    }
};
