import React, { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd';
import theme from './ObjectType.scss';
import messages from './ObjectType.messages';
import { InternationalString, ObjectType, ObjectTypeGroup } from '../../../../../../serverapi/api';
import { MultiLangInputDialog } from '../../../../../MultiLangInputDialog/MultiLangInputDialog.component';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../../../../selectors/locale.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';
import { InputId } from '../../../../../InputId/InputId.component';
import { InputSynonymsIds } from '../../../../../InputSynonymsIds/InputSynonymsIds.component';
import { Select } from '../../../../../UIKit/components/Select/Select.component';
import { Checkbox } from '../../../../../UIKit/components/Checkbox/Checkbox.component';

type TGeneralTabProps = {
    objectType: ObjectType;
    objectTypeGroups: ObjectTypeGroup[];
    createMode: boolean;
    generalForm: FormInstance<any> | undefined;
    onChangeObjectTypeName: (multilingualName: InternationalString) => void;
    onChangeObjectTypeId: (id: string) => void;
    onChangeObjectTypeDescription: (multilingualDescription: InternationalString) => void;
    onChangeObjectTypeGroup: (objectTypeGroup: ObjectTypeGroup) => void;
    onChangeHideInNavigator: (hideInNavigator: boolean) => void;
    onChangePreventMultipleInstances: (preventMultipleInstances: boolean) => void;
    onChangeObjectTypeSynonymsIds: (e: ChangeEvent<HTMLInputElement>) => void;
    onChangeObjectAllowApprovals: (allowApprovals: boolean) => void;
};

export const GeneralTab: FC<TGeneralTabProps> = (props) => {
    const currentLocale = useSelector(getCurrentLocale);
    const {
        createMode,
        objectType,
        generalForm,
        objectTypeGroups,
        onChangeObjectTypeId,
        onChangeObjectTypeSynonymsIds,
        onChangeObjectAllowApprovals,
    } = props;
    const intl = useIntl();

    const onChangeObjectTypeGroup = (groupId: string) => {
        const changeGroup = objectTypeGroups.find((otg) => otg.id === groupId);
        if (changeGroup) {
            props.onChangeObjectTypeGroup(changeGroup);
        }
    };

    return (
        <div className={theme.form}>
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.name)}
                multiLangValue={objectType?.multilingualName}
                onChange={props.onChangeObjectTypeName}
                label={intl.formatMessage(messages.name)}
                mainInputName="multilingualName"
                mainInputClassName={theme.input}
                generalForm={generalForm}
                required
                autoFocus
                data-test="object-type-name-input"
            />
            <InputId
                disabled={!createMode}
                value={objectType.id}
                required
                mainInputName="GUID"
                mainInputClassName={theme.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onChangeObjectTypeId(e.target.value.trim());
                }}
            />
            <div className={theme.selectWrapper}>
                <div className={theme.customRequired}>
                    <Select
                        data-test="object-type-group-select"
                        originalTheme
                        label={
                            <span className={theme.selectLabel}>{intl.formatMessage(messages.objectTypeGroups)}</span>
                        }
                        onChange={onChangeObjectTypeGroup}
                        value={LocalesService.internationalStringToString(
                            objectType.objectTypeGroup?.multilingualName,
                            currentLocale,
                        )}
                    >
                        {objectTypeGroups.map((group: ObjectTypeGroup) => {
                            return (
                                <Select.Option
                                    key={group.id}
                                    value={group.id}
                                    label={LocalesService.internationalStringToString(
                                        group.multilingualName,
                                        currentLocale,
                                    )}
                                />
                            );
                        })}
                    </Select>
                </div>
            </div>
            <InputSynonymsIds
                value={objectType.synonymsIds}
                mainInputName="synonymsIds"
                mainInputClassName={theme.input}
                onChange={onChangeObjectTypeSynonymsIds}
            />
            <MultiLangInputDialog
                placeholder={intl.formatMessage(messages.description)}
                multiLangValue={objectType.multilingualDescription}
                onChange={props.onChangeObjectTypeDescription}
                textarea
                label={intl.formatMessage(messages.description)}
                mainInputName="multilingualDescription"
                mainInputClassName={theme.textArea}
                generalForm={generalForm}
                data-test="object-type-description-input"
            />
            <Checkbox
                className={theme.firstCheckbox}
                onChange={() => {
                    props.onChangeHideInNavigator(!objectType.hideInNavigator);
                }}
                checked={objectType.hideInNavigator}
            >
                {intl.formatMessage(messages.hideInNavigator)}
            </Checkbox>
            <Checkbox
                className={theme.checkbox}
                checked={objectType?.allowApprovals}
                onChange={() => onChangeObjectAllowApprovals(!objectType?.allowApprovals)}
            >
                {intl.formatMessage(messages.alowObjectTypeApprovals)}
            </Checkbox>
            {/*
            <div>
                <Checkbox
                    onChange={(event: CheckboxChangeEvent) => {
                        props.onChangePreventMultipleInstances(event.target.checked);
                    }}
                    checked={objectType.preventMultipleInstances}
                >
                    <span>{intl.formatMessage(messages.preventMultipleInstances)}</span>
                </Checkbox>
            </div>
            */}
        </div>
    );
};
