import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import theme from './TextBlock.scss';
import { TWidgetProps } from '../widget.types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgetText } from '@/actions/dashboard.actions';
import WikiEditorToolbarForWidget from './WikiToolbar/WikiEditorToolbar.component';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { updateToolbarPosition, getDashboardContainer } from '../../dashboardUtils';
import RichTextEditor from '@/modules/UIKit/components/RichTextEditor/Editor/RichTextEditor.component';
import { CommentPopoverContainer } from '@/modules/Comments/containers/CommentPopover.container';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { EditorMode } from '@/models/editorMode';

export const TextBlock: FC<TWidgetProps> = ({
    title,
    dashboardNodeId,
    widget: { bgColor, border, id, text },
    selected,
}) => {
    const dispatch = useDispatch();

    const editorMode: EditorMode | undefined= useSelector(TabsSelectors.getEditorModeById(dashboardNodeId));
    const isEditMode = editorMode === EditorMode.Edit;

    // костыль для вики, т.к. если вики задизейблена изначально, не отрисовываются картинки и таблицы
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setDisabled(!isEditMode);
        }, 1000);
    }, [isEditMode]);

    const toolbarRef: RefObject<HTMLDivElement | null> = useRef(null);
    const editorRef: RefObject<HTMLDivElement> = useRef(null);

    const server = useSelector(ServerSelectors.server(dashboardNodeId.serverId));
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';
    const handlers = useMemo(
        () => ({
            imageLinkMapper: (src) => {
                return src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`;
            },
            openImageDialog: (onSubmit) => {
                dispatch(
                    openDialog(DialogType.IMAGE_EDIT_DIALOG_WIKI, {
                        id: uuid(),
                        workspaceId: dashboardNodeId,
                        onSubmit,
                    }),
                );
            },
            openLinkDialog: (onSubmit) => {
                const { serverId } = dashboardNodeId;
                dispatch(openDialog(DialogType.LINK_DIALOG, { serverId, onSubmit }));
            },
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
        }),
        [baseUrl, dashboardNodeId],
    );

    const onChange = (value: string) => {
        dispatch(setWidgetText(dashboardNodeId, id, value));
    };

    const wikiId = `${id}_widget`;

    updateToolbarPosition(editorRef.current, toolbarRef.current, getDashboardContainer(dashboardNodeId));

    return (
        <div
            className={cx(theme.textBlockContainer, !border ? theme.disableBorder : '')}
            style={{ backgroundColor: bgColor }}
            onKeyDown={(e) => e.stopPropagation()}
            ref={editorRef}
            data-test="dashboard-editor_container_widget_text-block"
        >
            <div className={theme.header} data-test="dashboard-editor_container_widget_text-block-header">
                {title}
            </div>
            <RichTextEditor
                disable={disabled}
                handlers={handlers}
                id={wikiId}
                onChange={onChange}
                value={text || ''}
                CommentPopoverContent={CommentPopoverContainer}
            />
            {selected && <WikiEditorToolbarForWidget id={wikiId} handlers={handlers} ref={toolbarRef} />}
        </div>
    );
};
