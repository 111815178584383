import {
    MatrixCellBPM8764,
    MatrixDataBPM8764,
    MatrixLane,
    MatrixStyle,
    MatrixStyleCustom,
    MatrixStyleIcon,
    MatrixStyleUserIcon,
    ObjectConnection,
    ObjectModelConnections,
    Node,
} from '@/serverapi/api';
import { v4 as uuid } from 'uuid';
import { EdgeSymbolType, IconType, TCellSymbol, TCheckForbiddenByProfile } from '../MatrixEditor/Matrix.types';
import React from 'react';
import { CheckOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ObjectDefinitionImpl } from '@/models/bpm/bpm-model-impl';
import { LocalesService } from '../../../services/LocalesService';
import { nvlAcl } from '../../../services/bll/ProfileBllService';
import { cellIdDivider } from './Matrix.constants';

export const completHeadersArray = (headers: MatrixLane[] = []) => {
    if (headers.length === 0 || headers[headers.length - 1].text) {
        headers.push({ id: uuid() });
    }
};

export const getCells = (matrixData?: MatrixDataBPM8764): MatrixCellBPM8764[] => {
    if (!matrixData) return [];

    const cells = matrixData.cells.filter(
        (cell) =>
            matrixData.rows.some((row) => row.id === cell.rowId) &&
            matrixData.columns.some((col) => col.id === cell.columnId),
    );

    if (matrixData.rows && matrixData.columns) {
        matrixData.rows.forEach(({ id: rowId }) => {
            matrixData.columns.forEach(({ id: columnId }) => {
                const isCellExists = cells.some((cell) => cell.rowId === rowId && cell.columnId === columnId);
                if (!isCellExists) cells.push({ rowId, columnId, styleIds: [] });
            });
        });
    }
    matrixData.cells = cells;

    return cells;
};

export const getCellIdByRowIdAndColId = (rowId: string, colId: string): string => {
    return `${rowId}${cellIdDivider}${colId}`;
};

export const getRowIdAndColIdByCellId = (
    cellId: string,
): {
    rowId: string;
    colId: string;
} => {
    const res = cellId.split(cellIdDivider);
    return {
        rowId: res[0],
        colId: res[1],
    };
};

export const renderCellSymbol = (style?: MatrixStyle, antdIconsClassName?: string): TCellSymbol => {
    let symbol: string | JSX.Element = '';
    let color = '';

    if (!style)
        return {
            symbol,
            color,
            description: undefined,
        };

    const { type, description } = style;

    if (type === EdgeSymbolType.custom) {
        symbol = (style as MatrixStyleCustom).text;
        color = (style as MatrixStyleCustom).color;
    }

    if (type === EdgeSymbolType.icon) {
        symbol = renderIcon(
            (style as MatrixStyleIcon).icon as IconType,
            (style as MatrixStyleIcon).color,
            antdIconsClassName,
        );
    }

    if (type === EdgeSymbolType.userIcon) {
        symbol = (
            <img
                src={(style as MatrixStyleUserIcon).iconData}
                style={{
                    maxWidth: '30px',
                    maxHeight: '24px',
                    minWidth: '30px',
                    minHeight: '24px',
                }}
            />
        );
    }

    return {
        symbol,
        color,
        description,
    };
};

export const renderIcon = (iconType: IconType, color: string, className?: string) => {
    const style: React.CSSProperties = {
        color: color,
        maxWidth: '30px',
        maxHeight: '24px',
        minWidth: '30px',
        minHeight: '24px',
    };
    switch (iconType) {
        case IconType.plus:
            return <PlusOutlined style={style} className={className} />;
        case IconType.minus:
            return <MinusOutlined style={style} className={className} />;
        case IconType.check:
            return <CheckOutlined style={style} className={className} />;
        case IconType.cross:
            return <CloseOutlined style={style} className={className} />;
    }
};

export const haveChildren = (id: string, lanes: MatrixLane[]) => {
    return lanes.some((lane) => lane.parentId === id);
};

export const getCurrentLvl = (id: string, lanes: MatrixLane[]) => {
    let lvl = 0;
    const currentLane = lanes.find((lane) => lane.id === id);
    let parentId: string | undefined = currentLane?.parentId;
    while (parentId) {
        const parent = lanes.find((lane) => lane.id === parentId);
        parentId = parent?.parentId;
        lvl += 1;
    }
    return lvl;
};

export const checkSameLvls = (selectedLanesIds: string[], allLanes: MatrixLane[]) => {
    const lvl = getCurrentLvl(selectedLanesIds[0], allLanes);
    return !selectedLanesIds.some((id) => getCurrentLvl(id, allLanes) !== lvl);
};

export const checkLanesSpaces = (selectedLanesIds: string[], allLanes: MatrixLane[]): boolean => {
    let minIndex = allLanes.length - 1;
    let maxIndex = 0;
    selectedLanesIds.forEach((selectedLaneId) => {
        const currentIndex = allLanes.findIndex((lane) => lane.id === selectedLaneId);
        minIndex = Math.min(minIndex, currentIndex);
        maxIndex = Math.max(maxIndex, currentIndex);
    });

    return selectedLanesIds.length - maxIndex + minIndex === 1;
};

export const checkLanesCanUpLvl = (selectedLanesIds: string[], allLanes: MatrixLane[]) => {
    return !allLanes.some((lane) => selectedLanesIds.includes(lane.id) && getCurrentLvl(lane.id, allLanes) === 0);
};

export const getLastChildIndex = (parentLane: MatrixLane, lanes: MatrixLane[], excludeId: string): number => {
    let currentIndex = lanes.findIndex((lane) => lane.id === parentLane.id);
    let currentLane: MatrixLane | undefined = parentLane;
    while (currentLane) {
        const lastChild = lanes.findLast((lane) => lane.parentId === currentLane?.id && lane.id !== excludeId);
        const lastChildIndex = lanes.findLastIndex(
            (lane) => lane.parentId === currentLane?.id && lane.id !== excludeId,
        );
        currentIndex = Math.max(currentIndex, lastChildIndex);
        currentLane = lastChild;
    }

    return currentIndex;
};

export const fixLanes = (lanes: MatrixLane[]) => {
    lanes.forEach((currentLane) => {
        if (!currentLane.parentId) return;

        const isParentFinded = lanes.some((lane) => lane.id === currentLane.parentId);
        if (!isParentFinded) {
            currentLane.parentId = undefined;
        }
    });
};

export const reorderLanes = (currentLane: MatrixLane, lanes: MatrixLane[], newLanesOrder: MatrixLane[]) => {
    const parent = lanes.find((lane) => lane.id === currentLane.parentId);

    if (parent) {
        const parentIndex = newLanesOrder.findIndex((lane) => lane.id === parent.id);
        const lastParentsChildIndex = getLastChildIndex(parent, newLanesOrder, currentLane.id);
        if (parentIndex !== -1) {
            if (lastParentsChildIndex !== -1) {
                newLanesOrder.splice(lastParentsChildIndex + 1, 0, currentLane);
            } else {
                newLanesOrder.splice(parentIndex + 1, 0, currentLane);
            }
        } else {
            reorderLanes(parent, lanes, newLanesOrder);
        }
    } else {
        newLanesOrder.push(currentLane);
    }

    const remainingLanes: MatrixLane[] = lanes.filter(
        (lane) => !newLanesOrder.some((orderedLane) => orderedLane.id === lane.id),
    );
    if (remainingLanes[0]) reorderLanes(remainingLanes[0], lanes, newLanesOrder);
};

export const getParentForLvlDown = (
    lanes: MatrixLane[],
    selectedIds: string[],
    currentLvl: number,
): MatrixLane | undefined => {
    let minIndex = lanes.length - 1;
    selectedIds.forEach((id) => {
        const selectedHederCellIndex = lanes.findIndex((lane) => lane.id === id);
        minIndex = Math.min(minIndex, selectedHederCellIndex);
    });
    const parent = lanes.findLast((lane, index) => {
        const laneLvl = getCurrentLvl(lane.id, lanes);
        return index < minIndex && laneLvl === currentLvl && lane.text;
    });

    return parent;
};

export const excludeChildIds = (selectedIds: string[], lanes: MatrixLane[]): string[] => {
    const filteredSelectedIds: string[] = [];
    selectedIds.forEach((selectedId) => {
        const currentLane = lanes.find((lane) => lane.id === selectedId);
        if (!currentLane) return;

        if (!currentLane.parentId || !selectedIds.includes(currentLane.parentId)) {
            filteredSelectedIds.push(selectedId);
        }
    });

    return filteredSelectedIds;
};

export const getParentsChildren = (parent: MatrixLane, lanesForSearch: MatrixLane[], result: MatrixLane[]) => {
    lanesForSearch.forEach((currentLane) => {
        if (currentLane.parentId === parent.id) {
            result.push(currentLane);
            if (haveChildren(currentLane.id, lanesForSearch)) {
                getParentsChildren(currentLane, lanesForSearch, result);
            }
        }
    });
};

export const isLaneExist = (searchingLane: MatrixLane, lanes: MatrixLane[]) => {
    return lanes.some((lane) => searchingLane.id === lane.id);
};

export const getSelectedLanesWithChildren = (
    selectedIds: string[],
    lanes: MatrixLane[],
    withEmpty?: boolean,
): MatrixLane[] => {
    const notEmptySelectedLanes: MatrixLane[] = lanes.filter(
        (lane) => selectedIds.includes(lane.id) && (lane.text || withEmpty),
    );
    const selectedLanesWithChildern: MatrixLane[] = [];
    notEmptySelectedLanes.forEach((selectedLane) => {
        const parentsChildern: MatrixLane[] = [];
        getParentsChildren(selectedLane, lanes, parentsChildern);
        if (!isLaneExist(selectedLane, selectedLanesWithChildern)) {
            selectedLanesWithChildern.push(selectedLane);
        }
        parentsChildern.forEach((child) => {
            if (!isLaneExist(child, selectedLanesWithChildern)) {
                selectedLanesWithChildern.push(child);
            }
        });
    });

    return selectedLanesWithChildern;
};

export const fillCellsWithSameLinkedNodeIds = (cell: MatrixCellBPM8764, matrixData: MatrixDataBPM8764) => {
    const { columnId, rowId } = cell;
    const rowsHeaders = matrixData.rows;
    const colsHeaders = matrixData.columns;

    const rowLinkedNodeId = rowsHeaders.find((row) => row.id === rowId)?.linkedNodeId;
    const colLinkedNodeId = colsHeaders.find((col) => col.id === columnId)?.linkedNodeId;

    if (!rowLinkedNodeId || !colLinkedNodeId) return;

    colsHeaders.forEach((col) => {
        rowsHeaders.forEach((row) => {
            if (
                (col.linkedNodeId === colLinkedNodeId && row.linkedNodeId === rowLinkedNodeId) ||
                (col.linkedNodeId === rowLinkedNodeId && row.linkedNodeId === colLinkedNodeId)
            ) {
                const cellWithSameLinkedNodeIds = matrixData.cells.find(
                    (cell) => cell.columnId === col.id && cell.rowId === row.id,
                );
                if (cellWithSameLinkedNodeIds) {
                    cellWithSameLinkedNodeIds.styleIds = [...cell.styleIds];
                }
            }
        });
    });
};

export const getStyleIdsFromCellWithSameLinkedNodeIds = (
    currentCell: MatrixCellBPM8764,
    matrixData: MatrixDataBPM8764,
    excludeLaneId: string = '',
) => {
    const { columnId, rowId } = currentCell;
    const rowsHeaders = matrixData.rows;
    const colsHeaders = matrixData.columns;

    const rowLinkedNodeId = rowsHeaders.find((row) => row.id === rowId)?.linkedNodeId;
    const colLinkedNodeId = colsHeaders.find((col) => col.id === columnId)?.linkedNodeId;

    if (!rowLinkedNodeId || !colLinkedNodeId) return;

    let finded = false;

    colsHeaders.forEach((col) => {
        if (finded) return;
        rowsHeaders.forEach((row) => {
            if (finded) return;
            if (
                (col.linkedNodeId === colLinkedNodeId && row.linkedNodeId === rowLinkedNodeId) ||
                (col.linkedNodeId === rowLinkedNodeId && row.linkedNodeId === colLinkedNodeId)
            ) {
                const findedCell = matrixData.cells.find(
                    (cell) =>
                        cell.columnId === col.id &&
                        cell.rowId === row.id &&
                        cell.columnId !== excludeLaneId &&
                        cell.rowId !== excludeLaneId,
                );

                if (findedCell && (findedCell.columnId !== columnId || findedCell.rowId !== rowId)) {
                    currentCell.styleIds = [...findedCell.styleIds];
                    finded = true;
                }
            }
        });
    });
};

export const findConnectedEdgeInstances = (
    sourceObjectDefinition: ObjectDefinitionImpl,
    targetObjectDefinitionId: string,
): ObjectConnection[] => {
    const allConnectionsBetweenObjects: ObjectConnection[] = [];
    const allObjectModelConnections: ObjectModelConnections[] = sourceObjectDefinition.objectModelConnections || [];

    allObjectModelConnections.forEach((objectModelConnections: ObjectModelConnections) => {
        objectModelConnections.connections?.forEach((objectConnection: ObjectConnection) => {
            if (
                objectConnection.edgeInstanceId &&
                !allConnectionsBetweenObjects.some(
                    (connetion) => connetion.edgeInstanceId === objectConnection.edgeInstanceId,
                ) &&
                objectConnection.connectedObjectDefinitionId === targetObjectDefinitionId
            ) {
                allConnectionsBetweenObjects.push(objectConnection);
            }
        });
    });

    return allConnectionsBetweenObjects;
};

export const hasDeletedHeaderNode = (
    objectDefinition: ObjectDefinitionImpl | undefined,
    cellHeader: MatrixLane | undefined,
): boolean => {
    if (!cellHeader) return false;
    if (!cellHeader.linkedNodeId) return true;

    if (!objectDefinition) return false;
    if ((objectDefinition as Node).deleted) return false;

    return true;
};

export const getHeaderCellText = (
    objectDefinition: ObjectDefinitionImpl | undefined,
    cellHeader: MatrixLane,
    isForbiddenToRead?: boolean,
): string => {
    if (!cellHeader.linkedNodeId) {
        return LocalesService.internationalStringToString(cellHeader.text);
    }

    const isDeletedItem: boolean = !!(objectDefinition as Node)?.deleted || !objectDefinition;

    if (isDeletedItem || isForbiddenToRead) {
        return cellHeader.linkedNodeId;
    }

    return LocalesService.internationalStringToString(objectDefinition?.multilingualName);
};

export const isForbiddenByProfile = ({
    selectedHeaders,
    userProfile,
    objectDefinitions,
    rights,
}: TCheckForbiddenByProfile): boolean => {
    if (!userProfile) return false;

    return selectedHeaders.some((header) => {
        if (!header.linkedNodeId) return false;

        const objectDefinition: ObjectDefinitionImpl | undefined = objectDefinitions[header.linkedNodeId || ''];
        if (!objectDefinition || (objectDefinition as Node).deleted) return false;

        return rights.some((right) => {
            return (
                nvlAcl(header.linkedNodeId, userProfile.symbolAcls[objectDefinition?.idSymbol || ''])[right] ===
                    false ||
                nvlAcl(header.linkedNodeId, userProfile.objectTypeAcls[objectDefinition?.objectTypeId || ''])[right] ===
                    false
            );
        });
    });
};

export const findSelectedHeadersByIds = (allHeaders: MatrixLane[], selectedIds: string[]): MatrixLane[] => {
    const selectedHeaders: MatrixLane[] = selectedIds
        .map((id) => allHeaders.find((header) => header.id === id))
        .filter((header): header is MatrixLane => !!header);

    return selectedHeaders;
};

export const setNewIds = (lanes: MatrixLane[]): MatrixLane[] => {
    const copiedElements: MatrixLane[] = lanes.map((lane) => ({ ...lane }));

    copiedElements.forEach((copiedElement) => {
        const oldId = copiedElement.id;
        const newId = uuid();
        copiedElement.id = newId;
        copiedElements.forEach((childElement) => {
            if (childElement.parentId === oldId) {
                childElement.parentId = newId;
            }
        });
    });

    return copiedElements;
};

export const filterToggledHeaderCells = (lanes: MatrixLane[], toggledIds: string[]) => {
    let result: MatrixLane[] = lanes;

    const fillter = (lanesForFillter: MatrixLane[], ids: string[]) => {
        const childIds: string[] = [];
        const filtredLanes: MatrixLane[] = [];
        lanesForFillter.forEach((lane) => {
            if (!lane.parentId || !ids.includes(lane.parentId)) {
                filtredLanes.push(lane);
            } else {
                childIds.push(lane.id);
            }
        });

        if (childIds.length !== 0) {
            fillter(filtredLanes, childIds);
        } else {
            result = filtredLanes;
        }
    };

    fillter(lanes, toggledIds);

    return result;
};

export const replaceIdenticalIds = (lanesForChange: MatrixLane[], lanesForComparison: MatrixLane[]) => {
    const res: { [oldId: string]: string } = {};
    const existingIds: string[] = lanesForComparison.map((lane) => lane.id);
    lanesForChange.forEach((laneForChange) => {
        if (existingIds.includes(laneForChange.id)) {
            const oldId = laneForChange.id;
            const newId = uuid();
            res[oldId] = newId;
            laneForChange.id = newId;
            lanesForChange.forEach((childLane) => {
                if (childLane.parentId === oldId) {
                    childLane.parentId = newId;
                }
            });
        }
    });

    return res;
};
