import React, { FC, useState } from 'react';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import messages from './MatrixCellSymbolsSettingsDialog.messages';
import { useIntl } from 'react-intl';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import { MatrixNode, MatrixStyle, NodeId } from '../../../../serverapi/api';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '@/actions/dialogs.actions';
import { TDialogProps } from '@/modules/UIKit/components/Dialog/Dialog.types';
import { changeMatrixProperties, matrixSaveRequest } from '@/modules/Matrix/actions/matrix.actions';
import { CellSymbolsSettings } from './CellSymbolsSettings/CellSymbolsSettings.component';
import { fillCellsWithSameLinkedNodeIds, getRowIdAndColIdByCellId } from '../../utils/Matrix.utils';
import { MatrixSelectors } from '../../selectors/matrix.selectors';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TMatrixSettingsDialogProps = {
    nodeId: NodeId;
    selectedStyleIds: string[];
    cellId: string;
} & TDialogProps;

export const MatrixCellSymbolsSettingsDialog: FC<TMatrixSettingsDialogProps> = (props) => {
    const { nodeId, selectedStyleIds = [], cellId } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const matrix: MatrixNode | undefined = useSelector(MatrixSelectors.byId(nodeId));
    const currentStyles: MatrixStyle[] = [...(matrix?.data2?.cellSettings.styles || [])];

    const [selectedCellStylesIds, setSelectedCellStylesIds] = useState<string[]>(selectedStyleIds);

    const cancelHandler = () => {
        dispatch(closeDialog(DialogType.MATRIX_CELL_SYMBOLS_SETTINGS_DIALOG));
    };

    const submitHandler = () => {
        if (matrix?.data2) {
            const { colId, rowId } = getRowIdAndColIdByCellId(cellId);
            const currentCell = matrix.data2.cells.find((cell) => cell.columnId === colId && cell.rowId === rowId);
            if (currentCell) {
                currentCell.styleIds = selectedCellStylesIds;
                fillCellsWithSameLinkedNodeIds(currentCell, matrix.data2);
                dispatch(changeMatrixProperties(matrix));
                dispatch(matrixSaveRequest(matrix));
            }
        }
        cancelHandler();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: cancelHandler,
                    value: intl.formatMessage(messages.cancelButton),
                },
                {
                    key: 'ok',
                    onClick: submitHandler,
                    value: intl.formatMessage(messages.okButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <div>
            <Dialog
                {...props}
                title={intl.formatMessage(messages.title)}
                footer={footer}
                width={768}
                onCancel={cancelHandler}
            >
                <CellSymbolsSettings
                    currentStyles={currentStyles}
                    selectedIds={selectedCellStylesIds}
                    setSelectedCellStylesIds={setSelectedCellStylesIds}
                />
            </Dialog>
        </div>
    );
};
