import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { closeDialog } from '../../../../../actions/dialogs.actions';
import { Checkbox, Input, Modal, Radio, Select, RadioChangeEvent } from 'antd';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch } from 'react-redux';
import messages from '../../messages/ImportVisioDBConflictDialog.messages';
import tableMessages from '../../messages/ImportVisioCollapseTable.messages';
import style from './ImportVisioDBConflictDialog.scss';
import { ObjectDefinitionNode, VisioConflictResolution } from '../../../../../serverapi/api';
import { differenceWith, isEqual } from 'lodash-es';
import { cancelImportVisio, importVisioFile, setImportVisioData } from '../../../../../actions/import.actions';
import { shouldCallModalSubmitHandler } from '../../../../../services/utils/ModalHelper';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

interface IOptionsState extends VisioConflictResolution {
    saveOptions: boolean;
}

const defaultOptions: IOptionsState = {
    strategy: 'FIND_MOST_COMMON',
    value: undefined,
    saveOptions: false,
};

type TConflictsProps = WrappedComponentProps & {
    conflicts: {
        [key: string]: {
            [key: string]: ObjectDefinitionNode[];
        };
    };
};

type TResolutionById = {
    [key: string]: {
        [key: string]: VisioConflictResolution;
    };
};

type TCurrentConflict = {
    id: string;
    fileName: string;
    dropdownOptions: TDropdownOptions[];
    isLast: boolean;
};

type Tobj = {
    [key: string]: {
        [key: string]: ObjectDefinitionNode[] | VisioConflictResolution;
    };
};

type TObjElement = {
    fileName: string;
    id: string;
};

type TDropdownOptions = {
    name: string;
    value: string;
};

const getArrayByFileNameAndId = (obj: Tobj) =>
    Object.keys(obj).reduce((acc, item) => {
        Object.keys(obj[item]).forEach((el) => {
            acc.push({
                fileName: item,
                id: el,
            });
        });

        return acc;
    }, [] as TObjElement[]);

const ImportVisioDBConflictDialog = ({ conflicts, intl }: TConflictsProps) => {
    const dispatch = useDispatch();

    const [options, setStrategy] = useState<IOptionsState>(defaultOptions);
    const [resolutionById, setResolutionById] = useState<TResolutionById>({});

    const currentConflict: TCurrentConflict = useMemo(() => {
        const conflictIdList = getArrayByFileNameAndId(conflicts);
        const resolutionByIdList = getArrayByFileNameAndId(resolutionById);
        const differenceIdList = differenceWith(conflictIdList, resolutionByIdList, isEqual);
        const [conflict] = differenceIdList;

        return {
            id: conflict?.id,
            fileName: conflict?.fileName,
            dropdownOptions: conflicts?.[conflict?.fileName]?.[conflict?.id].map((item) => ({
                name: item.name,
                value: item.nodeId.id!,
            })),
            isLast: differenceIdList.length < 1,
        };
    }, [conflicts, resolutionById]);

    useEffect(() => {
        dispatch(setImportVisioData({ resolutionById: {} }));
    }, []);

    useEffect(() => {
        if (currentConflict.isLast) {
            dispatch(setImportVisioData({ resolutionById }));
            dispatch(closeDialog(DialogType.IMPORT_VISIO_DB_CONFLICT));
            dispatch(importVisioFile());
        }
    }, [currentConflict.isLast]);

    // Скидываем все поля на начальные значения
    useEffect(() => {
        setStrategy(defaultOptions);
    }, [resolutionById]);

    const handlOkClick = () => {
        setResolutionById({
            ...resolutionById,
            [currentConflict.fileName]: {
                ...resolutionById?.[currentConflict.fileName],
                [currentConflict.id]: options,
            },
        });
    };

    const resolveAllConflicts = () => {
        const resultResolution: TResolutionById = Object.keys(conflicts).reduce(
            (resolutions, fileName): TResolutionById => {
                return {
                    ...resolutions,
                    [fileName]: Object.keys(conflicts[fileName]).reduce((prev, item): VisioConflictResolution => {
                        return {
                            ...prev,
                            [item]: { strategy: options.strategy, value: options.value },
                        };
                    }, {}),
                };
            },
            {},
        );

        setResolutionById(resultResolution);
    };

    const handleRadioChange = (e: RadioChangeEvent) => {
        setStrategy({
            ...options,
            strategy: e.target.value,
            value: undefined,
        });
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setStrategy({
            ...options,
            value: e.target.value,
        });
    };

    const handleSelectChange = (value: string) => {
        setStrategy({
            ...options,
            value,
        });
    };

    const handleCancelImport = () => {
        dispatch(closeDialog(DialogType.IMPORT_VISIO_DB_CONFLICT));
        dispatch(cancelImportVisio());
    };

    const handleSaveLastOptions = () => {
        setStrategy({
            ...options,
            saveOptions: !options.saveOptions,
        });
    };

    const footer = (
        <>
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: handleCancelImport,
                        value: intl.formatMessage(messages.cancelText),
                    },
                    {
                        key: 'ok',
                        onClick: options.saveOptions ? resolveAllConflicts : handlOkClick,
                        value: 'OK',
                        visualStyle: 'primary',
                        disabled:
                            ['CREATE_NEW', 'USE_EXISTING_ID'].includes(options.strategy!) &&
                            (options.saveOptions || !options.value),
                    },
                ]}
            />
        </>
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    if (options.saveOptions) {
                        resolveAllConflicts();
                    } else {
                        handlOkClick();
                    }
                }
            }}
        >
            <Modal
                className={style.modal}
                open
                title={<FormattedMessage id={messages.title.id} />}
                footer={footer}
                closable={false}
                onCancel={handleCancelImport}
            >
                <div className={style.dialogContainer}>
                    <div>
                        <FormattedMessage id={tableMessages.fileNameTitle.id} />
                        :&nbsp;
                        {currentConflict.fileName}
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <FormattedMessage id={messages.conflictObject.id} />
                        :&nbsp;
                        {currentConflict.id}
                    </div>
                    <Radio.Group onChange={handleRadioChange} value={options.strategy}>
                        <Radio value="FIND_MOST_COMMON">
                            <FormattedMessage id={tableMessages.findMostCommon.id} />
                        </Radio>
                        <Radio value="FIND_OLDEST">
                            <FormattedMessage id={tableMessages.findOldest.id} />
                        </Radio>
                        <Radio value="CREATE_NEW">
                            <FormattedMessage id={tableMessages.createNew.id} />
                        </Radio>
                        <Radio value="USE_EXISTING_ID">
                            <FormattedMessage id={tableMessages.useExistingId.id} />
                        </Radio>
                    </Radio.Group>

                    {options.strategy === 'CREATE_NEW' && (
                        <>
                            <span style={{ marginTop: '15px' }}>{intl.formatMessage(messages.newName)} </span>
                            <Input onChange={handleInputChange} />
                        </>
                    )}

                    {options.strategy === 'USE_EXISTING_ID' && (
                        <>
                            <span style={{ marginTop: '15px' }}>&nbsp;</span>
                            <Select onChange={handleSelectChange}>
                                {currentConflict?.dropdownOptions?.map((item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </>
                    )}
                    <div style={{ marginTop: '15px' }}>
                        <Checkbox onChange={handleSaveLastOptions}>{intl.formatMessage(messages.saveOptions)}</Checkbox>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

const ImportVisioDBConflictDialogComponent = injectIntl(ImportVisioDBConflictDialog);

export { ImportVisioDBConflictDialogComponent as ImportVisioDBConflictDialog };
