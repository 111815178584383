import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openSearchPathDialog } from '../../../actions/search.actions';
import iFolder from '../../../resources/icons/ic-tree-folder.svg';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import style from './SearchPath.scss';
import messages from './SearchPath.messages';

type TSearchPathProps = {
    intl: IntlShape;
};

const SearchPath = ({ intl }: TSearchPathProps) => {
    const dispatch = useDispatch();

    const path = useSelector(SearchSelectors.getPath);

    const handleClick = () => {
        dispatch(openSearchPathDialog());
    };

    return (
        <div className={style.searchPath}>
            <div className={style.icon} onClick={handleClick}>
                <Icon spriteSymbol={iFolder} />
            </div>
            <h3>{`${intl.formatMessage(messages.header)}:`}</h3>
            <span className={style.path}>{path}</span>
        </div>
    );
};

const IntlComponent = injectIntl(SearchPath);

export { IntlComponent as SearchPath };
