import { FileFolderNodeDTOFileFolderTypeEnum, FolderType, NodeId, RootNodeId, UserDTO } from '../../../serverapi/api';
import { TExecutingProcess, ProcessType } from '../../../reducers/statusBar.reducer.types';
import {
    FileFolderType,
    LICENSE_FORBIDDEN_ACTIONS,
    DELETED_NODE_FORBIDDEN_ACTIONS,
    TreeItemContextMenuAction,
    TreeItemType,
} from '../models/tree';
import { SUPERADMIN_LOGIN } from '../../AdminTools/data/admintool';
import { TFilteredMenu } from '../Tree.types';
import errMsg from '../messages/TreeContextMenu.messages';
import { MessageDescriptor } from 'react-intl';
import { getCreatableTreeContextMenuItem } from '../../../services/bll/FolderTypeBLLService';
import { LocalesService } from '../../../services/LocalesService';

const BPMN_MODEL_TYPE_ID = 'm_bpmn';

const getMsg = (msg: MessageDescriptor) => LocalesService.useIntl().formatMessage(msg);

export function getMenuItemsFilter(
    processes: TExecutingProcess[],
    connectedServerId: string,
    user: UserDTO | undefined,
    folderType: FolderType | undefined,
    isModelEditor: boolean,
    isEdgeTypeDeletable: boolean,
    isDeleted: boolean,
    isFavorite: boolean,
) {
    return function menuItemsFilter(
        nodeId: NodeId,
        type: TreeItemType,
        fileFolderType?: FileFolderNodeDTOFileFolderTypeEnum,
        modelTypeId?: string,
    ): TFilteredMenu {
        const { serverId } = nodeId;

        const result: TFilteredMenu = {};

        if (!nodeId) {
            return result;
        }

        // запрет повторного подключения / изменения подключенного сервера / удаления подключенного сервера
        if (type === TreeItemType.Server) {
            if (connectedServerId === serverId) {
                result[TreeItemContextMenuAction.CONNECT] = { disabled: true };
                result[TreeItemContextMenuAction.EDIT] = { disabled: true };
                result[TreeItemContextMenuAction.SERVER_DELETE] = { disabled: true };
            } else {
                result[TreeItemContextMenuAction.SWITCH_ACTIVE_SERVER_PROFILE] = { disabled: true };
                result[TreeItemContextMenuAction.ADD_DB] = { disabled: true };
                result[TreeItemContextMenuAction.IMPORT] = { disabled: true };
                result[TreeItemContextMenuAction.REFRESH] = { disabled: true };
            }
        }

        // если запущен процесс импорта / экспорта то повторный нельзя запустить
        if (processes.length) {
            const isAlreadyExporting = processes.filter((p) => p.type === ProcessType.EXPORT).length > 0;
            const isAlreadyImporting = processes.filter((p) => p.type === ProcessType.IMPORT).length > 0;
            const isCopyingDatabase = processes.filter((p) => p.type === ProcessType.COPY_DB).length > 0;

            result[TreeItemContextMenuAction.EXPORT] = {
                disabled: isAlreadyExporting,
                message: isAlreadyExporting ? getMsg(errMsg.alreadyExporting) : '',
            };
            result[TreeItemContextMenuAction.IMPORT] = {
                disabled: isAlreadyImporting || isCopyingDatabase,
                message: isAlreadyImporting
                    ? getMsg(errMsg.alreadyImporting)
                    : isCopyingDatabase
                    ? getMsg(errMsg.copyingDbInfo)
                    : '',
            };
            result[TreeItemContextMenuAction.COPY_DATABASE] = {
                disabled: isCopyingDatabase || isAlreadyImporting,
                message: isCopyingDatabase
                    ? getMsg(errMsg.copyingDbInfo)
                    : isAlreadyImporting
                    ? getMsg(errMsg.alreadyImporting)
                    : '',
            };
        }

        const isNotScriptRunner =
            !(user && user.login === SUPERADMIN_LOGIN) &&
            !(user && user.transitiveAccesses && user.transitiveAccesses.includes('SCRIPT_RUNNER'));
        result[TreeItemContextMenuAction.EXECUTE_SCRIPT] = { disabled: isNotScriptRunner };

        if (type === TreeItemType.Script || type === TreeItemType.ScriptFolder) {
            const isNotScriptEditor =
                !(user && user.login === SUPERADMIN_LOGIN) &&
                !(user && user.transitiveAccesses && user.transitiveAccesses.includes('SCRIPT_EDITOR'));
            result[TreeItemContextMenuAction.RENAME] = { disabled: isNotScriptEditor };
            result[TreeItemContextMenuAction.DELETE] = { disabled: isNotScriptEditor };
            result[TreeItemContextMenuAction.ADD_SCRIPT_FOLDER] = { disabled: isNotScriptEditor };
            result[TreeItemContextMenuAction.ADD_SCRIPT] = { disabled: isNotScriptEditor };
            result[TreeItemContextMenuAction.MANAGE_PERMISSIONS] = { disabled: isNotScriptEditor };
        }

        // корневой каталог со скриптами нельзя удалить
        if (nodeId.id === RootNodeId.ROOT_SCRIPT_FOLDER_ID) {
            result[TreeItemContextMenuAction.DELETE] = {
                disabled: true,
                message: getMsg(errMsg.cannotRemoveScriptFolder),
            };
            result[TreeItemContextMenuAction.RENAME] = {
                disabled: true,
                message: getMsg(errMsg.cannotRenameScriptFolder),
            };
        }

        if (
            fileFolderType === FileFolderType.COMMON ||
            fileFolderType === FileFolderType.REPOSITORY ||
            fileFolderType === FileFolderType.USER_PRIVATE ||
            fileFolderType === FileFolderType.TEMP
        ) {
            result[TreeItemContextMenuAction.DELETE] = { disabled: true };
            result[TreeItemContextMenuAction.RENAME] = { disabled: true };
        }

        if (type === TreeItemType.Model && modelTypeId !== BPMN_MODEL_TYPE_ID) {
            result[TreeItemContextMenuAction.EXPORT_BPMN] = { disabled: true };
        }

        if (type === TreeItemType.Folder) {
            const isCanAdd = getCreatableTreeContextMenuItem(folderType);

            result[TreeItemContextMenuAction.ADD_FOLDER] = {
                disabled: !isCanAdd.FOLDER,
                message: !isCanAdd.FOLDER ? getMsg(errMsg.cannotAddFolderType) : '',
            };
            result[TreeItemContextMenuAction.ADD_SPREADSHEET] = {
                disabled: !isCanAdd.SPREADSHEET,
                message: !isCanAdd.SPREADSHEET ? getMsg(errMsg.cannotAddSpreadsheet) : '',
            };
            result[TreeItemContextMenuAction.ADD_FILE] = {
                disabled: !isCanAdd.FILE,
                message: !isCanAdd.FILE ? getMsg(errMsg.cannotAddFile) : '',
            };
            result[TreeItemContextMenuAction.ADD_SIMULATION_MODELING] = {
                disabled: !isCanAdd.SIMULATION,
                message: !isCanAdd.SIMULATION ? getMsg(errMsg.cannotAddSimulationModeling) : '',
            };
            result[TreeItemContextMenuAction.ADD_MODEL] = {
                disabled: !isCanAdd.MODEL,
                message: !isCanAdd.MODEL ? getMsg(errMsg.cannotAddModel) : '',
            };
            result[TreeItemContextMenuAction.ADD_OBJECT] = {
                disabled: !isCanAdd.OBJECT,
                message: !isCanAdd.OBJECT ? getMsg(errMsg.cannotAddObject) : '',
            };
        }

        // Запрет на удаление  определения связи, если запрещено профилем пользователя
        if (type === TreeItemType.EdgeDefinition) {
            result[TreeItemContextMenuAction.DELETE] = {
                disabled: !isEdgeTypeDeletable,
                message: getMsg(errMsg.noAccess),
            };
        }

        // запрет создания БД для пользователя с лицензией Viewer
        if (!isModelEditor) {
            LICENSE_FORBIDDEN_ACTIONS.forEach((item: TreeItemContextMenuAction) => {
                result[item] = {
                    disabled: true,
                    message: getMsg(errMsg.needLicense),
                };
            });
        }

        if (isDeleted) {
            result[TreeItemContextMenuAction.DELETE] = { disabled: false, hidden: true };
            result[TreeItemContextMenuAction.ERASE] = { disabled: false, hidden: false };
            result[TreeItemContextMenuAction.RESTORE] = { disabled: false, hidden: false };
            result[TreeItemContextMenuAction.RENAME] = { disabled: true, hidden: false };
        } else {
            result[TreeItemContextMenuAction.DELETE] = { disabled: false, hidden: false };
            result[TreeItemContextMenuAction.ERASE] = { disabled: false, hidden: true };
            result[TreeItemContextMenuAction.RESTORE] = { disabled: false, hidden: true };
            result[TreeItemContextMenuAction.RENAME] = { disabled: false, hidden: false };
        }

        if (isFavorite) {
            result[TreeItemContextMenuAction.ADD_TO_FAVORITES] = { disabled: false, hidden: true };
            result[TreeItemContextMenuAction.REMOVE_FROM_FAVORITES] = { disabled: false, hidden: false };
        } else {
            result[TreeItemContextMenuAction.ADD_TO_FAVORITES] = { disabled: false, hidden: false };
            result[TreeItemContextMenuAction.REMOVE_FROM_FAVORITES] = { disabled: false, hidden: true };
        }

        if (isDeleted) {
            DELETED_NODE_FORBIDDEN_ACTIONS.forEach((item: TreeItemContextMenuAction) => {
                if (!result[item]) {
                    return;
                }

                result[item].disabled = true;
                result[item].message = getMsg(errMsg.forbiddenForDeletedElement);
            });
        }

        return result;
    };
}
