import {
    WIKI_REQUEST_SUCCESS,
    WIKI_OPEN_BY_ID,
    WIKI_CHANGE_LOCALLY_REQUEST,
    WIKI_CREATE,
    WIKI_CREATE_SUCCESS,
    WIKI_CREATE_FAILURE,
    WIKI_REMOVE_SUCCESS,
    CHANGE_WIKI_DATA,
    WIKI_COPY_TO_CLIPBOARD,
    WIKI_SET_IS_CLIPBOARD_AVAILABLE,
    WIKI_UPLOAD_IMAGE,
} from '../../actionsTypes/entities/wiki.actionTypes';
import { IWikiNode } from '../../models/bpm/bpm-model-impl.types';
import { NodeId } from '../../serverapi/api';
import {
    TWikiDefaultAction,
    TWikiOpenByIdAction,
    TWikiChangeLocallyAction,
    TWikiDeleteAction,
    TWikiCopyToClipboardAction,
    TWikiSetIsClipboardAvailable,
    TWikiUploadImageAction,
    TWikiUploadImagePayload,
} from './wiki.actions.types';

export const wikiRequestSuccess = (wiki: IWikiNode): TWikiDefaultAction => ({
    type: WIKI_REQUEST_SUCCESS,
    payload: {
        wiki,
    },
});

export const wikiOpenById = (nodeId: NodeId): TWikiOpenByIdAction => ({
    type: WIKI_OPEN_BY_ID,
    payload: {
        nodeId,
    },
});

export const wikiChangeRequest = (
    nodeId: NodeId,
    source: string | undefined,
    source2: string | undefined,
): TWikiChangeLocallyAction => ({
    type: WIKI_CHANGE_LOCALLY_REQUEST,
    payload: {
        nodeId,
        source,
        source2,
    },
});

export const wikiCreate = (wiki: IWikiNode): TWikiDefaultAction => ({
    type: WIKI_CREATE,
    payload: {
        wiki,
    },
});

export const wikiCreateSuccess = (wiki: IWikiNode): TWikiDefaultAction => ({
    type: WIKI_CREATE_SUCCESS,
    payload: {
        wiki,
    },
});

export const wikiCreateFail = (wiki: IWikiNode): TWikiDefaultAction => ({
    type: WIKI_CREATE_FAILURE,
    payload: {
        wiki,
    },
});

export const wikiRemoveSuccess = (nodeId: NodeId): TWikiDeleteAction => ({
    type: WIKI_REMOVE_SUCCESS,
    payload: {
        nodeId,
    },
});

export const changeWikiData = (nodeId: NodeId, wiki: IWikiNode) => ({
    type: CHANGE_WIKI_DATA,
    payload: {
        nodeId,
        wiki,
    },
});

export const wikiCopyToClipboard = (text: string): TWikiCopyToClipboardAction => ({
    type: WIKI_COPY_TO_CLIPBOARD,
    payload: {
        text,
    },
});

export const wikiSetIsClipboardAvailable = (isAvailable: boolean): TWikiSetIsClipboardAvailable => ({
    type: WIKI_SET_IS_CLIPBOARD_AVAILABLE,
    payload: {
        isAvailable,
    },
});

export const wikiUploadImage = (payload: TWikiUploadImagePayload): TWikiUploadImageAction => ({
    type: WIKI_UPLOAD_IMAGE,
    payload,
});
