import type { TSelectedNodesState } from './selectedNodes.reducer.types';
import type { TReducer } from '../utils/types';
import {
    TREE_ITEM_SELECT,
    TREE_ITEMS_SELECT_SLICE,
    TREE_ITEMS_SELECT_SEVERAL,
    TREE_ITEMS_CLEAR_SELECTION,
} from '../actionsTypes/tree.actionTypes';
import { compareNodeIds } from '../utils/nodeId.utils';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';

const initial: TSelectedNodesState = {};

export const selectedNodesReducer: TReducer<TSelectedNodesState> = (state = initial, action) => {
    switch (action.type) {
        case TREE_ITEM_SELECT: {
            const {
                payload: { selectedNode, treeName = NAVIGATOR_STRUCTURE },
            } = action;

            if (compareNodeIds(selectedNode?.nodeId, state[treeName]?.selectedNode?.nodeId)) return state;

            return {
                ...state,
                [treeName]: {
                    selectedNode,
                    selectedNodes: selectedNode ? [selectedNode] : [],
                },
            };
        }

        case TREE_ITEMS_SELECT_SLICE: {
            const {
                payload: { selectedNodes },
            } = action;

            const treeName = NAVIGATOR_STRUCTURE;

            return {
                ...state,
                [treeName]: {
                    selectedNodes,
                },
            };
        }
        case TREE_ITEMS_SELECT_SEVERAL: {
            const {
                payload: { selectedNode },
            } = action;

            const treeName = NAVIGATOR_STRUCTURE;

            return {
                ...state,
                [treeName]: {
                    selectedNode,
                    selectedNodes: state[treeName].selectedNodes.some((n) =>
                        compareNodeIds(n.nodeId, selectedNode.nodeId),
                    )
                        ? state[treeName].selectedNodes.filter((n) => !compareNodeIds(n.nodeId, selectedNode.nodeId))
                        : [...state[treeName].selectedNodes, selectedNode],
                },
            };
        }

        case TREE_ITEMS_CLEAR_SELECTION: {
            const treeName = NAVIGATOR_STRUCTURE;

            return {
                [treeName]: state[treeName],
            };
        }
        default: {
            return state;
        }
    }
};
