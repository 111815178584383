import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, FormInstance, Tabs } from 'antd';
import { LdapProperties, SystemKeycloakSettings, SystemProperties } from '../../../serverapi/api';
import messages from './SystemProperties.messages';
import { transformValues, validateKeycloakSettings, validateLdapProperties } from './SystemProperties.utils';
import theme from './SystemProperties.scss';
import { LdapSettingsComponent } from './components/LdapSettingsComponent/LdapSettings.component';
import {
    formItemLayout,
    menuItems,
    SideBarItemsId,
    SystemPropertiesFormItemNames,
    TSystemPropertiesProps,
} from './SystemProperties.types';
import { showNotification } from '../../../actions/notification.actions';
import { NotificationType } from '../../../models/notificationType';
import { v4 as uuid } from 'uuid';
import { InterFaceSettings } from './components/InterFaceSetting/InterFaceSettings.component';
import { LicenseAgreementSettings } from './components/LicenseAgreementSettingsComponent/LicenseAgreementSettings.component';
import { SecuritySettings } from './components/SecuritySettingsComponent/SecuritySettings.component';
import { AdditionalSettings } from './components/AdditionalSettingsComponent/AdditionalSetting.component';
import { HomePageSettings } from './components/HomePageSettings/HomePageSettings.component';
import { ServerSettings } from './components/ServerSettingsComponent/ServerSettings.component';
import { ImageSettings } from './components/ImageSettingsComponent/ImageSettings.component';
import { ScriptEngineSettings } from './components/ScriptEngineSettingsComponent/ScriptEngineSettings.component';
import { KerberosSettings } from './components/KerberosSettingsComponent/KerberosSettings.component';
import { UserSessionSettings } from './components/UserSessionsSettingsComponent/UserSessionSettings.component';
import { ModelSettings } from './components/ModelSettingsComponent/ModelSetting.component';
import { AppDBSettings } from './components/AppDBSettingsComponent/AppDBSetting.component';
import { MailSettings } from './components/MailSettingsComponent/MailSettings.component';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceRemoveTabByNodeId } from '../../../../src/actions/tabs.actions';
import { SystemPropertiesSelectors } from '../../../selectors/systemProperties.selectors';
import { IGenericNotification } from '../../../models/notification.types';
import { saveSystemProperties } from '../../../actions/systemProperties.actions';
import { KeycloakSettings } from './KeycloakSettings/KeycloakSettings.component';
import { RcFile } from 'rc-upload/lib/interface';
import { TButtonProps } from '@/modules/UIKit/components/Button/Button.types';
import { FooterButtons } from '@/modules/Footer/FooterButtons.component';

export const SystemPropertiesComponent = (props: TSystemPropertiesProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const formRef = useRef<FormInstance>(null);
    const ldapSettings: LdapProperties[] | undefined = useSelector(SystemPropertiesSelectors.getLdapSettings);
    const keycloakSettings: SystemKeycloakSettings | undefined = useSelector(
        SystemPropertiesSelectors.getKeycloakSettings,
    );
    const systemPropertiesFromStore: SystemProperties = useSelector(SystemPropertiesSelectors.getSystemProperties);
    const [isNeedValidateLdap, setIsNeedValidateLdap] = useState<boolean>(false);
    const [isNeedValidateKeycloak, setIsNeedValidateKeycloak] = useState<boolean>(false);

    const components = {
        [SideBarItemsId.SERVER]: <ServerSettings />,
        [SideBarItemsId.MODEL]: <ModelSettings />,
        [SideBarItemsId.USER_SESSION]: <UserSessionSettings />,
        [SideBarItemsId.SCRIPT_ENGINE]: <ScriptEngineSettings formRef={formRef} />,
        [SideBarItemsId.IMAGE]: <ImageSettings formRef={formRef} />,
        [SideBarItemsId.LDAP]: (
            <LdapSettingsComponent
                form={formRef.current}
                isNeedValidateLdap={isNeedValidateLdap}
                setIsNeedValidateLdap={setIsNeedValidateLdap}
            />
        ),
        [SideBarItemsId.KERBEROS]: <KerberosSettings />,
        [SideBarItemsId.KEYCLOAK]: (
            <KeycloakSettings
                form={formRef.current}
                isNeedValidateKeycloak={isNeedValidateKeycloak}
                setIsNeedValidateKeycloak={setIsNeedValidateKeycloak}
            />
        ),
        [SideBarItemsId.APP_DB]: <AppDBSettings />,
        [SideBarItemsId.MAIL_SETTINGS]: <MailSettings formRef={formRef} />,
        [SideBarItemsId.INTERFACE_SETTINGS]: <InterFaceSettings formRef={formRef} />,
        [SideBarItemsId.HOME_PAGE_SETTINGS]: <HomePageSettings formRef={formRef} />,
        [SideBarItemsId.ADDITIONAL_SETTINGS]: <AdditionalSettings />,
        [SideBarItemsId.SECURITY]: <SecuritySettings formRef={formRef} />,
        [SideBarItemsId.LICENSE_AGREEMENT]: <LicenseAgreementSettings formRef={formRef} />,
    };

    const tabs = menuItems.map(({ id, titleId }) => ({
        label: intl.formatMessage(titleId),
        key: id,
        children: components[id],
    }));

    const [formTabId, setFormTabId] = useState<string>(SideBarItemsId.SERVER);

    const closeTab = () => {
        dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId));
    };

    const handleChangeFormTab = (activeKey: string) => {
        setFormTabId(activeKey);
    };

    const handleShowNotification = (message: string) => {
        dispatch(
            showNotification({
                id: uuid(),
                type: NotificationType.SYSTEM_PROPERTY_FAIL,
                data: {
                    message,
                } as IGenericNotification,
            }),
        );
    };

    const handleSubmit = () => {
        const form: FormInstance<any> | null = formRef.current;
        
        if (form) {
            form.validateFields()
                .then((values: SystemProperties) => {
                    const greetingLogo: RcFile | string | undefined = values?.greetingLogo;
                    const transformAndSaveSystemProperties = (greetingLogoValue: string | ArrayBuffer | null | undefined) => {
                        const systemPropertiesValues: SystemProperties = {
                            ...values,
                            serverTime: undefined,
                        };
                      
                        if (greetingLogoValue && typeof greetingLogoValue === 'string') {
                            systemPropertiesValues.greetingLogo = greetingLogoValue;
                        } 
                        const transformedValues: SystemProperties = transformValues(systemPropertiesValues);
                        const newSystemProperties: SystemProperties = { ...systemPropertiesFromStore, ...transformedValues };

                        dispatch(saveSystemProperties(newSystemProperties));
                    };
                    if (!greetingLogo) {
                        transformAndSaveSystemProperties(undefined);
                    } else if (typeof greetingLogo === 'object') {
                        const reader = new FileReader();
                        reader.readAsDataURL(greetingLogo as RcFile);
                        reader.onloadend = () => {
                            transformAndSaveSystemProperties(reader.result);
                        };
                    } else {
                        transformAndSaveSystemProperties(greetingLogo);
                    }
                })
                .catch((error) => {
                    switch (error.errorFields[0].name[0]) {
                        case SystemPropertiesFormItemNames.ldapSettings: {
                            handleChangeFormTab(SideBarItemsId.LDAP);
                            handleShowNotification(error.errorFields[0].errors[0]);
                            setIsNeedValidateLdap(true);
                            break;
                        }

                        case SystemPropertiesFormItemNames.multilingualSilaAgreementDescription:
                        case SystemPropertiesFormItemNames.multilingualAgreementDescription: {
                            handleChangeFormTab(SideBarItemsId.LICENSE_AGREEMENT);
                            handleShowNotification(intl.formatMessage(messages.agreementRequired));
                            break;
                        }

                        case SystemPropertiesFormItemNames.userSessionLengthMax:
                        case SystemPropertiesFormItemNames.userSessionInactivity: {
                            handleChangeFormTab(SideBarItemsId.USER_SESSION);
                            handleShowNotification(error.errorFields[0].errors[0]);
                            break;
                        }

                        case SystemPropertiesFormItemNames.modelVersionSaveTimeout:
                        case SystemPropertiesFormItemNames.modelAutoSaveTimeout: {
                            handleChangeFormTab(SideBarItemsId.MODEL);
                            handleShowNotification(error.errorFields[0].errors[0]);
                            break;
                        }

                        case SystemPropertiesFormItemNames.minPasswordLength:
                        case SystemPropertiesFormItemNames.passwordMinDurationTime:
                        case SystemPropertiesFormItemNames.passwordMaxDurationTime: {
                            handleChangeFormTab(SideBarItemsId.SECURITY);
                            handleShowNotification(error.errorFields[0].errors[0]);
                            break;
                        }
                        case SystemPropertiesFormItemNames.keycloakSettings: {
                            handleChangeFormTab(SideBarItemsId.KEYCLOAK);
                            handleShowNotification(error.errorFields[0].errors[0]);
                            setIsNeedValidateKeycloak(true);
                            break;
                        }

                        default: {
                            handleShowNotification(intl.formatMessage(messages.notValidFields));
                        }
                    }
                });
        }
    };

    const buttons: TButtonProps[] = [
        {
            children: intl.formatMessage(messages.cancel),
            size: "large",
            key: "cancel",
            onClick: closeTab,
        },
        {
            children: intl.formatMessage(messages.save),
            size: "large",
            visualStyle: "primary",
            key: "ok",
            onClick: handleSubmit,
        }
    ];

    return (
        <div className={theme.tabContent} data-test="server-settings_container">
            <div className={theme.containerInner}>
                <div className={theme.rightBorder}>
                    <Form {...formItemLayout} ref={formRef}>
                        <Tabs activeKey={formTabId} tabPosition="left" items={tabs} onChange={handleChangeFormTab} />
                        {/* создан для хранения значения в форме, визуально не отображается */}
                        <Form.Item
                            name={SystemPropertiesFormItemNames.ldapSettings}
                            initialValue={ldapSettings || []}
                            noStyle
                            rules={[
                                {
                                    validator: (_, value: LdapProperties[]) => validateLdapProperties(value),
                                },
                            ]}
                        />
                        <Form.Item
                            name={SystemPropertiesFormItemNames.keycloakSettings}
                            initialValue={keycloakSettings}
                            noStyle
                            rules={[
                                {
                                    validator: (_, value: SystemKeycloakSettings) => validateKeycloakSettings(value),
                                },
                            ]}
                        />
                    </Form>
                </div>
            </div>
            <FooterButtons buttons={buttons} />
        </div>
    );
};
