import { Checkbox, Form, InputNumber } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';

export const AdditionalSettings = () => {
    const intl = useIntl();

    const isActiveHidingUsers = useSelector(SystemPropertiesSelectors.getIsActiveHidingUsers);
    const experimentalFeatures = useSelector(SystemPropertiesSelectors.getExperimentalFeatures);
    const messageStorageDays = useSelector(SystemPropertiesSelectors.getMessageStorageDays);

    return (
        <div className={theme.formTab}>
            <Form.Item
                initialValue={isActiveHidingUsers}
                name={SystemPropertiesFormItemNames.isActiveHidingUsers}
                className={classNames(theme.formItemForActiveHidingUsers, theme.formItem)}
                label={intl.formatMessage(messages.isActiveHidingUsers)}
                valuePropName="checked"
            >
                <Checkbox className={theme.checkbox} />
            </Form.Item>
            <Form.Item
                initialValue={experimentalFeatures}
                name={SystemPropertiesFormItemNames.experimentalFeatures}
                className={theme.formItem}
                label={intl.formatMessage(messages.experimentalFeatures)}
                valuePropName="checked"
            >
                <Checkbox data-test="enable-experimental-system-features_checkbox" className={theme.checkbox} />
            </Form.Item>
            <Form.Item
                initialValue={messageStorageDays || 90}
                name={SystemPropertiesFormItemNames.messageStorageDays}
                className={theme.formItem}
                label={intl.formatMessage(messages.messageStorageDays)}
            >
                <InputNumber min={3} max={99999}></InputNumber>
            </Form.Item>
        </div>
    );
};
