import { CellMeasurerCache, Column, SortDirectionType } from 'react-virtualized';
import { TColumn, TTableData } from '../TableUIKit.types';
import React from 'react';
import { ColumnHeader } from '../ColumnHeader.component';
import { ColumnCell } from '../ColumnCell.component';
import { ColumnHeaderWithCheckBox } from '../ColumnHeaderWithCheckBox.component';
import { ColumnCellWithCheckBox } from '../ColumnCellWithCheckBox.component';
import theme from '../TableUIKitComponent.scss';

type TGetColumnsProps = {
    withCkeckBox?: boolean;
    columns: TColumn[];
    width: number;
    cache: CellMeasurerCache;
    sortedList: TTableData[];
    checkRows?: (rows: TTableData[], checked: boolean) => void;
};

export const getColumns = (props: TGetColumnsProps): JSX.Element[] => {
    const { withCkeckBox, columns, width, cache, sortedList, checkRows } = props;

    const cols: JSX.Element[] = columns.map((column, ind) => {
        const { title, withoutSorter, dataKey, width: columnWidth } = column;

        return (
            <Column
                width={columnWidth || width}
                flexGrow={columnWidth ? 1 : undefined}
                maxWidth={columnWidth}
                minWidth={columnWidth}
                dataKey={dataKey}
                headerRenderer={(data) => <ColumnHeader label={title} withoutSorter={withoutSorter} data={data} />}
                cellRenderer={(data) => (
                    <ColumnCell
                        index={ind}
                        cellProps={data}
                        cache={cache}
                        value={sortedList[data.rowIndex][dataKey] ?? ''}
                    />
                )}
                key={column.dataKey}
                className={theme.column}
                disableSort={withoutSorter}
            />
        );
    });
    if (withCkeckBox)
        cols.unshift(
            <Column
                flexGrow={1}
                maxWidth={40}
                minWidth={40}
                width={40}
                dataKey="checkbox"
                headerRenderer={() => (
                    <ColumnHeaderWithCheckBox
                        isChecked={sortedList.every((item) => item?.checked)}
                        isIndeterminate={sortedList.some((item) => item?.checked)}
                        handleChange={(e) =>
                            checkRows
                                ? checkRows(sortedList, !!(e.nativeEvent.target as HTMLInputElement)?.checked)
                                : undefined
                        }
                    />
                )}
                cellRenderer={(data) => (
                    <ColumnCellWithCheckBox
                        cache={cache}
                        checked={!!sortedList[data.rowIndex].checked}
                        disabled={!!sortedList[data.rowIndex].disabled}
                        handleChange={(e) =>
                            checkRows
                                ? checkRows(
                                      [sortedList[data.rowIndex]],
                                      !!(e.nativeEvent.target as HTMLInputElement)?.checked,
                                  )
                                : undefined
                        }
                        cellProps={data}
                    />
                )}
                key="checkbox"
                className={theme.column}
                disableSort
            />,
        );

    return cols;
};

export const getInitialSortDirection = (columns: TColumn[]): SortDirectionType => {
    return (
        columns.find((column) => column.sortFunction && column.defaultSort && !column?.withoutSorter)?.defaultSort ||
        'ASC'
    );
};

export const getInitialSortState = (columns: TColumn[]): string => {
    return columns.find((column) => column.sortFunction && column.defaultSort && !column?.withoutSorter)?.dataKey || '';
};

export const getHeaderClassName = (list: TTableData[]): string => {
    return list.every((item) => item?.checked) ? 'checkedHeader' : 'headerRow';
};

export const getRowClassName = (index: number, list: TTableData[]): string => {
    if (list[index]?.checked && index !== -1) return 'checkedRow';

    return 'row';
};
