import { createSelector } from 'reselect';
import { ObjectTypeSelectors } from '../../../selectors/objectType.selectors';
import { ModelTypeSelectors } from '../../../selectors/modelType.selectors';
import { FolderTypeSelectors } from '../../../selectors/folderType.selectors';
import { EdgeTypeSelectors } from '../../../selectors/edgeType.selectors';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { LocalesService } from '../../../services/LocalesService';
import { TreeItemType } from '../../Tree/models/tree';
import { TRootState } from '../../../reducers/root.reducer.types';
import { EdgeType, FolderType, ModelType, NodeId, ObjectType } from 'src/serverapi/api';
import { Locale } from '../../Header/components/Header/header.types';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { getStore } from '../../../store';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import propertiesMessages from '../../../models/accessible-properties.messages';

export namespace SearchElementTypeSelectors {
    const selectModelType = (serverId: string, presetId: string, elementType: string) =>
        createSelector<TRootState, ModelType, Locale, string>(
            ModelTypeSelectors.byId(
                {
                    modelTypeId: elementType,
                    serverId,
                },
                presetId,
            ),
            getCurrentLocale,
            (modelType, locale) => LocalesService.internationalStringToString(modelType?.multilingualName, locale),
        );

    const selectObjectType = (serverId: string, presetId: string, elementType: string) =>
        createSelector<TRootState, ObjectType | undefined, Locale, string>(
            ObjectTypeSelectors.byId({
                objectTypeId: elementType,
                serverId,
                presetId,
            }),
            getCurrentLocale,
            (objectType, locale) => LocalesService.internationalStringToString(objectType?.multilingualName, locale),
        );

    const selectFolderType = (serverId: string, presetId: string, elementType: string) =>
        createSelector<TRootState, FolderType, Locale, string>(
            FolderTypeSelectors.byId({
                folderTypeId: elementType,
                serverId,
                presetId,
            }),
            getCurrentLocale,
            (folderType, locale) => {
                const intl = LocalesService.useIntl();

                return (
                    LocalesService.internationalStringToString(folderType?.multilingualName, locale) ||
                    LocalesService.internationalStringToString(
                        {
                            ru: intl.formatMessage(propertiesMessages.defaultFolderType),
                            en: intl.formatMessage(propertiesMessages.defaultFolderType),
                        },
                        locale,
                    )
                );
            },
        );

    const selectEdgeType = (serverId: string, presetId: string, elementType: string) =>
        createSelector<TRootState, EdgeType, Locale, string>(
            EdgeTypeSelectors.byId({
                edgeTypeId: elementType,
                serverId,
                presetId,
            }),
            getCurrentLocale,
            (edgeType, locale) => LocalesService.internationalStringToString(edgeType?.multilingualName, locale),
        );

    export const getElementTypeName = (serverId: string, presetId: string, elementType: string, type: TreeItemType) => {
        switch (type) {
            case 'MODEL':
                return selectModelType(serverId, presetId, elementType);
            case 'OBJECT':
                return selectObjectType(serverId, presetId, elementType);
            case 'FOLDER':
                return selectFolderType(serverId, presetId, elementType);
            case 'EDGE':
                return selectEdgeType(serverId, presetId, elementType);
            default:
                return () => '';
        }
    };

    export const getSearchResult = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchDataListItem[], string | undefined, TSearchDataListItem[]>(
            SearchSelectors.getFilterSearchResult(),
            TreeSelectors.presetById(nodeId),
            (res, presetId: string) => {
                return res?.map((item) => {
                    const state = getStore().getState();

                    return {
                        ...item,
                        itemTypeName: getElementTypeName(
                            nodeId.serverId,
                            presetId || '',
                            item.elementType || '',
                            item.type as TreeItemType,
                        )(state),
                    } as TSearchDataListItem;
                });
            },
        );
}
