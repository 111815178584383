// TODO использовать другой тип, не относящийся к Вики
import type { TWikiLink } from '@/models/tab.types';
import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash-es';
import messages from '../CommonToolbar.messages';
import icLinkCreate from 'icons/toolbar/controls/ic-link-create.svg';
import icLinkInSystem from 'icons/toolbar/controls/ic-link-in-system.svg';
import icLinkToSite from 'icons/toolbar/controls/ic-link-to-site.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { Dropdown } from '../../../Toolbar/Dropdown.component';
import { getSelectedText } from '../../common/helpers';
import { isInternalLink } from '@/utils/url.utils';

type TLinkState = {
    action: string;
    link?: TWikiLink;
};

type TLinkAttributes = {
    href: string;
    target?: string | null | undefined;
    rel?: string | null | undefined;
    class?: string | null | undefined;
};

const insertLink = (editor: Editor, text: string, attributes: TLinkAttributes) =>
    editor
        .chain()
        .focus()
        .deleteSelection()
        .setLink(attributes)
        .command(({ tr }) => {
            tr.insertText(text);
            return true;
        })
        .run();

const getFocusedBlock = (editor: Editor) => ({
    link: editor.getAttributes('link') as TLinkAttributes,
    text: getSelectedText(editor),
});

const getChangedBlock = (editor: Editor, value: TLinkState) => {
    const { action, link } = value;

    if (!link) {
        return false;
    }

    const target = action === 'EXTERNAL' ? '_blank' : '_self';

    return insertLink(editor, link.text, { href: link.url, target, rel: null });
};

export const LinksComponent = ({ openDialog }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<{ link: TLinkAttributes; text: string }, TLinkState>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const addLink = (action: string) =>
        openDialog((link: TWikiLink) => setCurrentState({ action, link }), {
            url: value?.link?.href || '',
            text: value?.text || '',
            external: action === 'EXTERNAL',
        });

    const hasLink = value && !isEmpty(value?.link?.href);

    const menuItems = [
        {
            value: 'INTERNAL',
            tooltip: intl.formatMessage(messages.internalLink),
            spriteSymbol: icLinkInSystem,
            selected: hasLink && isInternalLink(value.link.href),
            // dataTest: '',
        },
        {
            value: 'EXTERNAL',
            tooltip: intl.formatMessage(messages.externalLink),
            spriteSymbol: icLinkToSite,
            selected: hasLink && !isInternalLink(value.link.href),
            // dataTest: '',
        },
    ];

    return (
        <Dropdown<string>
            items={menuItems}
            title={intl.formatMessage(messages.links)}
            compact
            defaultSprite={icLinkCreate}
            onSelect={addLink}
            // dataTest=""
        />
    );
};
