import * as React from 'react';
import { Button } from 'antd';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import icCross from '../../../../resources/icons/ic-cross.svg';
import icOk from '../../../../resources/icons/ic-ok.svg';
import allowIcon from '../../../../resources/icons/ic-checkbox-allow-green.svg';
import denyIcon from '../../../../resources/icons/ic-checkbox-deny-color.svg';
import inheritIcon from '../../../../resources/icons/ic-checkbox-inherit-empty.svg';
import { TSpriteSymbol } from '../../../../models/spriteSymbol.types';
import theme from './PermissionSwitch.scss';
import { FC } from 'react';

type TPermissionSwitchProps = {
    isGranting: boolean | undefined;
    onClick?: () => any;
    onlyTwoIcon?: boolean;
    readOnly?: boolean;
    dataKey?: string;
    propagation?: boolean;
    isAllPermissions?: boolean;
};

export const PermissionSwitch: FC<TPermissionSwitchProps> = ({
    isGranting,
    onClick = (f?: any) => f,
    onlyTwoIcon = false,
    readOnly = false,
    dataKey = '',
    propagation,
    isAllPermissions,
}) => {
    const icon = (): TSpriteSymbol => {
        if (isGranting === undefined && !onlyTwoIcon) {
            return inheritIcon;
        }
        if (isGranting) {
            return isAllPermissions ? icOk : allowIcon;
        }

        return isAllPermissions ? icCross : denyIcon;
    };

    let status = 'negative';
    if (isGranting === undefined && !onlyTwoIcon) {
        status = 'empty';
    }
    if (isGranting) status = 'positive';

    return (
        <div className={theme.permissionSwitchContainer}>
            <Button
                ghost
                className={theme.button}
                style={readOnly ? { cursor: 'default' } : {}}
                onClick={(event) => {
                    if (!propagation) event.stopPropagation();
                    onClick();
                }}
                data-test={`additional-rights_table_column_${dataKey}`}
                data-test-id={`additional-rights_checkbox-status_${status}`}
            >
                <Icon spriteSymbol={icon()} />
            </Button>
        </div>
    );
};
