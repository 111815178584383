import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnType } from 'antd/es/table';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useSelector } from 'react-redux';
import { TEdgeDataSourceRow } from '../SelectExistingDefinitionsDialogs.types';
import { SelectEdgeDefinitionDialogSelectors } from '../../../../../selectors/selectEdgeDefinitionDialog.selectors';
import { EdgeDefinitionNode } from '../../../../../serverapi/api';

export const useEdgeTableData = (
    edges: EdgeDefinitionNode[],
    nameRenderer: (obj: EdgeDefinitionNode) => JSX.Element,
    onClick: (key: string) => void,
) => {
    const intl = useIntl();
    const { edgeTypeNameMap } = useSelector(SelectEdgeDefinitionDialogSelectors.selectEdgeDefinitionDialogState);

    const columns: ColumnType<string>[] = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.name),
                dataIndex: 'name',
                width: '40%',
            },
            {
                title: intl.formatMessage(messages.egdeTypeLabel),
                dataIndex: 'edgeType',
                width: '40%',
            },
            {
                title: intl.formatMessage(messages.author),
                dataIndex: 'author',
            },
        ],
        [],
    );

    const dataSource: TEdgeDataSourceRow[] = edges.map((edgeDef) => ({
        key: edgeDef.nodeId.id,
        name: nameRenderer(edgeDef),
        edgeType: edgeTypeNameMap.get(edgeDef.nodeId.id) || '',
        author: edgeDef.createdBy,
    }));

    const onRow = useCallback((record: TEdgeDataSourceRow) => ({ onClick: () => onClick(record.key) }), [onClick]);

    return { columns, dataSource, onRow };
};
