export enum TNavigatorTab {
    Tree = 'Tree',
    Symbols = 'Symbols',
    Properties = 'Properties',
    Outline = 'Outline',
    Comments = 'Comments',
}

export type TNavigatorState = {
    activeTabs: Array<TNavigatorTab>;
};
