import { Button, Tooltip } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
// import messages from '../ScriptGeneralToolbar/ScriptGeneralToolbar.messages';
import React, { FC } from 'react';
import theme from './ScriptGeneralToolbar.scss';
import menuItemTheme from '../items/MenuItem.scss';
import classnames from 'classnames';
import messages from '../../messages/CommonToolbar.messages';
import { EditorMode } from '../../../../models/editorMode';
import icReadMode from '../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../resources/icons/ic-edit-mode.svg';
import { injectIntl } from 'react-intl';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { TScriptGeneralToolbarProps } from './ScriptGeneralToolbar.types';

const ScriptGeneralToolbarComponent: FC<TScriptGeneralToolbarProps> = (props: TScriptGeneralToolbarProps) => {
    const { compact, isActiveScheme, script, activeWorkspaceTab, intl, editorMode, onModeChange } = props;
    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
    });
    const handleModeChange = () => {
        onModeChange(editorMode === EditorMode.Read ? EditorMode.Edit : EditorMode.Read, script.nodeId);
    };
    const clickRunWithProps = () => {
        props.clickRunWithProps(script);
    };
    const isReadMode = activeWorkspaceTab && activeWorkspaceTab.mode === EditorMode.Read;
    const editModeTooltipeTitle = isReadMode
        ? intl.formatMessage(messages.toEditMode)
        : intl.formatMessage(messages.toReadMode);

    return (
        <div className={className}>
            <div className={menuItemTheme.group}>
                <div className={!compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        overlayClassName={menuItemTheme.customTooltip}
                        title={isActiveScheme ? editModeTooltipeTitle : ''}
                    >
                        <Button className={theme.modeButton} onClick={handleModeChange}>
                            <Icon spriteSymbol={editorMode === EditorMode.Read ? icReadMode : icEditMode} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div className={menuItemTheme.divider} />
            <div className={classnames(menuItemTheme.group, !compact && theme.group_full_size)}>
                <Tooltip
                    mouseLeaveDelay={0}
                    placement="topLeft"
                    overlayClassName={menuItemTheme.customTooltip}
                    title={intl.formatMessage(messages.toRunScript)}
                >
                    <Button
                        onClick={clickRunWithProps}
                        className={theme.executeScript_button}
                        data-test="script_run_toolbar_button"
                    >
                        <PlayCircleOutlined />
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

const intlComponent = injectIntl(ScriptGeneralToolbarComponent);

export { intlComponent as ScriptGeneralToolbar };
