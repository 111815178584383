import { getOrigin, replaceLastSlash } from '@/utils/url.utils';
import { LockInfoDTO, MatrixNode, MatrixType, NodeId } from '../../../serverapi/api';
import { MatrixBllService } from '../bll/MatrixBllService';
import { apiBundle, ApiBundle } from '@/services/api/api-bundle';
import { IMatrixNode } from '@/models/bpm/bpm-model-impl.types';
import { setServerIdToNodeInterface } from '@/utils/nodeId.utils';
import { isEmpty } from 'lodash-es';

export class MatrixDaoService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async getMatrix(repositoryId: string, matrixId: string) {
        const api: ApiBundle = this.getApi();
        const matrix: MatrixNode = await api.matrix.get({ repositoryId, matrixId });
        // Временная проверка на старую матрицу
        // https://jira.silaunion.ru/browse/BPM-9582 задача с исправлением импорта старой матрицы
        if (isEmpty(matrix.data2)) {
            matrix.data2 = undefined;
        }
        const styledMatrix: MatrixNode = MatrixBllService.addStyleIdsToCells(matrix);

        return styledMatrix;
    }

    public static async saveMatrix(matrix: IMatrixNode) {
        const savedMatrix: MatrixNode = await apiBundle(matrix.nodeId.serverId)
            .matrix.save({ body: matrix })
            .then((result) => setServerIdToNodeInterface(result, matrix.nodeId.serverId));

        return savedMatrix;
    }

    public static async lockMatrix(nodeId: NodeId) {
        const lock: LockInfoDTO = await apiBundle(nodeId.serverId).matrix.lockMatrix({
            matrixId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });
        return lock;
    }

    public static async unlockMatrix(nodeId: NodeId) {
        const unlock = await apiBundle(nodeId.serverId).matrix.unlockMatrix({
            matrixId: nodeId.id,
            repositoryId: nodeId.repositoryId,
        });
        return unlock;
    }

    public static async createMatrixType(requestBody: { body: MatrixType }) {
        const api = this.getApi();
        await api.matrixType.create({ body: requestBody.body });
    }

    public static async saveMatrixType(requestBody: { body: MatrixType }) {
        const api = this.getApi();
        await api.matrixType.save({ body: requestBody.body });
    }

    public static async deleteMatrixType(presetId: string, id: string) {
        const api = this.getApi();
        await api.matrixType._delete({ presetId, id });
    }
}
