import React from 'react';
import { ReactSVG } from 'react-svg';

const CompareModelImage = ({ url, style = {}, className = '' }) => {
    if (!url) {
        return <></>;
    }

    return (
        <div className={className} style={style}>
            <ReactSVG src={url} />
        </div>
    );
};

export default CompareModelImage;

