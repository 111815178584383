import { SET_SCRIPT_CONTEXT_LOADING } from "../actionsTypes/scriptContext.actionTypes";
import { TReducer } from "../utils/types";
import { TScriptContextState } from "./scriptContext.reducer.types";

const initial: TScriptContextState = {
    isLoading: true
};

export const scriptContextReducer: TReducer<TScriptContextState> = (state = initial, action) => {
    switch (action.type) {
      
        case SET_SCRIPT_CONTEXT_LOADING: {
            const {isLoading} = action.payload;

            return {
                isLoading
            };
        }
        default:
            return state;
    }
};