// @test-ignore_ru
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './SymbolTypeSelectDialog.messages';
import symbolsTypesMessages from '../SymbolsTypes.messages';
import theme from './SymbolTypeSelectDialog.scss';
import {
    DEFAULT_SYMBOL_TYPE_ID,
    SequenceSymbolTypeId,
    SymbolTypeId,
} from '@/mxgraph/ComplexSymbols/symbols/ComplexSymbol.constants';
import { ExperimentalFeatures } from '../../../../../../../models/ExperimentalFeatures';
import { KANBAN_CARD } from '../../util/KanbanCardEditor.utils';
import { Dialog } from '../../../../../../UIKit/components/Dialog/Dialog.component';
import { Select } from '../../../../../../UIKit/components/Select/Select.component';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDialogProps = {
    open: boolean;
    onSubmit: (value: string) => void;
    onClose: () => void;
};

const symbolsTypesId = [
    SymbolTypeId.SIMPLE,
    SymbolTypeId.HORIZONTAL_SWIMLANE,
    SymbolTypeId.VERTICAL_SWIMLANE,
    SymbolTypeId.VR,
    SymbolTypeId.IDEF,
    SymbolTypeId.HORIZONTAL_POOL,
    SymbolTypeId.VERTICAL_POOL,
    SymbolTypeId.CROSS,
    SymbolTypeId.UML_CLASS,
];

const experimentalSymbolTypesId = [
    SequenceSymbolTypeId.LIFE_LINE,
    SequenceSymbolTypeId.LIFE_LINE_BOUNDERY,
    SequenceSymbolTypeId.LIFE_LINE_ENTITY,
    SequenceSymbolTypeId.LIFE_LINE_CONTROL,
    SequenceSymbolTypeId.LIFE_LINE_ACTOR,
    SymbolTypeId.UML_MESSAGE,
    KANBAN_CARD,
];

export const SymbolTypeSelectDialogComponent = (props: TDialogProps) => {
    const { onClose, onSubmit, open } = props;
    const [value, setValue] = useState(DEFAULT_SYMBOL_TYPE_ID);
    const intl = useIntl();
    const handleChange = (symbolTypeId: string) => setValue(symbolTypeId);
    const onOk = () => onSubmit(value);

    const selectOptions = ExperimentalFeatures.isEnable()
        ? [...symbolsTypesId, ...experimentalSymbolTypesId]
        : symbolsTypesId;

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                    dataTest: 'new-symbol-type-window_save-btn',
                },
            ]}
        />
    );

    return (
        <div>
            <Dialog
                title={intl.formatMessage(messages.selectSymbolType)}
                open={open}
                onCancel={onClose}
                className={theme.dialog}
                footer={footer}
            >
                <Select
                    originalTheme
                    value={intl.formatMessage(symbolsTypesMessages[value])}
                    onChange={handleChange}
                    data-test="new-symbol-type-window_type"
                >
                    {selectOptions.map((symbolsTypeId) => (
                        <Select.Option
                            key={symbolsTypeId}
                            value={symbolsTypeId}
                            label={intl.formatMessage(symbolsTypesMessages[symbolsTypeId])}
                        />
                    ))}
                </Select>
            </Dialog>
        </div>
    );
};
