import { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { TGraphPasteToolbarProps } from '../components/GraphPasteToolbar/GraphPasteToolbar.types';
import { GraphPasteToolbar } from '../components/GraphPasteToolbar/GraphPasteToolbar.component';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { dragShapeAction } from '../../../actions/editor.actions';
import { SymbolConstants } from '../../../models/Symbols.constants';
import { getShape } from '../../../models/Symbols.utils';
import { TWorkspaceTab } from '../../../models/tab.types';
import { LocalesService } from '../../../services/LocalesService';

const mapStateToProps = (state: TRootState): Partial<TGraphPasteToolbarProps> => {
    const activeScheme: TWorkspaceTab | undefined = TabsSelectors.getActiveTab(state);

    return {
        activeWorkspaceTab: activeScheme,
        compact: state.generalMenu.isCompact,
        isActiveScheme: !!TabsSelectors.getActiveTabId(state),
        locale: getCurrentLocale(state),
        locales: LocalesService.getLocaleList(),
        editorMode: activeScheme && activeScheme.mode,
    };
};

const mapDispatchToProps = (dispatch): Partial<TGraphPasteToolbarProps> => ({
    openImageDialog: () => {
        dispatch(openDialog(DialogType.IMAGE_EDIT_DIALOG));
    },
    onPasteTextAction: () => dispatch(dragShapeAction(SymbolConstants.shape)),
    onPasteShapeAction: (shapeStyle: string) => dispatch(dragShapeAction(getShape(shapeStyle))),
});

export const GraphPasteToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(GraphPasteToolbar);
