import React, { FC } from 'react';
import theme from './MatrixEditorToolbar.scss';
import menuItemTheme from '../items/MenuItem.scss';
import messages from './MatrixEditorToolbar.messages';
import commonToolbarMessages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import icReadMode from '@/resources/icons/ic-read-mode.svg';
import icEditMode from '@/resources/icons/ic-edit-mode.svg';
import icSettingsMatrix from '@/resources/icons/toolbar/ic-settingmatrix.svg';
import icRefreshMatrix from '@/resources/icons/ic-update.svg';
import icPaste from '@/resources/icons/insert.svg';
import icCopy from '@/resources/icons/copy.svg';
import icPastRowBtm from '@/resources/icons/toolbar/pastRowBtm.svg';
import icPastRowTop from '@/resources/icons/toolbar/pastRowTop.svg';
import icPastColLeft from '@/resources/icons/toolbar/pastColLeft.svg';
import icPastColRight from '@/resources/icons/toolbar/pastColRight.svg';
import { EditorMode } from '@/models/editorMode';
import { TMatrixEditorToolbarProps } from './MatrixEditorToolbar.types';
import { useDispatch, useSelector } from 'react-redux';
import { matrixEditorModeChangedAction, refreshMatrix } from '@/modules/Matrix/actions/matrix.actions';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { isCompact } from '@/selectors/generalMenu.selectors';
import { MatrixEditorSelectors } from '@/modules/Matrix/selectors/matrixEditor.selectors';
import { matrixCopyLanes, matrixPastEmptyLane, matrixPastObjects } from '@/modules/Matrix/actions/matrixEditor.actions';
import { Button } from '@/modules/UIKit/components/Button/Button.component';

export const MatrixEditorToolbar: FC<TMatrixEditorToolbarProps> = (props) => {
    const { nodeId } = props;

    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const isActiveScheme = !!activeWorkspaceTab;
    const compact = useSelector(isCompact);
    const isCopyEnabled = useSelector(MatrixEditorSelectors.isCopyEnabled(nodeId));
    const isPastEnabled = useSelector(MatrixEditorSelectors.isPastEnabled(nodeId));
    const isRowPastEnabled = useSelector(MatrixEditorSelectors.isRowPastEnabled(nodeId));
    const isColPastEnabled = useSelector(MatrixEditorSelectors.isColPastEnabled(nodeId));

    const intl = useIntl();
    const dispatch = useDispatch();

    const onModeChange = (mode: EditorMode) => {
        dispatch(matrixEditorModeChangedAction(mode));
    };

    const openSettings = () => {
        dispatch(openDialog(DialogType.MATRIX_SETTINGS_DIALOG, props));
    };

    const refreshMatrixHandler = () => {
        dispatch(refreshMatrix(nodeId));
    };

    const copy = () => {
        dispatch(matrixCopyLanes(nodeId));
    };

    const past = () => {
        dispatch(matrixPastObjects(nodeId));
    };

    const pastEmptyLane = (pastOnTheOtherSide?: boolean) => {
        dispatch(matrixPastEmptyLane(nodeId, pastOnTheOtherSide));
    };

    const isReadMode = activeWorkspaceTab?.mode === EditorMode.Read;

    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');

    const handleModeChange = () => {
        onModeChange(isReadMode ? EditorMode.Edit : EditorMode.Read);
    };

    const showTooltip = isActiveScheme && !isReadMode;
    const disableCopyBtn = isReadMode || !isCopyEnabled;
    const disablePastBtn = isReadMode || !isPastEnabled;
    const disablePastRowBtn = isReadMode || !isRowPastEnabled;
    const disablePastColBtn = isReadMode || !isColPastEnabled;

    const pastRowBtmTooltip = showTooltip && isRowPastEnabled ? intl.formatMessage(messages.pastRowBtm) : '';
    const pastRowTopTooltip = showTooltip && isRowPastEnabled ? intl.formatMessage(messages.pastRowTop) : '';
    const pastColLeftTooltip = showTooltip && isColPastEnabled ? intl.formatMessage(messages.pastColLeft) : '';
    const pastColRightTooltip = showTooltip && isColPastEnabled ? intl.formatMessage(messages.pastColRight) : '';
    const copyBtnTooltip = showTooltip && isCopyEnabled ? intl.formatMessage(messages.copy) : '';
    const pastBtnTooltip = showTooltip && isPastEnabled ? intl.formatMessage(messages.paste) : '';
    const refreshMatrixTooltip = showTooltip ? intl.formatMessage(messages.refreshMatrix) : '';
    const openMatrixSettingsTooltip = showTooltip ? intl.formatMessage(messages.openSettings) : '';
    const editModeTooltip = isReadMode
        ? intl.formatMessage(commonToolbarMessages.toEditMode)
        : intl.formatMessage(commonToolbarMessages.toReadMode);

    return (
        <div className={className}>
            <div className={groupClassName}>
                <div className={!compact ? theme.modeGroup_full : theme.modeGroup_compact}>
                    <Button
                        onClick={handleModeChange}
                        visualStyle={{ type: 'text' }}
                        size="large"
                        tooltip={editModeTooltip}
                        icon={{ ...(isReadMode ? icReadMode : icEditMode), width: 35, height: 35 }}
                    />
                </div>
            </div>

            <div className={theme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={isReadMode}
                        onClick={openSettings}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={openMatrixSettingsTooltip}
                        icon={icSettingsMatrix}
                    />
                </span>
            </div>
            <div className={theme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={isReadMode}
                        onClick={refreshMatrixHandler}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={refreshMatrixTooltip}
                        icon={icRefreshMatrix}
                    />
                </span>
            </div>
            <div className={theme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={disablePastBtn}
                        onClick={past}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={pastBtnTooltip}
                        icon={icPaste}
                    />
                </span>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={disableCopyBtn}
                        onClick={copy}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={copyBtnTooltip}
                        icon={icCopy}
                    />
                </span>
            </div>
            <div className={theme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={disablePastRowBtn}
                        onClick={() => pastEmptyLane()}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={pastRowTopTooltip}
                        icon={icPastRowTop}
                    />
                </span>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={disablePastRowBtn}
                        onClick={() => pastEmptyLane(true)}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={pastRowBtmTooltip}
                        icon={icPastRowBtm}
                    />
                </span>
            </div>
            <div className={theme.divider} />
            <div className={menuItemTheme.group}>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={disablePastColBtn}
                        onClick={() => pastEmptyLane()}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={pastColLeftTooltip}
                        icon={icPastColLeft}
                    />
                </span>
                <span className={!compact ? theme.modeGroup_full : theme.gridButton_compact}>
                    <Button
                        disabled={disablePastColBtn}
                        onClick={() => pastEmptyLane(true)}
                        visualStyle={{ type: 'text' }}
                        size="small"
                        tooltip={pastColRightTooltip}
                        icon={icPastColRight}
                    />
                </span>
            </div>
        </div>
    );
};
