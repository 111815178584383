import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { MatrixDataBPM8764, MatrixLane, MatrixNode, NodeId } from '../../../serverapi/api';
import { TMatrixState } from '../reducers/matrix.reducer.types';
import { getRowIdAndColIdByCellId } from '../utils/Matrix.utils';
import { TObjectDefinitionsId } from '../MatrixEditor/Matrix.types';

const matrixStateSelector = (state: TRootState): TMatrixState => state.matrix;

export namespace MatrixSelectors {
    export const byId = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixState, MatrixNode | undefined>(matrixStateSelector, (state) =>
            state.get(nodeId),
        );
    export const byIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TMatrixState, MatrixNode[]>(matrixStateSelector, (state) => {
            const matrixArr: MatrixNode[] = nodeIds
                .map((nodeId) => {
                    const matrix: MatrixNode | undefined = state.get(nodeId);
                    return matrix;
                })
                .filter((element): element is MatrixNode => !!element);

            return matrixArr;
        });

    export const isMatrixAutofilled = (nodeId: NodeId) =>
        createSelector<TRootState, TMatrixState, boolean>(
            matrixStateSelector,
            (state) => !!state.get(nodeId)?.data2?.cellSettings.isAutomatic,
        );

    export const objectDefinitionsIdsByCellId = (nodeId: NodeId, cellId: string) =>
        createSelector<TRootState, TMatrixState, TObjectDefinitionsId>(matrixStateSelector, (state) => {
            const { colId, rowId } = getRowIdAndColIdByCellId(cellId);
            const matrixData: MatrixDataBPM8764 | undefined = state.get(nodeId)?.data2;
            let rowObjectDefinitionId = '';
            let colObjectDefinitionId = '';
            if (matrixData) {
                const currentRow: MatrixLane | undefined = matrixData.rows.find((row) => row.id === rowId);
                const currentCol: MatrixLane | undefined = matrixData.columns.find((col) => col.id === colId);

                rowObjectDefinitionId = currentRow?.linkedNodeId || '';
                colObjectDefinitionId = currentCol?.linkedNodeId || '';
            }

            return { rowObjectDefinitionId, colObjectDefinitionId };
        });
}
