import { defineMessages } from 'react-intl';

export default defineMessages({
    FOLDER: {
        id: 'FOLDER',
        defaultMessage: 'Папка',
    },
    MODEL: {
        id: 'MODEL',
        defaultMessage: 'Модель',
    },
    OBJECT: {
        id: 'OBJECT',
        defaultMessage: 'Объект',
    },
    DB: {
        id: 'DB',
        defaultMessage: 'База данных',
    },
    SCRIPT: {
        id: 'SCRIPT',
        defaultMessage: 'Скрипт',
    },
    SCRIPT_FOLDER: {
        id: 'SCRIPT_FOLDER',
        defaultMessage: 'Папка для скриптов',
    },
    WIKI: {
        id: 'WIKI',
        defaultMessage: 'Wiki-страница',
    },
    MATRIX: {
        id: 'MATRIX',
        defaultMessage: 'Матричная модель',
    },
    SPREADSHEET: {
        id: 'SPREADSHEET',
        defaultMessage: 'Таблица',
    },
    SCRIPT_RESULT: {
        id: 'SCRIPT_RESULT',
        defaultMessage: 'Результаты скриптов',
    },
    SIMULATION: {
        id: 'SIMULATION',
        defaultMessage: 'Имитационная модель',
    },
    FILE: {
        id: 'FILE',
        defaultMessage: 'Файл',
    },
    FILE_FOLDER: {
        id: 'FILE_FOLDER',
        defaultMessage: 'Папка для файлов',
    },
    EDGE: {
        id: 'EDGE',
        defaultMessage: 'Связь',
    },
    KANBAN: {
        id: 'KANBAN',
        defaultMessage: 'Канбан',
    },
    DASHBOARD: {
        id: 'DASHBOARD',
        defaultMessage: 'Дашборд',
    },
});
