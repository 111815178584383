import type { Editor } from '@tiptap/react';
import React, { MouseEvent, useContext } from 'react';
import icEraser from 'icons/toolbar/controls/ic-eraser.svg';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import { Button } from '../../../Toolbar/Button.component';

const getChangedBlock = (editor: Editor) =>
    editor.chain().clearNodes().unsetAllMarks().unsetIndent().unsetLineHeight().unsetTextAlign().run();

export const EraserComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<void, MouseEvent>({ stateObserver, getChangedBlock });

    return (
        <Button
            tooltipTitle={intl.formatMessage(messages.eraser)}
            icon={icEraser}
            onClick={setCurrentState}
            data-test="wiki-toolbar-group_eraser-button"
        />
    );
};
