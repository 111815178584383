import { NodeId, NodeTypeEnum } from '@/serverapi/api';
import { TRedoAction, TUndoAction, TAddStateAction, TClearStateAction } from './undeRedo.actions.types';
import {
    REDO_ACTION,
    UNDO_ACTION,
    ADD_UNDO_REDO_STATE,
    CLEAR_UNDO_REDO_STATE,
} from '@/actionsTypes/undoRedo.actionTypes';
import { TUndoRedoStateTypes } from '@/reducers/undoRedo.reducer.types';

export const undoAction = (nodeId: NodeId): TUndoAction => ({
    type: UNDO_ACTION,
    payload: {
        nodeId,
    },
});

export const redoAction = (nodeId: NodeId): TRedoAction => ({
    type: REDO_ACTION,
    payload: {
        nodeId,
    },
});

export const addStateAction = (
    nodeId: NodeId,
    state: TUndoRedoStateTypes,
    nodeType: NodeTypeEnum,
): TAddStateAction => ({
    type: ADD_UNDO_REDO_STATE,
    payload: {
        nodeId,
        state,
        nodeType,
    },
});

export const clearStateAction = (nodeId: NodeId): TClearStateAction => ({
    type: CLEAR_UNDO_REDO_STATE,
    payload: {
        nodeId,
    },
});
