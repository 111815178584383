import { connect } from 'react-redux';
import { Editor } from '../components/Editor/Editor.component';
import { TRootState } from '../../../reducers/root.reducer.types';
import {
    editorCreated,
    editorDestroy,
    editorDragNode,
    zoomInAction,
    zoomOutAction,
} from '../../../actions/editor.actions';
import { CanvasSettings } from '../../../selectors/canvas-settings.selectors';
import { NodeId, Symbol, PresetImage, UserProperty } from '../../../serverapi/api';
import { editorDragFile } from '../../../actions/navigatorSymbol.actions';
import { saveModel } from '../../../actions/save.actions';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { ServerSelectors } from '../../../selectors/entities/server.selectors';
import { workspaceTabSetAction, workspaceTabSetParams } from '../../../actions/tabs.actions';
import { IWorkspaceTabItemModelParams, TWorkspaceTab, TWorkspaceTabItemActions } from '../../../models/tab.types';
import { EditorMode } from '../../../models/editorMode';
import { getDraggedIdItem, TreeSelectors } from '../../../selectors/tree.selectors';
import { CommentsSelectors } from '../../../selectors/comments.selectors';
import { TreeNode } from '../../../models/tree.types';
import { relock, unlock } from '../../../actions/lock.actions';
import { getUserProperty } from '../../../selectors/authorization.selectors';
import { SAVE_TIMEOUT } from '../../../utils/consts';
import { objectDefinitionService } from '../../../services/ObjectDefinitionService';
import { TEditorContainerOwnProps, TEditorProps } from '../Editor.types';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { UserProfileSelectors } from '../../../selectors/userProfile.selectors';
import { IModelNode } from '../../../models/bpm/bpm-model-impl.types';
import { PresetImageSelectors } from '../../../selectors/presetSettings/presetImage.selectors';
import { PrincipalsSelectors } from '../../../selectors/principals.selectors';
import { DialogsSelectors } from '../../../selectors/dialogs.selectors';
import { updateAllCellsOverlays } from '../../../actions/overlay.actions';
import { getSymbolsFromModelType } from '../../../utils/symbol.utils';
import { SymbolSelectors } from '../../../selectors/symbol.selectors';
import { addAutoSaveModelIntervalId, deleteAutoSaveModelIntervalId } from '@/actions/autoSaveModelIntervalIds.actions';

const getSaveTimeout = (property: UserProperty): number => {
    const propSaveTimeOut: number | undefined = property && property.autoSaveTimeout;

    if (propSaveTimeOut === undefined) return SAVE_TIMEOUT;

    return propSaveTimeOut <= 0 ? 0 : propSaveTimeOut;
};

const mapStateToProps = (state: TRootState, ownProps: TEditorContainerOwnProps): Partial<TEditorProps> => {
    const content = ownProps.tab.content as IModelNode;
    const tabParams = ownProps.tab.params as IWorkspaceTabItemModelParams;
    const objectDefinitions = objectDefinitionService().findAllObjectsInGraph(content.nodeId);
    const commentsEnabledSchemesIds: NodeId[] = CommentsSelectors.commentsEnabledSchemesIds(state);
    const dragId = getDraggedIdItem(state);
    const draggedNode: TreeNode | undefined = dragId && TreeSelectors.itemById(dragId)(state);
    const currentLocale = getCurrentLocale(state);
    const activeScheme: TWorkspaceTab | undefined = TabsSelectors.byId(content.nodeId)(state);
    const params: IWorkspaceTabItemModelParams | undefined = activeScheme?.params as IWorkspaceTabItemModelParams;
    const property: UserProperty | undefined = getUserProperty(state);
    const saveTimeOut: number | undefined = property && getSaveTimeout(property);
    const isAnyObjectAllowed = tabParams?.modelType?.allowAnyObject;
    const allSymbols =
        SymbolSelectors.byServerIdPresetId(tabParams.serverId, params?.modelType?.presetId || '')(state) || [];
    const availableSymbols = tabParams.symbols || getSymbolsFromModelType(tabParams.modelType);
    const symbols = isAnyObjectAllowed ? allSymbols : availableSymbols;
    const creatableSymbols = availableSymbols.filter((symbol) =>
        UserProfileSelectors.isSymbolCreatable(draggedNode?.nodeId, symbol.id)(state),
    );
    const presetImages: PresetImage[] = PresetImageSelectors.listAllByPreset(
        tabParams.serverId,
        tabParams.modelType?.presetId || '',
    )(state);

    // иногда при закрытии модели activeScheme пустой (это как то связано с автосохранением),
    // чтобы не падало прописываем состояние по умолчанию
    // но если при открытии модели придет пустой activeScheme, то все упадет из за пустого params
    const commonProps = {
        model: content,
        id: content.nodeId.id,
        modelType: params.modelType,
        isGridEnabled: CanvasSettings.isGridEnabled(state),
        gridType: CanvasSettings.gridType(state),
        dotSpace: CanvasSettings.dotSpace(state),
        // elements: ownProps.elements,
        actions: activeScheme?.actions || ({} as TWorkspaceTabItemActions),
        graph: tabParams.graph,
        serverUrl: ServerSelectors.server(tabParams.serverId)(state).url,
        symbols,
        creatableSymbols,
        activeKey: TabsSelectors.getActiveTabId(state),
        objectDefinitions,
        filters: params.filters,
        params,
        mode: activeScheme?.mode || EditorMode.Read,
        commentsEnabledSchemesIds,
        saveTimeOut,
        userProfile: UserProfileSelectors.selectUserProfileByNodeId(content.nodeId)(state),
        principals: PrincipalsSelectors.getAll(state),
        hasVisibleDialogs: DialogsSelectors.isVisibleDialog(state),
    };

    if (!activeScheme) {
        console.error('activeScheme is empty');

        return {
            ...commonProps,
            objectDefinitions: [],
            mode: EditorMode.Read,
            saveTimeOut: 10000,
            commentsEnabledSchemesIds: [],
        };
    }

    return {
        ...commonProps,
        mode: activeScheme.mode || EditorMode.Read,
        draggedNode,
        currentLocale,
        filters: params.filters,
        params,
        presetImages,
    };
};

const mapDispatchToProps: (dispatch, ownProps: TEditorContainerOwnProps) => Partial<TEditorProps> = (
    dispatch,
    ownProps,
) => {
    const content = ownProps.tab.content as IModelNode;

    return {
        onInit: (nodeId: NodeId) => dispatch(editorCreated({ nodeId })),
        onDestroy: (nodeId: NodeId) => dispatch(editorDestroy({ nodeId })),
        onFileDrop: (draggedFile: File) => dispatch(editorDragFile(draggedFile)),
        onLock: () => dispatch(relock(content.nodeId, 'MODEL')),
        onUnlock: () => dispatch(unlock(content.nodeId, 'MODEL')),
        onChangeModel: () => dispatch(saveModel(content.nodeId)),
        onParamsChange: (name: string, value: {}) => dispatch(workspaceTabSetParams(content.nodeId, { [name]: value })),
        onActionChange: (name: string, value: boolean) => {
            dispatch(workspaceTabSetAction({ nodeId: content.nodeId, name, value }));
        },
        onDragNode: (draggedNode: TreeNode, creatableSymbols: Symbol[]) => {
            dispatch(editorDragNode({ draggedNode, creatableSymbols }));
        },
        onZoomIn: () => dispatch(zoomInAction()),
        onZoomOut: () => dispatch(zoomOutAction()),
        updateAllCellsOverlays: (graphId: NodeId) => dispatch(updateAllCellsOverlays(graphId)),
        onAddAutoSaveModelIntervalId: (id: number) => dispatch(addAutoSaveModelIntervalId(id)),
        onDeleteAutoSaveModelIntervalId: (id: number) => dispatch(deleteAutoSaveModelIntervalId(id)),
    };
};

export const EditorContainer = connect(mapStateToProps, mapDispatchToProps)(Editor);
