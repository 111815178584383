import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { scriptExecuteDialogUploadFile } from '../../../actions/scriptExecuteDialog.actions';
import { TFileUploadDialogProps } from '../types/FileUploadDialog.types';

type TFileUploadScriptParamDialpgOwnProps = {
    serverId: string;
    executionId: string;
    paramName: string;
};

const mapDispatchToProps = (
    dispatch,
    ownProps: TFileUploadScriptParamDialpgOwnProps,
): Partial<TFileUploadDialogProps> => ({
    onClose: () => dispatch(closeDialog(DialogType.SCRIPT_UPLOAD_PARAM_FILE_DIALOG)),
    onSubmit: (file: File, serverId: string) =>
        dispatch(scriptExecuteDialogUploadFile(file, serverId, ownProps.paramName)),
});

export const FileUploadScriptParamDialogContainer = connect(null, mapDispatchToProps)(FileUploadDialog);
