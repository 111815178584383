// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScriptDialog__radio__BMAiW{display:inline-block}.ScriptDialog__radio__BMAiW label{display:block;line-height:30px}", "",{"version":3,"sources":["webpack://./src/modules/Script/ScriptCreateDialog/ScriptDialog.scss"],"names":[],"mappings":"AAAA,4BACI,oBAAA,CACA,kCACI,aAAA,CACA,gBAAA","sourcesContent":[".radio {\r\n    display: inline-block;\r\n    label {\r\n        display: block;\r\n        line-height: 30px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio": "ScriptDialog__radio__BMAiW"
};
export default ___CSS_LOADER_EXPORT___;
