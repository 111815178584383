import React from 'react';
import theme from './Wrapper.scss';
import { convertPaddingToValue } from './utils/styleProperties.utils';
import { TWrapper } from './Wrapper.types';

export const Wrapper = (props: TWrapper) => {
    const { children, padding } = props;
    return (
        <div className={theme.container} style={{ padding: convertPaddingToValue(padding) }}>
            {children}
        </div>
    );
};
