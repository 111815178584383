import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    IDeleteAuditRange,
    IDeleteSessionRange,
    IFetchDetailedAudit,
    IFetchAudit,
    TDownloadCSVAction,
} from '../actions/audit.actions.types';
import { fetchAuditSuccess, fetchDetailedAuditSuccess, finishAuditFecth, finishDownloadCSV } from '../actions/audit.actions';
import {
    DELETE_AUDIT_RANGE,
    DELETE_SESSION_RANGE,
    DOWNLOAD_CSV,
    FETCH_AUDIT,
    FETCH_DETAILED_AUDIT,
} from '../actionsTypes/audit.actionTypes';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { AuditDto, DetailedAuditDTO } from '../serverapi/api';
import { AuditDAOService } from '@/services/dao/AuditDAOService';
import { showNotification } from '@/actions/notification.actions';
import { v4 as uuid } from 'uuid';
import { NotificationType } from '@/models/notificationType';

function* handleAuditFecth({ params, serverId }: IFetchAudit) {
    try {
        const audit: AuditDto[] = yield call(() => AuditDAOService.loadAuditEvents(params));
        yield put(fetchAuditSuccess(serverId, audit));
    } catch(er) {
        yield put(finishAuditFecth());
        throw er;
    }
}

function* deleteAuditRange({ payload: { start, end, serverId, password } }: IDeleteAuditRange) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const resp = yield call(() => server.api.audit.deleteAudit({
        body: {
            startTime: start,
            endTime: end,
            userPassword: password
        }
    }));
    if(resp === 'UNAUTHORIZED'){
        yield put(showNotification({
                        id: uuid(),
                        type: NotificationType.UNAUTHORIZED_ERROR
                    })
                );
    }
}

function* deleteSessionRange({ payload: { start, end, serverId, password } }: IDeleteSessionRange) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const resp = yield call(() => server.api.session.deleteSession({
        body: {
            startTime: start,
            endTime: end,
            userPassword: password
        }
    }));
    if(resp === 'UNAUTHORIZED'){
        yield put(showNotification({
                        id: uuid(),
                        type: NotificationType.UNAUTHORIZED_ERROR
                    })
                );
    }
}

function* handleDetailedAuditFetch({ payload: { id, serverId } }: IFetchDetailedAudit) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const detailedAudit: DetailedAuditDTO = yield call(() => server.api.audit.detail({ id }));
    yield put(fetchDetailedAuditSuccess({ serverId, detailedAudit }));
}

function* handleDownloadCSV({ payload: { params } }: TDownloadCSVAction) {
    try {
        yield AuditDAOService.loadAuditEventsCsvFile(params);
    } finally {
        yield put(finishDownloadCSV());
    }
}

export function* auditSaga() {
    yield takeEvery(FETCH_AUDIT, handleAuditFecth);
    yield takeEvery(DELETE_AUDIT_RANGE, deleteAuditRange);
    yield takeEvery(DELETE_SESSION_RANGE, deleteSessionRange);
    yield takeEvery(FETCH_DETAILED_AUDIT, handleDetailedAuditFetch);
    yield takeEvery(DOWNLOAD_CSV, handleDownloadCSV);
}
