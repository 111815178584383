import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { setSearchPathElement } from '../../../actions/search.actions';
import { TreeSelectors } from '../../../selectors/tree.selectors';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import style from './SearchPathDialog.scss';
import { TreeNode } from '../../../models/tree.types';
import { injectIntl, IntlShape } from 'react-intl';
import messages from './SearchPathDialog.messages';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemsClearSelection } from '@/actions/tree.actions';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TSearchPathDialogProps = {
    intl: IntlShape;
};

const SearchPathDialog = ({ intl }: TSearchPathDialogProps) => {
    const dispatch = useDispatch();

    const nodes = useSelector(TreeSelectors.treeStructureConnected);
    const selectedNode: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(DialogType.SEARCH_PATH));

    const isDisable =
        selectedNode &&
        selectedNode.nodeId.id === selectedNode.nodeId.repositoryId &&
        selectedNode.nodeId.id === selectedNode.nodeId.serverId;

    const handleClose = () => {
        dispatch(closeDialog(DialogType.SEARCH_PATH));
        dispatch(treeItemsClearSelection());
    };

    const handleSelectPath = () => {
        if (selectedNode) {
            const { nodeId, type, name } = selectedNode;

            dispatch(setSearchPathElement(nodeId, type, name));
        }

        handleClose();
    };

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleClose,
                    value: intl.formatMessage(messages.cancelButtonText),
                },
                {
                    key: 'ok',
                    onClick: handleSelectPath,
                    value: intl.formatMessage(messages.selectButtonText),
                    visualStyle: 'primary',
                    disabled: isDisable,
                },
            ]}
        />
    );

    return (
        <Dialog
            width={DEFAULT_DIALOG_WIDTH}
            onOk={handleSelectPath}
            onCancel={handleClose}
            open
            title={intl.formatMessage(messages.header)}
            footer={footer}
        >
            <div className={style.treeContainer}>
                <Tree treeName={DialogType.SEARCH_PATH} data={nodes} />
            </div>
        </Dialog>
    );
};

const IntlComponent = injectIntl(SearchPathDialog);

export { IntlComponent as SearchPathDialog };
