// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultiLangEditableText-component__editableElementLarge__fCGIc{-webkit-box-flex:22;-ms-flex-positive:22;flex-grow:22;width:100%;min-width:60%}.MultiLangEditableText-component__editableElementButtons__hgx08{width:80px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.MultiLangEditableText-component__editableElementButtons__hgx08 button{margin:0}.MultiLangEditableText-component__editableElementButtons__hgx08 button+div{padding-left:4px;margin-right:4px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/MultiLangEditableText/MultiLangEditableText.component.scss"],"names":[],"mappings":"AAAA,8DACI,mBAAA,CAAA,oBAAA,CAAA,YAAA,CACA,UAAA,CACA,aAAA,CAEJ,gEACI,UAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,6BAAA,CAAA,4BAAA,CAAA,sBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAEA,uEACI,QAAA,CAGJ,2EACI,gBAAA,CACA,gBAAA","sourcesContent":[".editableElementLarge {\r\n    flex-grow: 22;\r\n    width: 100%;\r\n    min-width: 60%;\r\n}\r\n.editableElementButtons{\r\n    width: 80px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: flex-end;\r\n\r\n    button {\r\n        margin: 0;\r\n    }\r\n\r\n    button + div {\r\n        padding-left: 4px;\r\n        margin-right: 4px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableElementLarge": "MultiLangEditableText-component__editableElementLarge__fCGIc",
	"editableElementButtons": "MultiLangEditableText-component__editableElementButtons__hgx08"
};
export default ___CSS_LOADER_EXPORT___;
