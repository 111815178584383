import {
    TREE_ITEMS_EXPAND_WITHOUT_LOAD,
    TREE_ITEM_COLLAPSE_ALL,
    TREE_ITEM_COLLAPSE_SUCCESS,
    TREE_ITEM_DELETE,
    TREE_ITEM_EXPAND_SUCCESS,
    TREE_ITEM_UPDATE,
} from '@/actionsTypes/tree.actionTypes';
import type { TReducer } from '../utils/types';
import { TExpandedNodesState } from './expandedNodes.reducer.types';
import { ExpandStatus } from './tree.reducer.types';

const initial: TExpandedNodesState = {};

export const expandedNodesReducer: TReducer<TExpandedNodesState> = (state = initial, action) => {
    switch (action.type) {
        case TREE_ITEM_COLLAPSE_ALL: {
            const {
                payload: { treeName },
            } = action;

            return {
                ...state,
                [treeName]: {},
            };
        }

        case TREE_ITEM_COLLAPSE_SUCCESS: {
            const {
                payload: { nodesId, treeName },
            } = action;

            return nodesId.reduce((newState, nodeId) => {
                const { id, serverId, repositoryId } = nodeId;

                return {
                    ...newState,
                    [treeName]: {
                        ...newState[treeName],
                        [serverId]: {
                            ...newState?.[treeName]?.[serverId],
                            [repositoryId]: {
                                ...newState?.[treeName]?.[serverId]?.[repositoryId],
                                [id]: { expand: ExpandStatus.CLOSED },
                            },
                        },
                    },
                };
            }, state);
        }
        case TREE_ITEMS_EXPAND_WITHOUT_LOAD: {
            const {
                payload: { nodesId, treeName },
            } = action;

            return nodesId.reduce((newState, nodeId) => {
                const { id, serverId, repositoryId } = nodeId;
                // copy of TREE_ITEM_EXPAND

                return {
                    ...newState,
                    [treeName]: {
                        ...(newState[treeName] || {}),
                        [serverId]: {
                            ...(newState?.[treeName]?.[serverId] || {}),
                            [repositoryId]: {
                                ...(newState?.[treeName]?.[serverId]?.[repositoryId] || {}),
                                [id]: { expand: ExpandStatus.OPEN },
                            },
                        },
                    },
                };
            }, state);
        }
        case TREE_ITEM_EXPAND_SUCCESS: {
            const {
                payload: {
                    nodeId: { id, serverId, repositoryId },
                    treeName,
                },
            } = action;

            return {
                ...state,
                [treeName]: {
                    ...(state[treeName] || {}),
                    [serverId]: {
                        ...(state?.[treeName]?.[serverId] || {}),
                        [repositoryId]: {
                            ...(state?.[treeName]?.[serverId]?.[repositoryId] || {}),
                            [id]: { expand: ExpandStatus.OPEN },
                        },
                    },
                },
            };
        }

        case TREE_ITEM_UPDATE: {
            if (!action.payload.nodeId) {
                return state;
            }

            const {
                payload: {
                    nodeId: { id, repositoryId, serverId },
                    data,
                },
            } = action;

            // ниже блок кода удаляет признак открытия элемента (галка),
            // если у него не осталось вложенных элементов, иначе при появлении
            // новых вложенных элементов галка становится в открытое положение при закрытом элементе
            const arrayOfTreeNames = Object.keys(state);

            if (data.hasChildren === false && arrayOfTreeNames.length > 0) {
                return arrayOfTreeNames.reduce((prevState, treeName) => {
                    return {
                        ...prevState,
                        [treeName]: {
                            ...(prevState[treeName] || {}),
                            [serverId]: {
                                ...(prevState?.[treeName]?.[serverId] || {}),
                                [repositoryId]: {
                                    ...(prevState?.[treeName]?.[serverId]?.[repositoryId] || {}),
                                    [id]: { expand: ExpandStatus.CLOSED },
                                },
                            },
                        },
                    };
                }, state);
            }

            return state;
        }

        case TREE_ITEM_DELETE: {
            const {
                payload: {
                    nodeId: { serverId, repositoryId, id },
                },
            } = action;

            // ниже блок кода удаляет признак открытия элемента (галка),
            // если у него не осталось вложенных элементов, иначе при появлении
            // новых вложенных элементов галка становится в открытое положение при закрытом элементе
            const arrayOfTreeNames = Object.keys(state);

            return arrayOfTreeNames.reduce((prevState, treeName) => {
                const copyState = {
                    ...prevState,
                    [treeName]: {
                        ...(prevState[treeName] || {}),
                        [serverId]: {
                            ...(prevState[treeName]?.[serverId] || {}),
                            [repositoryId]: {
                                ...(prevState[treeName]?.[serverId]?.[repositoryId] || {}),
                            },
                        },
                    },
                };

                delete copyState[treeName]?.[serverId]?.[repositoryId]?.[id];

                return copyState;
            }, state);
        }

        default: {
            return state;
        }
    }
};
