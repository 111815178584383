import { put, select, takeEvery } from 'redux-saga/effects';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../../actionsTypes/tabs.actionTypes';
import { workspaceRemoveTab } from '../../actions/tabs.actions';
import { TWorkspaceTabsRemoveRequestAction } from '../../actions/tabs.actions.types';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { SAVE_MODEL } from '../../actionsTypes/save.actionTypes';
import { TSaveModelAction } from '../../actions/save.actions.types';
import { NodeId } from '../../serverapi/api';
import { IModelNode } from '../../models/bpm/bpm-model-impl.types';
import { navigatorClearProperties } from '../../actions/navigatorProperties.actions';
import { getLockingTool, SaveModelLockTool } from '../../modules/Editor/classes/SaveModelLockTool';
import { modelRequestSuccess } from '../../actions/model.actions';
import { EditorMode } from '../../models/editorMode';
import { unlock } from '../../actions/lock.actions';
import { clearStateComments, closeCommentsPanel, deleteEditingComment } from '../../actions/comments.actions';
import { TreeItemType } from '../../modules/Tree/models/tree';
import { ModelSelectors } from '../../selectors/model.selectors';
import { getCurrentLocale } from '../../selectors/locale.selectors';
import { modelService } from '../../services/ModelService';
import { TWorkspaceTab } from '@/models/tab.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { BPMMxGraph } from '@/mxgraph/bpmgraph';
import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { DefaultGraph } from '@/mxgraph/DefaultGraph';

function* handleTabCloseRequest(action: TWorkspaceTabsRemoveRequestAction) {
    const tabNodeId = action.payload.workspaceTab.nodeId;
    const workspaceTab: TWorkspaceTab = yield select(TabsSelectors.byId(tabNodeId));

    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(tabNodeId); // todo keep by nodeId

    // Завершаем редактирование имени у текстового блока и символа на графе
    if (graph) {
        graph.stopEditing(false);
    }

    if (workspaceTab?.content?.type === TreeItemType.Model) {
        const lock: SaveModelLockTool = getLockingTool();

        if (!lock.isLocked(tabNodeId.id)) {
            yield prepareAndSendModel(tabNodeId, true);
        }

        yield put(navigatorClearProperties());

        yield put(deleteEditingComment(tabNodeId));
        yield put(closeCommentsPanel({ modelId: tabNodeId }));
        yield put(clearStateComments(tabNodeId));

        if (workspaceTab.mode === EditorMode.Edit) {
            yield put(unlock(tabNodeId, 'MODEL'));
        }

        yield put(workspaceRemoveTab(workspaceTab));
    }
}

function* prepareAndSendModel(graphId: NodeId, forceSave: boolean = false) {
    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(graphId));
    const locale: Locale = yield select(getCurrentLocale);
    const model: IModelNode = yield select(ModelSelectors.byId(graphId));
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(graphId);
    const isDefaultGraph = graph instanceof DefaultGraph;

    if (!graph) {
        return;
    }

    const savedModel = yield modelService().prepareAndSaveModel(graph, isDefaultGraph, model, tab, locale, forceSave);

    if (savedModel) {
        yield put(modelRequestSuccess(savedModel.nodeId.serverId, savedModel));
    }
}

function* handleSaveModelRequest({ payload: { graphId, forceSave } }: TSaveModelAction) {
    yield prepareAndSendModel(graphId, forceSave);
}

export function* saveModelSagaInit() {
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabCloseRequest);
    yield takeEvery(SAVE_MODEL, handleSaveModelRequest);
}
