import React from 'react';
import { useIntl } from 'react-intl';
import style from './ActionsAudit.scss';
import { setAuditFilteredUsers } from '../../../actions/audit.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAuditSelectors } from '../../../selectors/actionAudit.selector';
import messages from './audit.messages';
import { Select } from '../../UIKit/components/Select/Select.component';
import { UserDTO } from '@/serverapi/api';
import { UsersSelectors } from '@/selectors/users.selectors';
import { MetaDataSelectors } from '@/selectors/admintools.selectors';

export const UserDataFilter = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const serverId = useSelector(MetaDataSelectors.getCurrentServerId);
    const userList: UserDTO[] | undefined = useSelector(UsersSelectors.getAll(serverId));

    const filteredUsers: number[] = useSelector(ActionAuditSelectors.filteredUsers);

    const onChange = (users: string[]) => dispatch(setAuditFilteredUsers(users.map(user => Number(user))));

    return (
        <div className={style.dropdownUserFilterContainer} data-test="security_audit_user-filtration">
            <Select
                value={intl.formatMessage(messages.byUser)}
                showSearch
                isMultiSelect
                onChange={onChange}
                dropdownClassName={style.ulContainerUser}
                dropdownWidth={400}
                allowSelectAll
            >
                {(userList || []).filter(user => user.firstName || user.middleName || user.lastName).map((user: UserDTO) => {
                    const lastName: string = user.lastName ? `${user.lastName} ` : '';
                    const firstName: string = user.firstName ? `${user.firstName} ` : '';
                    const middleName: string = user.middleName ? `${user.middleName}` : '';
                    const separator: string = user.lastName || user.firstName || user.middleName ? ', ' : '';
                    const label: string = `${user.login}${separator}${lastName}${firstName}${middleName}`;

                    return (
                        <Select.Option
                            key={String(user.id)}
                            checked={filteredUsers?.includes(user.id)}
                            value={String(user.id)}
                            label={label}
                            onChange={onChange}
                        />
                    )})}
            </Select>
        </div>
    );
};
