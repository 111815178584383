import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../messages/CommonToolbar.messages';
import classnames from 'classnames';
import { Button, Tooltip } from 'antd';
import menuItemTheme from '../items/MenuItem.scss';
import { NodeId } from '../../../../serverapi/api';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
import { EditorMode } from '../../../../models/editorMode';
import icReadMode from '../../../../resources/icons/ic-read-mode.svg';
import icEditMode from '../../../../resources/icons/ic-edit-mode.svg';
import theme from './SpreadsheetEditorToolbar.scss';
import { TabsSelectors } from '../../../../selectors/tabs.selectors';
import { spreadsheetEditorModeChanged } from '../../../../actions/entities/spreadsheet.actions';

type TSpreadsheetEditorToolbarProps = {
    compact?: boolean | undefined;
    id: NodeId;
};

export const SpreadsheetEditorToolbar: FC<TSpreadsheetEditorToolbarProps> = (props) => {
    const activeWorkspaceTab = useSelector(TabsSelectors.getActiveTab);
    const isActiveScheme: boolean | undefined = !!useSelector(TabsSelectors.getActiveTabId);
    const dispatch = useDispatch();
    const intl = useIntl();
    const editorMode = activeWorkspaceTab ? activeWorkspaceTab.mode : undefined;
    // const { activeWorkspaceTab, isActiveScheme, compact, editorMode, onModeChange } = props;
    const className = classnames(menuItemTheme.container, {
        [menuItemTheme.container_compact]: props.compact,
        [menuItemTheme.toolbar_disabled]:
            activeWorkspaceTab === undefined ||
            (activeWorkspaceTab && activeWorkspaceTab.type === 'HomePageTab') ||
            activeWorkspaceTab.type === 'ContentLoadingPageTab',
    });
    const groupClassName = classnames(menuItemTheme.group, 'group');
    const isReadMode = activeWorkspaceTab && activeWorkspaceTab.mode === EditorMode.Read;
    const editModeTooltipeTitle = isReadMode
        ? intl.formatMessage(messages.toEditMode)
        : intl.formatMessage(messages.toReadMode);

    const handleModeChange = () => {
        dispatch(spreadsheetEditorModeChanged(editorMode === EditorMode.Edit ? EditorMode.Read : EditorMode.Edit));
    };

    return (
        <div className={className}>
            <div className={groupClassName}>
                <div className={!props.compact ? theme.modeGroup_full : undefined}>
                    <Tooltip
                        mouseLeaveDelay={0}
                        placement="topLeft"
                        title={isActiveScheme ? editModeTooltipeTitle : ''}
                    >
                        <Button className={theme.modeButton} onClick={handleModeChange}>
                            <Icon spriteSymbol={editorMode === EditorMode.Edit ? icEditMode : icReadMode} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
