import type { TAttributeDiscriminator, TObjectStyle } from './FloatingAttributes.types';
import type {
    TFloatingAttributesPanelState,
    TReplacementTextStatusMap,
    TRulesHasNotValueStatusMap,
    TStyles,
    TStylesAttribute,
} from './reducers/FloatingAttributes.reducer.types';
import type { StyledAttributeType } from '../../models/bpm/bpm-model-impl';
import type {
    AttributeType,
    AttributeTypeStyle,
    AttributeTypeStyleRule,
    EdgeTypeAttributeTypeStyleDTO,
    SymbolAttributeTypeStyleDTO,
} from '../../serverapi/api';
import { modelSystemAttributeTypes, StyleFormat, systemAttributeTypes } from './FloatingAttributes.constants';
import { groupBy, uniqBy, memoize } from 'lodash-es';
import { RuleType } from './components/AttributesEditor/Attribute.types';
import { compareDiscriminators } from '../../mxgraph/overlays/BPMMxCellOverlay.utils';
import { LocalesService } from '../../services/LocalesService';

export const validateStyles = (
    styles: TStyles,
    replacementTextStatusMap: TReplacementTextStatusMap,
    rulesHasNotValueStatusMap: TRulesHasNotValueStatusMap,
) =>
    !Object.keys(styles).some((key) => {
        const style = styles[key];

        if (style.format === StyleFormat.TEXT) {
            return (
                (replacementTextStatusMap[key] || rulesHasNotValueStatusMap[key]) &&
                !(style.replacementText?.en || style.replacementText?.ru)
            );
        }
        if (style.format === StyleFormat.IMAGE) {
            return !style.imageId;
        }

        return false;
    });

export const getUpdatedAttributeTypes = (
    styledAttributeTypes: StyledAttributeType[],
    useUniqueStyledAttributeTypes: boolean,
    styles: TStyles,
    stylesAttribute: TStylesAttribute,
    replacementTextStatusMap: TReplacementTextStatusMap,
    rulesHasNotValueStatusMap: TRulesHasNotValueStatusMap,
    addOldStyles?: boolean,
): StyledAttributeType[] => {
    const changedStyles = Object.keys(styles).map((styleId) => {
        const { imageId, replacementText, ...style } = styles[styleId];

        style.id = styleId;

        if (
            style.format === StyleFormat.TEXT &&
            (replacementTextStatusMap[style.id as string] || rulesHasNotValueStatusMap[style.id as string]) &&
            replacementText
        ) {
            (style as AttributeTypeStyle).replacementText = replacementText;
        }

        if (style.format === StyleFormat.IMAGE && imageId) {
            (style as AttributeTypeStyle).imageId = imageId;
        }

        return style;
    });
    const groupedChangedStyles = groupBy(
        changedStyles,
        (el) => `${stylesAttribute[el.id as string]?.id}_${stylesAttribute[el.id as string]?.attributeDiscriminator}`,
    );
    const changedAttributeTypes: StyledAttributeType[] = styledAttributeTypes
        .map((el) =>
            useUniqueStyledAttributeTypes && el.styles && el.styles?.length > 1
                ? { ...el, styles: [el.styles[0]] }
                : el,
        )
        .map((el) => ({
            ...el,
            styles: uniqBy(
                [
                    ...(addOldStyles ? el?.styles || [] : []),
                    ...(groupedChangedStyles[`${el.id}_${el.attributeDiscriminator}`] || []),
                ],
                'id',
            ),
        }));

    return changedAttributeTypes;
};

export const findHasNotValueRule = (rules?: AttributeTypeStyleRule[]) =>
    !!rules?.some((rule) => rule.type === RuleType.HAS_NOT_VALUE);

export const getAvailableAttributeTypes = (state: TFloatingAttributesPanelState) =>
    state.useUniqueStyledAttributeTypes
        ? state.availableAttributeTypes.filter(
              ({ id, attributeDiscriminator }) =>
                  !Object.keys(state.styles).find(
                      (styleId) =>
                          state.stylesAttribute[styleId]?.id === id &&
                          compareDiscriminators(
                              state.stylesAttribute[styleId]?.attributeDiscriminator,
                              attributeDiscriminator,
                          ),
                  ),
          )
        : state.availableAttributeTypes;

export const getSystemStyledAttributeTypes = (
    attributeDiscriminator: TAttributeDiscriminator,
    attributeTypeStyles?: TObjectStyle[],
) =>
    (attributeDiscriminator === 'MODEL_SYSTEM' ? modelSystemAttributeTypes : systemAttributeTypes).map(
        ({ id, name, valueType }) => {
            const styles =
                attributeTypeStyles?.find(
                    ({ typeId, attributeDiscriminator: discriminator }) =>
                        typeId === id && compareDiscriminators(discriminator, attributeDiscriminator),
                )?.styles || [];

            return {
                id,
                name,
                valueType,
                styles,
                attributeDiscriminator,
            } as StyledAttributeType;
        },
    );

export const getStyledAttributeTypes = memoize(
    (
        presetStyles: EdgeTypeAttributeTypeStyleDTO[] | SymbolAttributeTypeStyleDTO[],
        definitionAttributes?: AttributeType[],
        instanceAttributes?: AttributeType[],
        modelAttributes?: AttributeType[],
    ) => {
        const objectStyles: TObjectStyle[] = presetStyles.map(
            (style) =>
                ({
                    styles: style.styles ?? [style.attributeStyle],
                    typeId: style.attributeTypeId,
                    attributeDiscriminator: style.attributeDiscriminator,
                } as TObjectStyle),
        );
        const systemAttributeTypes = getSystemStyledAttributeTypes('SYSTEM', objectStyles);
        const modelSystemAttributeTypes = getSystemStyledAttributeTypes('MODEL_SYSTEM', objectStyles);
        const styledDefinitionAttributes = getEntityStyledAttributesTypes(
            'DEFINITION',
            definitionAttributes || [],
            objectStyles,
        );
        const styledInstanceAttributes = getEntityStyledAttributesTypes(
            'INSTANCE',
            instanceAttributes || [],
            objectStyles,
        );
        const modelAttributeTypes = getModelStyledAttributesTypes(modelAttributes || [], objectStyles);

        return [
            ...styledDefinitionAttributes,
            ...styledInstanceAttributes,
            ...systemAttributeTypes,
            ...modelSystemAttributeTypes,
            ...modelAttributeTypes,
        ];
    },
);
export const isDefinitionDiscriminator = (
    attributeDiscriminator: TAttributeDiscriminator | undefined | null,
): boolean => {
    return attributeDiscriminator === 'DEFINITION' || (attributeDiscriminator || 'AUTO') === 'AUTO';
};

export const isInstanceDiscriminator = (
    attributeDiscriminator: TAttributeDiscriminator | undefined | null,
): boolean => {
    return attributeDiscriminator === 'INSTANCE' || (attributeDiscriminator || 'AUTO') === 'AUTO';
};

export const getEntityStyledAttributesTypes = (
    entity: 'DEFINITION' | 'INSTANCE',
    attributes: AttributeType[],
    objectStyles: TObjectStyle[],
): StyledAttributeType[] => {
    const isDefinition: boolean = entity === 'DEFINITION';

    return attributes.map(
        ({ id, name, multilingualName, valueType }) =>
            ({
                id,
                name: LocalesService.internationalStringToString(multilingualName) || name,
                styles:
                    objectStyles?.find(
                        ({ typeId, attributeDiscriminator }) =>
                            typeId === id &&
                            (isDefinition
                                ? isDefinitionDiscriminator(attributeDiscriminator)
                                : isInstanceDiscriminator(attributeDiscriminator)),
                    )?.styles || [],
                valueType,
                attributeDiscriminator: entity,
            } as StyledAttributeType),
    );
};

export const getModelStyledAttributesTypes = (
    attributes: AttributeType[],
    objectStyles: TObjectStyle[],
): StyledAttributeType[] => {
    return attributes.map(
        ({ id, name, multilingualName, valueType }) =>
            ({
                id,
                name: LocalesService.internationalStringToString(multilingualName) || name,
                styles:
                    objectStyles?.find(
                        ({ typeId, attributeDiscriminator }) => typeId === id && attributeDiscriminator === 'MODEL',
                    )?.styles || [],
                valueType,
                attributeDiscriminator: 'MODEL',
            } as StyledAttributeType),
    );
};

export const compareAttributesByIdAndDiscriminators = (
    styledAttributeTypeId: string,
    presetStyleAttributeTypeId: string,
    styledAttributeTypes: StyledAttributeType[],
    attributeDiscriminator: TAttributeDiscriminator | undefined | null,
    presetDiscriminator: TAttributeDiscriminator | undefined | null,
): boolean => {
    if (styledAttributeTypeId !== presetStyleAttributeTypeId) return false;

    if (
        attributeDiscriminator === 'DEFINITION' &&
        isDefinitionDiscriminator(presetDiscriminator) &&
        // если в styledAttributeTypes есть атрибуты с одним id но разными DEFINITION | INSTANCE,
        // и presetDiscriminator === AUTO, то непонятно к какому атрибуту применять стиль, если к обоим, то будет задвоение
        // решили в таком кейсе стиль применять только к INSTANCE
        // данный кейс может возникнуть, когда еще дискриминаторы не были введены и в старых пресетах presetDiscriminator не задан или задан 'AUTO'
        !hasBothDefinationAndInstance(styledAttributeTypeId, styledAttributeTypes, presetDiscriminator)
    )
        return true;
    if (attributeDiscriminator === 'INSTANCE' && isInstanceDiscriminator(presetDiscriminator)) return true;

    return compareDiscriminators(attributeDiscriminator, presetDiscriminator);
};

export const hasAttributeDiscriminatorById = (
    attributeTypeId: string,
    styledAttributeTypes: StyledAttributeType[],
    attributeDiscriminator: TAttributeDiscriminator,
): boolean => {
    return !!styledAttributeTypes.find(
        (type) => type.id === attributeTypeId && type.attributeDiscriminator === attributeDiscriminator,
    );
};

export const hasBothDefinationAndInstance = (
    attributeTypeId: string,
    styledAttributeTypes: StyledAttributeType[],
    presetDiscriminator: TAttributeDiscriminator | undefined | null,
): boolean => {
    const isAutoDiscriminator: boolean = (presetDiscriminator || 'AUTO') === 'AUTO';

    const isDefinitionByAttributeTypeId: boolean = hasAttributeDiscriminatorById(
        attributeTypeId,
        styledAttributeTypes,
        'DEFINITION',
    );

    const isInstanceByAttributeTypeId: boolean = hasAttributeDiscriminatorById(
        attributeTypeId,
        styledAttributeTypes,
        'INSTANCE',
    );

    return isAutoDiscriminator && isDefinitionByAttributeTypeId && isInstanceByAttributeTypeId;
};
