import { showNotification } from '@/actions/notification.actions';
import { useContextMenuDublicateDelete } from '@/hooks/useContextMenuDublicateDelete';
import { NotificationType } from '@/models/notificationType';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { Icon } from '@/modules/UIKit';
import defaultIcon from '@/resources/icons/defaultIcon.svg';
import { InternationalString, MatrixCellBPM8764, MatrixDataBPM8764, MatrixLane, Node, NodeId } from '@/serverapi/api';
import { getMenuItem } from '@/utils/antdMenuItem.utils';
import { Tooltip } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import cn from 'classnames';
import { cloneDeep } from 'lodash-es';
import { MenuInfo } from 'rc-menu/es/interface';
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { treeItemTitleChangeRequest } from '../../../actions/tree.actions';
import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { TCurrentUserProfile } from '../../../reducers/userProfile.reducer.types';
import { LocalesService } from '../../../services/LocalesService';
import {
    matrixCopyLanes,
    matrixMoveToggledHeaderCells,
    matrixPastObjects,
    matrixSelectHeaderCells,
    matrixToggleHeaderCell,
} from '../actions/matrixEditor.actions';
import { TSelectedHeadersCells } from '../reducers/matrixEditor.reducer.types';
import { MatrixEditorSelectors } from '../selectors/matrixEditor.selectors';
import {
    checkLanesCanUpLvl,
    checkLanesSpaces,
    checkSameLvls,
    excludeChildIds,
    fixLanes,
    getCells,
    getCurrentLvl,
    getParentForLvlDown,
    getSelectedLanesWithChildren,
    getStyleIdsFromCellWithSameLinkedNodeIds,
    hasDeletedHeaderNode,
    haveChildren,
    isForbiddenByProfile,
    reorderLanes,
    replaceIdenticalIds,
} from '../utils/Matrix.utils';
import { HeaderCellsContextActions, HeaderType } from './Matrix.types';
import { MatrixContextMenu } from './MatrixContextMenu/MatrixContextMenu.component';
import messages from './MatrixContextMenu/MatrixContextMenu.messages';
import theme from './MatrixEditor.scss';

type TMatrixHeaderCellProps = {
    id: string;
    text: string;
    icon: string;
    index: number;
    headerSize: number;
    columnHeaderHeight: number;
    rowHeaderWidth: number;
    isReadMode: boolean;
    isRowDrag: boolean;
    isCopyEnabled: boolean;
    isPastEnabled: boolean;
    nodeId: NodeId;
    type: HeaderType;
    selectedHeaderCells: TSelectedHeadersCells;
    matrixData: MatrixDataBPM8764;
    rowsHeaders: MatrixLane[];
    rowsHeadersForRender: MatrixLane[];
    colsHeaders: MatrixLane[];
    colsHeadersForRender: MatrixLane[];
    objectDefinitions: {
        [id: string]: ObjectDefinitionImpl;
    };
    matrixContainerRef: React.RefObject<HTMLDivElement>;
    linkedNodeId?: string;
    userProfile: TCurrentUserProfile | undefined;
};

type TMatrixHeaderCellActionProps = {
    updateMatrixData: (data: MatrixDataBPM8764) => void;
    openRenameDialog: (onSubmit: (newName: InternationalString) => void, initName?: InternationalString) => void;
    setIsRowDrag: (flag: boolean) => void;
};

export const MatrixHeaderCell: FC<TMatrixHeaderCellProps & TMatrixHeaderCellActionProps> = ({
    id,
    text,
    icon,
    index,
    headerSize,
    columnHeaderHeight,
    rowHeaderWidth,
    isReadMode,
    isRowDrag,
    isCopyEnabled,
    isPastEnabled,
    nodeId,
    type,
    selectedHeaderCells,
    matrixData,
    colsHeaders,
    colsHeadersForRender,
    rowsHeaders,
    rowsHeadersForRender,
    objectDefinitions,
    linkedNodeId,
    matrixContainerRef,
    userProfile,
    updateMatrixData,
    openRenameDialog,
    setIsRowDrag,
}) => {
    const [isDropTarget, setIsDropTarget] = useState<boolean>(false);
    const [isContextMenuVisible, setContextMenuVisible] = useContextMenuDublicateDelete('matrix', { ...nodeId, id });

    const [columnTextXOffset, setColumnTextXOffset] = useState<number>(0);
    const dragCounter = useRef<number>(0);

    const isHeaderCellToggled: boolean = useSelector(MatrixEditorSelectors.isHeaderCellToggled(nodeId, id, type));

    const intl = useIntl();
    const dispatch = useDispatch();

    const isColumn: boolean = type === HeaderType.column;
    const isRow: boolean = type === HeaderType.row;

    const currentMatrixLanes = (isColumn ? colsHeaders : rowsHeaders) || [];
    const currentMatrixLane = currentMatrixLanes.find((matrixLane) => matrixLane.id === id);

    const objectDefinition: ObjectDefinitionImpl | undefined = objectDefinitions[currentMatrixLane?.linkedNodeId || ''];
    const isObjectDefinition: boolean = !!objectDefinition;
    const isShowOnlyId: boolean =
        (!!currentMatrixLane?.linkedNodeId && !isObjectDefinition) ||
        !!(objectDefinition as Node | undefined)?.deleted ||
        isForbiddenByProfile({
            selectedHeaders: currentMatrixLane ? [currentMatrixLane] : [],
            userProfile,
            objectDefinitions,
            rights: ['read'],
        });

    const cellElement = useRef<HTMLTableCellElement | null>(null);
    const headerCellClickTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const { height, width } = entries[0].contentRect;
            setColumnTextXOffset((width - height) / 2);
        });
        if (cellElement.current) {
            observer.observe(cellElement.current);
        }

        return () => {
            cellElement.current && observer.unobserve(cellElement.current);
        };
    }, [cellElement.current]);

    const updateMatrixHandeler = (data: MatrixDataBPM8764) => {
        fixLanes(data.rows);
        fixLanes(data.columns);

        const newRowsOrder: MatrixLane[] = [];
        reorderLanes(data.rows[0], data.rows, newRowsOrder);
        data.rows = newRowsOrder;

        const newColsOrder: MatrixLane[] = [];
        reorderLanes(data.columns[0], data.columns, newColsOrder);
        data.columns = newColsOrder;

        updateMatrixData(data);
    };

    const selectHeaderCellsAction = (type: HeaderType, ids: string[]) => {
        dispatch(matrixSelectHeaderCells(nodeId, type, ids));
    };

    const toggleHeaderCell = () => {
        if (isReadMode) return;
        dispatch(matrixToggleHeaderCell(nodeId, id, type));
    };

    const moveToggledHeaderCells = (ids: string[], targetType: HeaderType) => {
        dispatch(matrixMoveToggledHeaderCells(nodeId, ids, targetType));
    };

    const isIdSelected = (id: string) => {
        return selectedHeaderCells.ids.includes(id) && selectedHeaderCells.type === type;
    };

    const selected: boolean = isIdSelected(id);

    const onHeaderCellSelect = (event: React.MouseEvent) => {
        event.preventDefault();
        if (event.ctrlKey) {
            let newCellsIds: string[] = selectedHeaderCells.type !== type ? [] : [...selectedHeaderCells.ids];
            if (newCellsIds.includes(id)) {
                newCellsIds = newCellsIds.filter((selectedId) => selectedId !== id);
            } else {
                newCellsIds.push(id);
            }
            selectHeaderCellsAction(type, newCellsIds);
        } else if (event.shiftKey) {
            if (selectedHeaderCells.type !== type || selectedHeaderCells.ids.length === 0) {
                selectHeaderCellsAction(type, [id]);
            } else {
                const currentLanes: MatrixLane[] = isColumn ? colsHeaders : rowsHeaders;
                const lasSelectedId: string = selectedHeaderCells.ids[selectedHeaderCells.ids.length - 1];
                const firstIndex: number = currentLanes.findIndex((lane) => lane.id === lasSelectedId);
                const secondIndex: number = currentLanes.findIndex((lane) => lane.id === id);
                const newCells: string[] = [];
                if (firstIndex > secondIndex) {
                    for (let i = firstIndex; i >= secondIndex; i--) {
                        newCells.push(currentLanes[i].id);
                    }
                } else {
                    for (let i = firstIndex; i <= secondIndex; i++) {
                        newCells.push(currentLanes[i].id);
                    }
                }

                selectHeaderCellsAction(type, newCells);
            }
        } else {
            selectHeaderCellsAction(type, [id]);
        }
    };

    const onHeaderCellDoubleClick = () => {
        if (currentMatrixLane && matrixData) {
            matrixData.columns = colsHeaders;
            matrixData.rows = rowsHeaders;

            const isForbiddeRenameByProfile: boolean = isForbiddenByProfile({
                selectedHeaders: [currentMatrixLane],
                userProfile,
                objectDefinitions,
                rights: ['update', 'read'],
            });
            if (isForbiddeRenameByProfile) {
                dispatch(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.ACCESS_DENIED_BY_PROFILE,
                    }),
                );

                return;
            }

            const isAllowRenameByDel: boolean = hasDeletedHeaderNode(objectDefinition, currentMatrixLane);
            if (!isAllowRenameByDel) {
                dispatch(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.RENAME_MATRIX_HEADER_FORBIDDEN,
                    }),
                );

                return;
            }

            const onSubmit = (newName: InternationalString) => {
                currentMatrixLane.text = newName;
                updateMatrixHandeler(matrixData);
                if (isObjectDefinition) {
                    dispatch(
                        treeItemTitleChangeRequest({
                            nodeId: objectDefinition.nodeId,
                            title: LocalesService.internationalStringToString(newName),
                        }),
                    );
                }
            };
            openRenameDialog(onSubmit, isObjectDefinition ? objectDefinition.multilingualName : currentMatrixLane.text);
        }
    };

    const onHeaderCellClickHandler = (event: React.MouseEvent) => {
        if (isReadMode) return;

        if (headerCellClickTimer.current) clearTimeout(headerCellClickTimer.current);
        onHeaderCellSelect(event);

        if (event.detail === 1) {
            headerCellClickTimer.current = setTimeout(() => {}, 200);
        } else if (event.detail === 2) {
            onHeaderCellDoubleClick();
        }
    };

    const getAddedObjectById = (objectNodeId: NodeId): ObjectDefinitionImpl | null => {
        const addedObject = objectDefinitions[objectNodeId.id];

        if (!addedObject) return null;

        if (
            addedObject.type !== TreeItemType.ObjectDefinition ||
            objectNodeId.repositoryId !== nodeId.repositoryId ||
            objectNodeId.serverId !== nodeId.serverId
        ) {
            dispatch(
                showNotification({
                    id: uuid(),
                    type: NotificationType.DND_ERROR_WRONG_NODE_TYPE,
                    data: addedObject.type as TreeItemType,
                }),
            );
            return null;
        }

        return addedObject;
    };

    const addObjectOnLane = (object: ObjectDefinitionImpl) => {
        const currentMatrixLaneIndex: number = currentMatrixLanes.findIndex((matrixLane) => matrixLane.id === id);
        if (matrixData && currentMatrixLaneIndex !== -1) {
            const newMatrixLane: MatrixLane = {
                id: uuid(),
                linkedNodeId: object.nodeId.id,
                symbolId: object.idSymbol,
                text: object.multilingualName,
            };
            const currentMatrixLane = currentMatrixLanes[currentMatrixLaneIndex];
            if (!currentMatrixLane.text) {
                newMatrixLane.parentId = currentMatrixLane.parentId;
                currentMatrixLanes.splice(currentMatrixLaneIndex, 1, newMatrixLane);
            } else {
                currentMatrixLanes.splice(currentMatrixLaneIndex, 0, newMatrixLane);
            }

            matrixData.columns = colsHeaders;
            matrixData.rows = rowsHeaders;
            const newCells: MatrixCellBPM8764[] = matrixData.cells.filter((cell) => {
                if (isColumn) {
                    return cell.columnId === id;
                } else {
                    return cell.rowId === id;
                }
            });
            newCells.forEach((newCell) => getStyleIdsFromCellWithSameLinkedNodeIds(newCell, matrixData));

            updateMatrixHandeler(matrixData);
        }
    };

    const onDropHandler = (event: React.DragEvent) => {
        if (!matrixData) return;

        if (isReadMode) {
            dispatch(showNotification({ id: uuid(), type: NotificationType.DND_ERROR_MODEL_IS_LOCKED }));
            return;
        }

        if (isRowDrag) {
            if (selected) return;
            const isDropBetweenLanes: boolean = type !== selectedHeaderCells.type;
            const isColumnDrop: boolean = type === HeaderType.column;

            // на какую ось происходит перенос
            const currentLanes: MatrixLane[] = isColumnDrop ? [...colsHeaders] : [...rowsHeaders];

            // с какой оси происходит перенос
            let initLanes: MatrixLane[] = [];
            if (isDropBetweenLanes) {
                initLanes = isColumnDrop ? [...rowsHeaders] : [...colsHeaders];
            } else {
                initLanes = isColumnDrop ? [...colsHeaders] : [...rowsHeaders];
            }

            const dropIndex: number = currentLanes.findIndex((lane) => lane.id === id);

            const lanesToPastWithChildren: MatrixLane[] = cloneDeep(
                getSelectedLanesWithChildren(selectedHeaderCells.ids, initLanes),
            );
            const pastedIds: string[] = lanesToPastWithChildren.map((lane) => lane.id);

            const notEmptySelectedLanes: MatrixLane[] = initLanes.filter((lane) => isIdSelected(lane.id) && lane.text);
            const lanesForNewParent: MatrixLane[] = notEmptySelectedLanes.filter(
                (lane) => lane.parentId && !pastedIds.includes(lane.parentId),
            );

            const lanesBefore: MatrixLane[] = currentLanes.filter((_, index) => index < dropIndex);

            const lanesAfter: MatrixLane[] = currentLanes.filter((_, index) => index >= dropIndex);

            const newLanes: MatrixLane[] = [...lanesBefore, ...lanesToPastWithChildren, ...lanesAfter];

            //если был перенос между осями, необходимо заменять одинаковые id, если такие есть
            if (isDropBetweenLanes) {
                replaceIdenticalIds(lanesToPastWithChildren, currentLanes);
            }

            const newPastedIds: string[] = lanesToPastWithChildren.map((lane) => lane.id);

            let newParent: MatrixLane | undefined;
            const lastBeforeLane: MatrixLane = lanesBefore[lanesBefore.length - 1];
            if (
                lastBeforeLane &&
                !haveChildren(lastBeforeLane.id, newLanes) &&
                getCurrentLvl(lastBeforeLane.id, newLanes) === 0
            ) {
                newParent = undefined;
            } else {
                newParent = lanesBefore.findLast((lane) => haveChildren(lane.id, currentLanes));
            }
            lanesForNewParent.forEach((lane) => {
                lane.parentId = newParent?.id;
            });

            if (isColumnDrop) {
                matrixData.columns = newLanes;
                if (isDropBetweenLanes) {
                    matrixData.rows = rowsHeaders.filter((row) => !pastedIds.includes(row.id));
                }
            } else {
                matrixData.rows = newLanes;
                if (isDropBetweenLanes) {
                    matrixData.columns = colsHeaders.filter((col) => !pastedIds.includes(col.id));
                }
            }
            // доп логика, которую нужно выполнить, после переноса с одной оси на другую
            if (isDropBetweenLanes) {
                const newSelectedHeaderType: HeaderType = isColumnDrop ? HeaderType.column : HeaderType.row;
                selectHeaderCellsAction(newSelectedHeaderType, newPastedIds);

                const updatedCells: MatrixCellBPM8764[] = getCells(matrixData);
                lanesToPastWithChildren.forEach((pastedLane) => {
                    const newCells: MatrixCellBPM8764[] = updatedCells.filter((cell) => {
                        return cell.rowId === pastedLane.id || cell.columnId === pastedLane.id;
                    });
                    newCells.forEach((newCell) =>
                        getStyleIdsFromCellWithSameLinkedNodeIds(newCell, matrixData, pastedLane.id),
                    );
                });
                moveToggledHeaderCells(pastedIds, newSelectedHeaderType);
            }

            updateMatrixHandeler(matrixData);
        } else {
            const droppedObjNodeId: NodeId = JSON.parse(event.dataTransfer.getData('text'));
            if (!droppedObjNodeId) return;

            const droppedObject: ObjectDefinitionImpl | null = getAddedObjectById(droppedObjNodeId);
            if (!droppedObject || !!(droppedObject as Node)?.deleted) return;

            addObjectOnLane(droppedObject);
        }
    };

    const getFrame = (items: HTMLTableCellElement[]) => {
        const { offsetLeft, offsetTop } = items[0],
            frame = { lx: offsetLeft, ly: offsetTop, rx: offsetLeft, ry: offsetTop };

        items.forEach((item) => {
            const { offsetLeft, offsetTop, clientHeight, clientWidth } = item;

            if (offsetLeft < frame.lx) frame.lx = offsetLeft;
            if (offsetTop < frame.ly) frame.ly = offsetTop;
            if (offsetLeft + clientWidth > frame.rx) frame.rx = offsetLeft + clientWidth;
            if (offsetTop + clientHeight > frame.ry) frame.ry = offsetTop + clientHeight;
        });
        return frame;
    };

    const startDragHeaderCellHandler = (event: React.DragEvent) => {
        setIsRowDrag(true);
        const selectedElements: HTMLTableCellElement[] = [];
        selectedHeaderCells.ids.forEach((id) => {
            const selectedElement = document.getElementById(id) as HTMLTableCellElement | null;
            if (selectedElement) {
                selectedElements.push(selectedElement);
            }
        });

        if (selectedElements.length === 0) return;

        const frame = getFrame(selectedElements);
        const image: HTMLDivElement = document.createElement('div');
        image.style.position = 'absolute';
        image.style.zIndex = '-1';
        const dy: number = isColumn ? 165 : 0;
        selectedElements.forEach((selectedElement) => {
            const clone = selectedElement.cloneNode(true) as HTMLTableCellElement;
            const { offsetLeft, offsetTop, clientWidth, clientHeight, style, className } = selectedElement;
            clone.setAttribute('style', style.cssText);
            clone.className = className;
            clone.style.position = 'absolute';
            clone.style.left = `${offsetLeft - frame.lx}px`;
            clone.style.top = `${offsetTop - frame.ly}px`;
            clone.style.width = `${clientWidth}px`;
            clone.style.height = `${clientHeight}px`;
            if (isColumn) {
                (clone.childNodes[0] as HTMLDivElement).style.transform =
                    'rotate(-90deg) translate(calc(var(--headerSize)* -0.4), calc(var(--headerSize)* -0.4))';
            }
            clone.setAttribute('id', clone.id + '_clone');
            const wrapper: HTMLDivElement = document.createElement('div');
            wrapper.setAttribute('id', clone.id + '_clone_wrapper');
            wrapper.appendChild(clone);
            image.appendChild(wrapper);
        });
        if (matrixContainerRef.current) {
            matrixContainerRef.current.appendChild(image);
            event.dataTransfer.setDragImage(image, 0, dy);
            setTimeout(() => {
                image.remove();
            }, 0);
        }
    };

    const getHeaderCellAttributes = (): React.HTMLAttributes<HTMLTableCellElement> => {
        return {
            id,
            className: cn(
                theme.headerCell,
                { [theme.filled]: !!text },
                { [theme.dropTarget]: isDropTarget },
                { [theme.selected]: selected },
                {
                    [theme.leftBorder]:
                        !selected && isRow && rowsHeadersForRender.findLastIndex((row) => row.text) >= index,
                },
                {
                    [theme.topBorder]:
                        !selected && isColumn && colsHeadersForRender.findLastIndex((col) => col.text) >= index,
                },
                {
                    [theme.noPointer]: isReadMode,
                },
            ),
            onClick: onHeaderCellClickHandler,
            onDragEnter: (event: React.DragEvent) => {
                dragCounter.current += 1;
                setIsDropTarget(true);
            },
            onDragLeave: (event: React.DragEvent) => {
                dragCounter.current -= 1;
                if (dragCounter.current === 0) {
                    setIsDropTarget(false);
                }
            },
            onDragOver: (event: React.DragEvent) => {
                event.preventDefault();
            },
            onDrop: (event: React.DragEvent) => {
                dragCounter.current = 0;
                setIsDropTarget(false);
                onDropHandler(event);
                setIsRowDrag(false);
            },
            onDragStart: startDragHeaderCellHandler,
            draggable: selected && !isReadMode,
            onContextMenu: (event) => {
                event.preventDefault();
                if (selected) setContextMenuVisible(true);
            },
        };
    };

    const renderObjectIcon = () => {
        if (!text || !linkedNodeId) return undefined;

        return icon ? (
            <svg className={theme.entityIcon}>
                <image xlinkHref={icon} />
            </svg>
        ) : (
            <Icon className={theme.entityIcon} spriteSymbol={defaultIcon} />
        );
    };

    const lvl: number = getCurrentLvl(id, currentMatrixLanes);
    const selectedCellsHaveSameLvl: boolean = checkSameLvls(selectedHeaderCells.ids, currentMatrixLanes);
    const selectedCellsWithoutSpaces: boolean = checkLanesSpaces(selectedHeaderCells.ids, currentMatrixLanes);
    const selectedCellsCanUpLvl: boolean = checkLanesCanUpLvl(selectedHeaderCells.ids, currentMatrixLanes);
    const parentForLvlDown: MatrixLane | undefined = getParentForLvlDown(
        currentMatrixLanes,
        selectedHeaderCells.ids,
        lvl,
    );

    const disableLvlDown: boolean = !selectedCellsHaveSameLvl || !selectedCellsWithoutSpaces || !parentForLvlDown;
    const disableLvlUp: boolean = !selectedCellsCanUpLvl || !selectedCellsWithoutSpaces;

    const contextMenuItems: ItemType[] = [
        getMenuItem(
            intl.formatMessage(messages[HeaderCellsContextActions.levelUp]),
            HeaderCellsContextActions.levelUp,
            disableLvlUp,
        ),
        getMenuItem(
            intl.formatMessage(messages[HeaderCellsContextActions.levelDown]),
            HeaderCellsContextActions.levelDown,
            disableLvlDown,
        ),
        getMenuItem(
            intl.formatMessage(messages[HeaderCellsContextActions.copy]),
            HeaderCellsContextActions.copy,
            !isCopyEnabled,
        ),
        getMenuItem(
            intl.formatMessage(messages[HeaderCellsContextActions.past]),
            HeaderCellsContextActions.past,
            !isPastEnabled,
        ),
    ];

    const hideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const headerLevelUp = () => {
        const selectedIdsWithoutChildren: string[] = excludeChildIds(selectedHeaderCells.ids, currentMatrixLanes);
        selectedIdsWithoutChildren.forEach((id) => {
            const selectedHederCell: MatrixLane | undefined = currentMatrixLanes.find((lane) => lane.id === id);
            if (!selectedHederCell?.parentId) return;

            const parent: MatrixLane | undefined = currentMatrixLanes.find(
                (lane) => lane.id === selectedHederCell.parentId,
            );
            if (parent) {
                selectedHederCell.parentId = parent.parentId;
            }
        });
        matrixData.columns = colsHeaders;
        matrixData.rows = rowsHeaders;
        updateMatrixHandeler(matrixData);
    };

    const headerLevelDown = () => {
        const selectedIdsWithoutChildren: string[] = excludeChildIds(selectedHeaderCells.ids, currentMatrixLanes);
        if (parentForLvlDown) {
            currentMatrixLanes.forEach((lane) => {
                if (selectedIdsWithoutChildren.includes(lane.id)) lane.parentId = parentForLvlDown.id;
            });
            matrixData.columns = colsHeaders;
            matrixData.rows = rowsHeaders;
            updateMatrixHandeler(matrixData);
        }
    };

    const copy = () => {
        dispatch(matrixCopyLanes(nodeId));
    };

    const past = () => {
        dispatch(matrixPastObjects(nodeId));
    };

    const handleMenuAction = (event: MenuInfo) => {
        switch (event.key) {
            case HeaderCellsContextActions.levelUp:
                headerLevelUp();
                break;
            case HeaderCellsContextActions.levelDown:
                headerLevelDown();
                break;
            case HeaderCellsContextActions.copy:
                copy();
                break;
            case HeaderCellsContextActions.past:
                past();
                break;
        }
    };

    const isHaveChildren: boolean = haveChildren(id, currentMatrixLanes);

    const headerLaneStyle: CSSProperties = {
        paddingLeft: `${25 * lvl}px`,
        transform: isColumn ? `translateX(${columnTextXOffset}px) rotate(-90deg)` : 'unset',
        width: `${isColumn ? columnHeaderHeight : rowHeaderWidth}px`,
    };

    const headerTextStyle: CSSProperties = { WebkitLineClamp: `${Math.trunc(headerSize / 19)}` };

    const ref = useRef<HTMLDivElement>(null);
    const showTooltip: boolean | null = ref.current && ref.current.clientHeight < ref.current.scrollHeight;

    return (
        <th
            {...getHeaderCellAttributes()}
            ref={cellElement}
            style={
                isRow
                    ? {
                          width: `${rowHeaderWidth + 8}px`,
                          minWidth: `${rowHeaderWidth + 8}px`,
                          maxWidth: `${rowHeaderWidth + 8}px`,
                      }
                    : {}
            }
        >
            {isContextMenuVisible && (
                <MatrixContextMenu
                    visible={isContextMenuVisible}
                    menuItems={contextMenuItems}
                    hideContextMenu={hideContextMenu}
                    handleMenuAction={handleMenuAction}
                />
            )}
            <div className={theme.headerLane} style={headerLaneStyle}>
                <div
                    className={cn({
                        [theme.toggler]: isHaveChildren,
                        [theme.haveChildren]: isHaveChildren,
                        [theme.open]: !isHeaderCellToggled,
                        [theme.enableHover]: !isReadMode,
                    })}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleHeaderCell();
                    }}
                />
                <div className={theme.headerIcon}>{renderObjectIcon()}</div>
                {showTooltip ? (
                    <Tooltip
                        placement="bottom"
                        mouseLeaveDelay={0}
                        title={text}
                        key={`headerCellTextTooltip_${id}`}
                        destroyTooltipOnHide
                    >
                        <div
                            ref={ref}
                            className={cn(theme.headerText, { [theme.deleted]: isShowOnlyId })}
                            style={headerTextStyle}
                        >
                            {text}
                        </div>
                    </Tooltip>
                ) : (
                    <div
                        ref={ref}
                        className={cn(theme.headerText, { [theme.deleted]: isShowOnlyId })}
                        style={headerTextStyle}
                    >
                        {text}
                    </div>
                )}
            </div>
        </th>
    );
};
