import React from 'react';
import theme from './approvalTable.scss';
import { useSelector } from 'react-redux';
import { ApprovalSelectors } from '../../../selectors/approval.selectors';
import { NodeId } from '../../../serverapi/api';
import { Checkbox } from '../../UIKit/components/Checkbox/Checkbox.component';

type TRowCheckBoxProps = {
    nodeId: NodeId;
    handleChange: (data: NodeId) => void;
};

export const RowCheckBox = (props: TRowCheckBoxProps): JSX.Element => {
    const { handleChange, nodeId } = props;
    const isChecked: boolean = useSelector(ApprovalSelectors.isApprovalChecked(nodeId));

    return (
        <div className={theme.checkBoxContainer}>
            <Checkbox
                onChange={() => handleChange(nodeId)}
                checked={isChecked}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
            />
        </div>
    );
};
