import React, { ComponentType, FC, useCallback, useMemo, useRef, useEffect } from 'react';
import { Tabs } from 'antd';
import { TabsType } from 'antd/es/tabs';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd';
import { arrayMove } from '../../../AdminTools/Methodology/components/Presets/ModelType/SymbolsTab/utils';
import theme from './DnDTabs.scss';
import { TWorkspaceTab } from '../../../../models/tab.types';
import { useDispatch } from 'react-redux';
import { workspaceTabsSetOrder } from '../../../../actions/tabs.actions';
import { DragItem } from './DragItem/DragItem.component';
import { EllipsisOutlined } from '@ant-design/icons';
import { useKeyDownListener } from '@/utils/useKeyDownListener';
import { openBdSearchAction, openSearchAction } from '@/actions/editor.actions';

type Tab = {
    key: string;
    label: JSX.Element | string;
    children?: JSX.Element;
};

type TDnDTabs = {
    tabs: TWorkspaceTab[];
    jsxTabs: Tab[];
    type: TabsType | undefined;
    onChange: (key: string) => void;
    activeKey: string;
    onEdit: (id: string, action: string) => void;
};

type TPane = {
    key: string;
};

const PLACEHOLDER_KEY = 'placeholder';

export const DnDTabs: FC<TDnDTabs> = (props) => {
    const { type, onChange, activeKey, onEdit, jsxTabs, tabs } = props;
    const dispatch = useDispatch();

    const moreIconRef = useRef<HTMLSpanElement | null>(null);

    const onMouseEnter = useCallback(() => {
        const tabsDropdown: HTMLDivElement = document.querySelector('.ant-tabs-dropdown') as HTMLDivElement;
        if (tabsDropdown) tabsDropdown.classList.remove('ant-tabs-dropdown-hidden');
    }, []);

    useEffect(() => {
        const moreButton = moreIconRef?.current?.parentNode;
        if (moreButton) moreButton.addEventListener('mouseenter', onMouseEnter);
    }, []);

    const onDragEnd = useCallback(
        ({ source, destination }: DropResult) => {
            if (!destination || destination.index === source.index) {
                return;
            }
            const newTabs = arrayMove(tabs, source.index, destination.index);
            dispatch(workspaceTabsSetOrder(newTabs));
        },
        [tabs],
    );

    const getDragItem = useCallback(
        (jsxTab: JSX.Element, index: number) => (
            <Draggable
                key={jsxTab.key}
                index={index}
                draggableId={jsxTab.key as string}
                isDragDisabled={tabs.length < 2}
            >
                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <DragItem
                        provided={provided}
                        snapshot={snapshot}
                        jsxTab={jsxTab}
                        isActiveTab={jsxTab.key === activeKey}
                        isHomePageTab={JSON.parse(jsxTab.key as string).id === 'homePageTab'}
                    />
                )}
            </Draggable>
        ),
        [activeKey, tabs],
    );

    const memoizedTabs = useMemo(() => [...jsxTabs], [jsxTabs]);

    const renderTabBar = useCallback(
        (provided: DroppableProvided) => (tabProps, DefaultTabBar: ComponentType) =>
            (
                <DefaultTabBar {...tabProps}>
                    {(jsxTab: JSX.Element) => {
                        if (jsxTab.key === PLACEHOLDER_KEY) {
                            return provided.placeholder;
                        }

                        const index = (tabProps?.panes as TPane[])?.findIndex((pane) => pane.key === jsxTab.key);

                        return getDragItem(jsxTab, index);
                    }}
                </DefaultTabBar>
            ),
        [getDragItem],
    );

    const render = useCallback(
        (provided: DroppableProvided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className={theme.container}>
                <Tabs
                    type={type}
                    onChange={onChange}
                    activeKey={activeKey}
                    onEdit={onEdit}
                    renderTabBar={renderTabBar(provided)}
                    moreIcon={<EllipsisOutlined data-test="nav-button-more" ref={moreIconRef} />}
                    items={memoizedTabs}
                />
                {provided.placeholder}
            </div>
        ),
        [type, activeKey, onChange, onEdit],
    );
    useKeyDownListener(['altKey', 'ctrlKey'], 'KeyF', () => {
        dispatch(openBdSearchAction());
    });
    useKeyDownListener(['shiftKey', 'ctrlKey'], 'KeyF', () => {
        dispatch(openSearchAction());
    });
    
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="dndTabs" direction="horizontal">
                {render}
            </Droppable>
        </DragDropContext>
    );
};
