import { apiBundle } from '../api/api-bundle';
import { StubResponse } from '../../serverapi/api';

export class FileDaoService {
    public static async uploadTmpFile(serverId: string, file: File): Promise<StubResponse> {
        return apiBundle(serverId).fileTmpStorage.uploadBigTmpFile({ body: { file } });
    }

    public static async uploadTmpFileWithProgress(
        file: File,
        onUploadProgress: Function,
        URL: string,
        setXhr: Function,
    ): Promise<StubResponse> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            setXhr(xhr);
            xhr.upload.onprogress = function (e) {
                onUploadProgress(e);
            };

            xhr.onloadend = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const response = JSON.parse(xhr.response);
                    resolve(response);
                } else {
                    reject(xhr.response);
                }
            };

            xhr.open('POST', URL, true);

            xhr.setRequestHeader('Accept', 'application/json');
            xhr.withCredentials = true;

            const data = new FormData();

            if (file instanceof File || typeof file === 'string') {
                data.append('file', file);
            } else {
                data.append('file', JSON.stringify(file));
            }

            xhr.send(data);
        });
    }

    public static async getFileWindowUrl(serverId: string, repositoryId: string, fileId: string) {
        try {
            const response: Response = await (apiBundle(serverId).file.downloadFile({
                repositoryId,
                fileId,
            }) as unknown as Promise<Response>);
            const blob: Blob = await response.blob();
            const url: string = window.URL.createObjectURL(blob);

            return url;
        } catch (e) {
            console.error(e);

            return '';
        }
    }
}
