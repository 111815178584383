import { addStateAction, clearStateAction } from '@/actions/undoRedo.actions';
import { getLockingTool, SaveModelLockTool } from '@/modules/Editor/classes/SaveModelLockTool';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { closeDialog, openDialog } from '../../../actions/dialogs.actions';
import { objectDefinitionsAdd } from '../../../actions/entities/objectDefinition.actions';
import { unlock } from '../../../actions/lock.actions';
import { showNotification, showNotificationByType } from '../../../actions/notification.actions';
import { recentAddModel } from '../../../actions/recent.actions';
import { workspaceActivateTab, workspaceAddTab, workspaceRemoveTab } from '../../../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../../../actions/tabs.actions.types';
import { treeItemFetchChildSuccess } from '../../../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../../../actions/tree.actions.types';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../../../actionsTypes/tabs.actionTypes';
import { TabsBusActions } from '../../../actionsTypes/tabsBus.actionTypes';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../../../actionsTypes/tree.actionTypes';
import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { IMatrixNode } from '../../../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../../../models/editorMode';
import { IDiagramLockError } from '../../../models/notification/diagramLockError.types';
import { NotificationType } from '../../../models/notificationType';
import { IWorkspaceTabItemMatrixParams, TMatrixTabType, TWorkspaceTab } from '../../../models/tab.types';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import { presetLoadModelTypes } from '../../../sagas/notation.saga';
import { getActiveModelContext, getContentLoadingPageTab } from '../../../sagas/utils';
import { IModelContext } from '../../../sagas/utils.types';
import { getAbsentNodesId } from '../../../sagas/utils/matrix.saga.utils';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { getNodeWithChildrenById, getTreeItems, TreeSelectors } from '../../../selectors/tree.selectors';
import { LockInfoDTO, MatrixLane, NodeId, ObjectDefinitionNode } from '../../../serverapi/api';
import { LocalStorageDaoService } from '../../../services/dao/LocalStorageDaoService';
import { LocalesService } from '../../../services/LocalesService';
import { objectDefinitionService } from '../../../services/ObjectDefinitionService';
import { setServerIdToNodeOriginal } from '../../../utils/nodeId.utils';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeItemContextMenuAction, TreeItemType } from '../../Tree/models/tree';
import {
    matrixCreateSuccess,
    matrixOpen,
    matrixRequestSuccess,
    matrixSaveRequest,
    matrixSaveRequestFailure,
    matrixSaveRequestSuccess,
    matrixSaveFromStoreRequest,
    refreshMatrix,
} from '../actions/matrix.actions';
import {
    TMatrixDefaultAction,
    TMatrixDefaultRequestAction,
    TMatrixEditorModeChangedAction,
    TMatrixOpenAction,
    TMatrixOpenByNodeIdAction,
    TMatrixSaveFromStoreRequestAction,
    TRefreshMatrixAction,
} from '../actions/matrix.actions.types';
import {
    MATRIX_CREATE,
    MATRIX_EDITOR_MODE_CHANGED,
    MATRIX_OPEN,
    MATRIX_OPEN_BY_NODE_ID,
    MATRIX_SAVE_FROM_STORE_REQUEST,
    MATRIX_SAVE_REQUEST,
    REFRESH_MATRIX,
} from '../actionTypes/matrix.actionTypes';
import { MatrixDaoService } from '../dao/MatrixDaoService';
import messages from '../MatrixEditor/MatrixEditor.messages';
import { MatrixSelectors } from '../selectors/matrix.selectors';
import { MatrixEditorSelectors } from '../selectors/matrixEditor.selectors';
import { matrixClearSelectedCells } from '../actions/matrixEditor.actions';
import { editorModeChangedAction } from '@/actions/editor.actions';

function* handleMatrixOpen(action: TMatrixOpenAction) {
    const { matrix, mode } = action.payload;
    const workspaceTab: TMatrixTabType = <TMatrixTabType>{
        title: matrix.name,
        type: WorkSpaceTabTypes.MARTIX_EDITOR,
        nodeId: matrix.nodeId,
        content: matrix,
        mode: mode || EditorMode.Read,
        params: <IWorkspaceTabItemMatrixParams>{ content: matrix },
    };
    yield put(workspaceAddTab(workspaceTab));

    const locale: Locale = yield select(getCurrentLocale);

    yield put(
        recentAddModel({
            nodeId: matrix.nodeId,
            type: TreeItemType.Matrix,
            parentId: matrix.parentNodeId || null,
            createdAt: new Date().toISOString(),
            title: matrix.name,
            modelTypeId: WorkSpaceTabTypes.MARTIX_EDITOR,
            modelTypeName: LocalesService.useIntl(locale).formatMessage(messages.matrixModel),
            messageDescriptor: messages.matrixModel,
        }),
    );
    if (matrix.data2) {
        yield put(addStateAction(matrix.nodeId, matrix.data2, matrix.type));
    }
}

function* handleTreeItemsCreateMatrix({ payload: { nodeId, type, action } }: TTreeItemContextMenuAction) {
    if (
        (type === TreeItemType.Folder || type === TreeItemType.Repository) &&
        action === TreeItemContextMenuAction.ADD_MATRIX
    ) {
        yield put(openDialog(DialogType.MATRIX_CREATE_DIALOG, { parentNodeId: nodeId }));
    }
}

export function* handleMatrixCreate(action: TMatrixDefaultAction) {
    let { matrix } = action.payload;
    const columns: MatrixLane[] = [];
    const rows: MatrixLane[] = [];
    for (let i = 0; i < 15; i++) {
        columns.push({ id: uuid() });
        rows.push({ id: uuid() });
    }

    matrix = {
        ...matrix,
        data2: {
            cells: [],
            columns,
            rows,
            rowHeaderWidth: 165,
            columnHeaderHeight: 165,
            cellSettings: {
                id: uuid(),
                isAutomatic: false,
            },
            columnSettings: {
                id: uuid(),
                isAutomatic: false,
            },
            rowSettings: {
                id: uuid(),
                isAutomatic: false,
            },
        },
        nodeId: {
            ...matrix.parentNodeId,
            id: uuid(),
        },
        type: TreeItemType.Matrix,
        serverId: matrix.parentNodeId?.serverId,
    } as IMatrixNode;

    const newTreeNode: TreeNode = {
        nodeId: matrix.nodeId,
        name: matrix.name,
        parentNodeId: matrix.parentNodeId && matrix.parentNodeId,
        type: TreeItemType.Matrix,
        hasChildren: !!(matrix.children && matrix.children.length > 0),
        countChildren: matrix?.children?.length || 0,
    };
    const parentNodeWithChildren: TTreeEntityState = yield select(getNodeWithChildrenById(matrix.nodeId));

    if (parentNodeWithChildren.childrenIds) {
        parentNodeWithChildren.childrenIds.push(matrix.nodeId.id);
    } else {
        parentNodeWithChildren.childrenIds = [matrix.nodeId.id];
    }

    const createdMatrix: IMatrixNode = yield handleMatrixSaveRequest(matrixSaveRequest(matrix));

    yield put(
        treeItemFetchChildSuccess({
            parentNodeId: createdMatrix.parentNodeId!,
            child: [newTreeNode],
        }),
    );

    yield put(matrixOpen(createdMatrix, EditorMode.Edit));
    yield put(matrixCreateSuccess(createdMatrix));
    yield put(closeDialog(DialogType.MATRIX_CREATE_DIALOG)); // todo этого тут быть не должно, диалог надо закрывать там где открывали
}

function* handleMatrixSaveRequest(action: TMatrixDefaultRequestAction) {
    const {
        matrix,
        matrix: {
            nodeId: { serverId },
        },
    } = action.payload;

    try {
        const savedMatrix: IMatrixNode = yield MatrixDaoService.saveMatrix(matrix as IMatrixNode);
        yield put(matrixSaveRequestSuccess(savedMatrix));
        return savedMatrix;
    } catch (e) {
        yield put(matrixSaveRequestFailure(serverId));
        throw e;
    }
}

function* handleMatrixSaveFromStoreRequest(action: TMatrixSaveFromStoreRequestAction) {
    const { nodeId } = action.payload;

    const isMatrixUnsaved: IMatrixNode = yield select(MatrixEditorSelectors.isMatrixUnsaved(nodeId));
    if (!isMatrixUnsaved) return;

    const matrix: IMatrixNode | undefined = yield select(MatrixSelectors.byId(nodeId));

    if (matrix) {
        const lock: SaveModelLockTool = getLockingTool();

        lock.addLock(nodeId.id);
        try {
            const data: IMatrixNode = yield MatrixDaoService.saveMatrix(matrix as IMatrixNode);
            yield put(matrixSaveRequestSuccess(data));
        } catch (e) {
            yield put(matrixSaveRequestFailure(nodeId.serverId));
            throw e;
        } finally {
            lock.deleteLock(nodeId.id);
        }
    }
}

function* handleMatrixOpenByNodeId(action: TMatrixOpenByNodeIdAction) {
    const { nodeId } = action.payload;
    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(nodeId));
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);

    if (tab) {
        yield put(workspaceActivateTab(tab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }

    try {
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));

        yield put(workspaceAddTab(contentLoadingPageTab));
        yield presetId && presetLoadModelTypes(nodeId.serverId, presetId);

        const matrix: IMatrixNode | undefined = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);

        // Временная проверка на старую матрицу
        // https://jira.silaunion.ru/browse/BPM-9582 задача с исправлением импорта старой матрицы
        if (!matrix?.data2) {
            yield put(showNotificationByType(NotificationType.OLD_MATRIX));
            return;
        }

        setServerIdToNodeOriginal(matrix, nodeId.serverId);

        const treeItemsById: { [id: string]: TTreeEntityState } = yield select(
            getTreeItems(nodeId.serverId, nodeId.repositoryId),
        );

        const absentNodesId: NodeId[] = getAbsentNodesId(matrix, treeItemsById);

        if (absentNodesId.length) {
            const objDefinitions: ObjectDefinitionNode[] = yield call(() =>
                objectDefinitionService().loadObjectsFromServer(nodeId.serverId, absentNodesId),
            );
            if (objDefinitions.length) {
                yield put(objectDefinitionsAdd(objDefinitions as ObjectDefinitionImpl[]));
            }
        }

        yield put(matrixRequestSuccess(matrix));
        yield put(matrixOpen(matrix));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    } finally {
        yield put(workspaceRemoveTab(contentLoadingPageTab));
    }
}

function* handleTabMatrixClose(action: TWorkspaceTabsRemoveAction) {
    const {
        workspaceTab,
        workspaceTab: { content, nodeId, mode },
    } = action.payload;

    if (content?.type === TreeItemType.Matrix) {
        yield put(workspaceRemoveTab(workspaceTab));
        yield put(clearStateAction(nodeId));
        if (mode === EditorMode.Edit) {
            yield put(matrixSaveFromStoreRequest(nodeId));
            yield put(unlock(nodeId, 'MATRIX'));
        }
    }
}

function* handleModeChanged({ payload: { mode } }: TMatrixEditorModeChangedAction) {
    const activeModelContext: IModelContext = yield getActiveModelContext();
    if (
        activeModelContext &&
        activeModelContext.schema &&
        activeModelContext.schema.type === WorkSpaceTabTypes.MARTIX_EDITOR
    ) {
        const graphId = activeModelContext.schema.nodeId;
        const isMatrixAutofill = yield select(MatrixSelectors.isMatrixAutofilled(graphId));
        if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
            // todo: 1647
            const lock: LockInfoDTO = yield MatrixDaoService.lockMatrix(graphId);
            if (lock.locked) {
                yield put(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.DIAGRAM_LOCK_ERROR,
                        data: {
                            lockOwner: lock.ownerName,
                        } as IDiagramLockError,
                    }),
                );

                return;
            }

            if (isMatrixAutofill) {
                yield put(refreshMatrix(graphId));
            }
        } else if (mode !== EditorMode.Edit && activeModelContext.schema.mode === EditorMode.Edit) {
            if (isMatrixAutofill) {
                yield put(refreshMatrix(graphId));
            } else {
                yield put(matrixSaveFromStoreRequest(graphId));
            }
            yield put(unlock(graphId, 'MATRIX'));
            yield put(matrixClearSelectedCells(graphId));
        }
        yield put(editorModeChangedAction(mode));
    }
}

function* handleRefreshMatrix(action: TRefreshMatrixAction) {
    const { nodeId } = action.payload;

    const lock: SaveModelLockTool = getLockingTool();
    if (lock.isLocked(nodeId.id)) {
        yield put(showNotificationByType(NotificationType.MATRIX_IS_SAVING));
        return;
    }

    try {
        yield handleMatrixSaveFromStoreRequest(matrixSaveFromStoreRequest(nodeId));

        const matrix: IMatrixNode | undefined = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);

        // Временная проверка на старую матрицу
        // https://jira.silaunion.ru/browse/BPM-9582 задача с исправлением импорта старой матрицы
        if (!matrix?.data2) {
            yield put(matrixSaveRequestFailure(nodeId.serverId));
            yield put(showNotificationByType(NotificationType.OLD_MATRIX));
            return;
        }

        setServerIdToNodeOriginal(matrix, nodeId.serverId);
        yield put(matrixSaveRequestSuccess(matrix));
        yield put(clearStateAction(nodeId));
    } catch (e) {
        yield put(matrixSaveRequestFailure(nodeId.serverId));
        throw e;
    }
}

export function* matrixSaga() {
    yield takeEvery(MATRIX_CREATE, handleMatrixCreate);
    yield takeEvery(MATRIX_OPEN, handleMatrixOpen);
    yield takeEvery(MATRIX_OPEN_BY_NODE_ID, handleMatrixOpenByNodeId);
    yield takeEvery(MATRIX_SAVE_REQUEST, handleMatrixSaveRequest);
    yield takeEvery(MATRIX_SAVE_FROM_STORE_REQUEST, handleMatrixSaveFromStoreRequest);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleTreeItemsCreateMatrix);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabMatrixClose);
    yield takeEvery(MATRIX_EDITOR_MODE_CHANGED, handleModeChanged);
    yield takeEvery(REFRESH_MATRIX, handleRefreshMatrix);
}
