import React from 'react';
import theme from './DeletedElementsTable.scss';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';
import { NodeId } from '@/serverapi/api';

type TRowCheckBoxProps = {
    nodeId: NodeId;
    isChecked: boolean;
    handleChange: (data: NodeId) => void;
};

export const RowCheckBox = (props: TRowCheckBoxProps): JSX.Element => {
    const { handleChange, nodeId, isChecked } = props;

    return (
        <div className={theme.checkBoxContainer}>
            <Checkbox
                onChange={() => handleChange(nodeId)}
                checked={isChecked}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
            />
        </div>
    );
};
