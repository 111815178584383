import { createSelector } from 'reselect';
import { TRootState } from '../reducers/root.reducer.types';

export const workspaceTabData = (state: TRootState) => state.workspaceTabData.editModelTypeWorkspaceTab;

export const workspaceTabKanbanData = (state: TRootState) => state.workspaceTabData.editKanbanModelTypeWorkspaceTab;

export const workspaceTabKanbanCardData = (state: TRootState) => state.workspaceTabData.editKanbanCardTypeWorkspaceTab;

export const workspaceTabMatrixData = (state: TRootState) => state.workspaceTabData.editMatrixModelTypeWorkspaceTab;
export namespace WorkspaceTabDataSelectors {
    export const getEditModelType = (presetId: string, serverId: string, modelTypeId: string) =>
        createSelector(workspaceTabData, (state) => state[serverId]?.[presetId]?.[modelTypeId]);

    export const getEditKanbanModelType = (presetId: string, modelTypeId: string) =>
        createSelector(workspaceTabKanbanData, (state) => state[presetId]?.[modelTypeId]);

    export const getEditKanbanCardType = (presetId: string, kanbanCardTypeId: string) =>
        createSelector(workspaceTabKanbanCardData, (state) => state[presetId]?.[kanbanCardTypeId]);

    export const getEditMatrixModelType = (presetId: string, modelTypeId: string) =>
        createSelector(workspaceTabMatrixData, (state) => state[presetId]?.[modelTypeId]);
}
