// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPath__searchPath__pdscm{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;margin-bottom:10px}.SearchPath__path__uPUN_{margin-left:10px}.SearchPath__icon__urCFk{height:14px;-ms-flex-item-align:center;align-self:center;margin-right:10px;cursor:pointer}", "",{"version":3,"sources":["webpack://./src/modules/Search/SearchPath/SearchPath.scss"],"names":[],"mappings":"AACI,+BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA,CACA,kBAAA,CAGJ,yBACI,gBAAA,CAGJ,yBACI,WAAA,CACA,0BAAA,CAAA,iBAAA,CACA,iBAAA,CAEA,cAAA","sourcesContent":[":local {\r\n    .searchPath {\r\n        display: flex;\r\n        align-items: baseline;\r\n        margin-bottom: 10px;\r\n    }\r\n\r\n    .path {\r\n        margin-left: 10px;\r\n    }\r\n\r\n    .icon {\r\n        height: 14px;\r\n        align-self: center;\r\n        margin-right: 10px;\r\n\r\n        cursor: pointer;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchPath": "SearchPath__searchPath__pdscm",
	"path": "SearchPath__path__uPUN_",
	"icon": "SearchPath__icon__urCFk"
};
export default ___CSS_LOADER_EXPORT___;
