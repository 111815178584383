import type { TImageUpload } from '../../extensions/image.types';
import type { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { debounce } from 'lodash-es';
import { IMAGE_NODE_NAME } from '../../extensions/constants';

type TImagePastePluginProps = {
    editor: Editor;
    allowedImageTypes: string[];
    imageUpload: TImageUpload;
};

const insertImageDebounced = debounce(
    (blob: BlobPart, editor: Editor, pos: number, imageUpload: TImageUpload) => {
        const imageFileAttrs = imageUpload(blob);

        editor.commands.insertContentAt(
            pos,
            // TODO вынести в отдельную команду и заменить на this.name
            { type: IMAGE_NODE_NAME, attrs: imageFileAttrs },
            { updateSelection: false },
        );
    },
    500,
    // TODO исследовать совместно с setTimeout в image.ts
    // { leading: true, trailing: false },
);

export const ImagePastePlugin = ({ editor, allowedImageTypes, imageUpload }: TImagePastePluginProps) => {
    const pluginKey = new PluginKey('pasteImage');
    const plugin = new Plugin({
        key: pluginKey,
        props: {
            handlePaste: (view, event, slice) => {
                void (async () => {
                    const clipboardItems = await navigator.clipboard.read();

                    const clipboardItem = clipboardItems[0];
                    const { types } = clipboardItem;
                    const type = types[0];
                    const blob: BlobPart = await clipboardItem.getType(type);

                    if (allowedImageTypes.includes(type)) {
                        const pos = view.state.selection.$anchor.pos;

                        // в ProseMirror существует дефект:
                        // при вставке нетекстого контента из буфера обмена дважды вызывается handlePaste
                        // https://discuss.prosemirror.net/t/can-not-paste-a-single-image-from-word-doc-or-local-folder/1874
                        // поэтому добавлен debounce для исключения дополнительного вызова
                        insertImageDebounced(blob, editor, pos, imageUpload);
                    }
                })();
            },
        },
    });

    return plugin;
};
