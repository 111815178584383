import { put, select, takeEvery } from 'redux-saga/effects';
import jsPDF from 'jspdf';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ModelNode, NodeId } from '../serverapi/api';
import { SvgImage } from '../mxgraph/bpmgraph';
import { svgService } from '../services/SvgServices';
import { TabsSelectors } from '../selectors/tabs.selectors';
import {
    IMAGE_TO_CLIPBOARD,
    OPEN_IMAGE_DOWNLOAD_SETTINGS,
    PRINT_MODEL,
    SAVE_GRAY_SCALE,
    SAVE_HIDE_DECOMPOSITION,
    SAVE_IMAGE,
    SAVE_IMAGE_SVG,
    SAVE_PDF,
} from '../actionsTypes/image.actionTypes';
import {
    TSaveImageAction,
    TPrintModelAction,
    TSaveImageSvgAction,
    TSavePdfAction,
    TImageToClipboardAction,
    TSaveGrayScaleToLocalStorageAction,
    TSaveHideDecompositionToLocalStorageAction,
} from '../actions/image.actions.types';
import { TPrintDialogProps } from '../modules/dialogs/PrintDialog/PrintDialog.types';
import { CanvasSettings } from '../selectors/canvas-settings.selectors';
import { Orientation, PaperFormat } from '../utils/types';
import { ModelSelectors } from '../selectors/model.selectors';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TServerEntity } from '../models/entities.types';
import { GridTypeOnCanvas } from '../modules/MainMenu/components/GraphGeneralToolbar/GridTypeOnCanvasConstant';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';
import { ModelDaoService } from '@/services/dao/ModelDaoService';
import { CREATE_PRINT_MODEL_IMAGES } from '../actionsTypes/printModel.actionTypes';
import { TCreatePrintModelImagesAction } from '../actions/printModel.actions.types';
import { createPrintModelImages, savePrintModelImages } from '../actions/printModel.actions';
import { ModelSvgImage, TGridType } from './types/imageSaga.types';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';

const DEFAULT_FILE_NAME = 'model';

function* getSvg(
    nodeId: NodeId | undefined,
    onlySelected?: boolean,
    withoutNegativeCoordinates?: boolean,
    hideDecompositions?: boolean,
    isPrint?: boolean,
) {
    if (!nodeId) return undefined;

    const model: ModelNode | undefined = yield select(ModelSelectors.byId(nodeId));

    if (!model) return undefined;

    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const modelSvg: ModelSvgImage | undefined = yield svgService().getSvg(
        nodeId,
        model,
        server,
        onlySelected,
        withoutNegativeCoordinates,
        hideDecompositions,
        isPrint,
    );

    return modelSvg;
}

function* svgToClipboard({ payload: { nodeId } }: TImageToClipboardAction) {
    // если у пользователя нет прав на печать, checkPrint вернет ошибку, дальнейший код не будет выполнен
    yield ModelDaoService.checkPrint(nodeId);
    const modelSvg: ModelSvgImage | undefined = yield getSvg(nodeId);

    if (modelSvg && modelSvg.svgString) {
        svgService().svgToClipboard(modelSvg);
    }
}

function* savePdf({ payload: { nodeId } }: TSavePdfAction) {
    // если у пользователя нет прав на печать, checkPrint вернет ошибку, дальнейший код не будет выполнен
    yield ModelDaoService.checkPrint(nodeId);
    const modelSvg: ModelSvgImage | undefined = yield getSvg(nodeId);

    const save = (canvas: HTMLCanvasElement) => () => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF('p', 'pt', 'a4', 1);

        if (modelSvg && modelSvg.width && modelSvg.height && imgData) {
            const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
            const pdfHeight = pdf.internal.pageSize.getHeight() - 20;

            const modelWidth = modelSvg.width / pdfWidth;
            const modelHeight = modelSvg.height / pdfHeight;

            if (modelWidth < 1 && modelHeight < 1) {
                // картинка меньше листа
                pdf.addImage(imgData, 'PNG', 10, 10, modelSvg.width, modelSvg.height, undefined, 'FAST');
            } else if (modelWidth > modelHeight) {
                // картинка высокая
                const height = (modelSvg.height * pdfWidth) / modelSvg.width;
                pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth, height, undefined, 'FAST');
            } else {
                // картинка широкая
                const width = (modelSvg.width * pdfHeight) / modelSvg.height;
                pdf.addImage(imgData, 'PNG', 10, 10, width, pdfHeight, undefined, 'FAST');
            }
        }

        const resultBlob = pdf.output('blob');
        const fileName = `${modelSvg?.modelName || DEFAULT_FILE_NAME}.pdf`;
        BrowserDaoService.downloadFile(resultBlob, { defaultPath: fileName });
        // стандартный механизм для сохранения в jsPDF,
        // в толстом клиенте появляется кнопка открыть файл, поэтому не используем
        // pdf.save((modelSvg.modelName || DEFAULT_FILE_NAME) + '.pdf');
    };

    if (modelSvg && modelSvg.svgString) {
        svgService().convertSvg(modelSvg, save, 'image/png');
    }
}

function* printModel({ payload: { nodeId } }: TPrintModelAction) {
    // если у пользователя нет прав на печать, checkPrint вернет ошибку, дальнейший код не будет выполнен
    yield ModelDaoService.checkPrint(nodeId);
    yield put(createPrintModelImages(nodeId, false));
    const zoomScale: number = yield select(TabsSelectors.getActiveTabZoomLevel);
    let gridType: TGridType | undefined = yield select(CanvasSettings.gridType);
    gridType = gridType === GridTypeOnCanvas.dot ? undefined : gridType;
    const [paperFormat, orientation] = (gridType?.split('/') as [PaperFormat, Orientation]) || [];

    const convertSvg = (svg: SvgImage, save: (canvas: HTMLCanvasElement) => void) =>
        svgService().convertSvg(svg, (canvas: HTMLCanvasElement) => () => save(canvas), 'image/png');
    const props: TPrintDialogProps = {
        type: 'svg',
        convertSvg,
        zoomScale,
        paperFormat,
        orientation,
        nodeId,
    };
    yield put(openDialog(DialogType.PRINT_DIALOG, props));
}

function* saveImage({ payload: { extension, nodeId } }: TSaveImageAction) {
    // если у пользователя нет прав на печать, checkPrint вернет ошибку, дальнейший код не будет выполнен
    yield ModelDaoService.checkPrint(nodeId);
    const modelSvg: ModelSvgImage | undefined = yield getSvg(nodeId);

    const save = () => (blob: Blob | null) => {
        if (!blob) {
            return;
        }
        const fileName = `${modelSvg?.modelName || DEFAULT_FILE_NAME}.${extension}`;
        BrowserDaoService.downloadFile(blob, { defaultPath: fileName });
    };

    if (modelSvg && modelSvg.svgString) {
        const mimeType = `image/${extension}`;

        svgService().convertSvg(modelSvg, save, mimeType);
    }
}

function* saveSvg({ payload: { nodeId } }: TSaveImageSvgAction) {
    // если у пользователя нет прав на печать, checkPrint вернет ошибку, дальнейший код не будет выполнен
    yield ModelDaoService.checkPrint(nodeId);
    const modelSvg: ModelSvgImage | undefined = yield getSvg(nodeId);

    if (modelSvg && modelSvg.svgString) {
        const blob = new Blob([modelSvg.svgString], { type: 'image/svg+xml' });
        const fileName = `${modelSvg.modelName || DEFAULT_FILE_NAME}.svg`;

        BrowserDaoService.downloadFile(blob, { defaultPath: fileName });
    }
}

function* createImages({ payload: { nodeId, hideDecompositions } }: TCreatePrintModelImagesAction) {
    const modelSvg: ModelSvgImage | undefined = yield getSvg(nodeId, false, true, hideDecompositions, true);
    const modelSelectedSvg: ModelSvgImage | undefined = yield getSvg(nodeId, true, true, hideDecompositions);
    yield put(savePrintModelImages(modelSvg, modelSelectedSvg));
}

function* openImageDownloadSettings() {
    yield put(openDialog(DialogType.IMAGE_DOWNLOAD_SETTINGS_DIALOG));
}

function* saveGrayScaleToLocalStorage({ payload: { checked } }: TSaveGrayScaleToLocalStorageAction) {
    LocalStorageDaoService.setGrayScale(checked);
}

function* saveHideDecompositionToLocalStorage({ payload: { checked } }: TSaveHideDecompositionToLocalStorageAction) {
    LocalStorageDaoService.setHideDecomposition(checked);
}

export function* imageSagaInit() {
    yield takeEvery(SAVE_IMAGE, saveImage);
    yield takeEvery(SAVE_PDF, savePdf);
    yield takeEvery(SAVE_IMAGE_SVG, saveSvg);
    yield takeEvery(PRINT_MODEL, printModel);
    yield takeEvery(IMAGE_TO_CLIPBOARD, svgToClipboard);
    yield takeEvery(CREATE_PRINT_MODEL_IMAGES, createImages);
    yield takeEvery(OPEN_IMAGE_DOWNLOAD_SETTINGS, openImageDownloadSettings);
    yield takeEvery(SAVE_GRAY_SCALE, saveGrayScaleToLocalStorage);
    yield takeEvery(SAVE_HIDE_DECOMPOSITION, saveHideDecompositionToLocalStorage);
}
