import { defineMessages } from 'react-intl';

export default defineMessages({
    userList: {
        id: 'userList',
        defaultMessage: 'Список пользователей',
    },
    groupsOfUsers: {
        id: 'groupsOfUsers',
        defaultMessage: 'Группы пользователей',
    },
    licensesDistribution: {
        id: 'licensesDistribution',
        defaultMessage: 'Распределение лицензий',
    },
    accessesRights: {
        id: 'accessesRights',
        defaultMessage: 'Функциональные права',
    },
    dbAccess: {
        id: 'dbAccess',
        defaultMessage: 'Доступ к БД',
    },
    assignedLicenses: {
        id: 'assignedLicenses',
        defaultMessage: 'Назначенные лицензии',
    },
    titleCol: {
        id: 'titleCol',
        defaultMessage: 'Наименование',
    },
    userId: {
        id: 'userId',
        defaultMessage: 'ID',
    },
    userLogin: {
        id: 'userLogin',
        defaultMessage: 'Логин',
    },
    lastName: {
        id: 'lastName',
        defaultMessage: 'Фамилия',
    },
    firstName: {
        id: 'firstName',
        defaultMessage: 'Имя',
    },
    middleName: {
        id: 'middleName',
        defaultMessage: 'Отчество',
    },
    blockedColumn: {
        id: 'blockedColumn',
        defaultMessage: 'Статус',
    },
    blocked: {
        id: 'blocked',
        defaultMessage: 'Заблокирован',
    },
    notBlocked: {
        id: 'notBlocked',
        defaultMessage: 'Активный',
    },
    allStatuses: {
        id: 'allStatuses',
        defaultMessage: 'Все статусы',
    },
    userMainData: {
        id: 'userMainData',
        defaultMessage: 'Основные данные',
    },
    inGroups: {
        id: 'inGroups',
        defaultMessage: 'Состоит в группах',
    },
    loginPlaceholder: {
        id: 'loginPlaceholder',
        defaultMessage: 'Логин',
    },
    pswPlaceholder: {
        id: 'pswPlaceholder',
        defaultMessage: 'Пароль',
    },
    fnamePlaceholder: {
        id: 'fnamePlaceholder',
        defaultMessage: 'Имя',
    },
    lnamePlaceholder: {
        id: 'lnamePlaceholder',
        defaultMessage: 'Фамилия',
    },
    emailPlaceholder: {
        id: 'emailPlaceholder',
        defaultMessage: 'E-mail',
    },
    midllenamePlaceholder: {
        id: 'midllenamePlaceholder',
        defaultMessage: 'Отчество',
    },
    companyPlaceholder: {
        id: 'companyPlaceholder',
        defaultMessage: 'Компания',
    },
    departmentPlaceholder: {
        id: 'departmentPlaceholder',
        defaultMessage: 'Отдел',
    },
    positionPlaceholder: {
        id: 'positionPlaceholder',
        defaultMessage: 'Должность',
    },
    descPlaceholder: {
        id: 'descPlaceholder',
        defaultMessage: 'Описание',
    },
    noInfoAvailable: {
        id: 'noInfoAvailable',
        defaultMessage: 'Нет информации',
    },
    addBtnLabel: {
        id: 'addBtnLabel',
        defaultMessage: 'Добавить',
    },
    showSessionsLabel: {
        id: 'showSessionsLabel',
        defaultMessage: 'История сессий',
    },
    today: {
        id: 'today',
        defaultMessage: 'Сегодня',
    },
    yesterday: {
        id: 'yesterday',
        defaultMessage: 'Вчера',
    },
    beginSession: {
        id: 'beginSession',
        defaultMessage: 'Начало сессии',
    },
    endSession: {
        id: 'endSession',
        defaultMessage: 'Последнее действие',
    },
    deleteUserQuestion: {
        id: 'deleteUserQuestion',
        defaultMessage: 'Удалить пользователя?',
    },
    userFIO: {
        id: 'userFIO',
        defaultMessage: 'ФИО пользователя',
    },
    newUser: {
        id: 'newUser',
        defaultMessage: 'Новый пользователь',
    },
    usersGroupsColTitle: {
        id: 'usersGroupsColTitle',
        defaultMessage: 'Пользователи/группы',
    },
    accessesRight: {
        id: 'accessesRight',
        defaultMessage: 'Функциональное право',
    },
    dbTitle: {
        id: 'dbTitle',
        defaultMessage: 'База Данных',
    },
    adminsTitle: {
        id: 'adminsTitle',
        defaultMessage: 'Администраторы',
    },
    userProfiles: {
        id: 'userProfiles',
        defaultMessage: 'Профили пользователей',
    },
    editPrincipalServerProfile: {
        id: 'editPrincipalServerProfile',
        defaultMessage: 'Редактирование профиля',
    },
    agreement: {
        id: 'agreement',
        defaultMessage: 'Соглашение',
    },
    silaAgreement: {
        id: 'silaAgreement',
        defaultMessage: 'Соглашение SILA Union',
    },
    confirmedAgreements: {
        id: 'confirmedAgreements',
        defaultMessage: 'Подписанные лиц. соглашения',
    },
    delete: {
        id: 'delete',
        defaultMessage: 'Удалить',
    },
    block: {
        id: 'block',
        defaultMessage: 'Заблокировать',
    },
    unblock: {
        id: 'unblock',
        defaultMessage: 'Разблокировать',
    },
    deleteTitle: {
        id: 'deleteTitle',
        defaultMessage: 'Удаление пользователей',
    },
    blockTitle: {
        id: 'blockTitle',
        defaultMessage: 'Блокировка пользователей',
    },
    unblockTitle: {
        id: 'unblockTitle',
        defaultMessage: 'Разблокировка пользователей',
    },
    yes: {
        id: 'yes',
        defaultMessage: 'Да',
    },
    no: {
        id: 'no',
        defaultMessage: 'Нет',
    },
});
