// TODO использовать другой тип
import type { TWikiImage } from '@/models/tab.types';
import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import icImage from 'icons/toolbar/controls/ic-attach-image.svg';
import { Button } from '../../../Toolbar/Button.component';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';

const getChangedBlock = (editor: Editor, image: TWikiImage) =>
    !!image.src && editor.chain().focus().setImage(image).run();

export const ImageComponent = ({ openDialog, linkMapper }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TWikiImage>({ stateObserver, getChangedBlock });

    const onRevieveImage = (image: TWikiImage) => {
        setCurrentState({ ...image, src: linkMapper(image.src) });
    };

    return (
        <Button
            tooltipTitle={intl.formatMessage(messages.insertImage)}
            icon={icImage}
            onClick={() => openDialog(onRevieveImage)}
            dataTest="wiki-toolbar-group_add-image-button"
        />
    );
};
