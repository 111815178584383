import type { TTabComponentProps } from '@/modules/Workspace/Workspace.types';
import type { TWikiEditorProps } from '../components/WikiEditor.types';
import type { TDispatch } from '@/utils/types';
import { v4 as uuid } from 'uuid';
import WikiEditor from '../components/WikiEditor.component';
import { connect } from 'react-redux';
import { loadComments } from '@/actions/comments.actions';
import { wikiChangeRequest, wikiUploadImage } from '@/actions/entities/wiki.actions';
import { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { TRootState } from '@/reducers/root.reducer.types';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { CommentsSelectors } from '@/selectors/comments.selectors';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { openNodeById } from '@/actions/openNode.actions';
import { getEditorData, isNewEditorData } from '../WikiEditor.utils';
import { ExternalLinkBLLService } from '@/services/bll/ExternalLinkBLLService';
import { NodeId } from '../../../serverapi/api';

const mapStateToProps = (state: TRootState, ownProps: TTabComponentProps) => {
    const content = ownProps.tab.content as IWikiNode;
    const server = ServerSelectors.server(content.serverId)(state);
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';
    const { zoomLevel } = ownProps.tab.params;
    const commentsEnabledSchemesIds: NodeId[] = CommentsSelectors.commentsEnabledSchemesIds(state);
    const isShowCommentsPanel = commentsEnabledSchemesIds.some((id) => compareNodeIds(id, ownProps.tab.nodeId));
    const { nodeId } = ownProps.tab;

    return {
        id: 'WikiModel',
        value: getEditorData(content),
        isNewEditor: isNewEditorData(content),
        zoomLevel,
        baseUrl,
        isShowCommentsPanel,
        modelId: nodeId,
    };
};

const mapDispatchToProps = (dispatch: TDispatch, ownProps: TTabComponentProps) => {
    const { nodeId } = ownProps.tab;
    const content = ownProps.tab.content as IWikiNode;
    const isNewEditor = isNewEditorData(content);

    return {
        onRendered: () => {
            dispatch(loadComments(nodeId));
        },
        onChange: (value: string) => {
            const source = isNewEditor ? content.source : value;
            const source2 = !isNewEditor ? content.source2 : value;

            dispatch(wikiChangeRequest(nodeId, source, source2));
        },
        handlers: {
            imageLinkMapper: (src: string, baseUrl: string) =>
                src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`,
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
            openComment: (commentId: string, threadId: string, onSubmit: (threadId: string) => void) =>
                dispatch(
                    openDialog(DialogType.WRITE_COMMENT_DIALOG, {
                        modelId: nodeId,
                        parentId: threadId,
                        threadId,
                        commentId,
                        onSubmit,
                    }),
                ),
            openInternalLink: (href: string) => {
                const link = ExternalLinkBLLService.parseExternalLink(href, nodeId.serverId);

                dispatch(openNodeById({ nodeId: link.nodeId }));
            },
            uploadImage: (image: BlobPart, baseUrl: string) => {
                const id = uuid();
                const fileId = { ...nodeId, id };
                const file = new File([image], `${id}.png`);

                dispatch(wikiUploadImage({ file, fileId, modelId: nodeId }));

                return {
                    id: fileId.id,
                    src: `${baseUrl}/${fileId.repositoryId}/${fileId.id}/`,
                };
            },
        },
    };
};

const mergeProps = (ownProps, mapProps, dispatchProps): TWikiEditorProps => {
    const { handlers } = mapProps;
    const { baseUrl } = ownProps;

    const newMapProps = {
        ...mapProps,
        handlers: {
            ...handlers,
            imageLinkMapper: (src: string) => {
                return handlers.imageLinkMapper(src, baseUrl);
            },
            uploadImage: (image: BlobPart) => {
                return handlers.uploadImage(image, baseUrl);
            },
        },
    };

    return { ...ownProps, ...newMapProps, ...dispatchProps };
};

export const WikiEditorContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WikiEditor);
