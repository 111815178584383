// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchFilter__searchFilterContainer___Nk02{width:400px;margin-top:10px}", "",{"version":3,"sources":["webpack://./src/modules/Search/SearchFilter/SearchFilter.scss"],"names":[],"mappings":"AAAA,4CACI,WAAA,CACA,eAAA","sourcesContent":[".searchFilterContainer {\r\n    width: 400px;\r\n    margin-top: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFilterContainer": "SearchFilter__searchFilterContainer___Nk02"
};
export default ___CSS_LOADER_EXPORT___;
