export const MATRIX_OPEN = 'MATRIX_OPEN';

export const MATRIX_OPEN_BY_NODE_ID = 'MATRIX_OPEN_BY_NODE_ID';

export const MATRIX_CREATE = 'MATRIX_CREATE';

export const MATRIX_CREATE_SUCCESS = 'MATRIX_CREATE_SUCCESS';

export const MATRIX_REQUEST_SUCCESS = 'MATRIX_REQUEST_SUCCESS';

export const MATRIX_SAVE_REQUEST = 'MATRIX_SAVE_REQUEST';

export const MATRIX_SAVE_FROM_STORE_REQUEST = 'MATRIX_SAVE_FROM_STORE_REQUEST';

export const MATRIX_SAVE_REQUEST_SUCCESS = 'MATRIX_SAVE_REQUEST_SUCCESS';

export const MATRIX_SAVE_REQUEST_FAILURE = 'MATRIX_SAVE_REQUEST_FAILURE';

export const MATRIX_EDITOR_MODE_CHANGED = 'MATRIX_EDITOR_MODE_CHANGED';

export const CHANGE_MATRIX_PROPERTIES = 'CHANGE_MATRIX_PROPERTIES';

export const UPDATE_MATRIX_DATA = 'UPDATE_MATRIX_DATA';

export const REFRESH_MATRIX = 'REFRESH_MATRIX';
