export const WIKI_REQUEST_SUCCESS = 'WIKI_REQUEST_SUCCESS';

export const WIKI_OPEN_BY_ID = 'WIKI_OPEN_BY_ID';

export const WIKI_CHANGE_LOCALLY_REQUEST = 'WIKI_CHANGE_LOCALLY_REQUEST';

export const WIKI_CREATE = 'WIKI_CREATE';

export const WIKI_CREATE_SUCCESS = 'WIKI_CREATE_SUCCESS';

export const WIKI_CREATE_FAILURE = 'WIKI_CREATE_FAILURE';

export const WIKI_REMOVE_SUCCESS = 'WIKI_REMOVE_SUCCESS';

export const WIKI_COPY_TO_CLIPBOARD = 'WIKI_COPY_TO_CLIPBOARD';

export const WIKI_SET_IS_CLIPBOARD_AVAILABLE = 'WIKI_SET_IS_CLIPBOARD_AVAILABLE';

export const CHANGE_WIKI_DATA = 'CHANGE_WIKI_DATA';

export const WIKI_UPLOAD_IMAGE = 'WIKI_UPLOAD_IMAGE';
