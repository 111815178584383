import React, { FC } from 'react';
import theme from './Summary.scss';
import messages from '../widget.messages';
import { TSummaryData, TWidgetProps } from '../widget.types';
import { useIntl } from 'react-intl';

const initData = { data: '0', legend: '' };

export const Summary: FC<TWidgetProps> = ({ data: summaryData = initData, title }) => {
    const { data, legend } = summaryData as TSummaryData;
    const intl = useIntl();

    return (
        <div className={theme.summaryContainer}>
            <div className={theme.header}>{title}</div>

            {data ? (
                <div className={theme.summaryDataWrapper}>
                    <div className={theme.summaryData}>{data}</div>
                    <div className={theme.legend}>{legend}</div>
                </div>
            ) : (
                <div className={theme.noDataText}>{intl.formatMessage(messages.noData)}</div>
            )}
        </div>
    );
};
