import React, { useState } from 'react';
import { Input } from 'antd';
import theme from './EdgeType.scss';
import messages from './EdgeType.messages';
import { convertToNumber } from '../../../../../../utils/ConvertToNumber.utils';
import { useIntl } from 'react-intl';

type TInputEdgeSettingProps = {
    label: string;
    value: number;
    onChange: (value: number) => void;
    min: number;
    max: number;
    'data-test': string
};

export const InputEdgeSetting = (props: TInputEdgeSettingProps) => {
    const { label, min, max } = props;
    const [value, setValue] = useState(String(props.value));
    const intl = useIntl();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(convertToNumber(e.target.value));

    const onBlur = () => {
        const newValue = Number(value);

        if (newValue < min) {
            props.onChange(min);
            setValue(String(min));
        } else if (newValue > max) {
            props.onChange(max);
            setValue(String(max));
        } else {
            props.onChange(newValue);
            setValue(String(newValue));
        }
    };

    return (
        <div>
            <div className={theme.label}>{label}</div>
            <div data-test={props['data-test']}>
                <Input
                    className={theme.edgeSettingControl}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={intl.formatMessage(messages.inputEdgeSettingPlaceholder, {min, max})}
                />
            </div>
        </div>
    );
};
