// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableOfContents-styles__tableOfContents__bsMpg{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;grid-gap:.25rem;gap:.25rem;overflow:auto;text-decoration:none}.TableOfContents-styles__tableOfContents__bsMpg>span{display:-webkit-box;display:-ms-flexbox;display:flex;grid-gap:.25rem;gap:.25rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;border-radius:.25rem;-webkit-transition:all .2s cubic-bezier(0.65, 0.05, 0.36, 1);transition:all .2s cubic-bezier(0.65, 0.05, 0.36, 1)}.TableOfContents-styles__tableOfContents__bsMpg>span>span{color:var(--Neutral800)}.TableOfContents-styles__tableOfContents__bsMpg a{display:-webkit-box;display:-ms-flexbox;display:flex;text-decoration:none;font-weight:350}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/TipTapTextEditor/Editor/renderers/tableOfContents/TableOfContents.styles.scss"],"names":[],"mappings":"AAAA,gDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,eAAA,CAAA,UAAA,CACA,aAAA,CACA,oBAAA,CAEA,qDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,eAAA,CAAA,UAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,oBAAA,CACA,4DAAA,CAAA,oDAAA,CAEA,0DACI,uBAAA,CAIR,kDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CACA,eAAA","sourcesContent":[".tableOfContents {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 0.25rem;\r\n    overflow: auto;\r\n    text-decoration: none;\r\n\r\n    > span {\r\n        display: flex;\r\n        gap: 0.25rem;\r\n        width: fit-content;\r\n        border-radius: 0.25rem;\r\n        transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);\r\n\r\n        > span {\r\n            color: var(--Neutral800);\r\n        }\r\n    }\r\n\r\n    a {\r\n        display: flex;\r\n        text-decoration: none;\r\n        font-weight: 350;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableOfContents": "TableOfContents-styles__tableOfContents__bsMpg"
};
export default ___CSS_LOADER_EXPORT___;
