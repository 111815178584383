import { AuditDto, AuditRequest } from '@/serverapi/api';
import { ApiBundle } from '../api/api-bundle';
import { getOrigin, replaceLastSlash } from '../../utils/url.utils';
import { BrowserDaoService } from './BrowserDaoService';

export class AuditDAOService {
    private static getApi() {
        const hostName = replaceLastSlash(getOrigin());
        const bundle: ApiBundle = new ApiBundle(hostName);

        return bundle;
    }

    public static async loadAuditEvents(params: AuditRequest): Promise<Array<AuditDto>> {
        const api = this.getApi();
        const audit: AuditDto[] = await api.audit.loadAuditEvents({ body: params });

        return audit;
    }

    public static async loadAuditEventsCsvFile(params: AuditRequest): Promise<void> {
        const hostName: string = replaceLastSlash(getOrigin());
        const url: string = `${hostName}/api/audit/actions/loadAuditEventsCsvFile`;
        const response: Response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        });

        if (response.ok) {
            const blob = await response.blob();
            const contentDisposition: string | null = response.headers.get('Content-Disposition');

            const dialogOptions: Electron.SaveDialogOptions = {
                defaultPath: contentDisposition ? BrowserDaoService.getFilename(contentDisposition) : 'download.zip',
            };

            BrowserDaoService.downloadUrl(blob, dialogOptions, dialogOptions.defaultPath!);
        }
    }
}
