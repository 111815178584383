import React, { FC, useState } from 'react';
import theme from './MultiLangStringDialogComponent.scss';
import { Form, Input, Modal } from 'antd';
import multiLangMessages from '../../MultiLangInputDialog/MultiLangInputDialog.messages';
import footerMessages from '../../Footer/FooterButtons.messages';
import locales from '../../../static/locales/index.json';
import { AttributeValueString, InternationalString } from '../../../serverapi/api';
import { LANG } from '../../../config/config';
import { useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { useIntl } from 'react-intl';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { trimMultiLangValue } from './utils';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TMultiLangStringDialogComponent = {
    handleOk: (formValues: InternationalString) => void;
    record: AttributeValueString;
    allowEmptyValue?: boolean;
    curLocale?: string | undefined;
};

export const MultiLangStringDialogComponent: FC<TMultiLangStringDialogComponent> = ({
    handleOk,
    record,
    allowEmptyValue,
    curLocale,
}) => {
    const currentLocale = useSelector(getCurrentLocale);
    const intl = useIntl();
    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const showMultilingualModal = (multiLangValue: InternationalString | undefined) => {
        form.resetFields();
        form.setFieldsValue(trimMultiLangValue(multiLangValue));
        setIsModalVisible(true);
    };

    const onValidateFields = () => {
        form.validateFields()
            .then((formValues: InternationalString) => {
                handleOk({ ...formValues, [curLocale as string]: formValues?.[curLocale as string]?.trim() });
                setIsModalVisible(false);
                form.resetFields();
                // todo нужно ли всплывающее окно об ошибке?
            })
            .catch((e) => console.error('error', e));
    };

    const customFooter = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'back',
                    onClick: () => setIsModalVisible(false),
                    value: intl.formatMessage(footerMessages.cancel),
                    dataTest: 'translation-window_button-cancel',
                },
                {
                    key: 'ok',
                    onClick: onValidateFields,
                    value: intl.formatMessage(footerMessages.save),
                    visualStyle: 'primary',
                    dataTest: 'translation-window_button-ok',
                },
            ]}
        />
    );

    return (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    e.stopPropagation();
                    onValidateFields();
                }
            }}
        >
            <span className={theme.attribute_openMultiLangBtn} onClick={() => showMultilingualModal(record.str)}>
                <u data-test="element-properties_change-name-for-two-locale">({currentLocale.toUpperCase()})</u>
            </span>
            <Modal
                footer={customFooter}
                destroyOnClose
                title={intl.formatMessage(multiLangMessages.modalTitle)}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                className={theme.dialog}
            >
                <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} form={form}>
                    {locales.map((locale: keyof InternationalString) => {
                        const required = !allowEmptyValue && record.typeId === 'SYSTEM' && locale === currentLocale;

                        return (
                            <Form.Item
                                name={locale}
                                key={locale}
                                label={LANG[locale]}
                                className={theme.formItem}
                                rules={[
                                    {
                                        whitespace: required,
                                        required,
                                        message: intl.formatMessage(multiLangMessages.requiredField),
                                    },
                                ]}
                            >
                                <Input className={theme.input} />
                            </Form.Item>
                        );
                    })}
                </Form>
            </Modal>
        </div>
    );
};
