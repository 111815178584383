import { TReducer } from '../utils/types';
import { ModelType, ModelTypeGroup } from '../serverapi/api';
import { MODEL_TYPE_REQUEST_SUCCESS } from '../actionsTypes/modelType.actionTypes';
import { TModelTypeState } from './modelType.reducer.types';

export const INITIAL_MODEL_TYPE_SERVER_STATE = {
    byId: {},
    groupsById: {},
};

const initial: TModelTypeState = {
    byServerId: {},
};

export const modelTypeReducer: TReducer<TModelTypeState> = (state = initial, action) => {
    switch (action.type) {
        // case ADD_MODEL_TYPE: {
        //      const { payload: { serverId } } = action;
        //      return {
        //          byServerId: {
        //              ...state.byServerId,
        //              [serverId]: {
        //                  ...INITIAL_MODEL_TYPE_SERVER_STATE,
        //                  ...state.byServerId[serverId]
        //              }
        //          }
        //      };
        //  }

        case MODEL_TYPE_REQUEST_SUCCESS: {
            const {
                payload: { serverId, modelTypes, presetId },
            } = action;
            const modelTypesMap = modelTypes.reduce(
                (result: {}, item: ModelType) => ({ ...result, [item.id]: item }),
                {},
            );

            const groups = (state[serverId] && state[serverId][presetId] && state[serverId][presetId].groupsById) || {};

            if (action.payload.groups) {
                let { groups } = action.payload;
                groups = {
                    ...groups,
                    ...groups.reduce((result: {}, item: ModelTypeGroup) => ({ ...result, [item.id]: item }), {}),
                };
            }

            return {
                ...state,
                byServerId: {
                    ...state.byServerId,
                    [serverId]: {
                        ...(state.byServerId[serverId] || {}),
                        [presetId]: {
                            byId: {
                                // ...((state.byServerId[serverId] &&
                                //     state.byServerId[serverId][presetId] &&
                                //     state.byServerId[serverId][presetId].byId) ||
                                //     {}),

                                // типы моделей из предыдущего состояния вставлять не нужно т.к. предполагается что в данном
                                // экшене типы либо приходят с сервера либо полностью подменяются все типы после сохранения пресета
                                // но возможно что-то где-то сломается
                                ...modelTypesMap,
                            },
                            groupsById: groups,
                        },
                    },
                },
            };
        }
        default:
            return state;
    }
};
