import React from 'react';
import messages from './ApprovalDialog.messages';
import { useIntl } from 'react-intl';
import { Form, InputNumber, Radio, RadioChangeEvent } from 'antd';
import { ApprovalRateOfSuccess } from './ApprovalDialog.types';
import { StagesBllService } from '@/services/bll/ApprovalDialogStagesBLLService';
import { TRateAndVoteStrategyProps } from './StageSettingsTab.types';

export const RateOfSuccessComponent = ({
    selectedStage,
    isEditingDisabled,
    isTemplate,
    stageFormRef,
    onChangeStages,
    stages,
    stageId,
}: TRateAndVoteStrategyProps) => {
    const intl = useIntl();

    const onRateOfSuccessTypeChange = (e: RadioChangeEvent) => {
        const value = e.target.value === ApprovalRateOfSuccess.ALL_POSITIVE ? 100 : null;
        onRateOfSuccessChange(value);

        const fieldsValue = stageFormRef.current?.getFieldsValue();
        if (fieldsValue) {
            fieldsValue.RateOfSuccess = value;
            stageFormRef.current?.setFieldsValue(fieldsValue);
        }
    };

    const onRateOfSuccessChange = (rate: number | null) => {
        const rateOfSuccess: number | undefined = rate === null ? undefined : rate;
        if (rate === 100) {
            const fieldsValue = stageFormRef.current?.getFieldsValue();
            if (fieldsValue) {
                fieldsValue.RateOfSuccessType = ApprovalRateOfSuccess.ALL_POSITIVE;
                stageFormRef.current?.setFieldsValue(fieldsValue);
            }
        }

        const stageList = StagesBllService.changeRateOfSuccess(stages, stageId, rateOfSuccess);

        onChangeStages(stageList);
    };

    return (
        <>
            <Form.Item
                label={intl.formatMessage(messages.rateOfSuccessLabel)}
                initialValue={
                    selectedStage.rateOfSuccess === 100
                        ? ApprovalRateOfSuccess.ALL_POSITIVE
                        : ApprovalRateOfSuccess.PERCENTAGE
                }
                name="RateOfSuccessType"
            >
                <Radio.Group
                    disabled={!isTemplate && isEditingDisabled}
                    onChange={onRateOfSuccessTypeChange}
                    value={
                        selectedStage.rateOfSuccess === 100
                            ? ApprovalRateOfSuccess.ALL_POSITIVE
                            : ApprovalRateOfSuccess.PERCENTAGE
                    }
                >
                    <Radio value={ApprovalRateOfSuccess.PERCENTAGE}>{intl.formatMessage(messages.percentage)}</Radio>
                    <Radio value={ApprovalRateOfSuccess.ALL_POSITIVE}>
                        {intl.formatMessage(messages.allVotedPositive)}
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                initialValue={selectedStage.rateOfSuccess}
                name="RateOfSuccess"
                required
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage(messages.thisFieldIsRequired),
                    },
                ]}
            >
                <InputNumber
                    placeholder={intl.formatMessage(messages.selectPositiveVotePercentage)}
                    data-test={isTemplate ? 'approval-template-percentage-input' : 'approval-percentage-input'}
                    size="middle"
                    min={1}
                    max={100}
                    value={selectedStage.rateOfSuccess}
                    controls={false}
                    onChange={onRateOfSuccessChange}
                    disabled={selectedStage.rateOfSuccess === 100 || (!isTemplate && isEditingDisabled)}
                />
            </Form.Item>
        </>
    );
};
