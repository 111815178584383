import { TAppState } from '@/reducers/app.reducer.types';
import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';

const getState = (state: TRootState) => state.app;

export const getStatus = createSelector(getState, (state) => state.status);

export const getIsStarted = createSelector(getState, (state) => state.isStarted);

export const getFullModelDefinition = createSelector(getState, (state) => state.fullModelDefinition);

export const getAppColor = createSelector(getState, (state) => state.appColor);

export const getIsEnLocaleAllowed = createSelector<TRootState, TAppState, boolean>(getState, (state: TAppState) => state.isEnLocaleAllowed);
