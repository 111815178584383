import React from 'react';
import theme from './DeletedElementsTable.scss';
import { Checkbox } from '../UIKit/components/Checkbox/Checkbox.component';

type THeadCheckBoxProps = {
    isCheckedAll: boolean;
    handleChange: () => void;
};

export const HeadCheckBox = (props: THeadCheckBoxProps): JSX.Element => {
    const { isCheckedAll, handleChange } = props;

    return (
        <div className={theme.headerCheckBoxContainer}>
            <Checkbox checked={isCheckedAll} onChange={handleChange} />
        </div>
    );
};
