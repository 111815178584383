import { PrincipalDescriptor } from "@/serverapi/api";

export const getDisplayName = (user: PrincipalDescriptor): string => {
    const { lastname, name, middlename, login } = user;

    if (!lastname && !name && !middlename) {

        return login;
    }

    return [lastname, name, middlename].filter((el) => !!el).join(' ');
};

export const sortUsers = (users: PrincipalDescriptor[]): PrincipalDescriptor[] => {
    return users.slice().sort((a, b) => {
        const nameA = getDisplayName(a).toLowerCase();
        const nameB = getDisplayName(b).toLowerCase();

        return nameA.localeCompare(nameB);
    });
};
