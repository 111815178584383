import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ObjectDefinitionImpl } from '../../../../../models/bpm/bpm-model-impl';
import { ColumnType } from 'antd/es/table';
import messages from '../SelectExistingDefinitionDialogs.messages';
import { useSelector } from 'react-redux';
import { SelectObjectDefinitionDialogSelectors } from '../../../../../selectors/selectObjectDefinitionDialog.selectors';
import { TObjectDataSourceRow } from '../SelectExistingDefinitionsDialogs.types';

export const useObjectTableData = (
    objects: ObjectDefinitionImpl[],
    nameRenderer: (obj: ObjectDefinitionImpl) => JSX.Element,
    onClick: (key: string) => void,
) => {
    const intl = useIntl();
    const { pathsMap } = useSelector(SelectObjectDefinitionDialogSelectors.selectObjectDefinitionDialogState);

    const columns: ColumnType<string>[] = useMemo(
        () => [
            {
                title: intl.formatMessage(messages.name),
                dataIndex: 'name',
                width: '40%',
            },
            {
                title: intl.formatMessage(messages.path),
                dataIndex: 'path',
                width: '40%',
            },
            {
                title: intl.formatMessage(messages.author),
                dataIndex: 'author',
            },
        ],
        [],
    );

    const dataSource: TObjectDataSourceRow[] = useMemo(
        () =>
            objects.map((objectDef) => ({
                key: objectDef.nodeId.id,
                name: nameRenderer(objectDef),
                path: pathsMap.get(objectDef.nodeId.id) || '',
                author: objectDef.createdBy,
            })),
        [objects, pathsMap, nameRenderer],
    );

    const onRow = useCallback((record: TObjectDataSourceRow) => ({ onClick: () => onClick(record.key) }), [onClick]);

    return { columns, dataSource, onRow };
};
