import { InputNumber, Modal, Radio, Tabs } from 'antd';
import React, { useState } from 'react';
import cx from 'classnames';
import theme from './KanbanCardEditor.scss';
import { KanbanHorizontalAlign, KanbanSizeType, KanbanVerticalAlign } from '../../../../../../../models/kanban.types';
import { COLUMN_NUMBER_TO_NAME_MAP } from '../../util/KanbanCardEditor.utils';
import { KanbanCardGridSettings, KanbanCardSectorSettings } from '../../../../../../../serverapi/api';
import { useIntl } from 'react-intl';
import messages from './KanbanCardEditor.messages';
import { DialogFooterButtons } from '../../../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TDialogProps = {
    open: boolean;
    onSubmit: (gridSettings: KanbanCardGridSettings, sectorSettings: KanbanCardSectorSettings[] | null) => void;
    onClose: () => void;
    resetSelectedSector: () => void;
    gridSettings: KanbanCardGridSettings;
    sectorSettings: KanbanCardSectorSettings[];
    id: string;
};

type TGridSectionsType = 'rows' | 'columns';

export const KanbanCardGridControlDialog = (props: TDialogProps) => {
    const { onClose, onSubmit, open, gridSettings, sectorSettings, id, resetSelectedSector } = props;
    const intl = useIntl();
    const [cardGridSettings, setCardGridSettings] = useState<KanbanCardGridSettings>(gridSettings);
    const [cardSectionSettings, setCardSectionSettings] = useState<KanbanCardSectorSettings[]>(sectorSettings);
    const [columnsCount, setColumnsCount] = useState<number>(gridSettings.columns.length);
    const [rowsCount, setRowsCount] = useState<number>(gridSettings.rows.length);

    const onOk = () => {
        const isColRowCountChanged =
            gridSettings.columns.length !== columnsCount || gridSettings.rows.length !== rowsCount;
        const newSectionSettings = isColRowCountChanged ? cardSectionSettings : null;

        if (isColRowCountChanged) resetSelectedSector();

        onSubmit(cardGridSettings, newSectionSettings);
        onClose();
    };

    const onValueTypeChange = (e, gridSectionsType: TGridSectionsType): void => {
        const id = e.target.id;
        const newType = e.target.value;
        const newValue = (document.querySelector(`[data-radio=${id}]`) as HTMLInputElement)?.value;

        const newSettingsValue = {
            ...cardGridSettings,
            [gridSectionsType]: cardGridSettings[gridSectionsType].map((item) => {
                if (item.name !== e.target.name) return item;
                if (gridSectionsType === 'columns')
                    return {
                        ...item,
                        type: newType,
                        value: newValue ? +newValue : item.value,
                    };

                return newValue
                    ? { name: item.name, type: newType, value: +newValue }
                    : { name: item.name, type: newType };
            }),
        };
        setCardGridSettings(newSettingsValue);
    };

    const onValueChange = (value: number, sectionName: string, gridSectionsType: TGridSectionsType) => {
        const newSettingsValue = {
            ...cardGridSettings,
            [gridSectionsType]: cardGridSettings[gridSectionsType].map((item) => {
                if (item.name !== sectionName) return item;
                return {
                    ...item,
                    value,
                };
            }),
        };
        setCardGridSettings(newSettingsValue);
    };

    const updateCardSectionSettings = (
        gridSectionsType: TGridSectionsType,
        newColumnsCount: number,
        newRowsCount: number,
    ) => {
        let newCardSectionSettings = [...cardSectionSettings];

        if (gridSectionsType === 'columns') {
            const isColumnAdded = newColumnsCount > columnsCount;
            for (let index = 1; index <= newRowsCount; index++) {
                if (isColumnAdded) {
                    newCardSectionSettings.push({
                        name: `${COLUMN_NUMBER_TO_NAME_MAP[newColumnsCount]}${index}`,
                        position: {
                            start: {
                                column: newColumnsCount,
                                row: index,
                            },
                            end: {
                                column: newColumnsCount,
                                row: index,
                            },
                        },
                        styles: {
                            verticalAlign: KanbanVerticalAlign.CENTER,
                            horizontalAlign: KanbanHorizontalAlign.CENTER,
                            padding: {
                                top: 5,
                                right: 5,
                                bottom: 5,
                                left: 5,
                            },
                            backgroundColor: '#f5f5f5',
                            border: {
                                top: true,
                                right: true,
                                bottom: true,
                                left: true,
                                color: '#b3b3b3',
                                width: 2,
                            },
                        },
                    });
                } else {
                    newCardSectionSettings = newCardSectionSettings.filter(
                        ({ position: { start } }) => start.column !== columnsCount || start.row !== index,
                    );
                }
            }
            return setCardSectionSettings(newCardSectionSettings);
        }

        const isRowAdded = newRowsCount > rowsCount;
        for (let index = 1; index <= newColumnsCount; index++) {
            if (isRowAdded) {
                newCardSectionSettings.push({
                    name: `${COLUMN_NUMBER_TO_NAME_MAP[index]}${newRowsCount}`,
                    position: {
                        start: {
                            column: index,
                            row: newRowsCount,
                        },
                        end: {
                            column: index,
                            row: newRowsCount,
                        },
                    },
                    styles: {
                        verticalAlign: KanbanVerticalAlign.CENTER,
                        horizontalAlign: KanbanHorizontalAlign.CENTER,
                        padding: {
                            top: 5,
                            right: 5,
                            bottom: 5,
                            left: 5,
                        },
                        backgroundColor: '#f5f5f5',
                        border: {
                            top: true,
                            right: true,
                            bottom: true,
                            left: true,
                            color: '#b3b3b3',
                            width: 2,
                        },
                    },
                });
            } else {
                newCardSectionSettings = newCardSectionSettings.filter(
                    ({ position: { start } }) => start.column !== index || start.row !== rowsCount,
                );
            }
        }

        return setCardSectionSettings(newCardSectionSettings);
    };

    const onGridSectionsCountChange = (value: number, gridSectionsType: TGridSectionsType): void => {
        let newGridSectionsValue;

        if (cardGridSettings[gridSectionsType].length > value) {
            newGridSectionsValue = cardGridSettings[gridSectionsType].slice(0, -1);
        } else {
            const newRadioValue: string =
                gridSectionsType === 'columns' ? COLUMN_NUMBER_TO_NAME_MAP[value] : `${value}`;
            const newInputValue = (
                document.querySelector(`[data-radio=card-${id}-section-${newRadioValue}-absolute]`) as HTMLInputElement
            )?.value;

            newGridSectionsValue = [
                ...cardGridSettings[gridSectionsType],
                {
                    name: newRadioValue,
                    value: newInputValue ? +newInputValue : 1,
                    type: KanbanSizeType.ABSOLUTE,
                },
            ];
        }

        const updatedColsCount = gridSectionsType === 'columns' ? value : columnsCount;
        const updatedRowsCount = gridSectionsType === 'rows' ? value : rowsCount;
        updateCardSectionSettings(gridSectionsType, updatedColsCount, updatedRowsCount);

        setCardGridSettings({ ...cardGridSettings, [gridSectionsType]: newGridSectionsValue });

        if (gridSectionsType === 'columns') return setColumnsCount(value);
        setRowsCount(value);
    };

    const getGridSectionInputNumberDefaultValue = (
        index: number,
        type: string,
        gridSectionsType: TGridSectionsType,
    ): number => {
        const gridSection = cardGridSettings[gridSectionsType][index];
        if (gridSectionsType === 'rows') return gridSection?.value || 1;
        return gridSection?.type === type ? gridSection.value! : 1;
    };

    const showColumns = () =>
        Object.values(COLUMN_NUMBER_TO_NAME_MAP).map((name, index) => {
            const isColumnDisabled = cardGridSettings.columns.length < index + 1;
            const radioRelativeId = `card-${id}-section-${name}-relative`;
            const radioAbsoluteId = `card-${id}-section-${name}-absolute`;

            return (
                <div className={theme.gridDialogSection} key={name}>
                    <div
                        className={cx(
                            theme.gridDialogSectionName,
                            isColumnDisabled && theme.gridDialogSectionNameDisabled,
                        )}
                    >{`${intl.formatMessage(messages.column)} ${name}`}</div>
                    <div className={theme.gridDialogFormItem}>
                        <Radio.Group
                            name={name}
                            disabled={isColumnDisabled}
                            onChange={(e) => onValueTypeChange(e, 'columns')}
                            value={cardGridSettings.columns[index]?.type}
                        >
                            <Radio
                                value={KanbanSizeType.RELATIVE}
                                className={theme.gridDialogRadio}
                                id={radioRelativeId}
                            >
                                <label htmlFor={radioRelativeId}>
                                    {intl.formatMessage(messages.adaptiveWidth)} (%)
                                </label>
                                <InputNumber
                                    min={1}
                                    max={100}
                                    disabled={
                                        isColumnDisabled ||
                                        cardGridSettings.columns[index]?.type === KanbanSizeType.ABSOLUTE
                                    }
                                    defaultValue={getGridSectionInputNumberDefaultValue(
                                        index,
                                        KanbanSizeType.RELATIVE,
                                        'columns',
                                    )}
                                    onChange={(value) => onValueChange(value!, name, 'columns')}
                                    data-radio={radioRelativeId}
                                />
                            </Radio>
                            <Radio
                                value={KanbanSizeType.ABSOLUTE}
                                className={theme.gridDialogRadio}
                                id={radioAbsoluteId}
                            >
                                <label htmlFor={radioAbsoluteId}>{intl.formatMessage(messages.fixedWidth)} (px)</label>
                                <InputNumber
                                    min={1}
                                    max={500}
                                    disabled={
                                        isColumnDisabled ||
                                        cardGridSettings.columns[index]?.type === KanbanSizeType.RELATIVE
                                    }
                                    defaultValue={getGridSectionInputNumberDefaultValue(
                                        index,
                                        KanbanSizeType.ABSOLUTE,
                                        'columns',
                                    )}
                                    onChange={(value) => onValueChange(value!, name, 'columns')}
                                    data-radio={radioAbsoluteId}
                                />
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            );
        });

    const showRows = () =>
        Object.keys(COLUMN_NUMBER_TO_NAME_MAP).map((name, index) => {
            const isRowDisabled = cardGridSettings.rows.length < index + 1;
            const radioRelativeId = `card-${id}-section-${name}-relative`;
            const radioAbsoluteId = `card-${id}-section-${name}-absolute`;

            return (
                <div className={theme.gridDialogSection} key={name}>
                    <div
                        className={cx(
                            theme.gridDialogSectionName,
                            isRowDisabled && theme.gridDialogSectionNameDisabled,
                        )}
                    >{`${intl.formatMessage(messages.row)} ${name}`}</div>
                    <div className={theme.gridDialogFormItem}>
                        <Radio.Group
                            name={`${name}`}
                            disabled={isRowDisabled}
                            onChange={(e) => onValueTypeChange(e, 'rows')}
                            value={cardGridSettings.rows[index]?.type}
                        >
                            <Radio value={KanbanSizeType.RELATIVE} id={radioRelativeId}>
                                <label htmlFor={radioRelativeId}>{intl.formatMessage(messages.adaptiveHeight)}</label>
                            </Radio>
                            <Radio
                                value={KanbanSizeType.ABSOLUTE}
                                id={radioAbsoluteId}
                                className={theme.gridDialogRadio}
                            >
                                <label htmlFor={radioAbsoluteId}>{intl.formatMessage(messages.fixedHeight)} (px)</label>
                                <InputNumber
                                    min={1}
                                    max={500}
                                    disabled={
                                        isRowDisabled || cardGridSettings.rows[index]?.type === KanbanSizeType.RELATIVE
                                    }
                                    defaultValue={getGridSectionInputNumberDefaultValue(
                                        index,
                                        KanbanSizeType.ABSOLUTE,
                                        'rows',
                                    )}
                                    onChange={(value) => onValueChange(value!, name, 'rows')}
                                    data-radio={radioAbsoluteId}
                                />
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            );
        });

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onOk,
                    value: intl.formatMessage(messages.save),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Modal
            open={open}
            footer={footer}
            onOk={onOk}
            onCancel={onClose}
            title={intl.formatMessage(messages.cardGridControl)}
            width="600px"
            className={theme.gridControlDialog}
        >
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab={intl.formatMessage(messages.columns)} key="1">
                    <div className={theme.gridDialogFormItem}>
                        <label htmlFor="grid-columns-input">{intl.formatMessage(messages.columnsCount)}</label>
                        <InputNumber
                            id="grid-columns-input"
                            min={1}
                            max={4}
                            value={columnsCount}
                            onChange={(val) => onGridSectionsCountChange(val!, 'columns')}
                        />
                    </div>
                    {showColumns()}
                </Tabs.TabPane>

                <Tabs.TabPane tab={intl.formatMessage(messages.rows)} key="2">
                    <div className={theme.gridDialogFormItem}>
                        <label htmlFor="grid-rows-input">{intl.formatMessage(messages.rowsCount)}</label>
                        <InputNumber
                            id="grid-rows-input"
                            min={1}
                            max={4}
                            value={rowsCount}
                            onChange={(val) => onGridSectionsCountChange(val!, 'rows')}
                        />
                    </div>
                    {showRows()}
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};
