import React from 'react';
import theme from '../ApprovalsTable/approvalTable.scss';
import { useSelector } from 'react-redux';
import { Checkbox } from '../../UIKit/components/Checkbox/Checkbox.component';
import { ApprovalTemplatesSelectors } from '@/selectors/approvalTemplates.selectors';

type TRowCheckBoxProps = {
    id: string;
    handleChange: (data: string) => void;
};

export const RowCheckBox = (props: TRowCheckBoxProps): JSX.Element => {
    const { handleChange, id } = props;
    const isChecked: boolean = useSelector(ApprovalTemplatesSelectors.isApprovalTemplatesChecked(id));
 
    return (
        <div className={theme.checkBoxContainer}>
            <Checkbox
                onChange={() => handleChange(id)}
                checked={isChecked}
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
            />
        </div>
    );
};
