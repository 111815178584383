import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { FileUploadDialog } from '../components/FileUploadDialog.component';
import { editorDragFile } from '../../../actions/navigatorSymbol.actions';

const mapStateToProps = () => ({ filters: '.jpg, .gif, .png' });

const mapDispatchToProps = (dispatch) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.IMAGE_EDIT_DIALOG));
    },
    onSubmit: (file: File) => {
        dispatch(editorDragFile(file));
        dispatch(closeDialog(DialogType.IMAGE_EDIT_DIALOG));
    },
});

export const ImageUploadDialogContainer = connect(mapStateToProps, mapDispatchToProps)(FileUploadDialog);
