import type { TGeneralToolbarProps } from '@/modules/MainMenu/components/items/GeneralMenuItem/GeneralMenuItem.types';
import type { TWikiEditorToolbarProps } from '../components/WikiEditor.types';
import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import type { TRootState } from '@/reducers/root.reducer.types';
import type { TDispatch } from '@/utils/types';
import { connect } from 'react-redux';
import { openDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { v4 as uuid } from 'uuid';
import WikiEditorToolbar from '../components/WikiEditorToolbar.component';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { ServerSelectors } from '@/selectors/entities/server.selectors';
import { PictureSymbolConstants } from '@/models/pictureSymbolConstants';
import { isNewEditorData } from '../WikiEditor.utils';

const mapStateToProps = (state: TRootState, props: TGeneralToolbarProps) => {
    const activeTab = TabsSelectors.getActiveTab(state);
    const content = activeTab?.content as IWikiNode;
    const server = ServerSelectors.server(content.serverId)(state);
    const baseUrl = server?.url ? `${server.url}/${PictureSymbolConstants.DOWNLOAD_LINK}` : '';

    return {
        ...props,
        id: 'WikiModel',
        baseUrl,
        isNewEditor: isNewEditorData(content),
    };
};

const mapDispatchToProps = (dispatch: TDispatch, ownProps: TGeneralToolbarProps) => {
    const { nodeId } = ownProps;

    return {
        handlers: {
            openImageDialog: (onSubmit) => {
                dispatch(
                    openDialog(DialogType.IMAGE_EDIT_DIALOG_WIKI, {
                        id: uuid(),
                        workspaceId: nodeId,
                        onSubmit,
                    }),
                );
            },
            openLinkDialog: (onSubmit, link) => {
                const { url, text, external } = link || {};
                const { serverId } = nodeId;

                dispatch(openDialog(DialogType.LINK_DIALOG, { serverId, onSubmit, url, text, external }));
            },
            readFromClipboard: (callback) => {
                navigator.clipboard.readText().then(callback);
            },
            copyToClipboard: (text: string) => {
                navigator.clipboard.writeText(text);
            },
            imageLinkMapper: (src, baseUrl) => (src?.startsWith('data:image/') ? src : `${baseUrl}/${src}/`),
        },
    };
};

const mergeProps = (ownProps, mapProps, dispatchProps): TWikiEditorToolbarProps => {
    const { handlers } = mapProps;
    const { baseUrl } = ownProps;

    const newMapProps = {
        ...mapProps,
        handlers: {
            ...handlers,
            imageLinkMapper: (src: string) => handlers.imageLinkMapper(src, baseUrl),
        },
    };

    return { ...ownProps, ...newMapProps, ...dispatchProps };
};

export const WikiEditorToolbarContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(WikiEditorToolbar);
