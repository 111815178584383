// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClearJournal__container__pU_ha{height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin:10px}.ClearJournal__rangePicker__iekfY{width:400px;text-align:left;margin:10px 0 10px}.ClearJournal__rangePicker__iekfY div{margin-bottom:0;min-height:0}.ClearJournal__inputEl__lHNb6{width:300px;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/SafetySettings/ClearJournal/ClearJournal.scss"],"names":[],"mappings":"AACA,gCACI,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,4BAAA,CAAA,yBAAA,CAAA,qBAAA,CACA,WAAA,CAEJ,kCACI,WAAA,CACA,eAAA,CACA,kBAAA,CACA,sCACI,eAAA,CACA,YAAA,CAIR,8BACI,WAAA,CACA,eAAA","sourcesContent":["\r\n.container {\r\n    height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    margin: 10px;\r\n}\r\n.rangePicker {\r\n    width: 400px;\r\n    text-align: left;\r\n    margin: 10px 0 10px;\r\n    div {\r\n        margin-bottom: 0;\r\n        min-height: 0;\r\n    }\r\n}\r\n\r\n.inputEl {\r\n    width: 300px;\r\n    margin-bottom: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ClearJournal__container__pU_ha",
	"rangePicker": "ClearJournal__rangePicker__iekfY",
	"inputEl": "ClearJournal__inputEl__lHNb6"
};
export default ___CSS_LOADER_EXPORT___;
