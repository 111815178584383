let experimentalFeatures: boolean = false;

/**
 * Класс отвечающий за работу отключаемой функциональности в приложении
 */
export class ExperimentalFeatures {
    public static enable(): void {
        experimentalFeatures = true;
    }

    public static isEnable(): boolean {
        return experimentalFeatures;
    }
}
