import { TEditModelTypeWorkspaceTabState } from './editModelTypeWorkspaceTab.reducers.types';
import { AttributeType, AttributeTypeGroup, ModelEdgeDefinition, ModelType, Symbol } from '../../../serverapi/api';
import { TWorkspaceTabStoreActions } from '../../../actions/workspaceTab/editModelTypeWorkspaceTab.actions.types';
import {
    ADD_ATTRIBUTE_TYPES,
    ADD_MODEL_TYPE_EDGES,
    ADD_SYMBOLS_MODEL_TYPE,
    CHANGE_MODEL_TYPE_DESCRIPTION,
    CHANGE_MODEL_TYPE_ENABLE_MODEL,
    CHANGE_MODEL_TYPE_GROUP,
    CHANGE_MODEL_TYPE_GROUP_SYMBOL,
    CHANGE_MODEL_TYPE_ID,
    CHANGE_MODEL_TYPE_NAME,
    ADD_STORE_EDIT_MODEL_TYPE_WORKSPACE_TAB,
    DELETE_MODEL_TYPE_EDGES,
    DELETE_SYMBOL_EDIT_MODEL_TYPE,
    REMOVE_ATTRIBUTE_TYPES,
    REMOVE_ATTRIBUTE_TYPES_GROUPS,
    CHANGE_MODEL_TYPE_ALLOW_ANY_OBJECT,
    CHANGE_MODEL_TYPE_SYNONYMS_IDS,
    CHANGE_MODEL_TYPE_ALLOW_APPROVALS,
    CHANGE_MODEL_TYPE_AUTO_CREATE_EDGE,
    CHANGE_MODEL_TYPE_ALLOW_DRAW_INVISIBLE_EDGES,
} from '../../../actionsTypes/workspaceTab/editModelTypeWorkspaceTab.actionTypes';

const initial: TEditModelTypeWorkspaceTabState = {} as TEditModelTypeWorkspaceTabState;

const setNewData = (
    state: TEditModelTypeWorkspaceTabState,
    serverId: string,
    presetId: string,
    modelTypeId: string,
    modelType: ModelType,
): TEditModelTypeWorkspaceTabState => {
    return {
        ...state,
        [serverId]: {
            ...state[serverId],
            [presetId]: {
                ...state[serverId]?.[presetId],
                [modelTypeId]: modelType,
            },
        },
    };
};

const getModelType = (
    state: TEditModelTypeWorkspaceTabState,
    serverId: string,
    presetId: string,
    modelTypeId: string,
): ModelType => {
    return { ...state[serverId][presetId][modelTypeId] };
};

export const editModelTypeWorkspaceTabReducer = (
    state = initial,
    action: TWorkspaceTabStoreActions,
): TEditModelTypeWorkspaceTabState => {
    switch (action.type) {
        case ADD_STORE_EDIT_MODEL_TYPE_WORKSPACE_TAB: {
            const { modelType, presetId, serverId } = action.payload;

            return setNewData(state, serverId, presetId, modelType.id, modelType);
        }

        case DELETE_SYMBOL_EDIT_MODEL_TYPE: {
            const { serverId, presetId, modelTypeId } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const newSymbols: Symbol[] = modelType.symbols.filter(
                (s: Symbol) => !action.payload.symbols.some((sym) => sym.id === s.id),
            );

            const changedModelType: ModelType = { ...modelType, symbols: newSymbols };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_NAME: {
            const { serverId, presetId, modelTypeId, multilingualName } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, multilingualName };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_ID: {
            const { serverId, presetId, modelTypeId, id } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, id };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_SYNONYMS_IDS: {
            const { serverId, presetId, modelTypeId, synonymsIds } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, synonymsIds };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_DESCRIPTION: {
            const { serverId, presetId, modelTypeId, multilingualDescription } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, multilingualDescription };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_GROUP: {
            const { serverId, presetId, modelTypeId, modelTypeGroup } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, modelTypeGroup, groupId: modelTypeGroup.id };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_GROUP_SYMBOL: {
            const { serverId, presetId, modelTypeId, groupSymbol } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, groupSymbol };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_ENABLE_MODEL: {
            const { serverId, presetId, modelTypeId, enableModel } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, enableModel };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_ALLOW_ANY_OBJECT: {
            const { serverId, presetId, modelTypeId, allowAnyObject } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, allowAnyObject };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case ADD_ATTRIBUTE_TYPES: {
            const { serverId, presetId, modelTypeId, attributeTypes } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const newAttributes: AttributeType[] = (modelType.attributes || []).concat(attributeTypes);

            const changedModelType: ModelType = { ...modelType, attributes: newAttributes };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case REMOVE_ATTRIBUTE_TYPES: {
            const { serverId, presetId, modelTypeId, attributeTypes } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const newAttributeTypes: AttributeType[] | undefined = modelType.attributes?.filter(
                (at: AttributeType) => !attributeTypes.some((a) => a.id === at.id),
            );

            const changedModelType: ModelType = { ...modelType, attributes: newAttributeTypes };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case REMOVE_ATTRIBUTE_TYPES_GROUPS: {
            const { serverId, presetId, modelTypeId, attributeTypeGroups } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const newAttributeTypes: AttributeTypeGroup[] | undefined = attributeTypeGroups
                ? modelType.attributes?.filter(
                      (at: AttributeType) => !attributeTypeGroups.some((atg) => at.attributeTypeGroup?.id === atg.id),
                  )
                : [];

            const changedModelType: ModelType = { ...modelType, attributes: newAttributeTypes };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case ADD_SYMBOLS_MODEL_TYPE: {
            const { serverId, presetId, modelTypeId, symbols } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, symbols };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case DELETE_MODEL_TYPE_EDGES: {
            const { serverId, presetId, modelTypeId, modelEdgeDefinitions } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const newModelTypeEdges = modelType.modelEdgeDefinitions.filter(
                (definition: ModelEdgeDefinition) => !modelEdgeDefinitions.some((e) => e.id === definition.id),
            );

            const changedModelType: ModelType = { ...modelType, modelEdgeDefinitions: newModelTypeEdges };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case ADD_MODEL_TYPE_EDGES: {
            const { serverId, presetId, modelTypeId, modelEdgeDefinitions } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, modelEdgeDefinitions };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_ALLOW_APPROVALS: {
            const { serverId, presetId, modelTypeId, allowApprovals } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, allowApprovals };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_AUTO_CREATE_EDGE: {
            const { serverId, presetId, modelTypeId, autoCreateEdge } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, autoCreateEdge };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        case CHANGE_MODEL_TYPE_ALLOW_DRAW_INVISIBLE_EDGES: {
            const { serverId, presetId, modelTypeId, allowDrawInvisibleEdges } = action.payload;

            const modelType: ModelType = getModelType(state, serverId, presetId, modelTypeId);

            const changedModelType: ModelType = { ...modelType, allowDrawInvisibleEdges };

            return setNewData(state, serverId, presetId, modelTypeId, changedModelType);
        }

        default: {
            return state;
        }
    }
};
