import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';
import cx from 'classnames';
import { Icon } from '../../../../../UIKit/components/Icon/Icon.component';
import menuItemTheme from '../../../items/MenuItem.scss';
import { TSpriteSymbol } from '../../../../../../models/spriteSymbol.types';

interface IToolbarButtonProps {
    tooltipTitle: string;
    disabled: boolean | undefined;
    icon: TSpriteSymbol;
    text?: string;
    onClick: () => void;
    dataTest?: string;
    containerDataTest?: string;
}

export const ToolbarButton: FC<IToolbarButtonProps> = (props) => {
    const { tooltipTitle, text, disabled, icon, dataTest = '', containerDataTest = '', onClick } = props;
    const containerTestAttr = containerDataTest ? { 'data-test': containerDataTest } : {};
    const testAttr = dataTest ? { 'data-test': dataTest } : {};

    return (
        <span {...containerTestAttr}>
            <Tooltip placement="top" title={tooltipTitle} overlayStyle={{ maxWidth: '350px' }} mouseLeaveDelay={0}>
                <span
                    {...testAttr}
                    className={cx(menuItemTheme.buttonWrapper, disabled && menuItemTheme.buttonDisabled)}
                >
                    <Button onClick={onClick} disabled={disabled}>
                        <Icon spriteSymbol={icon} disabled={disabled} />
                        {text && <span className="title">{text}</span>}
                    </Button>
                </span>
            </Tooltip>
        </span>
    );
};
