import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Table } from 'antd';
import messages from '../../messages/PrincipalAttributeDialog.messages';
import theme from './PrincipalAttributeDialog.scss';
import { sortByAlpha } from '../../../../utils/sortByAlpha';
import { TUserListFullProps, TUserListTableRecord } from './UserList.types';
import { PrincipalDescriptor } from '../../../../serverapi/api';
import UserLogin from './UserLogin.component';
import { DELETED_USER_ID, getUserFIO } from '../utils';

class UserList extends Component<TUserListFullProps> {
    getColumns = () => {
        const { intl } = this.props;
        return [
            {
                title: intl.formatMessage(messages.userLogin),
                dataIndex: 'login',
                key: 'login',
                width: 200,
                sorter: (a: TUserListTableRecord, b: TUserListTableRecord) => sortByAlpha(a.login, b.login),
                render: (text = '', user: TUserListTableRecord) => <UserLogin text={text} user={user} withGap />,
            },
            {
                title: intl.formatMessage(messages.userFIO),
                dataIndex: 'fio',
                key: 'fio',
                width: 200,
                sorter: (a: TUserListTableRecord, b: TUserListTableRecord) => sortByAlpha(a.fio, b.fio),
            },
        ];
    };

    getData = (rawData: PrincipalDescriptor[]): TUserListTableRecord[] => {
        return rawData.map((item) => ({
            key: item.login,
            id: item.id,
            login: item.login,
            fio: getUserFIO(item),
            blocked: item.blocked,
        }));
    };

    addRowClassName = (user: TUserListTableRecord) => {
        const isDeleted = user.id === DELETED_USER_ID;
        const isBlocked = !isDeleted && user.blocked;

        if (this.props.readOnly) {
            return 'disabled-row';
        }

        return isDeleted || isBlocked ? 'blocked-or-deleted' : '';
    };

    render() {
        const rowSelection = {
            selectedRowKeys: this.props.selected,
            preserveSelectedRowKeys: true,
            hideSelectAll: this.props.readOnly,
            onChange: this.props.onSelectChange,
        };

        return (
            <Table
                className={theme.dataTable}
                size="middle"
                rowSelection={this.props.readOnly ? undefined : rowSelection}
                bordered
                columns={this.getColumns()}
                dataSource={this.getData(this.props.data)}
                pagination={false}
                rowClassName={this.addRowClassName}
                scroll={{ y: 400 }}
            />
        );
    }
}

export default injectIntl(UserList);
