
export const defaultAttributeIds: string[] = [
    'id',
    'name',
    'multilingualName',
    'objectType',
    'language',
    'createdAt',
    'createdBy',
    'updatedAt',
    'updatedBy',
    'modelTypeId',
    'confidential',
    'direction',
    'edgeType',
    'endArrow',
    'lineType',
    'linkedObjectTypes',
    'startArrow',
    'folderType',
    'userEditDisabled',
    'scriptEngineEditDisabled',
];

export const MULTILINGUAL_NAME = 'multilingualName';

export const maxFileSizeLimit = 250;