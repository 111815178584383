import { put, select, takeEvery } from 'redux-saga/effects';
import { TUndoAction } from '@/actions/undeRedo.actions.types';
import { TUndoRedoData } from '@/reducers/undoRedo.reducer.types';
import { UndoRedoSelectors } from '@/selectors/undoRedo.selectors';
import { updateMatrixData } from '@/modules/Matrix/actions/matrix.actions';
import { REDO_ACTION, UNDO_ACTION } from '@/actionsTypes/undoRedo.actionTypes';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { CHANGE_MATRIX_PROPERTIES } from '@/modules/Matrix/actionTypes/matrix.actionTypes';
import { TMatrixChangePropertiesAction } from '@/modules/Matrix/actions/matrix.actions.types';
import { addStateAction } from '@/actions/undoRedo.actions';

function* handleUndoRedo(action: TUndoAction) {
    const { nodeId } = action.payload;
    const undoRedoData: TUndoRedoData | undefined = yield select(UndoRedoSelectors.getUndoRedoStates(nodeId));

    if (!undoRedoData) return;

    const { states, currentIndex, nodeType } = undoRedoData;
    const currentState = states[currentIndex];

    if (!currentState) return;

    if (nodeType === TreeItemType.Matrix) {
        yield put(updateMatrixData(nodeId, currentState));
    }
}

function* handleMatrixChange(action: TMatrixChangePropertiesAction) {
    const { matrix } = action.payload;

    if (matrix.data2) {
        yield put(addStateAction(matrix.nodeId, matrix.data2, matrix.type));
    }
}

export function* undoRedoSaga() {
    yield takeEvery(UNDO_ACTION, handleUndoRedo);
    yield takeEvery(REDO_ACTION, handleUndoRedo);
    yield takeEvery(CHANGE_MATRIX_PROPERTIES, handleMatrixChange);
}
