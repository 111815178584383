import React, { FC } from 'react';
import theme from './SummarySourceInput.scss';
import { Col, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { SummaryOperations, TWidget, TWidgetDataset, UpperCaseAlp } from '../../Dashboard.types';
import { setWidgetSource } from '@/actions/dashboard.actions';
import { useIntl } from 'react-intl';
import messages from './SummarySourceInput.messages';
import { SourceTableSelect } from '../SourceTableSelect/SourceTableSelect';

const initDatasete: TWidgetDataset[] = [{ col1: '', col2: '', legend: '' }];

export const SummarySourceInput: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const currentWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(activeTab?.nodeId));

    const dataSet: TWidgetDataset = currentWidget?.source?.dataset?.length
        ? currentWidget?.source?.dataset[0]
        : initDatasete[0];

    const setDataSourceHandler = (value: string, key: keyof TWidgetDataset) => {
        if (!activeTab || !currentWidget || !currentWidget.source) return;
        const isString = key === 'legend' || key === 'summaryOperation';
        const newValue = isString ? value : value.toUpperCase();
        if (Object.keys(UpperCaseAlp).includes(newValue) || isString) {
            const newDataSet = { ...dataSet };
            if (newDataSet) {
                //@ts-ignore
                newDataSet[key] = newValue;
                dispatch(
                    setWidgetSource(activeTab.nodeId, currentWidget.id, {
                        ...currentWidget.source,
                        dataset: [newDataSet],
                    }),
                );
            }
        }
    };

    const options = (Object.keys(SummaryOperations) as SummaryOperations[]).map((key) => {
        return {
            value: key,
            label: intl.formatMessage(messages[key]),
        };
    });

    return (
        <>
            <SourceTableSelect
                nodeId={activeTab?.nodeId}
                widgetId={currentWidget?.id}
                tableName={currentWidget?.source?.name}
            />
            {currentWidget?.source && (
                <div>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.sourceCol)}</div>
                        <Input
                            value={dataSet.col1 || ''}
                            onChange={(e) => setDataSourceHandler(e.currentTarget.value, 'col1')}
                            className={theme.input}
                        />
                    </Col>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.operation)}</div>
                        <Select
                            onChange={(value) => setDataSourceHandler(value, 'summaryOperation')}
                            value={dataSet.summaryOperation}
                            options={options}
                            className={theme.select}
                        />
                    </Col>
                    <Col className={theme.inputContainer}>
                        <div className={theme.label}>{intl.formatMessage(messages.legend)}</div>
                        <Input
                            value={dataSet.legend || ''}
                            onChange={(e) => setDataSourceHandler(e.currentTarget.value, 'legend')}
                            className={theme.input}
                        />
                    </Col>
                </div>
            )}
        </>
    );
};
