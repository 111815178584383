import { Form, Input, Radio, RadioChangeEvent } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { scriptCreate } from '../../../actions/entities/script.actions';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { NodeId } from '../../../serverapi/api';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import messages from './ScriptDialog.messages';
import theme from './ScriptDialog.scss';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

enum ScriptNodeLanguageEnum {
    GROOVY = 'GROOVY',
    JS = 'JS',
    GROOVY_DEBUG = 'GROOVY_DEBUG',
    KOTLIN = 'KOTLIN',
    KOTLIN_DEBUG = 'KOTLIN_DEBUG',
}

type TScriptCreateDialogProps = WrappedComponentProps & {
    parentId: NodeId;
};

type TFormValues = {
    scriptName: string;
};

const ScriptDialog: FC<TScriptCreateDialogProps> = (props) => {
    const { intl, parentId } = props;
    const [form] = Form.useForm();
    const GROOVY_DEBUG_POSTFIX: string = '[debug]';
    const KOTLIN_DEBUG_POSTFIX: string = '[debug_kotlin]';
    const { JS, GROOVY, GROOVY_DEBUG, KOTLIN, KOTLIN_DEBUG } = ScriptNodeLanguageEnum;
    const [lang, setLang] = useState<ScriptNodeLanguageEnum>(GROOVY);
    const dispatch = useDispatch();

    const handleChangeLang = (e: RadioChangeEvent) => {
        setLang(e.target.value);
    };

    const handleSubmit = () => {
        form.validateFields()
            .then((values: TFormValues) => {
                const { scriptName } = values;
                let language: ScriptNodeLanguageEnum;

                if (scriptName.endsWith(GROOVY_DEBUG_POSTFIX)) {
                    language = GROOVY_DEBUG;
                } else if (scriptName.endsWith(KOTLIN_DEBUG_POSTFIX)) {
                    language = KOTLIN_DEBUG;
                } else {
                    language = lang;
                }

                dispatch(
                    scriptCreate({
                        name: scriptName,
                        parentNodeId: parentId,
                        language,
                    }),
                );
            })
            .catch(() => undefined);
    };

    const onCancelHandler = () => {
        dispatch(closeDialog(DialogType.SCRIPT_CREATE_DIALOG));
    };

    const children = (
        <Form form={form} layout="vertical">
            <Form.Item
                label={<FormattedMessage {...messages.scriptNameLabel} />}
                required
                name="scriptName"
                rules={[
                    {
                        whitespace: true,
                        required: true,
                        message: intl.formatMessage(messages.scriptNameRequiredError),
                    },
                ]}
            >
                <Input
                    data-test="new-skript-dialog_name"
                    placeholder={intl.formatMessage(messages.scriptNamePlaceholder)}
                />
            </Form.Item>
            <Radio.Group value={lang} className={theme.radio} onChange={handleChangeLang}>
                <Radio data-test="new-skript-dialog_radio-groovy" value={GROOVY}>
                    Groovy
                </Radio>
                <Radio data-test="new-skript-dialog_radio-kotlin" value={KOTLIN}>
                    Kotlin
                </Radio>
                <Radio data-test="new-skript-dialog_radio-js" value={JS}>
                    JavaScript
                </Radio>
            </Radio.Group>
        </Form>
    );

    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onCancelHandler,
                    value: intl.formatMessage(messages.scriptFormDeclineButton),
                },
                {
                    key: 'ok',
                    onClick: handleSubmit,
                    value: intl.formatMessage(messages.scriptFormConfirmButton),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog
            onOk={handleSubmit}
            onCancel={onCancelHandler}
            title={intl.formatMessage(messages.scriptFormName)}
            open
            width={DEFAULT_DIALOG_WIDTH}
            footer={footer}
        >
            {children}
        </Dialog>
    );
};

const ScriptDialogWithIntl = injectIntl(ScriptDialog);

export { ScriptDialogWithIntl as ScriptDialog };
