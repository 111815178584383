import * as React from 'react';
import { Dialog } from '../../UIKit/components/Dialog/Dialog.component';
import { injectIntl } from 'react-intl';
import messages from '../messages/FileUploadDialog.messages';
import { Input, Upload } from 'antd';
import { Icon as UIKitIcon } from '../../UIKit/components/Icon/Icon.component';
import theme from './FileUploadDialog.scss';
import iconOk from '../../../resources/icons/Symbol_OK.svg';
import UploadSVG from '../../../resources/icons/Upload.svg';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { TWikiImage } from '../../../models/tab.types';
import { TFileUploadDialogProps } from '../types/FileUploadDialog.types';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

const { Dragger } = Upload;

type TState = {
    file?: File | File[];
    serverId: string;
    repositoryId: string;
    alt: string | null | undefined;
    title: string | undefined;
};

class FileUploadDialog extends React.Component<TFileUploadDialogProps, TState> {
    state: TState = {
        serverId: this.props.serverId,
        repositoryId: '',
        alt: this.props.formInitData?.alt,
        title: this.props.formInitData?.title,
    };

    isSubmitEnabled = (): boolean => {
        return !!this.state.file;
    };

    handleSubmit = () => {
        const { id, isWiki, onSubmit } = this.props;
        const { serverId, repositoryId, alt, title, file } = this.state;

        if (isWiki) {
            onSubmit({
                src: `${repositoryId}/${id}`,
                alt,
                title,
            } as TWikiImage);
        } else if (file) {
            onSubmit(file, serverId);
        }
    };

    dropFile = (e: UploadRequestOption) => {
        const { id, uploadFile, content } = this.props;

        if (this.props.isWiki && content) {
            this.setState({
                repositoryId: content?.nodeId.repositoryId,
            });
            uploadFile?.(e.file as File, { ...content.nodeId, id }, content.nodeId);
        }

        const { multiple = false } = this.props;

        if (multiple) {
            this.setState((state) => {
                if (!Array.isArray(state.file)) {
                    return {
                        file: [e.file as File],
                    };
                }

                return { file: [...state.file, e.file as File] };
            });
        } else if (e.file) {
            this.setState({
                file: e.file as File,
            });
        }

        const submitBtn: HTMLButtonElement | null = document.querySelector('[data-test="dragger_submit-button"]');

        if (submitBtn?.focus) submitBtn.focus();
    };

    beforeUpload = () => {
        this.setState(() => ({ file: undefined }));

        return true;
    };

    public render() {
        const { onClose, open, intl, filters, multiple = false } = this.props;
        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: onClose,
                        value: intl.formatMessage(messages.cancelButton),
                        dataTest: 'dragger_cancel-button',
                    },
                    {
                        key: 'ok',
                        onClick: this.handleSubmit,
                        value: intl.formatMessage(messages.okButton),
                        visualStyle: 'primary',
                        dataTest: 'dragger_submit-button',
                        disabled: !this.isSubmitEnabled(),
                    },
                ]}
            />
        );

        return (
            <Dialog
                className={theme.dialog}
                onCancel={onClose}
                open={open}
                title={intl.formatMessage(messages.dialogTitle)}
                footer={footer}
            >
                <div className={theme.uploadArea}>
                    <Dragger
                        name="file"
                        multiple={multiple}
                        showUploadList={false}
                        customRequest={this.dropFile}
                        accept={filters || ''}
                        className={theme.dragger}
                        beforeUpload={this.beforeUpload}
                    >
                        {this.state.file ? (
                            <UIKitIcon className={theme.uploadedSvgIcon} spriteSymbol={iconOk} />
                        ) : (
                            <div className={theme.uploadAreaTextWrapper}>
                                <div className={theme.uploadAreaTitle}>
                                    <span className={theme.uploadAreaTitleIcon}>
                                        <UIKitIcon className={theme.uploadSvg} spriteSymbol={UploadSVG} />
                                    </span>
                                    <span className={theme.uploadAreaTitleText}>
                                        {intl.formatMessage(messages.dialogUploadAreaMessage)}
                                    </span>
                                </div>
                            </div>
                        )}
                    </Dragger>
                </div>
                {this.props.isWiki ? (
                    <div>
                        <div className={theme.fieldsContainer}>
                            {intl.formatMessage(messages.altLabel)}
                            <Input
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({
                                        alt: e.currentTarget.value,
                                    })
                                }
                                value={this.state?.alt || ''}
                                className={theme.input}
                            />
                        </div>
                        <div className={theme.fieldsContainer}>
                            {intl.formatMessage(messages.titleLabel)}
                            <Input
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({
                                        title: e.currentTarget.value,
                                    })
                                }
                                value={this.state?.title || ''}
                                className={theme.input}
                                data-test="dragger_file-downloaded"
                            />
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(FileUploadDialog);

export { IntlComponent as FileUploadDialog };
