import { instancesBPMMxGraphMap } from '@/mxgraph/bpm-mxgraph-instance-map';
import { BPMMxKeyHandler } from '@/mxgraph/handler/BPMMxKeyHandler';
import { NodeId } from '@/serverapi/api';
import { useEffect } from 'react';

type TKeys = 'ctrlKey' | 'altKey' | 'shiftKey';

export const useKeyDownListener = (keys: TKeys[], code: string, func: (...args: any) => void, garphId?: NodeId) => {
    useEffect(() => {
        const listener = (evt: KeyboardEvent) => {
            let isAllKeysPresed = true;

            keys.forEach((key) => {
                isAllKeysPresed = isAllKeysPresed && evt[key];
            });

            if (isAllKeysPresed && evt.code === code) {
                func();
            }
        };

        let keyHandler: BPMMxKeyHandler;

        if (garphId) {
            const graph = instancesBPMMxGraphMap.get(garphId);
            keyHandler = new BPMMxKeyHandler(graph, document.querySelector('body'));

            keyHandler.getFunction = (evt: KeyboardEvent) => {
                if (evt != null) {
                    return keyHandler.codes.get(evt.code);
                }

                return null;
            };

            keyHandler.bindCode(code, listener);
        } else {
            document.addEventListener('keydown', listener);
        }

        return () => {
            if (keyHandler) keyHandler.destroy();
            document.removeEventListener('keydown', listener);
        };
    }, []);
};
