import type { TWithWindowResizeProps } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.types';
import type { IWorkspaceTabItemModelEditParams, TWorkspaceTab } from '../../../../../../models/tab.types';
import type { AttributeType, AttributeTypeGroup, MatrixType, ModelTypeGroup } from '../../../../../../serverapi/api';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from './MatrixModelType.messages';
import presetMessages from '../../../messages/Presets.messages';
import { Form, FormInstance, Tabs } from 'antd';
import { MatrixModelTypeGeneralTab } from './MatrixModelTypeGeneralTab/MatrixModelTypeGeneralTab.component';
import { AttributesTab } from '../AttributeType/AttributesTab.component';
import { FooterButtons } from '@/modules/Footer/FooterButtons.component';
import footerMessages from '@/modules/Footer/FooterButtons.messages';
import { AttributeTypeSelectors } from '../../../../../../selectors/attributeType.selectors';
import { workspaceRemoveTabByNodeId } from '../../../../../../actions/tabs.actions';
import { WorkspaceTabDataSelectors } from '../../../../../../selectors/workspaceTabData.selectors';
import {
    matrixModelTypeAddAttributeTypes,
    changeMatrixModelTypeGroup,
    matrixModelTypeRemoveAttributeTypes,
    matrixModelTypeRemoveAttributeTypesGroups,
} from '../../../../../../actions/workspaceTab/editMatrixModelTypeWorkspaceTab.actions';
import { withWindowResize } from '../../../../../UIKit/H.O.C/withWindowResize/withWindowResize.hoc';
import theme from '../Presets.scss';
import { showNotificationByType } from '../../../../../../actions/notification.actions';
import { NotificationType } from '../../../../../../models/notificationType';
import { ModelTypeGroupSelectors } from '../../../../../../selectors/modelTypeGroup.selectors';
import { LocalesService } from '../../../../../../services/LocalesService';
import { createButtonsCompositeDataTestId } from '../util/createButtonsCompositeDataTestId.utils';
import type { Tab } from 'rc-tabs/lib/interface';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { submitMatrixModelType } from '@/actions/presetSettings/presetSettingsMatrixModelType.actions';
import { TSubmitMatrixModelTypePayload } from '@/actions/presetSettings/presetSettingsMatrixModelType.actions.types';
import { TFooterButtonProps } from '@/modules/Footer/FooterButtons.types';

type TMatrixModelTypeEditorProps = TWithWindowResizeProps & {
    tab: TWorkspaceTab;
};

const MatrixModelTypeEditorComponent: FC<TMatrixModelTypeEditorProps> = (props) => {
    const params = props.tab.params as IWorkspaceTabItemModelEditParams;
    const { serverNode, preset, createMode, modelTypeId } = params;
    const { serverId } = serverNode.nodeId;
    const presetId = preset.id;

    const currentLocale = useSelector(getCurrentLocale);

    const modelType: MatrixType | undefined = useSelector(
        WorkspaceTabDataSelectors.getEditMatrixModelType(presetId, modelTypeId),
    );
    const modelTypeGroups: ModelTypeGroup[] = useSelector(
        ModelTypeGroupSelectors.byPresetIdExcludeDeleted({
            serverId,
            presetId: preset.id,
        }),
    );
    const availableAttributeTypes: AttributeType[] =
        useSelector(AttributeTypeSelectors.allInPreset(serverId, preset.id)) || [];

    const [saveDisabled, setSaveDisabled] = useState<boolean>(!modelType?.groupId);

    const formRef = useRef<FormInstance>(null);
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!modelType) {
            dispatch(showNotificationByType(NotificationType.MODEL_TO_EDIT_NOT_FOUND));
        }
    }, [modelType]);

    const onChangeModelTypeGroup = useCallback((modelTypeGroup: ModelTypeGroup) => {
        dispatch(changeMatrixModelTypeGroup({ presetId, modelTypeId, modelTypeGroup }));

        setSaveDisabled(!modelTypeGroup);
    }, []);

    const sendAttributeTypes = useCallback((attributeTypes: AttributeType[]) => {
        dispatch(matrixModelTypeAddAttributeTypes({ presetId, modelTypeId, attributeTypes }));
    }, []);

    const deleteAttributeTypes = useCallback(
        (attributeTypes: AttributeType[]) => {
            dispatch(matrixModelTypeRemoveAttributeTypes({ presetId, modelTypeId, attributeTypes }));
        },
        [modelType],
    );

    const deleteAttributeTypeGroups = useCallback(
        (attributeTypeGroups: AttributeTypeGroup[]) => {
            dispatch(matrixModelTypeRemoveAttributeTypesGroups({ presetId, modelTypeId, attributeTypeGroups }));
        },
        [modelType],
    );

    const deleteQuestion: string = intl.formatMessage(presetMessages.deleteModel, {
        name: LocalesService.internationalStringToString(modelType?.multilingualName, currentLocale),
    });

    const buttons: TFooterButtonProps[] = [
        {
            children: intl.formatMessage(footerMessages.cancel),
            onClick: () => dispatch(workspaceRemoveTabByNodeId(props.tab.nodeId)),
            dataTest: 'methodology-model-type-editor_cancel-btn',
        },
        {
            children: createMode ? intl.formatMessage(footerMessages.create) : intl.formatMessage(footerMessages.save),
            visualStyle: 'primary',
            disabled: saveDisabled,
            onClick: () => {
                const form = formRef.current;
                if (form) {
                    form.validateFields()
                        .then(() => {
                            const payload: TSubmitMatrixModelTypePayload = {
                                requestBody: { body: modelType },
                                presetId: preset.id,
                                createMode,
                                needTabClose: true,
                                tabNodeId: props.tab.nodeId,
                            };
                            dispatch(submitMatrixModelType(payload));
                        })
                        .catch(() => undefined);
                }
            },
            dataTest: 'methodology-model-type-editor_save-btn',
        },
    ];

    const tabs: Tab[] = [
        {
            label: <span>{intl.formatMessage(messages.generalSettings)}</span>,
            key: 'MatrixModeltTypeGeneralSettings',
            children: (
                <div className={theme.tabContent}>
                    <Form ref={formRef} layout="vertical">
                        <MatrixModelTypeGeneralTab
                            modelType={modelType}
                            modelTypeGroups={modelTypeGroups}
                            createMode={createMode}
                            onChangeModelTypeGroup={onChangeModelTypeGroup}
                            generalForm={formRef.current!}
                        />
                    </Form>
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'general-settings_tab')}
                    />
                </div>
            ),
            disabled: createMode,
        },
        {
            label: <span>{intl.formatMessage(messages.attributes)}</span>,
            key: 'MatrixModelTypeAttributes',
            children: (
                <div className={theme.tabContent}>
                    <AttributesTab
                        attributeTypes={modelType?.attributes || []}
                        availableAttributeTypes={availableAttributeTypes}
                        addAttributeTypes={sendAttributeTypes}
                        deleteAttributeTypes={deleteAttributeTypes}
                        deleteAttributeTypeGroups={deleteAttributeTypeGroups}
                    />
                    <FooterButtons
                        deleteQuestion={deleteQuestion}
                        buttons={createButtonsCompositeDataTestId(buttons, 'attributes_tab')}
                    />
                </div>
            ),
            disabled: createMode,
        },
    ];

    return (
        <div className={theme.container}>
            {modelType ? (
                <>
                    <span className={theme.navigationTitle}>{`${preset.name} > ${intl.formatMessage(
                        messages.typeModel,
                    )} > ${LocalesService.internationalStringToString(
                        modelType?.multilingualName,
                        currentLocale,
                    )}`}</span>
                    <Tabs
                        className={theme.tabs}
                        tabPosition="left"
                        defaultActiveKey="MatrixModeltTypeGeneralSettings"
                        items={tabs}
                    />
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default withWindowResize(MatrixModelTypeEditorComponent);
