import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import modelTypeMessages from '../../../models/modelType.messages';
import { setFilterData } from '../../../actions/search.actions';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { NodeId } from '../../../serverapi/api';
import messages from './SearchFilter.messages';
import style from './SearchFilter.scss';
import { useIntl } from 'react-intl';
import { Select } from '@/modules/UIKit/components/Select/Select.component';

export const SearchFilter = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const filterUniqueType = useSelector(SearchSelectors.getFilterUniqueType());
    const { id }: NodeId = useSelector(SearchSelectors.getNodeId);
    const currentMarkedElem: string[] = useSelector(SearchSelectors.getFilter);

    const onChangeByType = (types: string[]) => {
        dispatch(setFilterData({ id, filter: types }));
    };

    return (
        <div className={style.searchFilterContainer}>
            <Select
                isMultiSelect
                value={intl.formatMessage(messages.filter)}
                onChange={onChangeByType}
                data-test="search_search-filter"
            >
                {filterUniqueType.map((type) => (
                    <Select.Option
                        key={type}
                        value={type}
                        checked={currentMarkedElem.includes(type)}
                        label={intl.formatMessage(modelTypeMessages[type])}
                    />
                ))}
            </Select>
        </div>
    );
};
