import React, { useCallback } from 'react';
import { Radio, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../Header/header.types';
import messages from './LocaleSelect.messages';

type TLocaleSelectProps = {
    locale: Locale;
    onLocaleSelect: (locale: Locale) => void;
    isEnLocaleAllowed: boolean;
};

export const LocaleSelect = (props: TLocaleSelectProps) => {
    const intl = useIntl();

    const setLocale = useCallback(
        (locale: Locale) => {
            return () => {
                props.onLocaleSelect(locale);
            };
        },
        [props.onLocaleSelect],
    );

    return (
        <Radio.Group value={props.locale}>
            <Space direction="vertical">
                <Radio data-test="locale-ru" onChange={setLocale(Locale.ru)} value={Locale.ru}>
                    {intl.formatMessage(messages.russian)}
                </Radio>
                <Radio disabled={!props.isEnLocaleAllowed} data-test="locale-en" onChange={setLocale(Locale.en)} value={Locale.en}>
                    English
                </Radio>
            </Space>
        </Radio.Group>
    );
};
