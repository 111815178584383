import type { IWikiNode } from '@/models/bpm/bpm-model-impl.types';
import { isUndefined } from 'lodash-es';

export const WIKI_SOURCE_PROP_NAME = 'source2';

export const getEditorData = (content?: IWikiNode): string | undefined => {
    return content?.[WIKI_SOURCE_PROP_NAME] || content?.source;
};

export const isNewEditorData = (content?: IWikiNode): boolean => {
    return !isUndefined(content?.[WIKI_SOURCE_PROP_NAME]);
};
