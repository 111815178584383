import { Alert, Radio } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ImportPresetRequestImportPresetResolutionEnum } from '../../../../../serverapi/api';
import { ConflictResolutionStrategy } from '../../../../../services/api/custom/TransferApi';
import { Dialog } from '../../../../UIKit/components/Dialog/Dialog.component';
import { TImportPresetDialogProps, TImportPresetDialogState } from './ImportPresetDialog.types';
import messages from './ImportPresetDialog.messages';
import theme from './ImportPresetDialog.scss';
import { DialogFooterButtons } from '../../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

class ImportPresetDialog extends React.Component<TImportPresetDialogProps, TImportPresetDialogState> {
    state = {
        resolutionStrategy: ConflictResolutionStrategy.REPLACE,
    };

    handleMethodChange = (event) => {
        this.setState({
            resolutionStrategy: event.target.value as ImportPresetRequestImportPresetResolutionEnum,
        });
    };

    onHandleOk = () => {
        this.props.onSubmit(
            this.state.resolutionStrategy,
            this.props.serverNode,
            this.props.fileName,
            this.props.process,
        );
    };

    render() {
        const { intl } = this.props;
        const footer = (
            <DialogFooterButtons
                buttons={[
                    {
                        key: 'cancel',
                        onClick: this.props.onCancel,
                        value: intl.formatMessage(messages.cancelButton),
                    },
                    {
                        key: 'ok',
                        onClick: this.onHandleOk,
                        value: intl.formatMessage(messages.conflictDialogForNodeButton),
                        visualStyle: 'primary',
                    },
                ]}
            />
        );

        return (
            <Dialog
                open
                title={`${intl.formatMessage(messages.dialogLabel)} ${this.props.conflictingPreset.name}`}
                closable={false}
                onOk={this.onHandleOk}
                onCancel={this.props.onCancel}
                footer={footer}
            >
                {/* todo 2979 удалить алерт */}
                {this.props.error && <Alert type="error" message={intl.formatMessage(this.props.error)} showIcon />}
                <Radio.Group
                    value={this.state.resolutionStrategy}
                    className={theme.radio}
                    onChange={this.handleMethodChange}
                    data-test="import_conflict"
                >
                    <Radio value={ConflictResolutionStrategy.REPLACE}>
                        {intl.formatMessage(messages.conflictDialogReplaceRadio)}
                    </Radio>
                    <Radio value={ConflictResolutionStrategy.CLONE}>
                        {intl.formatMessage(messages.conflictDialogCloneRadio)}
                    </Radio>
                </Radio.Group>
            </Dialog>
        );
    }
}

const IntlComponent = injectIntl(ImportPresetDialog);

export { IntlComponent as ImportPresetDialog };
